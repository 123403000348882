import React, { } from "react";
import MyTextField from "../../../components/TextField/MyTextField";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import MySwicth from "../../../components/Switch/MySwitch";
import MyTextFieldV2 from "../../../components/TextField/MyTextFieldv2";
import { HourGlass02 } from "untitledui-js/icons/time";
import { Send01 } from "untitledui-js/icons/communication";

const GeneralSetting = () => {
    return (
        <>
            <form>
                <div className="flex flex-col gap-y-6 p-8 rounded-xl bg-gray-light/50 w-full max-w-[977px]">
                    <section className="flex flex-col gap-y-6">
                        <div className="flex flex-col gap-1">
                            <p className="text-lg-semibold text-gray-light/900">General settings</p>
                            <p className="text-sm-regular text-gray-light/600">We may still send you important notifications about your account outside of your notification settings.</p>
                        </div>
                        <hr className="border-gray-light/200" />
                    </section>
                    <div className="flex flex-col gap-y-5">
                        <section className="flex items-start gap-x-16">
                            <div className="w-full max-w-[280px]">
                                <p className="text-sm-semibold text-gray-light/700">Name & profile picture</p>
                                <p className="text-sm-regular text-gray-light/600">Standardise name and profile picture for each new registered number to enhance the credibility of the organisation.</p>
                            </div>
                            <div className="flex-1 flex flex-col gap-y-4 w-full">
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={"isSetIdentity"} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Set identity for new registered number</p>
                                </div>
                                <div className="flex flex-col gap-y-1.5 w-full max-w-[336px]">
                                    <label htmlFor="profileName" className="text-sm-medium text-gray-light/700">Profile name</label>
                                    <MyTextFieldV2 name="profileName" placeholder={'Enter your profile name'} />
                                </div>
                                <div className="flex flex-col gap-y-5 w-full max-w-[352px]">
                                    <div>
                                        <p className="text-sm-semibold text-gray-light/700">Profile picture</p>
                                        <p className="text-sm-regular text-gray-light/600">This will be displayed on user profile picture.</p>
                                    </div>
                                    <div className="flex items-start justify-between">
                                        <div className="w-16 h-16 min-w-[64px] min-h-[64px] rounded-full overflow-hidden">
                                            <img className="w-full h-full object-cover" src="" alt="" />
                                        </div>
                                        <div className="flex gap-x-4">
                                            <button className="text-sm-semibold text-gray-light/600">Delete</button>
                                            <button className="text-sm-semibold text-brand/700">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr className="border-gray-light/200" />
                        <section className="flex items-start gap-x-16">
                            <div className="w-full max-w-[280px]">
                                <p className="text-sm-semibold text-gray-light/700">Other user conversation</p>
                                <p className="text-sm-regular text-gray-light/600">Users can see conversations held by other users on the same customer.</p>
                            </div>
                            <div className="flex-1 flex flex-col gap-y-4 w-full">
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isShowRecentConversation'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Show recent conversation</p>
                                </div>
                            </div>
                        </section>
                        <hr className="border-gray-light/200" />
                        <section className="flex items-start gap-x-16">
                            <div className="w-full max-w-[280px]">
                                <p className="text-sm-semibold text-gray-light/700">Other user conversation</p>
                                <p className="text-sm-regular text-gray-light/600">Users can see conversations held by other users on the same customer.</p>
                            </div>
                            <div className="flex-1 flex flex-col gap-y-4 w-full">
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isMaskedHome'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Home</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isMaskedTaskDistribution'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Task distribution</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isMaskedConversationMonitoring'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Conversation monitoring</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isMaskedChats'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Chats</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isMaskedSettings'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Settings</p>
                                </div>
                            </div>
                        </section>
                        <hr className="border-gray-light/200" />
                        <section className="flex items-start gap-x-16">
                            <div className="w-full max-w-[280px]">
                                <p className="text-sm-semibold text-gray-light/700">Send media through chat</p>
                                <p className="text-sm-regular text-gray-light/600">Specify the types of media that can be sent by all users.</p>
                            </div>
                            <div className="flex-1 flex flex-col gap-y-4 w-full">
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isSendFile'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">File</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isSendPhoto'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Photos & video</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isSendVoiceNote'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Voice note</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isSendEmoji'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Emoji</p>
                                </div>
                                <div className="flex items-center gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'isSendSticker'} value={true} />
                                    <p className="text-sm-medium text-gray-light/700">Sticker</p>
                                </div>
                            </div>
                        </section>
                        <hr className="border-gray-light/200" />
                        <section className="flex items-start gap-x-16">
                            <div className="w-full max-w-[280px]">
                                <p className="text-sm-semibold text-gray-light/700">Spam detection settings</p>
                                <p className="text-sm-regular text-gray-light/600">Set number of messages & interval to set detection as spam</p>
                            </div>
                            <div className="flex-1 flex flex-col gap-y-4 w-full">
                                <div className="flex flex-col gap-y-1.5 w-full max-w-[336px]">
                                    <label htmlFor="maxMessageSent" className="text-sm-medium text-gray-light/700">Max. message sent after latest replied message</label>
                                    <MyTextFieldV2 name="maxMessageSent" type="number" placeholder={'Enter your max sent'}
                                        startAdornment={<Send01 size={20} className={'text-gray-light/500'} stroke="currentColor" />} />
                                </div>
                                <div className="flex flex-col gap-y-1.5 w-full max-w-[336px]">
                                    <label htmlFor="resetIntervalSec" className="text-sm-medium text-gray-light/700">Reset interval (sec.) after latest max. message</label>
                                    <MyTextFieldV2 name="resetIntervalSec" type="number" placeholder={'Enter your sec'}
                                        startAdornment={<HourGlass02 size={20} className={'text-gray-light/500'} stroke="currentColor" />} />
                                </div>
                                <div className="flex items-start gap-2 w-full max-w-[336px]">
                                    <MySwicth name={'preventSendMessages'} value={true} />
                                    <div>
                                        <p className="text-sm-medium text-gray-light/700">Prevent send messages</p>
                                        <p className="text-sm-regular text-gray-light/600">The system saves the message, but it won't be sent to customer.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr className="border-gray-light/200" />
                        <section className="flex items-start gap-x-16">
                            <div className="w-full max-w-[280px]">
                                <p className="text-sm-semibold text-gray-light/700">Notification</p>
                                <p className="text-sm-regular text-gray-light/600">Configure notifications based on the frequency of successfully sent messages and the type of activated notification.</p>
                            </div>
                            <div className="flex-1 flex flex-col gap-y-4 w-full">
                                <div className="flex flex-col gap-y-1.5 w-full max-w-[336px]">
                                    <label htmlFor="minIntervalInSec" className="text-sm-medium text-gray-light/700">Min. interval (sec.) between message</label>
                                    <MyTextFieldV2 type="number" name="minIntervalInSec" placeholder={'Enter your sec'}
                                        startAdornment={<HourGlass02 size={20} className={'text-gray-light/500'} stroke="currentColor" />} />
                                </div>
                                <div className="flex items-start gap-2 w-full">
                                    <MyCheckbox name="isMessageFromCustomer" value={true} />
                                    <div>
                                        <p className="text-sm-medium text-gray-light/700">Message from customer</p>
                                        <p className="text-sm-regular text-gray-light/600">Stay informed about each customer message on chat module.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-2 w-full">
                                    <MyCheckbox name="isViolatedRules" value={true} />
                                    <div>
                                        <p className="text-sm-medium text-gray-light/700">Violated rules</p>
                                        <p className="text-sm-regular text-gray-light/600">Stay informed about every user-violated word.</p>
                                    </div>
                                </div>
                                <div className="flex items-start gap-2 w-full">
                                    <MyCheckbox name="isChangeObject" value={true} />
                                    <div>
                                        <p className="text-sm-medium text-gray-light/700">Change object</p>
                                        <p className="text-sm-regular text-gray-light/600">Stay updated on all CRUD activity on certain object.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="flex flex-col gap-y-5">
                        <hr className="border-gray-light/200" />
                        <div className="w-full flex items-center justify-end gap-x-3">
                            <button className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button type="submit" className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Save</p>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default GeneralSetting;