import React, { useEffect, useRef, useState } from "react";
import { DownloadCloud02, Edit01, FilterLines, LinkExternal02, Logout04, XClose } from "untitledui-js/icons/general";
import MyTextField from "../../components/TextField/MyTextField";
import { SwitchVertical01 } from "untitledui-js/icons/arrow";
import MyPagination from "../../components/Pagination/MyPagination";
import SimpleBar from "simplebar-react";
import { useAuditTrial } from "../../contexts/AuditTrialContext";
import moment from "moment";
import { debounce } from "lodash";
import MyFilterModal from "../../components/Modal/MyFilterModal";
import MyAutocompleteV3 from "../../components/Autocomplete/MyAutocompleteV3";
import MyDatePickerRange from "../../components/DatePicker/MyCalendar";
import $ from "jquery";
import { User01 } from "untitledui-js/icons/users";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const TableContainerRegistered = () => {
    const [date, setDate] = useState({})
    const [users, setUsers] = useState([])
    const [customers, setCustomers] = useState([])
    const { conversations, getConversations, handleCurrentSlider, data, params, setParams, download, searchUser, searchCustomer } = useAuditTrial();
    useEffect(() => {
        getConversations()
    }, [params])
    useEffect(() => {
        if (conversations && data?.id) {
        }
    }, [conversations, data])
    const search = (e) => {
        setParams(value => { return { ...value, search: e?.target?.value, page: 1 } })
    }
    useEffect(() => {
        setParams(value => { return { ...value, ...date, page: 1 } })
    }, [date])
    const debounceSearch = debounce(search, 1000)
    const getUser = debounce((e) => searchUser(e).then(({ data }) => setUsers(data)), 1000)
    const getCustomer = debounce((e) => searchCustomer(e).then(({ data }) => setCustomers(data)), 1000)
    return (
        <>
            <div className="px-8 w-full">
                <div className="w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                    <div className="py-5 px-6 flex gap-4">
                        <div className="flex-1 flex-col gap-1">
                            <p className="text-lg-semibold text-gray-light/900">Conversations logs</p>
                            <p className="text-sm-regular text-gray-light/600">Monitor the conversation involving senders and the receivers.</p>
                        </div>
                        <button onClick={download} className="border border-gray-light/300 flex items-center rounded-lg px-3 py-2 gap-x-1 text-gray-light/700">
                            <DownloadCloud02 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Export</p>
                        </button>
                    </div>
                    <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                        <div className="w-full max-w-xs">
                            <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                        </div>
                        {<MyFilterModal id={'filter-audit-trail'} currentFilters={conversations?.filter} onChange={(filter) => setParams({ ...params, filter: filter, page: 1 })}
                            target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                                <FilterLines size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Filter</p>
                            </button>)} />}

                        {/* <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                            <SwitchVertical01 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Sort</p>
                        </button>)} /> */}
                        {/* <div className="flex">
                            <DatePicker id="date-picker-start" value={date.start_date}
                                className="hidden" onChange={(value) => setDate({...date,start_date:value})} />
                            <button onClick={() => { document.getElementById('date-picker-start').click() }} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                                <p className="text-sm">{date.start_date?moment(date.start_date).format('DD-MM-YYYY'):'Start Date'}</p>
                                {date.start_date && <XClose onClick={e=>{e.stopPropagation();setDate({...date,start_date:null})}} size={15} className={'text-gray-light/700'} strokeWidth={3} stroke={'currentColor'}/>}
                            </button>
                            <div className="p-2"></div>
                            <DatePicker id="date-picker-end"  value={date.end_date} minDate={date.start_date}
                                className="hidden" onChange={(value) => setDate({...date,end_date:value})} />
                            <button onClick={() => { document.getElementById('date-picker-end').click() }} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                                <p className="text-sm">{date.end_date?moment(date.end_date).format('DD-MM-YYYY'):'End Date'}</p>
                                {date.end_date && <XClose onClick={e=>{e.stopPropagation();setDate({...date,end_date:null})}} size={15} className={'text-gray-light/700'} strokeWidth={3} stroke={'currentColor'}/>}
                            </button>
                        </div>
                        <div className="flex-1 max-w-[300px]">
                            <MyAutocompleteV3 placeholder={"All User"} value={params.user} options={users}
                                onInputFocus={(e) => getUser()}
                                getOptionLabel={(e) => e.name}
                                filterOptions={(e, inputValue) => e.name.trim().toLowerCase().includes(inputValue) || e.email.trim().toLowerCase().includes(inputValue)}
                                onInputChange={({ target: { value } }) => {
                                    getUser({ search: value })
                                }}
                                onChange={(e, value) => {
                                    console.log(value)
                                    setParams(params => { return { ...params, user: value } })
                                }}
                                renderOption={(option) => {
                                    return (<div className="flex items-center gap-x-2 overflow-hidden">
                                        <div className="w-6 h-6 min-w-[24px] min-h-[24px] rounded-full overflow-hidden">
                                            <img className="w-full h-full object-cover" src={option?.full_path_photo} alt="" />
                                        </div>
                                        <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option?.name}</p>
                                        <p className="text-md-regular text-gray-light/600 line-clamp-1">{option?.email}</p>
                                    </div>)
                                }}
                            />
                        </div>
                        <div className="flex-1 max-w-[300px]">
                            <MyAutocompleteV3 placeholder={"All Customer"} value={params.customer} options={customers}
                                onInputFocus={(e) => getCustomer()}
                                getOptionLabel={(e) => e.name}
                                filterOptions={(e, inputValue) => e.number.trim().toLowerCase().includes(inputValue) || e.name.trim().toLowerCase().includes(inputValue)}
                                onInputChange={({ target: { value } }) => {
                                    getCustomer({ search: value })
                                }}
                                onChange={(e, value) => {
                                    setParams(params => { return { ...params, customer: value } })
                                }}
                                renderOption={(option) => {
                                    return (<div className="flex items-center gap-x-2 overflow-hidden">
                                        <div className="w-6 h-6 min-w-[24px] min-h-[24px] rounded-full overflow-hidden">
                                            <User01 size={24} className={'text-gray-light/500'} stroke="currentColor" />
                                        </div>
                                        <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option?.name}</p>
                                        <p className="text-md-regular text-gray-light/600 line-clamp-1">{option?.number}</p>
                                    </div>)
                                }}
                            />
                        </div>
                        </div> */}
                        {/* <div className="flex-1"></div> */}
                    </div>

                    <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                        <table className='table border-collapse w-full'>
                            <thead className='border-t border-gray-light/200 p-0'>
                                <tr className='p-0'>
                                    <th className='px-6 py-3 text-left' width={"20%"}>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Timestamp</p>
                                    </th>
                                    <th className='px-6 py-3 text-left' width={"15%"}>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">User Email</p>
                                    </th>
                                    <th className='px-6 py-3 text-left' width={"15%"}>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Sender</p>
                                    </th>
                                    <th className='px-6 py-3 text-left' width={"15%"}>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Receiver</p>
                                    </th>
                                    <th className='px-6 py-3 text-left max-w-[384px]' >
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Message</p>
                                    </th>
                                    <th className='px-6 py-3' width='5%'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(conversations?.data ?? []).map((e, i) => {
                                    var senderNumber = e.is_sender ? e?.user_sim_card : e?.sender?.number
                                    var receiverNumber = !e.is_sender ? e?.user_sim_card : e?.receiver?.number
                                    return (
                                        <tr key={i}>
                                            <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                <p className="text-sm-regular text-gray-light/600">{moment(e.created_at).format("D MMMM YYYY • h:mma")}</p>
                                            </td>
                                            <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                <p className="text-sm-regular text-gray-light/600">{!e.is_sender ? e?.receiver?.email : e?.sender?.email}</p>
                                            </td>
                                            <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                <div className="flex flex-col">
                                                    <p className="text-sm-regular text-gray-light/900 line-clamp-1">{e?.sender?.name}</p>
                                                    <p className="text-sm-regular text-gray-light/600">{senderNumber}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                <div className="flex flex-col">
                                                    <p className="text-sm-regular text-gray-light/900 line-clamp-1">{e?.receiver?.name}</p>
                                                    <p className="text-sm-regular text-gray-light/600">{receiverNumber}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                <div className="max-w-[384px] overflow-hidden">
                                                    <p className="text-sm-regular break-words text-gray-light/600 line-clamp-2">{e.text}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                <div className="flex items-center justify-end gap-1">
                                                    <button onClick={() => handleCurrentSlider({ status: true, current: 'slider-detail-conversation' }, e)} className="flex items-center p-2">
                                                        <LinkExternal02 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </SimpleBar>
                    <div className="border-t border-gray-light/200">
                        <MyPagination setParams={setParams}
                            meta={conversations?.meta}
                        />
                    </div>
                </div>

            </div>
        </>
    );
};

export default TableContainerRegistered;