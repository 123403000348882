import React, { } from "react";
import { DownloadCloud02, Edit01, FilterLines, Trash01 } from "untitledui-js/icons/general";
import MyPagination from "../../../components/Pagination/MyPagination";
import MyTextField from "../../../components/TextField/MyTextField";
import MyFilter from "../../SimCard/Poppers/filter";
import { ArrowDown, SwitchVertical01 } from "untitledui-js/icons/arrow";
import MySorted from "../../SimCard/Poppers/sorted";
import SimpleBar from "simplebar-react";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";

const CustomerSetting = () => {
    return (
        <>
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                <div className="pt-5 flex flex-col gap-y-5">
                    <div className='px-6 flex items-center gap-x-4 w-full'>
                        <div className="flex-1 flex-col gap-1">
                            <div className="flex items-center gap-2">
                                <p className="text-lg-semibold text-gray-light/900">Customer</p>
                                <div className="px-2 py-0.5 rounded-full bg-brand/50 border border-brand/200">
                                    <p className="text-xs-medium text-brand/700">42 number</p>
                                </div>
                            </div>
                            <p className="text-sm-regular text-gray-light/600">List of customers registered in the system based on submitted uploaded plans.</p>
                        </div>
                        <div className="flex items-center gap-x-3">
                            <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                <DownloadCloud02 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Download</p>
                            </button>
                        </div>
                    </div>
                    <hr className='border-gray-light/200' />
                </div>
                <div className="py-3 px-4 flex gap-3">
                    <div className="w-full max-w-xs">
                        <MyTextField placeholder={'Search'} />
                    </div>
                    {/* <MyFilter id={'filter-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                        <FilterLines size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Filter</p>
                    </button>)} />

                    <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                        <SwitchVertical01 size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Sort</p>
                    </button>)} /> */}
                </div>
                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                    <table className='table border-collapse w-full'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            <tr className='p-0'>
                                <th className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-3">
                                        {/* <MyCheckbox /> */}
                                        <div className="flex items-center gap-1 text-gray-light/600">
                                            <p className="text-xs-medium  whitespace-nowrap">Customer name</p>
                                            {/* <button>
                                                <ArrowDown size={16} stroke={'currentColor'} />
                                            </button> */}
                                        </div>
                                    </div>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Latest attempt status</p>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Latest interaction</p>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Static label</p>
                                </th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5].map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-3">
                                                {/* <MyCheckbox /> */}
                                                <div className="flex items-center gap-3">
                                                    <div className="w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src="" alt="" />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">Endang</p>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">081878543672</p>
                                                    </div>
                                                </div>                                                    </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-x-1">
                                                <div className="w-max px-2 py-0.5 rounded-md border border-gray-light/200">
                                                    <p className="text-xs-medium text-gray-light/700">Berjanji membayar</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">3 Feb 2021 • 09:05am</p>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-x-1">
                                                <div className="w-max px-2 py-0.5 rounded-md bg-blue-light/50 border border-blue-light/200">
                                                    <p className="text-xs-medium text-blue-light/700">Difficult to handle</p>
                                                </div>
                                                <div className="w-max px-2 py-0.5 rounded-md bg-blue-light/50 border border-blue-light/200">
                                                    <p className="text-xs-medium text-blue-light/700">Fast response</p>
                                                </div>
                                                <div className="w-max px-2 py-0.5 rounded-md bg-gray-light/50 border border-gray-light/200">
                                                    <p className="text-xs-medium text-gray-light/700">+4</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex justify-end gap-x-1">
                                                <button onClick={() => { }} className="my-button flex items-center p-2.5">
                                                    <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                                <button onClick={() => { }} className="my-button flex items-center p-2.5">
                                                    <Edit01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SimpleBar>
                <div className="border-t border-gray-light/200">
                    <MyPagination meta={null} />
                </div>
            </div>
        </>
    );
};

export default CustomerSetting;