import React, { } from "react";
import { VerifyLogo } from "../../contants/icons/MyIcon/VerifyLogo";

const Welcome = () => {
    return (
        <>
            <div className="w-full h-screen flex items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-8">
                    <div className="flex items-center justify-center text-center gap-x-6">
                        <VerifyLogo size={90} />
                        <p className="display-2xl-semibold text-gray-light/900">Welcome!</p>
                    </div>
                    <p className="text-center display-xs-regular text-gray-dark/800">Choose your desired menu from the options on the sidebar to get started.</p>
                </div>
            </div>
        </>
    );
};

export default Welcome;