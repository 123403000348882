import React, { useEffect, useState } from "react";
import { XClose } from "untitledui-js/icons/general";
import SimpleBar from 'simplebar-react';
import { useSettings } from "../../../contexts/SettingContext";
import MyTextFieldV2 from "../../../components/TextField/MyTextFieldv2";
import { Key01 } from "untitledui-js/icons/security";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { roleSchema } from "../../../yup_schema/setting_schema";
import MyAutocompleteV3 from "../../../components/Autocomplete/MyAutocompleteV3";
import { debounce } from "lodash";

const ModalRoleSetting = () => {
    const { searchRolePage, createRole, showRole, updateRole, currentSlider, handleCurrentSlider } = useSettings();
    const { clearErrors, watch, setValue, handleSubmit, control, formState: { errors }, register } = useForm({ resolver: yupResolver(roleSchema) });

    const [pageOptions, setPageOption] = useState([]);
    const submit = currentSlider.id ? updateRole : createRole;

    const [rolePages, setRolePages] = useState([])
    const debounceSearchRolePage = debounce((e) => searchRolePage(e).then(setRolePages), 1000)

    useEffect(() => {
        if (currentSlider.id) {
            showRole(currentSlider.id).then(data => {
                setValue('name', data.name);
                setValue('grade', data.grade);
                setValue('pages', data.pages.map(e => e.value));
                setPageOption((data.pages ?? []))
            })
        } else {
            setValue('pages', []);
        }
    }, []);
    if (errors) console.log(errors)

    return (
        <div className="w-full h-screen flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} onClick={() => handleCurrentSlider()} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <Key01 size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">{currentSlider?.id ? 'Edit' : 'Add'} role</p>
                    <p className="text-sm-regular text-gray-light/600">Please provide the details for a new role.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <form className="h-full flex flex-col gap-6" onSubmit={handleSubmit(submit)}>
                        <div className="px-6 flex flex-col gap-4 flex-1">
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="name" className="text-sm-medium text-gray-light/700">Role name</label>
                                <MyTextFieldV2 maxLength={60} name="name" control={control} placeholder={'Enter your name'} />
                            </div>
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="grade" className="text-sm-medium text-gray-light/700">Grade</label>
                                <MyTextFieldV2 type="number" name="grade" control={control} placeholder={'Enter your grade'} />
                            </div>
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="page" className="text-sm-medium text-gray-light/700">Page access</label>
                                {<MyAutocompleteV3 value={pageOptions} options={rolePages} name="pages" placeholder={'Enter your page'} multiple={true}
                                    onInputChange={debounceSearchRolePage}
                                    disabled={true}
                                    removeable={false}
                                    onInputFocus={(e) => searchRolePage(e).then(setRolePages)}
                                    onChange={(e, value) => {
                                        setPageOption(value);
                                        setValue('pages', value.map((e) => e.value));
                                    }}
                                />}
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 px-6 py-4 flex items-center justify-end gap-4">
                            <button type="reset" onClick={() => handleCurrentSlider({ status: false, current: null })} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button type="submit" className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Apply</p>
                            </button>
                        </footer>
                    </form>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalRoleSetting;