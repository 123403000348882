import React, { useEffect, useState } from "react";
import { Popover } from "@mui/material";
import MyTextField from "../../../components/TextField/MyTextField";
import { Hash02, Plus, SearchLG } from "untitledui-js/icons/general";
import SimpleBar from "simplebar-react";
import { useMessage } from "../../../contexts/MessageContext";
import { debounce } from "lodash";
import { notify } from "../../../helpers/Helpers";
const TemplateCard = ({ e, params, sendTemplate, handleCollapse, handleClose }) => {
    var keys = e.shortcut.split('+')
    var text = e.text
    params.current.custom.map(e => {
        var label = e.label.split('} {')[1].replace('}', '')
        text = text.replace(new RegExp(`<<${label}>>`, 'ig'), `<label class="text-sm-semibold text-brand/700">${e.value}</label>`)
    })
    if (params.current.detail_customer) {
        text = text.replace(new RegExp(`<<Receiver Name>>`, 'ig'), `<label class="text-sm-semibold text-brand/700">${params.current.detail_customer.name}</label>`)
    }
    var isValid = !text.includes('<<')
    return (<div key={e.id} onClick={() => {
        if (!isValid) return
        if (window.confirm('Anda yakin ingin mengirim pesan ini?')) sendTemplate(e).then(handleClose)
    }} className="px-2 py-0.5">
        <div className="p-2.5 pl-[14px] flex items-start gap-2 cursor-pointer hover:bg-gray-light/50 rounded-lg">
            <div className="p-2.5 w-10 h-10 min-w-[40px] min-h-[40px] border border-gray-light/200 shadow-shadows/shadow-xs rounded-lg">
                <Hash02 size={20} className={'text-gray-light/700'} stroke={'currentColor'} />
            </div>
            <div className="flex flex-col flex-1">
                <p className={`text-sm-medium ${isValid ? "text-gray-light/900" : "text-red-500"}`}>{e.name}</p>
                <p className={`text-sm-regular whitespace-pre-line break-words text-gray-light/600 ${e.isCollapseText ? '' : 'line-clamp-5'} `} dangerouslySetInnerHTML={{ __html: `${text}` }}>
                </p>
                <div className="w-full flex justify-center">
                    <button type="buttom" onClick={(e) => {
                        e.stopPropagation();
                        handleCollapse && handleCollapse();
                    }} className="text-sm-semibold text-brand/700 pt-2">{!e.isCollapseText ? 'View more' : 'Show less'}</button>
                </div>
            </div>
            <div className="flex items-center gap-1">
                <div className="border border-gray-light/200 rounded px-1 py-0.5">
                    <p className="text-sm-regular text-gray-light/600">{keys[0]}</p>
                </div>
                <span>
                    <Plus size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                </span>
                <div className="border border-gray-light/200 rounded px-1 py-0.5">
                    <p className="text-sm-regular text-gray-light/600">{keys[1]}</p>
                </div>
            </div>
        </div>
    </div>)
}
const PopperCommandBar = ({ id, target }) => {
    const { getTemplate, params, sendTemplate } = useMessage()
    const [templates, setTemplates] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [recent, setRecent] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    useEffect(() => {
        getRecent()
        getTemplate().then(e => setTemplates(e?.data ?? []))
    }, [])
    const debounceSearch = debounce((e) => getTemplate(e).then(e => setTemplates(e.data)), 500)
    useEffect(() => {
        const listener = function (e) {
            var key = e.key;
            if (e.ctrlKey && e.code.includes("Digit")) {
                e.preventDefault()
                var template = templates.find(e => e.shortcut.includes(`Ctrl + ${key}`))
                if (!template) return notify({ message: 'This template is not valid', status: 500 })
                sendTemplate(template).then(handleClose)
                localStorage.setItem('last-template', template.id)
                getRecent()
            }
        }
        if (anchorEl) {
            document.addEventListener('keydown', listener)
        } else {
            document.removeEventListener('keydown', listener)
        }
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [anchorEl, templates])
    const getRecent = () => {
        var id = localStorage.getItem('last-template')
        setRecent(templates.find(e => e.id == id))
    }
    return (
        <>
            {target && target(open, handleClick)}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{
                    '&.MuiPopover-root .MuiPaper-root': {
                        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                        border: '1px solid #EAECF0',
                        borderRadius: '12px',
                        width: 'max-content',
                    }
                }}
            >
                <div className="flex flex-col w-[640px] h-max">
                    <div className="flex items-center gap-2 p-4 border-b border-gray-light/200">
                        <span>
                            <SearchLG size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                        </span>
                        <div className="flex-1">
                            <input onChange={debounceSearch} placeholder="Search" className="outline-none" type="text" />
                        </div>
                    </div>
                    {recent && <div className="border-b border-gray-light/200 flex flex-col gap-0.5 py-4">
                        <div className="px-[18px]">
                            <p className="text-sm-medium text-gray-light/600">Recent</p>
                        </div>
                        <div className="flex flex-col">
                            <TemplateCard e={recent} params={params} sendTemplate={sendTemplate} handleClose={handleClose} />
                        </div>
                    </div>}
                    <div className="h-72 overflow-hidden py-4">
                        <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                            <div className="flex flex-col">
                                {templates.map((e, i) => {
                                    return <TemplateCard key={i} e={e} params={params} sendTemplate={sendTemplate} handleClose={handleClose}
                                        handleCollapse={() => {
                                            console.log('line 130');
                                            const updatedTemplates = templates.map((template, index) => {
                                                if (index === i) return { ...template, isCollapseText: !template.isCollapseText };
                                                return template;
                                            });

                                            setTemplates(updatedTemplates);
                                        }} />
                                })}
                            </div>
                        </SimpleBar>
                    </div>

                </div>
            </Popover>
        </>
    );
};

export default PopperCommandBar;