import React from "react";

const MyIconPatternDecorativeRadial = ({ children }) => {
    return (
        <>
            <div className={`w-max relative flex items-center justify-center z-10`}>
                <div className="z-20">
                    {children}
                </div>
                <div style={{}} className="absolute flex w-[768px] h-[768px] items-center justify-center">
                   
                </div>
            </div>
            {/* <div style="width: 100%; height: 100%; position: relative">
                <div style="width: 768px; height: 768px; left: 0px; top: 0px; position: absolute; justify-content: center; align-items: center; display: inline-flex">
                    <div style="width: 768px; height: 768px; position: relative; background: radial-gradient(100.00% 100.00% at -509436065.57% 0.00%, black 0%, rgba(0, 0, 0, 0) 100%)"></div>
                </div>
                <div style="left: 0px; top: 0px; position: absolute; justify-content: center; align-items: center; display: inline-flex">
                    <div style="align-self: stretch; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                        <div style="height: 768px; border: 1px #EAECF0 solid; justify-content: flex-start; align-items: flex-start; gap: 48px; display: inline-flex">
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                            <div style="width: 768px; align-self: stretch; transform: rotate(90deg); transform-origin: 0 0; border: 1px #EAECF0 solid"></div>
                        </div>
                        <div style="height: 768px; border: 1px #EAECF0 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 48px; display: flex">
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                            <div style="align-self: stretch; height: 0px; border: 1px #EAECF0 solid"></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>

    );
};

export default MyIconPatternDecorativeRadial;
