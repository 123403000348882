import socketio from "socket.io-client";
import axios from "axios";
import { notify } from "../helpers/Helpers";
import { AlertCircle } from "untitledui-js/icons/alerts";
import moment from "moment";
const baseURL = process.env.REACT_APP_API_HOST;

export const socket = socketio.connect(baseURL + "/v1", {
  transports: ["websocket"],
  rejectUnauthorized: false,
});
export const socketLocal = socketio.connect("http://localhost:3000/v1", {
  transports: ["websocket"],
});
export const instance = axios.create({ baseURL: baseURL });

export const getCookie = (name) => {
  try {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  } catch (error) {
    console.log("getCookie", error);
    return null;
  }
};
export const setCookie = (key, value, expiry) => {
  var expires = new Date();
  expires.setTime(expires.getTime() + expiry * 60 * 60 * 1000);
  document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
};
const logout = () => {
  setCookie("token", null, "-1");
  window.location.href = "/login";
};
// const headers = { authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNjMjYyOTRhLThiYzMtNDUyNS05OThjLTVmMGUzNGUxMmFjZiIsImVtYWlsIjoiemlrcmlAbmlnaHRjb2RlLmlkIiwiaWF0IjoxNjk5MDk4NTQxLCJleHAiOjE2OTkzNTc3NDF9.qAt2j6KPT0XrS7d8lNEOPXVjBeXMPtCDakv87MEQIII' }
var requestId = {};
export const emit = async (socketName, data, timeout, autoRefresh) => {
  const isItDoneYet = new Promise((resolve, reject) => {
    var complete = false;
    var id = `${new Date().getTime() + Math.random(3)}`;
    var emitData = {
      header: {
        "access-token": getCookie("token"),
      },
      requestId: id,
      data: data,
    };
    requestId[socketName] = id;
    socket.emit(socketName, emitData);
    socket.on(socketName, (data) => {
      complete = true;
      if (data.msg === "Token Tidak Valid" || data.msg === "Invalid Token") {
        setCookie("token", null, "-1");
        window.location.href = "/login";
      }

      if (data.requestId && requestId[socketName] !== data.requestId) return;

      socket.off(socketName);
      console.log("EMITTER [" + socketName + "] = ", data);
      resolve(data);
    });

    if (autoRefresh)
      setTimeout(() => {
        if (complete) return;
        if (requestId[socketName] !== id) return;

        // showNotify({ status: 'error', msg: 'Request timeout' })
        socket.emit(socketName, emitData);
        console.log("ERROR EMITTER [" + socketName + "] = Request timeout");
        setTimeout(() => {
          if (complete) return;
          if (requestId[socketName] !== id) return;

          reject("Request timeout");
        }, 15000);
      }, timeout ?? 10000);
    else
      setTimeout(() => {
        if (complete) return;
        if (requestId[socketName] !== id) return;

        socket.off(socketName);
        console.log("ERROR EMITTER [" + socketName + "] = Request timeout");
        reject("Request timeout");
      }, timeout ?? 10000);
  });
  return await isItDoneYet;
};
const getHeader = (type) => {
  var headers = { authorization: "Bearer " + getCookie("token") };
  switch (type) {
    case "json": {
      headers = {
        ...headers,
        "Content-Type": "application/json",
        "Accept-Language": "en",
      };
      break;
    }
    case "form-data": {
      headers = {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": "en",
      };
      break;
    }
    default:
  }
  return headers;
};
export const get = async (endpoint, params, type = "json", timeout = 10000) => {
  try {
    const headers = getHeader(type);
    var url = `${baseURL}${endpoint}`;
    const response = await instance.get(url, { headers, params, timeout });
    return response.data;
  } catch (error) {
    if (error.response?.status == 401) logout();
    throw error.response?.data ?? error.message ?? "Something wrong";
  }
};

export const post = async (endpoint, data, type = "json", timeout = 10000) => {
  try {
    console.log("endpoint", endpoint);
    console.log("data", data);
    //console.log('endpoint', endpoint);
    const headers = getHeader(type);
    var url = `${baseURL}${endpoint}`;
    const response = await instance.post(url, data, { headers, timeout });
    return response.data;
  } catch (error) {
    if (error.response?.status == 401) logout();
    throw error.response?.data ?? error.message ?? "Something wrong";
  }
};

export const patch = async (
  endpoint,
  data,
  params,
  type = "json",
  timeout = 10000
) => {
  try {
    const headers = getHeader(type);
    var url = `${baseURL}${endpoint}`;
    const response = await instance.patch(url, data, {
      headers,
      params,
      timeout,
    });
    return response.data;
  } catch (error) {
    if (error.response?.status == 401) logout();
    throw error.response?.data ?? error.message ?? "Something wrong";
  }
};

export const remove = async (endpoint, timeout = 10000) => {
  try {
    const headers = { authorization: "Bearer " + getCookie("token") };
    var url = `${baseURL}${endpoint}`;
    const response = await instance.delete(url, { headers, timeout });
    return response.data;
  } catch (error) {
    if (error.response?.status == 401) logout();
    throw error.response?.data ?? error.message ?? "Something wrong";
  }
};
export const download = (endpoint, params) => {
  var url = `${baseURL}${endpoint}?token=${getCookie("token")}`;
  if (params)
    Object.keys(params).forEach((key) => {
      url += `&${key}=${params[key] ?? ""}`;
    });
  return url;
};
