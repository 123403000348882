import React, { useEffect } from "react";
import ReactDOM from "react-dom"
import $ from 'jquery';

const MyContainerSliderModal = ({ children, open, onClose }) => {
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if ($(e.target).closest('#root').length) return false;
            if ($(e.target).closest('.MuiPopover-root').length) return false;
            if ($(e.target).closest('#my-slider-modal').length) return false;
            // console.log('on close');

            onClose && onClose();
        }
        if (open && children) {
            document.querySelector('#my-slider-modal-backdrop').addEventListener("click", checkIfClickedOutside);
            $('#my-slider-modal-backdrop').removeClass('hidden');
            setTimeout(() => {
                $('#my-slider-modal').addClass('right-0').removeClass('right-[-100%]');
            }, 200);

            document.body.style.overflow = "hidden"
            return () => {
                document.querySelector('#my-slider-modal-backdrop').removeEventListener("click", checkIfClickedOutside);
                document.body.style.overflow = "auto"
            }
        } else {
            $('#my-slider-modal').removeClass('right-0').addClass('right-[-100%]');
            setTimeout(() => {
                $('#my-slider-modal-backdrop').addClass('hidden');
            }, 500);

            document.querySelector('#my-slider-modal-backdrop').removeEventListener("click", checkIfClickedOutside);
        }
    }, [open])

    return (
        ReactDOM.createPortal(<><div id="my-slider-modal-backdrop" className={`w-full h-full fixed top-0 left-0 hidden`}>
            <aside id="my-slider-modal" style={{ zIndex: "999" }} className={`fixed right-[-100%] top-0 duration-500 ease-out bg-white w-max h-screen shadow-shadows/shadow-xl border-l border-gray-light/200`}>
                <div className="h-full w-max">{children}</div>
            </aside>
        </div>
        </>, document.getElementById("my-slider-modal-root"))
    );
};

export default MyContainerSliderModal;