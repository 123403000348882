import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import {
  FilterLines,
  HelpCircle,
  Plus,
  Trash03,
} from "untitledui-js/icons/general";
import { FileAttachment02 } from "untitledui-js/icons/files";
import MyCheckbox from "../../components/Checkbox/MyCheckbox";
import MyTooltip from "../../components/Tooltip/MyTooltip";
import { Csv } from "../../contants/icons/MyIcon/Extension";
import ModalMessage from "./modal";
import { useEffect } from "react";
import HttpRequests from "../../services/ApiServiceHttp";
import { formatBytes, notify } from "../../helpers/Helpers";
import moment from "moment";
import { User01 } from "untitledui-js/icons/users";
import MyPagination from "../../components/Pagination/MyPagination";
import MyTextField from "../../components/TextField/MyTextField";
import MyFilter from "../SimCard/Poppers/filter";
import MySorted from "../SimCard/Poppers/sorted";
import { SwitchVertical01 } from "untitledui-js/icons/arrow";
import MyContainerSliderModal from "../../components/SliderModal/MyContainerSliderModal";
import { useTaskDistribution } from "../../contexts/TaksDistributionContext";
import ModalUploadPlans from "./modal_upload_plans";
import { debounce } from "lodash";

const TaskDistribution = () => {
  const { currentSlider, handleCurrentSlider } = useTaskDistribution();

  const [param, setParam] = useState({ page: 1 });
  const [task, setTask] = useState({});

  const search = (e) => {
    setParam((value) => {
      return { ...value, search: e?.target?.value, page: 1 };
    });
  };
  const getTasks = async () => {
    await HttpRequests.getTaskDistribution(param)
      .then((res) => {
        setTask(res);
      })
      .catch(notify);
  };
  useEffect(() => {
    getTasks();
  }, [param]);

  const removeTask = async (id) => {
    var valid = window.confirm("Are you sure want delete this task?");
    if (!valid) return;
    await HttpRequests.deleteTask(id).then(notify).catch(notify);
    setParam((value) => {
      return { ...value, page: 1 };
    });
  };
  const debounceSearch = debounce(search, 1000);
  return (
    <SimpleBar forceVisible="y" className="flex-1 h-screen">
      <MyContainerSliderModal
        open={currentSlider?.status}
        onClose={() => handleCurrentSlider()}>
        {currentSlider?.current === "upload-plans" && (
          <ModalUploadPlans
            reload={() =>
              setParam((value) => {
                return { ...value, page: 1 };
              })
            }
          />
        )}
      </MyContainerSliderModal>
      <main className="w-full h-full pt-8 flex flex-col gap-8 bg-gray-light/25">
        <header className="px-8 flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            <p className="display-sm-semibold text-gray-light/900">
              Task distribution
            </p>
            <p className="text-md-regular text-gray-light/600">
              Distribute and manage your team assignments.
            </p>
          </div>
          <hr className="border-gray-light/200" />
        </header>
        <div className="flex-1 pb-12">
          <div className="px-8 w-full">
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
              <div className="pt-5 flex flex-col gap-y-5">
                <div className="px-6 flex items-center gap-x-4 w-full">
                  <div className="flex-1 flex-col gap-1">
                    <p className="text-lg-semibold text-gray-light/900">
                      Uploaded plans
                    </p>
                    <p className="text-sm-regular text-gray-light/600">
                      The list of plans you have uploaded.
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    {/* <button onClick={() => { }} className="w-max h-max flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/600">
                      <BookOpen01 size={20} stroke={'currentColor'} />
                      <p className="text-sm-semibold">Guide</p>
                    </button> */}
                    {/* <a href='http://103.181.182.240:3001/uploads/files/Template%20-%20Task%20Distribution.xlsx' className="w-max h-max border border-brand/300 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-brand/700"> */}
                    <a
                      href={`${process.env.REACT_APP_API_HOST}/uploads/files/Template%20-%20Task%20Distribution.xlsx`}
                      className="w-max h-max border border-brand/300 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-brand/700">
                      {/* <FileAttachment02 size={20} stroke={'currentColor'} /> */}
                      <p className="text-sm-semibold">
                        Download Inactive Number
                      </p>
                    </a>
                    <a
                      href={`${process.env.REACT_APP_API_HOST}/uploads/files/Template%20-%20Task%20Distribution.xlsx`}
                      className="w-max h-max border border-brand/300 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-brand/700">
                      <FileAttachment02 size={20} stroke={"currentColor"} />
                      <p className="text-sm-semibold">Download template</p>
                    </a>
                    <button
                      onClick={() => {
                        handleCurrentSlider({
                          status: true,
                          current: "upload-plans",
                        });
                      }}
                      className="w-max h-max bg-brand/600 flex items-center rounded-lg px-3 py-2 gap-x-1 text-white">
                      <Plus size={20} stroke={"currentColor"} />
                      <p className="text-sm-semibold">Add plan</p>
                    </button>
                    {/* <ModalMessage reload={getTasks} /> */}
                  </div>
                </div>
                <hr className="border-gray-light/200" />
              </div>
              <div className="py-3 px-4 flex gap-3">
                <div className="w-full max-w-xs">
                  <MyTextField
                    onChange={debounceSearch}
                    placeholder={"Search"}
                  />
                </div>
                {/* <MyFilter id={'filter-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                  <FilterLines size={20} stroke={'currentColor'} />
                  <p className="text-sm-semibold">Filter</p>
                </button>)} />

                <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                  <SwitchVertical01 size={20} stroke={'currentColor'} />
                  <p className="text-sm-semibold">Sort</p>
                </button>)} /> */}
              </div>
              <SimpleBar forceVisible="x" style={{ maxWidth: "100%" }}>
                <table className="table border-collapse w-full">
                  <thead className="border-t border-gray-light/200 p-0">
                    <tr className="p-0">
                      <th className="px-6 py-3 text-left">
                        <div className="flex items-center gap-3">
                          {/* <MyCheckbox /> */}
                          <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">
                            File name
                          </p>
                        </div>
                      </th>
                      <th className="px-6 py-3 text-center">
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">
                          Sender
                        </p>
                      </th>
                      <th className="px-6 py-3 text-center">
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">
                          Receiver
                        </p>
                      </th>
                      <th className="px-6 py-3 text-left">
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">
                          Uploaded date
                        </p>
                      </th>
                      <th className="px-6 py-3 text-left">
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">
                          Uploaded by
                        </p>
                      </th>
                      <th className="px-6 py-3">
                        <div className="flex justify-center">
                          <div className="flex items-center gap-1">
                            <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">
                              Is active
                            </p>
                            <MyTooltip
                              target={
                                <span>
                                  <HelpCircle
                                    size={16}
                                    className={"text-gray-light/400"}
                                    stroke={"currentColor"}
                                  />
                                </span>
                              }>
                              <p className="text-xs-semibold text-white">
                                Active status indicates that tasks distributed
                                to the agents in this file are still ongoing, as
                                theyremain responsible for contacting the
                                assigned customers in this file.
                              </p>
                            </MyTooltip>
                          </div>
                        </div>
                      </th>
                      <th className="px-6 py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {task?.data?.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-6 py-4 text-left border-t border-gray-light/200">
                            <div className="flex items-center gap-x-3">
                              <span>{/* <MyCheckbox /> */}</span>
                              <span>
                                <Csv size={40} />
                              </span>
                              <div className="flex flex-col ">
                                <p className="text-sm-medium text-gray-light/900">
                                  {e.filename}
                                </p>
                                <p className="text-sm-regular text-gray-light/600">
                                  {formatBytes(e.filesize)}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-center border-t border-gray-light/200">
                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">
                              {e.sender}
                            </p>
                          </td>
                          <td className="px-6 py-4 text-center border-t border-gray-light/200">
                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">
                              {e.receiver}
                            </p>
                          </td>
                          <td className="px-6 py-4 text-left border-t border-gray-light/200">
                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">
                              {moment(e.created_at).format(
                                "DD MMM YYYY • HH:mma"
                              )}
                            </p>
                          </td>
                          <td className="px-6 py-4 text-left border-t border-gray-light/200">
                            <div className="flex items-center gap-3">
                              {/* <div className="w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                <img className="w-full h-full object-cover" src="" alt="" />
                              </div> */}
                              {e.created_by?.photo ? (
                                <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                  <img
                                    className="w-full h-full object-cover"
                                    src={e.created_by?.full_path_photo}
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <div className="flex items-center justify-center bg-gray-light/100 w-10 h-10 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                  <User01
                                    size={20}
                                    className={"text-gray-light/500"}
                                    stroke="currentColor"
                                  />
                                </div>
                              )}

                              <div className="flex flex-col">
                                <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">
                                  {/* {e.created_by.name} */}
                                  User
                                </p>
                                <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">
                                  {/* {e.created_by.email} */}
                                  Email User
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 border-t border-gray-light/200">
                            {e.status && (
                              <div className="w-max py-0.2 px-2  m-auto rounded-full border border-success/200 bg-success/50">
                                <p className="text-xs-medium text-success/700">
                                  Active
                                </p>
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-4 text-left border-t border-gray-light/200">
                            <div className="flex items-center justify-end gap-1">
                              {/* <button className="flex items-center p-2.5">
                                <DownloadCloud01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                              </button> */}
                              <button
                                onClick={() => removeTask(e.id)}
                                className="flex items-center p-2.5">
                                <Trash03
                                  size={20}
                                  className={"text-gray-light/600"}
                                  stroke={"currentColor"}
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </SimpleBar>
              <div className="border-t border-gray-light/200">
                <MyPagination meta={task?.meta} setParams={setParam} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </SimpleBar>
  );
};

export default TaskDistribution;
