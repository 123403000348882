import React from 'react';
import { Check } from 'untitledui-js/icons/general';
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from '@mui/material';

const MyCheckbox = ({
    name, value, id, checked, control,
    disabled, onChangeForm
}) => {
    const uniqueId = id && uuidv4();
    const style = {
        '&.MuiButtonBase-root.MuiCheckbox-root': {
            margin: 0, padding: 0,
            borderRadius: '6px',
            '&.Mui-focusVisible.Mui-checked': {
                boxShadow: '0px 0px 0px 4px #9e77ed3d',
            },
            '&.Mui-focusVisible': {
                boxShadow: '0px 0px 0px 4px #98a2b324',
            }
        },
    };

    return (
        <>
            {control ? <Controller name={name} control={control} render={({ field }) => {
                return (
                    <Checkbox name={name} id={uniqueId} value={value} checked={checked || field?.value === value} disabled={disabled} disableRipple
                        onChange={(e) => {
                            var { target: { checked } } = e;
                            field?.onChange && field?.onChange((checked) ? value : null);
                            onChangeForm && onChangeForm(e);
                        }}
                        icon={<span className={`${disabled ? 'bg-gray-light/50 cursor-not-allowed' : ''} flex items-center justify-center min-h-[20px] min-w-[20px] w-5 h-5 rounded-md border border-gray-light/300`}> </span>}
                        checkedIcon={<span className={`${disabled ? 'bg-gray-light/50 cursor-not-allowed text-gray-light/300 border border-gray-light/300' : 'text-white bg-brand/600'} flex items-center justify-center min-h-[20px] min-w-[20px] w-5 h-5 rounded-md `}>
                            <Check size={14} stroke="currentColor" strokeWidth={3.5} />
                        </span>}
                        sx={style}
                    />
                )
            }} /> : <Checkbox id={uniqueId} name={name} value={value} disabled={disabled} disableRipple
                onChange={onChangeForm} checked={checked ?? false}
                icon={<span className={`${disabled ? 'bg-gray-light/50 cursor-not-allowed' : ''} flex items-center justify-center min-h-[20px] min-w-[20px] w-5 h-5 rounded-md border border-gray-light/300`}> </span>}
                checkedIcon={<span className={`${disabled ? 'bg-gray-light/50 cursor-not-allowed text-gray-light/300 border border-gray-light/300' : 'text-white bg-brand/600'} flex items-center justify-center min-h-[20px] min-w-[20px] w-5 h-5 rounded-md `}>
                    <Check size={14} stroke="currentColor" strokeWidth={3.5} />
                </span>}
                sx={style}
            />}
        </>
    );
};


export default MyCheckbox;
