import React, { useEffect, useState } from "react";
import { Popover } from "@mui/material";
import MyTextField from "../../../components/TextField/MyTextField";
import MyIconPatternDecorative from "../../../components/Icon/MyIconPatternDecorative";
import MyAutocomplete from "../../../components/Autocomplete/MyAutocomplete";
import { notify } from "../../../helpers/Helpers";
import HttpRequests from "../../../services/ApiServiceHttp";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useMessage } from "../../../contexts/MessageContext";
import AttemptHistory from "../Components/AttemptHistory";
import MyAutocompleteV3 from "../../../components/Autocomplete/MyAutocompleteV3";

const PopperNewReport = ({ refresh, tasks_id, id, target, history }) => {
    const { reportAttempt } = useMessage()
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false)
    const handleClick = (event) => {
        getAttempts()
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
        formReport.reset();
    };
    const [attempts, setAttempts] = useState([]);
    const reportSchema = Yup.object().shape({
        attempt: Yup.object().required('Status harus diisi'),
        description: Yup.string().optional()
    });
    const formReport = useForm({
        resolver: yupResolver(reportSchema)
    });
    const { clearErrors, watch, setValue, handleSubmit, control, formState: { errors }, register } = formReport;
    const open = Boolean(anchorEl);
    const getAttempts = async (name) => {
        await HttpRequests.listAttempts({ search: name })
            .then(res => {
                setAttempts(res.data)
            })
            .catch(notify)
    }
    const report = async (data) => {
        setLoading(true);
        data.attempts_id = data.attempt.id;
        await reportAttempt(tasks_id, data)
            .then(refresh)
        setLoading(false);
        handleClose()
    }
    const { attempt } = watch();

    return (
        <>
            {target && target(open, handleClick)}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }
                } sx={{
                    '&.MuiPopover-root .MuiPaper-root': {
                        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                        border: '1px solid #EAECF0',
                        borderRadius: '12px',
                        width: 'max-content',
                    }
                }}
            >
                <div className="flex flex-col w-[478px] h-max">
                    <form control={control} onSubmit={handleSubmit(report)}>
                        <div className="p-6 pb-5 flex flex-col gap-5 w-full">
                            <section className="flex flex-col gap-y-1.5">
                                <MyIconPatternDecorative>
                                    <label htmlFor="" className="text-sm-medium text-gray-light/700">Status</label>
                                </MyIconPatternDecorative>
                                <div className="z-20">
                                    {/* <MyTextField placeholder={'Search'} /> */}
                                    <MyAutocompleteV3 disableRemove={true} value={attempt} placeholder={'Search Attempts'} options={attempts}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => {
                                            clearErrors('attempt')
                                            setValue('attempt', value);
                                        }}
                                        onInputChange={(e) => {
                                            getAttempts(e?.target?.value);
                                        }}
                                        onInputFocus={() => getAttempts()}
                                    />
                                    {/* <MyAutocomplete placeholder={'Search Attempts'}
                                        options={attempts}
                                        value={attempt}
                                        onChange={(e, value) => {
                                            clearErrors('attempt')
                                            setValue('attempt', value);
                                        }}
                                        onInputChange={(e) => {
                                            getAttempts(e?.target?.value);
                                        }}
                                        onFocus={(e) => {
                                            getAttempts();
                                        }}
                                        defaultLabel={'Select Status'}
                                        filterOptions={(option, inputValue) => option.name.trim().toLowerCase().includes(inputValue) ||
                                            option.name.trim().toLowerCase().includes(inputValue)}
                                        getOptionLabel={(option => option.name)}
                                        renderOption={(option) => option ? (
                                            <div className="w-full flex items-center gap-x-2">
                                                <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option.name}</p>
                                            </div>
                                        ) : (<>Select User</>)}
                                    /> */}
                                    {errors.attempt && <div className="text-red-500">*{errors.attempt.message}</div>}
                                </div>
                            </section>
                            <section className="flex flex-col gap-y-1.5 z-20">
                                <label htmlFor="" className="text-sm-medium text-gray-light/700">Description</label>
                                <textarea {...register('description')} rows={6} className="w-full px-3.5 py-2.5 text-gray-light/900 resize-none border border-gray-light/300 shadow-shadows/shadow-xs rounded-lg outline-none placeholder-gray-light/500 focus:outline-none focus:border-brand/300 focus:shadow-focus-rings/ring-brand-shadow-xs"
                                    placeholder={'Enter a description...'}></textarea>
                            </section>
                        </div>
                        {history}
                        <div className="pt-8 pb-6 px-6 flex items-center gap-3 border-t border-gray-light/200">
                            <button type="reset" onClick={handleClose} className="flex-1 py-2.5 px-4 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg text-md-semibold text-gray-light/700">Cancel</button>
                            <button disabled={loading} type="submit" className="flex-1 py-2.5 px-4 shadow-shadows/shadow-xs bg-brand/600 border border-brand/600 rounded-lg text-md-semibold text-white">Submit</button>
                        </div>
                    </form>
                </div>
            </Popover>
        </>
    );
};

export default PopperNewReport;