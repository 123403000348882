import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import { useApp } from "./AppContext";
import { newMessage, notify } from "../helpers/Helpers";
import { CheckCircle } from "untitledui-js/icons/general";
import $ from "jquery";
import { isEmpty, random } from "lodash";
const { socket } = HttpRequests;


const MessageContext = createContext();
const MessageProvider = props => {
    const { getSession, logoutLocal } = useApp()
    const [searchRes, setSearchRes] = useState();
    const [currentModal, setCurrentModal] = useState();
    const [currentSlider, setCurrentSlider] = useState({ status: null, current: null });
    const [params, setParams] = useState({
        page: 1,
        current: null,
        bottom: false
    });
    const [text, setText] = useState('');
    const [tasks, setTasks] = useState([]);
    const [filter, setFilter] = useState([]);
    const page = useState(1);
    const [chatRoom, setChatRoom] = useState();
    const [current, setCurrent] = useState(null);
    const [isLoadingFetchMessage, setIsLoadingFetchMessage] = useState(false);

    const handleCurrentSlider = (slider, data) => {
        if (slider && slider.status) {
            setCurrentSlider({ status: slider.status, current: slider.current })
        } else {
            setCurrentSlider({ status: false, current: null })
        }
    }

    const handleCurrentModal = (current) => {
        setCurrentModal(current)
    }
    const handleSelectedTask = (task, target) => {
        setChatRoom(null);
        if (task.messages.length !== 0) {
            task.messages[0].read_time = new Date();
        }
        console.log('target', target)
        setParams({
            page: 1,
            target: target,
            current: task,
            bottom: false,
        })
    }

    const getTask = async () => {
        return await HttpRequests.getTask().then(res => {
            setTasks(res.data);
            setFilter(res.filter);
            return res.data
        }).catch(notify);
    }
    const fetchMessage = HttpRequests.getMessage
    const getMessage = useCallback(
        async () => {
            if (chatRoom?.last) return;
            if (chatRoom?.isLoading) return;
            setChatRoom({ ...chatRoom, isLoading: true })
            return await HttpRequests.getMessage(params.current.id, { page: params.page })
                .then(res => {
                    if (params.page !== 1) {
                        if (res.data.messages.length != 0)
                            res.data.messages[res.data.messages.length - 1].last = true;
                        var result = [...res.data.messages, ...chatRoom.messages].sort((a, b) => new Date(a.send_time) - new Date(b.send_time))
                        result = result.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
                        chatRoom.last = res.data.messages.length == 0;
                        chatRoom.messages = result;
                        setChatRoom({ ...chatRoom, isLoading: false, meta: res.meta })
                        if (params.target && res.data.messages && !chatRoom.last && !result.map(e => e.id).includes(params.target))
                            setParams(value => { return { ...value, page: value.page + 1 } })
                        return res.data.messages
                    } else {
                        setChatRoom({ ...res.data, isLoading: false, meta: res.meta })
                        if (params.target && res.data.messages && !res.data.messages.map(e => e.id).includes(params.target))
                            setParams(value => { return { ...value, page: value.page + 1 } })
                        return res.data.messages
                    }
                })
                .catch(notify);
        },
        [params, chatRoom]
    )

    const sendMessage = async () => {
        await HttpRequests.sendMessage(params.current.id, text.replace(/\n/g, '\\n'))
            .then(res => {
                // res.data.is_sender = true;
                // var result = [res.data, ...chatRoom.messages].sort((a, b) => new Date(a.send_time) - new Date(b.send_time))
                // chatRoom.messages = result
                // setChatRoom({ ...chatRoom })
            })
            .catch(notify);
        setText('')
    }

    const assignSim = async () => {
        await HttpRequests.assignSim()
            .then(res => {
                notify(res, {
                    icon: <CheckCircle size={20} stroke={'currentColor'} />,
                    onDismiss: () => {
                        // TODO Dismiss new add number
                    }
                })
                getSession()
                    .then(() => logoutLocal())
            })
            .catch(notify);
    }
    const discardSim = async () => {
        if (window.confirm('Anda yakin ingit melepas nomor ini?'))
            await HttpRequests.removeSim()
                .then(res => {
                    notify(res)
                    getSession()
                        .then(() => logoutLocal())
                })
                .catch(notify);
    }

    const reportAttempt = async (id, data) => {
        await HttpRequests.reportAttempt(id, data)
            .then(res => {
                notify(res, {
                    icon: <CheckCircle size={20} stroke={'currentColor'} />,
                    onDismiss: () => {
                        // TODO Dismiss new add number
                    }
                })
                getTask().then(e => {
                    var task = e.find(task => task.id == params.current.id)
                    setParams({
                        current: task,
                        page: 1,
                        bottom: false,
                    })
                });
            })
            .catch(notify);
    }
    const attemptHistory = async (setData) => {
        await HttpRequests.attemptHistory(params.current.id).then(res => {
            setData(res.data);
        }).catch(notify);
    }
    const search = async (e, filter) => {
        setParams({
            page: 1,
            current: null,
            bottom: false
        })
        console.log('e.target.value', e?.target?.value, filter)
        if (!e?.target?.value?.length && isEmpty(filter)) return setSearchRes(null)
        await HttpRequests.search(e?.target?.value, filter).then(res => {
            if (res?.data?.messages && e?.target?.value) {
                res?.data?.messages.forEach((element) => {
                    if (element.messages.length) {
                        var text = element.messages[0].text;
                        var idx = text.toLowerCase().indexOf(e.target.value.toLowerCase());
                        var highlightedText = ' <span class="text-sm-bold text-gray-light/900">' + text.substring(idx, idx + e.target.value.length) + '</span>';
                        element.messages[0].textPreview = text.substring(0, idx) + highlightedText + text.substring(idx + e.target.value.length);
                    }
                });
            }
            if (res?.data?.tasks) {
                res?.data?.tasks.forEach((element) => {
                    if (element.messages.length && e?.target?.value) {
                        var text = element.messages[0].text;
                        var idx = text.toLowerCase().indexOf(e.target.value);
                        var highlightedText = ' <span class="text-sm-bold text-gray-light/900">' + text.substring(idx, idx + e.target.value.length) + '</span>';
                        element.messages[0].textPreview = text.substring(0, idx) + highlightedText + text.substring(idx + e.target.value.length);
                    }
                });
            }


            setSearchRes(res.data);
        }).catch(notify);
    }
    const getTemplate = async (e) => {
        return await HttpRequests.searchTemplate(e?.target?.value ?? '').catch(notify);
    }
    const sendTemplate = async (message) => {
        var text = message?.text;
        if (!text) return notify({ message: 'This template is not valid', status: 500 })
        text = [text, ...message.generates][random(0, message.generates.length)]
        params.current.custom.map(e => {
            var label = e.label.split('} {')[1].replace('}', '')
            text = text.replace(new RegExp(`<<${label}>>`, 'ig'), e.value)
        })
        if (params.current.detail_customer) {
            text = text.replace(new RegExp(`<<Receiver Name>>`, 'ig'), params.current.detail_customer.name)
        }
        if (text.includes("<<")) return notify({ message: 'This template is not valid', status: 500 })
        await HttpRequests.sendMessage(params.current.id, text.replace(/\n/g, '\\n'))
            .catch(notify);
        setText('')
    }
    const handleCheckNumber = async (id) => {
        if (!id) return notify({ message: 'Number Invalid', status: 500 })
        console.log("check number", id)
        const testing = await HttpRequests.checkNumber(id).catch(notify);
        if(testing?.status) notify({status: 200, message: 'Phone Number registered'});
        console.log('check',testing);
    }
    return <MessageContext.Provider value={{
        page, getTask, tasks, current, setCurrent, setChatRoom, chatRoom, getMessage, sendMessage, setText, text, assignSim,
        currentSlider, setCurrentSlider, handleSelectedTask,
        currentModal, setCurrentModal,
        handleCurrentSlider, handleCurrentModal, search, searchRes,
        params, setParams, reportAttempt, attemptHistory,
        fetchMessage, getTemplate, sendTemplate, discardSim,
        filter, setFilter, handleCheckNumber
    }}> {props.children}</MessageContext.Provider >;
};

const useMessage = () => {
    const context = useContext(MessageContext);
    if (context === undefined) {
        throw new Error('useMessage must be used within a MessageProvider')
    }
    return context;
}

export { MessageProvider, useMessage };