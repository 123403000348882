import React, { createContext, useContext, useState, useEffect } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useCookies } from "react-cookie";
import { notify, saveDataWithExpiration } from "../helpers/Helpers";
import { error } from "jquery";
import { AlertCircle } from "untitledui-js/icons/alerts";


const LoginContext = createContext();
const LoginProvider = props => {
    const [cookies, setCookie] = useCookies(['token']);

    const loginSchema = Yup.object().shape({
        email: Yup.string().required('Email harus diisi').email('Masukkan alamat email yang valid'),
        password: Yup.string().required('Password harus diisi').min(6, 'Password harus memiliki setidaknya 6 karakter'),
    });
    const formLogin = useForm({
        resolver: yupResolver(loginSchema)
    });

    const { register, watch, setValue, handleSubmit, control, formState: { errors }, trigger } = formLogin;

    const handleChangeEmail = async (value) => {
        const valid = await trigger("email");
        // TODO onChange email
    }
    const handleChangePassword = async (value) => {
        const valid = await trigger("password");
        // TODO onChange password
    }

    const setCookieToken = (token) => {
        setCookie('token', token, { path: '/', maxAge: 31536000 });
    }

    const checkLoginValid = () => {
        const { email, password } = watch();
        if (!email || !password || errors.email || errors.password) {
            return false;
        }

        return true;
    }

    const loginError = async (errors, e) => {
        console.log('error', errors, e);
    }

    const login = async (data) => {
        var body = { "email": data.email, "password": data.password }
        await HttpRequests.login(body)
            .then(response => {
                if (data?.remember) {
                    saveDataWithExpiration('login', { email: data?.email, password: data.password, remember: data.remember }, 7);
                } else {
                    localStorage.removeItem('login');
                }
                // notify(response)
                setCookieToken(response.token)
                window.location.href = "/";
            })
            .catch(notify)
    }

    return <LoginContext.Provider value={{
        ...formLogin, loginSchema,
        checkLoginValid, handleChangeEmail, handleChangePassword,
        login, loginError,
    }}> {props.children}</LoginContext.Provider >;
};
const useLogin = () => {
    const context = useContext(LoginContext);
    if (context === undefined) {
        throw new Error('useLogin must be used within a LoginProvider')
    }
    return context;
}

export { LoginProvider, useLogin };