import React, { createContext, useContext } from "react";

const ForgotPasswordContext = createContext();
const ForgotPasswordProvider = props => {

    return <ForgotPasswordContext.Provider value={{
    }}> {props.children}</ForgotPasswordContext.Provider >;
};

const useForgotPassword = () => {
    const context = useContext(ForgotPasswordContext);
    if (context === undefined) {
        throw new Error('userForgotPassword must be used within a ForgotPasswordProvider')
    }
    return context;
}

export { ForgotPasswordProvider, useForgotPassword };