import React from "react";

const MyStepper = ({ steps = [], activeStep = 0 }) => {
    return (
        <div>
            {steps.map((step, i) => {
                return (<div className={`${i <= activeStep ? '' : 'opacity-60'} flex gap-x-4`}>
                    <div className="flex flex-col items-center pb-1 gap-y-1">
                        <span className="text-gray-light/700 w-12 h-12 min-w-[48px] min-h-[48px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                            {step.icon ?? <>{i + 1}</>}
                        </span>
                        <div className="bg-gray-light/200 flex-1 w-0.5" />
                    </div>
                    <div className="flex flex-col gap-y-0.5 pb-8 flex-1">
                        <p className="text-md-semibold text-gray-light/700">{step.label ?? '-'}</p>
                        <p className="text-md-regular text-gray-light/600">{step.description ?? '-'}</p>
                    </div>
                </div>)
            })}
        </div>
    );
};

export default MyStepper;