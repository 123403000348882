import React, { } from "react";
import SimpleBar from "simplebar-react";
import MyTextField from "../../components/TextField/MyTextField";

const Profile = () => {
    return (
        <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
            <main className="w-full h-full pt-8 pb-12 flex flex-col gap-8">
                <header className="px-8 flex flex-col gap-y-5">
                    <div className="flex flex-col gap-1 flex-1">
                        <p className="display-sm-semibold text-gray-light/900">Profile</p>
                        <p className="text-md-regular text-gray-light/600">Here are the details of your profile.</p>
                    </div>
                    <hr className="border-gray-light/200" />
                </header>
                <div className="flex-1 flex flex-col gap-y-8">
                    <div className="px-8 flex flex-col gap-6 w-full">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex items-start gap-4">
                                <div className="flex flex-col gap-1 flex-1">
                                    <p className="text-lg-semibold text-gray-light/900">Personal info</p>
                                    <p className="text-sm-regular text-gray-light/600">Update your photo and personal details here.</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <button className="w-max h-max flex items-center text-sm-semibold border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                        Cancel
                                    </button>
                                    <button className={`bg-gray-light/100 border border-gray-light/200 text-gray-light/400 text-center shadow-shadows/shadow-xs text-md-semibold px-4 py-2.5 w-full rounded-lg`}>
                                        Save
                                    </button>
                                </div>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-5">
                            <div className="flex items-start gap-x-8">
                                <div className="w-full max-w-[280px]">
                                    <p className="text-sm-semibold text-gray-light/700">Name</p>
                                </div>
                                <div className="w-full max-w-lg">
                                    <MyTextField placeholder={'Enter your name'} />
                                </div>
                            </div>
                            <hr className='border-gray-light/200' />
                            <div className="flex items-start gap-x-8">
                                <div className="w-full max-w-[280px]">
                                    <p className="text-sm-semibold text-gray-light/700">Email address</p>
                                </div>
                                <div className="w-full max-w-lg">
                                    <MyTextField placeholder={'Enter your email'} />
                                </div>
                            </div>
                            <hr className='border-gray-light/200' />
                            <div className="flex items-start gap-x-8">
                                <div className="w-full max-w-[280px]">
                                    <p className="text-sm-semibold text-gray-light/700">Your photo</p>
                                    <p className="text-sm-regular text-gray-light/600">This will be displayed on your profile.</p>
                                </div>
                                <div className="flex items-start justify-between w-full max-w-lg">
                                    <div className="w-16 h-16 min-w-[64px] min-h-[64px] rounded-full overflow-hidden">
                                        <img className="w-full h-full object-cover" src="" alt="" />
                                    </div>
                                    <div className="flex gap-x-4">
                                        <button className="text-sm-semibold text-gray-light/600">Delete</button>
                                        <button className="text-sm-semibold text-brand/700">Update</button>
                                    </div>
                                </div>
                            </div>
                            <hr className='border-gray-light/200' />
                            <div className="flex items-start gap-x-8">
                                <div className="w-full max-w-[280px]">
                                    <p className="text-sm-semibold text-gray-light/700">Role</p>
                                </div>
                                <div className="w-full max-w-lg">
                                    <MyTextField placeholder={'Enter your role'} />
                                </div>
                            </div>
                            <hr className='border-gray-light/200' />
                        </div>
                    </div>
                    <div className="px-8 flex flex-col gap-6 w-full">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex items-start gap-4">
                                <div className="flex flex-col gap-1 flex-1">
                                    <p className="text-lg-semibold text-gray-light/900">Password</p>
                                    <p className="text-sm-regular text-gray-light/600">Change your password.</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <button className="w-max h-max flex items-center text-sm-semibold border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                        Cancel
                                    </button>
                                    <button className={`bg-brand/600 text-white text-center shadow-shadows/shadow-xs text-md-semibold px-4 py-2.5 w-full rounded-lg`}>
                                        Update password
                                    </button>
                                </div>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-5">
                            <div className="flex items-start gap-x-8">
                                <div className="w-full max-w-[280px]">
                                    <p className="text-sm-semibold text-gray-light/700">New password</p>
                                </div>
                                <div className="w-full max-w-lg flex flex-col gap-1.5">
                                    <MyTextField placeholder={'Enter your new password'} />
                                    <p className="text-sm-regular text-gray-light/600">Your new password must be more than 8 characters.</p>
                                </div>
                            </div>
                            <hr className='border-gray-light/200' />
                            <div className="flex items-start gap-x-8">
                                <div className="w-full max-w-[280px]">
                                    <p className="text-sm-semibold text-gray-light/700">Confirm new password</p>
                                </div>
                                <div className="w-full max-w-lg">
                                    <MyTextField placeholder={'Enter your confirm new password'} />
                                </div>
                            </div>
                          
                        </div>
                    </div>

                </div>
            </main>
        </SimpleBar>
    );
};

export default Profile;