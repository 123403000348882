import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import { loremIpsum } from 'lorem-ipsum';

import usePrevious from './use-previous';
import { useMessage } from '../../../contexts/MessageContext';
import MessageSenderBox from '../../../components/Message/MessageSenderBox';
import MessageReceiverBox from '../../../components/Message/MessageReceiverBox';
import moment from 'moment';
import MessageDivheaderBox from '../../../components/Message/MessageDivheaderBox';
import { socket } from '../../../services/NetworkUtils';
import HttpRequests from '../../../services/ApiServiceHttp';
import { useApp } from '../../../contexts/AppContext';
import { User01 } from 'untitledui-js/icons/users';
import { newMessage, notify } from '../../../helpers/Helpers';
import $ from "jquery";
import SimpleBar from 'simplebar-react';
import { debounce } from 'lodash';
var timout;

// globalThis.VIRTUOSO_LOG_LEVEL = 0;

// const START_INDEX = 500;
// const PAGE_SIZE = 10;


// const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const savedMessages = {};
function MessagesList({ virtuoso, messages, params, searchRes, customer, max, startReached }) {
    const [scrollParent, setScrollParent] = useState(null);
    // START_INDEX starting value is the max value, makes figuring out the index in the messages array easier
    // const [firstItemIndex, setFirstItemIndex] = useState(max - messages.length);
    const [firstItemIndex, setFirstItemIndex] = useState(() => {
        // if (searchRes && params.target) {
        //     var idxExist = messages.findIndex((e) => e.id === params.target);
        //     if (idxExist !== -1) {
        //         console.log("LINE 41", idxExist);
        //         return idxExist;
        //     } else {
        //         return 0;
        //     }
        // }
        // else {
        //     return 0;
        return max;
        // }

    });

    // console.log('MessagesList: Starting firstItemIndex', firstItemIndex);
    // console.log('MessagesList: Starting messages length', messages.length);

    // keep a copy of messages so I can set the next first item index before new messages set
    const internalMessages = useMemo(() => {
        if (searchRes && params.target) {
            var idxExist = messages.findIndex((e) => e.id === params.target);
            if (idxExist !== -1) {
                console.log("LINE 58", idxExist);
                setFirstItemIndex(messages.length - idxExist);
                // setTimeout(() => {
                // console.log("jalannn", params.target)
                // if (!messages.length) return;
                // var $element = $(`#${params.target}`);
                // console.log("nemu", $element)
                // if ($element.length) {
                //     $element[0].scrollIntoView({ behavior: 'smooth' });
                // }
                // }, 1000);
            } else {
                console.log('aaa', messages.length - 1)
                setFirstItemIndex(1);
                timout = setTimeout(() => {
                    clearTimeout(timout)
                    startReached()
                }, 1000);
            }
        } else {
            const nextFirstItemIndex = max - messages.length;
            setFirstItemIndex(nextFirstItemIndex);
        }


        return messages;
    }, [messages]);

    const itemContent = useCallback((i, message) => {
        const date = moment(message.send_time).format("YYYY-MM-DD");
        return (
            <div key={message.id} className="flex flex-col px-6">
                {message.is_start_date ? <MessageDivheaderBox date={date} /> : <></>}
                <div className='flex flex-col py-2'>
                    {(message.is_sender) ? <MessageSenderBox key={i} message={message} />
                        : <MessageReceiverBox key={i} message={message} customer={customer} />}
                </div>
            </div>
        )
    }, []);

    // setting 'auto' for behavior does help in this sample, but not in my actual code
    const followOutput = useCallback((isAtBottom) => {
        return isAtBottom ? 'smooth' : false;
    }, []);

    return (
        <div className="h-full w-full " id="scrollbar-chat-box">
            <SimpleBar ref={setScrollParent} style={{ height: '100%' }}>
                <Virtuoso
                    ref={virtuoso}
                    customScrollParent={scrollParent ? scrollParent.contentWrapperEl : undefined}
                    // initialTopMostItemIndex={0}
                    increaseViewportBy={500}
                    initialTopMostItemIndex={Math.max(0, firstItemIndex)}
                    firstItemIndex={Math.max(0, firstItemIndex)}
                    itemContent={itemContent}
                    data={internalMessages}
                    startReached={startReached}
                    followOutput={followOutput}
                />
            </SimpleBar>
        </div>
    );
}

export default function Chat({ current }) {
    const { session } = useApp()
    const chatId = current?.id
    const { fetchMessage, getTask, params, searchRes } = useMessage()
    const [messages, setMessages] = useState([]);
    const [customer, setCustomer] = useState(0);
    const [max, setMax] = useState(0);
    const [last, setLast] = useState(false);
    const [page, setPage] = useState();
    const prevChatId = usePrevious(chatId);

    const virtuoso = useRef(null);

    useEffect(() => {
        console.log('Chat: set messages for chat id', chatId);
        fetchMessage(chatId, { page: 1 }).then(result => {
            setMax(result.meta.total);
            setCustomer(result.data.customer);
            grouping(result.data.messages);
            setMessages(result.data.messages);
            setLast(false);
            setPage(result.meta.next_page);

        })
    }, [chatId]);

    useEffect(() => {
    }, [messages])

    useEffect(() => {
        if (session && session.sim_card) {
            socket.on(`violated-${session.sim_card.id}`, (data) => {
                if (messages && data.message.tasks_id == chatId) {
                    var index = messages.findIndex(e => e.id == data.message.id)
                    let newArray = [...messages]
                    if (index !== -1) {
                        newArray[index] = data.message;
                    } else {
                        newArray.push(data.message)
                    }
                    grouping(newArray)
                    setMessages(newArray)
                }
            })
            // socket.on(`command-${session.sim_card.id}`, (data) => {
            //     HttpRequests.sendMessageLocal(data).then(e => {
            //         console.log('sendMessageLocal')
            //         socket.emit('success-send-message', {
            //             instance_id: session.sim_card.id,
            //             message_id: data.message.id,
            //             response: e,
            //         })
            //     })
            //         .catch(console.error)
            // })
            socket.on(`message:${session.sim_card.id}`, (data) => {
                if (data.message.sender_id !== session.id) {
                    newMessage({ status: 200, title: data.message?.sender?.name, message: data.message?.text }, {
                        icon: <User01 size={24} stroke={'currentColor'} />,
                        onDismiss: () => { }
                    })
                }
                if (messages && data.message.tasks_id == chatId) {
                    var index = messages.findIndex(e => e.id == data.message.id)
                    let newArray = [...messages]
                    if (index !== -1) {
                        newArray[index] = data.message;
                    } else {
                        newArray.push(data.message)
                    }
                    grouping(newArray)
                    setMessages(newArray)
                }
                getTask()
            })
        }

        return () => {
            if (session && session.sim_card) {
                socket.off(`command-${session.sim_card.id}`)
                socket.off(`message:${session.sim_card.id}`)
            }
        }
    }, [session, messages])
    useEffect(() => {
        console.log("jalannn", params.target)
        if (!messages.length) return;
        var $element = $(`#${params.target}`);
        console.log("nemu", $element)
        if ($element.length) {
            $element[0].scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatId, messages, params])
    const grouping = (datas = []) => {
        // datas.filter(e => e && e.send_time).reduce((groups, item) => {
        //     item.is_start_date = false
        //     const date = moment(item.send_time).format("YYYY-MM-DD");
        //     if (!groups[date]) item.is_start_date = true;
        //     (groups[date] = groups[date] || []).push(item);
        //     return groups;
        // }, {});

        datas.reduce((groups, item, index, array) => {
            item.is_start_date = false;
            item.isFirstSameUser = false;
            item.isSameUser = false;
            item.isLastSameUser = false;

            const date = moment(item.send_time).format("YYYY-MM-DD");
            const receiverId = item.receiver_id;

            if (!groups[date]) {
                item.is_start_date = true;
            }

            if (index > 0) {
                const prevItem = array[index - 1];
                if (prevItem.receiver_id === receiverId && moment(prevItem.send_time).isSame(item.send_time, 'day')) {
                    item.isSameUser = true;

                    if (index === array.length - 1 || array[index + 1].receiver_id !== receiverId || !moment(array[index + 1].send_time).isSame(item.send_time, 'day')) {
                        item.isLastSameUser = true;
                    }
                }
            }

            const nextItem = (index + 1 < array.length) ? array[index + 1] : null;
            if (nextItem && nextItem.receiver_id === receiverId && moment(nextItem.send_time).isSame(item.send_time, 'day')) {
                if (index > 0) {
                    const prevItem = array[index - 1];
                    if (!prevItem.isFirstSameUser) {
                        item.isFirstSameUser = true;
                        item.isSameUser = true;
                    }
                } else {
                    item.isFirstSameUser = true;
                    item.isSameUser = true;
                }

            }

            groups[date] = groups[date] || [];
            groups[date].push(item);

            return groups;
        }, {});


        console.log("datas", datas);
    }
    const startReached = useCallback((index) => {
        if (last) return;
        if (!page) return;
        fetchMessage(chatId, { page: page }).then(result => {
            const combinedMessages = [...result.data.messages, ...messages];
            savedMessages[chatId] = combinedMessages;
            grouping(combinedMessages);

            if (!result.meta.next_page) setLast(true);
            else setPage(result.meta.next_page);

            setMessages(combinedMessages);
        })
    }, [messages]);

    // if switching lists, unmount virtuoso so internal state gets reset
    if (prevChatId !== chatId) {
        return null;
    }

    return (
        <MessagesList virtuoso={virtuoso} chatId={chatId} max={max}
            customer={customer} messages={messages} params={params} searchRes={searchRes}
            startReached={startReached}
        />
    );
}
