import React from "react";
import TextField from '@mui/material/TextField';
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';

const MyFormTextField = ({ type = "text", name, control, placeholder, onChangeForm }) => {
    const style = {
        // Gaya/gaya komponen TextField
        '& .MuiInputBase-root': {
            borderRadius: '8px',
            padding: '10px 14px',
            height: "44px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
            boxShadow: '0px 1px 2px 0px #1018280D',
            "&.Mui-focused": {
                border: "none",
                boxShadow: '0px 0px 0px 4px #9e77ed3d',
            },
            '& .MuiInputBase-input': {
                padding: "0px"
            },
            '&.Mui-disabled .MuiInputBase-input': {
                WebkitTextFillColor: '#667085',
                color: '#667085',
            },
            '& .MuiInputAdornment-root': {
                marginRight: "0px",
            },
            '& fieldset': {
                border: '1px solid #D0D5DD',
            },
            '&:hover fieldset': {
                border: '1px solid #D0D5DD',
            },
            "&.Mui-focused fieldset": {
                border: '1px solid #D6BBFB',
            },
            "&.Mui-disabled fieldset": {
                border: '1px solid #D0D5DD',
                WebkitTextFillColor: 'red'
            },
        },
    };

    return (
        <Controller name={name} control={control} render={({ field: { value, onChange, ...field } }) => (
            <TextField sx={style} value={value || ''} variant="outlined" autoComplete="off" fullWidth
                name={name} type={type} placeholder={placeholder}
                onChange={({ target: { value } }) => {
                    onChange(value);
                    if (onChangeForm)
                        onChangeForm(value);
                }} />
        )}
        />
    );
};

MyFormTextField.propTypes = {
    control: PropTypes.object.isRequired, // PropTypes untuk memastikan "control" adalah objek yang diperlukan
    type: PropTypes.string,
    name: PropTypes.string.isRequired, // PropTypes untuk memastikan "name" adalah string yang diperlukan
    placeholder: PropTypes.string,
    onChange: PropTypes.func, // PropTypes untuk "onChange" (opsional)
};

export default MyFormTextField;