import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import TableExcel from "./table";
import HttpRequests from "../../services/ApiServiceHttp";
import { notify } from "../../helpers/Helpers";
import { Plus } from "untitledui-js/icons/general";
// import VCard from "vcard-creator";

// import { CSVLink, CSVDownload } from "react-csv";
// var host = "http://localhost:3001";
// var host = process.env.REACT_APP_API_HOST;

// const socket = io(host);
const ModalMessage = ({ reload }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const multiIndexOf = (arr, el) => {
    var idxs = [];
    for (var i = arr.length - 1; i >= 0; i--) {
      if (arr[i].includes(el)) {
        idxs.unshift(i);
      }
    }
    return idxs;
  };

  const readFile = async (e) => {
    console.log("reading input file:");
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    // const templates = [];
    // workbook.SheetNames.forEach((element) => {
    //   if (element.includes("template"))
    //     templates.push(workbook.Sheets[element]);
    // });
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });
    var headers = jsonData[0];
    var fields = headers
      .filter((e) => e.includes("{") && e.includes("}") && !e.includes('dynamic_label') && !e.includes('custom_field'))
      .map((e) => headers.findIndex((column) => column === e));
    console.log(headers)
    var dynamic = multiIndexOf(headers, "{dynamic_label}")
    var custom = multiIndexOf(headers, "{custom_field}")
    console.log(fields, dynamic, custom)
    // var number = headers
    //   .filter((e) => e.toLowerCase() === "number")
    //   .map((e) => headers.findIndex((column) => column === e));
    // var templateTexts = templates.map(
    //   (e) =>
    //     XLSX.utils.sheet_to_json(e, {
    //       header: 1,
    //       defval: "",
    //     })[0]
    // );
    var datas = jsonData.slice(1, jsonData.length);
    // var vcards = [];
    datas = datas.map((e) => {
      if (!e[0]) return null;
      return {
        fields: fields.map((field) => {
          return {
            label: headers[field],
            value: e[field],
          };
        }),
        custom: custom.map((field) => {
          return {
            label: headers[field],
            value: e[field],
          };
        }),
        dynamic: dynamic.map((field) => {
          return {
            label: headers[field],
            value: e[field],
          };
        }),
      };
    }).filter(e => e);
    console.log(datas);
    setDatas(datas);
  };
  const submit = async () => {
    setLoading(true);
    await HttpRequests.taskDistribution({ tasks: datas }).then(response => {
      console.log(response)
      notify(response);
    })
      .catch((e) => {
        notify(e);
      })
      .finally(() => {
        setLoading(false);
        setShowModal(false);
        reload()
      });
  };
  // const getActiveNumber = (inputValue) =>
  //   new Promise((resolve) => {
  //     axios.get(host + "/v1/instance?status=active").then(({ data }) => {
  //       resolve(
  //         data.data.map((e) => {
  //           return { label: e.number, value: e.id };
  //         })
  //       );
  //     });
  //   });
  //   const downloadTxtFile = (datas) => {
  //     var vcards = [];
  //     datas.map((e) => {
  //       const myVCard = new VCard();
  //       myVCard
  //         .addName(e.fields[0].value, new Date().getTime())
  //         .addPhoneNumber(e.number);
  //       vcards.push(myVCard.buildVCard());
  //     });

  //     const element = document.createElement("a");
  //     const file = new Blob([vcards.join("")], {
  //       type: "text/plain",
  //     });
  //     element.href = URL.createObjectURL(file);
  //     element.download = "contact.vcf";
  //     document.body.appendChild(element); // Required for this to work in FireFox
  //     element.click();
  //     element.remove();
  //   };
  return (
    <>
      <button onClick={() => { setShowModal(true) }} className="w-max h-max bg-brand/600 flex items-center rounded-lg px-3 py-2 gap-x-1 text-white">
        <Plus size={20} stroke={'currentColor'} />
        <p className="text-sm-semibold">Add plan</p>
      </button>

      {showModal ? (
        <>
          <div
            className="py-12 bg-gray-700 bg-opacity-60 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0"
            id="modal"
          >
            <div role="alert" className="container mx-auto w-11/12 md:w-2/3">
              <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                {/* <div className="w-full flex justify-start text-gray-600 mb-3"></div>
                <label
                  htmlFor="email2"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Whatsapp Number
                </label>
                <div className="relative mb-5 mt-2">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={getActiveNumber}
                  />
                </div> */}
                <div className="w-full flex justify-start text-gray-600 mb-3"></div>
                <label
                  htmlFor="email2"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Upload Xlsx File
                </label>
                <div className="relative mb-5 mt-2 flex justify-center">
                  <Upload
                    className="ml-24"
                    type="file"
                    onInput={(e) => readFile(e)}
                  />
                </div>

                {datas.length !== 0 && (
                  <div className="overflow-scroll h-1/2">
                    {<TableExcel datas={datas} />}
                  </div>
                )}
                <div className="flex items-center justify-start w-full">
                  <button
                    disabled={loading}
                    onClick={submit}
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                  >
                    Submit
                  </button>
                  <button
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
                <button
                  className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                  onClick={() => setShowModal(false)}
                  aria-label="close modal"
                  role="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-x"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};



const Upload = ({ onInput }) => {
  return (
    <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
      <svg
        className="w-8 h-8"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
      </svg>
      <span className="mt-2 text-base leading-normal">Select a file</span>
      <input type="file" className="hidden" onInput={onInput} />
    </label>
  );
};
export default ModalMessage;
