import React from "react";
import { ArrowLeft, ArrowUp, SwitchVertical01 } from "untitledui-js/icons/arrow";
import { DownloadCloud02, FilterLines } from "untitledui-js/icons/general";
import SimpleBar from "simplebar-react";
import MyPagination from "../../../../components/Pagination/MyPagination";
import MySorted from "../../../SimCard/Poppers/sorted";
import MyFilter from "../../../SimCard/Poppers/filter";
import MyTextField from "../../../../components/TextField/MyTextField";

const TopRuleBreakers = () => {
    return (
        <>
            <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
                <main className="pt-8 pb-12 flex flex-col gap-8">
                    <div className="px-8 flex flex-col gap-y-6 w-full">
                        <button className="flex items-center gap-1.5 text-brand/700">
                            <ArrowLeft size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Back to more report</p>
                        </button>
                        <div className="w-full flex items-start gap-x-4">
                            <div className="flex flex-col gap-y-1 w-full flex-1">
                                <p className="display-sm-semibold text-gray-light/900">Violater</p>
                                <p className="text-md-regular text-gray-light/600">View the top violater in your team, determined by the number of rules violated.</p>
                            </div>
                            <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                <DownloadCloud02 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Export</p>
                            </button>
                        </div>
                        <div className="w-max border border-gray-light/300 rounded-lg shadow-shadows/shadow-xs overflow-hidden">
                            <button className="px-4 py-2 text-gray-light/700 text-sm-semibold border-r border-border-gray-light/300">
                                Hourly
                            </button>
                            <button className="px-4 py-2 text-gray-light/700 text-sm-semibold border-r border-border-gray-light/300">
                                Daily
                            </button>
                            <button className="px-4 py-2 text-gray-light/700 text-sm-semibold border-r border-border-gray-light/300">
                                Monthly
                            </button>
                            <button className="bg-gray-light/50 px-4 py-2 text-gray-light/700 text-sm-semibold">
                                Yearly
                            </button>
                        </div>
                    </div>

                    <div className="px-8">
                        <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                            <div className="py-3 px-4 flex gap-3">
                                <div className="w-full max-w-xs">
                                    <MyTextField placeholder={'Search'} />
                                </div>
                                {/* <MyFilter id={'filter-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                                    <FilterLines size={20} stroke={'currentColor'} />
                                    <p className="text-sm-semibold">Filter</p>
                                </button>)} />

                                <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                                    <SwitchVertical01 size={20} stroke={'currentColor'} />
                                    <p className="text-sm-semibold">Sort</p>
                                </button>)} /> */}
                            </div>
                            <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                                <table className='table border-collapse w-full'>
                                    <thead className='border-t border-gray-light/200 p-0'>
                                        <tr className='p-0'>
                                            <th className='px-6 py-3'>
                                                <div className="flex items-center gap-x-1 text-gray-light/600">
                                                    <p className="text-xs-medium  whitespace-nowrap">Period</p>
                                                    {/* <ArrowUp size={16} stroke={'currentColor'} /> */}
                                                </div>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">User</p>
                                            </th>
                                            <th className='px-6 py-3 text-right'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Rules violated</p>
                                            </th>
                                            <th className='px-6 py-3 text-right'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Words violated</p>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Most rule violated</p>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Most word violated</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[1, 2, 3, 4, 5].map((e, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">28 May 2023</p>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div className="flex items-center gap-3">
                                                            <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                                <img className="w-full h-full object-cover" src="" alt="" />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">Katherine Moss</p>
                                                                <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">08*******192</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className='px-6 py-4 text-right border-t border-gray-light/200'>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">2</p>
                                                    </td>
                                                    <td className='px-6 py-4 text-right border-t border-gray-light/200'>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">10</p>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-b border-gray-light/200'>
                                                        <div className="flex items-center gap-x-1">
                                                            <div className="w-max bg-gray-light/50 px-1.5 py-0.5 rounded-md border border-gray-light/200">
                                                                <p className="text-xs-medium text-gray-light/700">Rule 1</p>
                                                            </div>
                                                            <div className="w-max bg-gray-light/50 px-1.5 py-0.5 rounded-md border border-gray-light/200">
                                                                <p className="text-xs-medium text-gray-light/700">Rule 2</p>
                                                            </div>
                                                            <div className="w-max bg-gray-light/50 px-1.5 py-0.5 rounded-md border border-gray-light/200">
                                                                <p className="text-xs-medium text-gray-light/700">Rule 4</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div className="flex items-center gap-1">
                                                            <div className="w-max px-2 py-0.5 border rounded-full border-error/600 ">
                                                                <p className="text-xs-medium text-error/700">gila</p>
                                                            </div>
                                                            <div className="w-max px-2 py-0.5 border rounded-full border-error/600 ">
                                                                <p className="text-xs-medium text-error/700">jengkel</p>
                                                            </div>
                                                            <div className="w-max px-2 py-0.5 border rounded-full border-error/600 ">
                                                                <p className="text-xs-medium text-error/700">bodoh</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </SimpleBar>
                            <div className="border-t border-gray-light/200">
                                <MyPagination meta={null} />
                            </div>
                        </div>
                    </div>
                </main>
            </SimpleBar>
        </>
    );
};

export default TopRuleBreakers;