import React, { useState } from "react";
import { Popover } from "@mui/material";
import { Hash02, Plus, SearchLG } from "untitledui-js/icons/general";
import SimpleBar from "simplebar-react";
import MyIconPatternDecorative from "../../../components/Icon/MyIconPatternDecorative";
import { SimCard } from "untitledui-js/icons/media-devices";

const PopperAssignNew = ({ id, placement = "bottom-start", target, onClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    return (
        <>
            {target && target(open, handleClick)}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={(placement === 'bottom-start') ? { vertical: "bottom", horizontal: "left" } : { vertical: "top", horizontal: "right" }}
                transformOrigin={(placement === 'bottom-start') ? { vertical: "top", horizontal: "left" } : { vertical: "bottom", horizontal: "right" }}
                sx={{
                    '&.MuiPopover-root .MuiPaper-root': {
                        marginTop: placement === 'bottom-start' ? "8px" : "-8px",
                        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                        border: '1px solid #EAECF0',
                        borderRadius: '12px',
                        width: 'max-content',
                    }
                }}
            >
                <div className="flex flex-col w-[400px] h-max">
                    <div className="p-6 pb-5 flex flex-col gap-y-4">
                        <MyIconPatternDecorative>
                            <div className="w-max p-3 rounded-xl border border-gray-light/200 shadow-shadows/shadow-xs">
                                <SimCard size={24} className={'text-gray-light/700'} stroke={'currentColor'} />
                            </div>
                        </MyIconPatternDecorative>
                        <div className="flex flex-col z-20">
                            <p className="text-lg-semibold text-gray-light/900">Assign SIM card</p>
                            <p className="text-sm-regular text-gray-light/600">The delivery of your messages will take place through new number.</p>
                        </div>
                    </div>
                    <div className="pt-8 pb-6 px-6 flex items-center justify-end gap-3">
                        <button onClick={handleClose} className="w-max py-2.5 px-4 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg text-md-semibold text-gray-light/700">Cancel</button>
                        {/* <button onClick={() => { onClick(); handleClose() }} className="w-max py-2.5 px-4 shadow-shadows/shadow-xs bg-brand/600 border border-brand/600 rounded-lg text-md-semibold text-white">Assign new</button> */}
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default PopperAssignNew;