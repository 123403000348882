import React, { useEffect, useState } from 'react'
import MyPopper from '../Poppper/MyPopper';
import { Calendar } from 'react-date-range';
import { ChevronLeft, ChevronRight } from 'untitledui-js/icons/arrow';
import { format, parse } from 'date-fns';
import MyTextFieldV2 from '../TextField/MyTextFieldv2';
import { AlertTriangle } from 'untitledui-js/icons/alerts';

const MyCalendar = ({ target, value = null, onChange }) => {
    const [isInvalidDate, setIsInvalidDate] = useState(false);
    const [inputDate, setInputDate] = useState(value && format(value, 'MMM d, yyyy'));
    const [date, setDate] = useState(value);

    useEffect(() => {
        if (date) {
            setInputDate(format(date, 'MMM d, yyyy'));
            setIsInvalidDate(false);
        }
    }, [date])

    return (
        <>
            <MyPopper id={'my-calendar'} target={target} placement={'bottom-start'}>
                {(open, handleOpen, handleClose) => (
                    <div className='flex flex-col w-[328px]'>
                        <div className='px-6 py-5'>
                            <Calendar onChange={item => setDate(item)} date={date}
                                navigatorRenderer={(focusedDate, changeShownDate, props) => {
                                    return (<div className='flex flex-col gap-3 pb-3' onMouseUp={e => e.stopPropagation()}>
                                        <div className='flex items-center justify-between'>
                                            <button onClick={() => changeShownDate(-1, 'monthOffset')} className='w-10 h-10 p-2 flex items-center justify-center'>
                                                <ChevronLeft size={20} stroke={'currentColor'} />
                                            </button>
                                            <p className='text-md-semibold text-gray-light/700'>{format(focusedDate, 'MMMM yyyy')}</p>
                                            <button onClick={() => changeShownDate(+1, 'monthOffset')} className='w-10 h-10 p-2 flex items-center justify-center'>
                                                <ChevronRight size={20} stroke={'currentColor'} />
                                            </button>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <MyTextFieldV2 value={inputDate} isRealtime={true}
                                                placeholder={"MMM d, yyyy"}
                                                onChangeForm={(e) => setInputDate(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        const parsedDate = parse(e.target.value, 'MMM d, yyyy', new Date());
                                                        if (!isNaN(parsedDate.getTime())) {
                                                            changeShownDate(parsedDate);
                                                            setDate(parsedDate);
                                                        } else {
                                                            setIsInvalidDate(true);
                                                        }
                                                    }
                                                }}
                                                endAdornment={isInvalidDate && <AlertTriangle className={'text-error/700'} size={20} stroke={'currentColor'} />} />
                                            <button className='px-[14px] py-2.5 border border-gray-light/300 rounded-lg shadow-shadows/shadow-xs text-sm-semibold text-gray-light/900'
                                                onClick={() => {
                                                    var now = new Date();
                                                    changeShownDate(now);
                                                    setDate(now);
                                                }}>
                                                Today
                                            </button>
                                        </div>
                                    </div>);
                                }}
                                showMonthAndYearPickers={false}
                                weekStartsOn={1}
                                weekdayDisplayFormat={'EEEEEE'}
                                rangeColors={['#7F56D9']}
                                color={'#7F56D9'}
                            />
                        </div>
                        <footer className="border-t border-gray-light/200 p-4 flex items-center gap-3">
                            <button onClick={handleClose} type="button" className="flex-1 text-center px-[14px] py-2.5 flex items-center justify-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/700">Cancel</p>
                            </button>
                            <button onClick={() => {
                                onChange && onChange(date);
                                handleClose();
                            }} type="button" className="flex-1 text-center px-[14px] py-2.5 flex items-center justify-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Apply</p>
                            </button>
                        </footer>
                    </div>
                )}
            </MyPopper>
        </>
    )
}

export default MyCalendar