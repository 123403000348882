import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowsDown, ArrowsUp, SwitchVertical01 } from "untitledui-js/icons/arrow";
import { TrendDown01, TrendUp01 } from "untitledui-js/icons/charts";
import MyPagination from "../../../components/Pagination/MyPagination";
import MyTextField from "../../../components/TextField/MyTextField";
import MyFilter from "../../SimCard/Poppers/filter";
import { DownloadCloud02, FilterLines, Settings03 } from "untitledui-js/icons/general";
import MySorted from "../../SimCard/Poppers/sorted";
import SimpleBar from "simplebar-react";
import { useDashboard } from "../../../contexts/DashboardContext";
import moment from "moment";
import { User01 } from "untitledui-js/icons/users";
import { debounce } from "lodash";
import { useApp } from "../../../contexts/AppContext";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import MyFilterModal from "../../../components/Modal/MyFilterModal";

const Violation = () => {
    const { session } = useApp()
    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState("12m");
    const { violation, params, setParams, getViolation, getViolationChart, downloadViolated } = useDashboard()
    useEffect(() => {
        getViolation()
    }, [params])
    const search = (e) => {
        setParams(value => { return { ...value, search: e?.target?.value, page: 1 } })
    }
    useEffect(() => {
        getViolationChart(dates).then(({ data }) => {
            data.labels = data?.labels?.map(e => {
                if (dates == '24h')
                    e = moment(e, 'hh:mm').add({ minutes: moment().utcOffset() }).format('HH:mma');
                console.log(e)
                return e
            })
            setChart(data)
        })
    }, [dates])
    Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    console.log(chart)
    const debounceSearch = debounce(search, 1000)
    return (
        <>
            <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
                <main className="pt-8 pb-12 flex flex-col gap-8">
                    <div className="px-8 flex">
                        <div className="flex-1 flex flex-col gap-y-1">
                            <p className="display-sm-semibold text-gray-light/900">Conversation monitoring</p>
                            <p className="text-md-regular text-gray-light/600">Monitor and analyze your team's verbal interactions.</p>
                        </div>
                        {/* <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                            <Settings03 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Rule</p>
                        </button> */}
                    </div>
                    <div className="px-8">
                        <div className="bg-white w-full p-6 flex flex-col gap-y-5 rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                            <div className="flex items-center justify-between">
                                <p className="text-lg-semibold gray-light/600">Violated conversation movement</p>
                                {/* <button className="text-sm-semibold text-gray-light/700 px-3 py-2 flex items-center gap-x-1.5 border gray-light/300 rounded-md">
                                    More report
                                </button> */}
                            </div>
                            <div className="flex items-center gap-x-1">
                                <button onClick={() => setDates('12m')} className={[dates == '12m' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                                    12 months
                                </button>
                                <button onClick={() => setDates('3m')} className={[dates == '3m' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                                    3 months
                                </button>
                                <button onClick={() => setDates('30d')} className={[dates == '30d' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                                    30 days
                                </button>
                                <button onClick={() => setDates('7d')} className={[dates == '7d' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                                    7 days
                                </button>
                                <button onClick={() => setDates('24h')} className={[dates == '24h' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                                    24 hours
                                </button>
                            </div>
                            {chart && <Bar
                                height={80}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        title: {
                                            display: true,
                                            text: "Number of rules violated"
                                        }
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            reverse: true,
                                        },
                                        y: {
                                            ticks: {
                                                stepSize: 1,
                                            },
                                        }
                                    }
                                }}
                                data={chart}
                            />}
                        </div>
                    </div>
                    <div className="px-8">
                        <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                            <div className="pt-5 flex flex-col gap-y-5">
                                <div className='px-6 flex items-center gap-x-4 w-full'>
                                    <div className="flex-1 flex-col gap-1">
                                        <p className="text-lg-semibold text-gray-light/900">Recent violation</p>
                                        <p className="text-sm-regular text-gray-light/600">See your team’s most recent violations.</p>
                                    </div>
                                    <div className="flex items-center gap-x-3">
                                        <button onClick={downloadViolated} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                            <DownloadCloud02 size={20} stroke={'currentColor'} />
                                            <p className="text-sm-semibold">Export</p>
                                        </button>
                                    </div>
                                </div>
                                <hr className='border-gray-light/200' />
                            </div>
                            <div className="py-3 px-4 flex gap-3">
                                <div className="w-full max-w-xs">
                                    <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                                </div>
                                <MyFilterModal id={'filter-violated'} currentFilters={violation?.filter} onChange={(filter) => setParams({ ...params, filter: filter, page: 1 })}
                                    target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                                        <FilterLines size={20} stroke={'currentColor'} />
                                        <p className="text-sm-semibold">Filter</p>
                                    </button>)} />
                                {/* 

                                <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                                    <SwitchVertical01 size={20} stroke={'currentColor'} />
                                    <p className="text-sm-semibold">Sort</p>
                                </button>)} /> */}
                            </div>
                            <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                                <table className='table border-collapse w-full'>
                                    <thead className='border-t border-gray-light/200 p-0'>
                                        <tr className='p-0'>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">User</p>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Receiver</p>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Violated rule</p>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Violated word</p>
                                            </th>
                                            <th className='px-6 py-3 text-left'>
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Date & message</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {violation?.data?.map((e, i) => {
                                            const task = e.task;
                                            const customer = task.detail_customer;
                                            const user = e.sender ?? e.receiver;
                                            var text = e.text;
                                            e.is_violated.map(e => {
                                                e.words.map(word => {
                                                    text = text.replace(new RegExp(word, "ig"),
                                                        `<label class="text-red-500">${word}</label>`)
                                                })
                                            })
                                            return (
                                                <tr key={i}>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div className="flex items-center gap-3">
                                                            <div className="relative">
                                                                {user.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                                    <img className="w-full h-full object-cover" src={user.full_path_photo} alt="" />
                                                                </div>
                                                                    : <div className="bg-gray-light/100 flex justify-center items-center w-10 h-10 min-w-[40px] min-h-[40px] rounded-full border border-[#00000014]">
                                                                        <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                                                                    </div>}
                                                                {/* <span className="absolute bottom-0 right-0 rounded-full flex bg-success/500 w-2.5 h-2.5 border border-white"></span> */}
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">{user.name}</p>
                                                                <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{user.email}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div className="flex items-center gap-3">
                                                            <div className="bg-gray-light/100 flex justify-center items-center w-10 h-10 min-w-[40px] min-h-[40px] rounded-full border border-[#00000014]">
                                                                <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">{customer.name}</p>
                                                                <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{customer.number}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{e.is_violated.map(e => e.name).join(', ')}</p>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div className="flex items-center gap-1">
                                                            {e.is_violated.reduce((prev, value) => {
                                                                prev.push(...value.words)
                                                                return prev;
                                                            }, []).map(e => {
                                                                return (<div className="w-max px-2 py-0.5 border rounded-full border-error/600 ">
                                                                    <p className="text-xs-medium text-error/700">{e}</p>
                                                                </div>)
                                                            })}
                                                            {/*
                                                            <div className="w-max px-2 py-0.5 border rounded-full border-error/600 ">
                                                                <p className="text-xs-medium text-error/700">+1</p>
                                                            </div> */}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div>
                                                            <p className="text-sm-regular text-gray-light/900">{moment(e.created_at).format('DD MMM YYYY • hh:mma')}</p>
                                                            <p className="text-sm-regular text-gray-light/600 line-clamp-1" dangerouslySetInnerHTML={{ __html: text }}></p>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </SimpleBar>
                            <div className="border-t border-gray-light/200">
                                <MyPagination meta={violation.meta} setParams={setParams} />
                            </div>
                        </div>
                    </div>
                </main>
            </SimpleBar>
        </>
    );
};

export default Violation;