import React, { useState, useRef } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/material/styles';
import { InputAdornment, Paper } from "@mui/material";
import { SearchLG, XClose } from "untitledui-js/icons/general";
import { ChevronDown } from "untitledui-js/icons/arrow";




const MyAutocomplete = ({ options, value, placeholder, multiple, defaultLabel,
  onChange, onInputChange, filterOptions, getOptionLabel, renderOption, onFocus = () => { } }) => {
  const [isHideInput, setIsHideInput] = useState(true);
  const [isOpen, setIsOpen] = useState(false)

  const textFieldRef = useRef();

  const handleShowInput = () => {
    setIsHideInput(false);
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
        setIsOpen(true)
      }
    }, 100);
  };

  const handleHideInput = () => {
    if (!isHideInput && textFieldRef.current) {
      textFieldRef.current.blur();
      setIsOpen(false)

      setTimeout(() => {
        setIsHideInput(true);
      }, 100);
    }

  };

  return (
    <div>
      {isHideInput ? <div onClick={handleShowInput} className="h-11 cursor-pointer flex items-center gap-x-2 px-[14px] py-2.5 border border-gray-light/300 rounded-lg shadow-shadows/shadow-xs" >
        <div className="flex-1">
          {(value && renderOption && renderOption(value)) ?? defaultLabel ?? 'Select'}
        </div>
        <button type="button" className="w-5 h-5 min-w-[20px] min-h-[20px]">
          <ChevronDown className={'text-gray-light/500'} size={20} stroke={'currentColor'} />
        </button>
      </div > :
        <Autocomplete open={isOpen} id="my-auto-complete" fullWidth freeSolo
          // multiple
          // defaultValue={value}
          onClose={() => {
            handleHideInput();
          }}
          options={options}
          onChange={onChange}
          ListboxProps={{ style: { maxHeight: 320 } }}
          PaperComponent={(props) => <Paper id="my-papper-auto-complete"
            sx={{
              "&.MuiPaper-root": {
                marginTop: '4px',
                border: "1px solid #EAECF0",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px -2px #10182808"
              },
              "&.MuiPaper-root .MuiAutocomplete-listbox": {
                padding: "4px 0px"
              }
            }}
            {...props} />}

          // renderTags={(options, getTagProps, ownerState) => {
          //   return options.map((e, i) => {
          //     var { onDelete } = getTagProps(i);
          //     return (
          //       <div key={i} className="bg-blue-light/50 flex items-center gap-x-0.5 border border-blue-light/200 rounded-md py-0.5 pr-1 pl-2">
          //         <p className="text-sm-medium text-blue-light/700">{e.name}</p>
          //         <button type="button" onClick={() => onDelete(i)} className="w-4 h-max min-w-[16px] min-h-[16px] p-0.5">
          //           <XClose className={'text-blue-light/400'} size={14} strokeWidth={3} stroke={'currentColor'} />
          //         </button>
          //       </div>
          //     );
          //   })
          // }}
          // isOptionEqualToValue={(option, value) => {
          //   console.log('isOptionEqualToValue', option,value)
          //   return true
          // }}
          // onHighlightChange={(event,option, value) => {
          //   console.log('onHighlightChange',event, option,value)
          //   return true
          // }}
          getOptionLabel={getOptionLabel || (option => option.label)}
          renderOption={(props, option) => (
            <div {...props} key={option.id} className={["w-full ", option.id == value?.id ? " cursor-not-allowed bg-green-300 " : " cursor-pointer "]}>
              <div className="px-4 py-2.5 pl-[14px] w-full">
                {renderOption && renderOption(option)}
              </div>
            </div>
          )}
          filterOptions={(options, state) => {
            var inputValue = state.inputValue.trim().toLowerCase();
            return options.filter((e) => {
              if (filterOptions && filterOptions(e, inputValue)) {
                return true;
              }

              if (e.label && e.label.trim().toLowerCase().includes(inputValue)) {
                return true;
              }

              return false;
            });
          }}
          renderInput={(params) => {
            // params.inputProps.value = value;
            // console.log('Textfield', params, value);
            return (
              <TextField
                {...params}
                autoFocus
                inputRef={textFieldRef}
                placeholder={placeholder}
                onChange={onInputChange}
                onFocus={onFocus}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start">
                    <span><SearchLG size={20} stroke={'currentColor'} /></span>
                  </InputAdornment>,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '8px',
                    padding: '10px 14px',
                    height: "44px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    boxShadow: '0px 1px 2px 0px #1018280D',
                    "&.Mui-focused": {
                      border: "none",
                      boxShadow: '0px 0px 0px 4px #9e77ed3d',
                    },
                    '& .MuiInputBase-input': {
                      padding: "0px"
                    },
                    '&.Mui-disabled .MuiInputBase-input': {
                      WebkitTextFillColor: '#667085',
                      color: '#667085',
                    },
                    '& .MuiInputAdornment-root': {
                      marginRight: "0px",
                    },
                    '& fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    "&.Mui-focused fieldset": {
                      border: '1px solid #D6BBFB',
                    },
                    "&.Mui-disabled fieldset": {
                      border: '1px solid #D0D5DD',
                      WebkitTextFillColor: 'red'
                    },
                  },
                }}
              />
            )
          }}
        />}
    </div>
  );
};

export default MyAutocomplete;