import React, { useEffect, useState } from "react";
import { Copy01, Trash01, XClose } from "untitledui-js/icons/general";
import { RefreshCCW04 } from "untitledui-js/icons/arrow";
import SimpleBar from 'simplebar-react';
import { useSettings } from "../../../contexts/SettingContext";
import { User01, UserPlus01 } from "untitledui-js/icons/users";
import MySwicth from "../../../components/Switch/MySwitch";
import MyTextFieldV2 from "../../../components/TextField/MyTextFieldv2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userSettingSchema } from "../../../yup_schema/setting_schema";
import { debounce } from "lodash";
import $, { error } from "jquery";
import MyAutocompleteV3 from "../../../components/Autocomplete/MyAutocompleteV3";
import { notify } from "../../../helpers/Helpers";

const ModalUserSetting = () => {
    const { searchRoleSetting, searchUser, showUserSetting, createUserSetting, updateUserSetting, currentSlider, handleCurrentSlider, deleteUserSetting } = useSettings();
    const userForm = useForm({ resolver: yupResolver(userSettingSchema) });
    const { watch, setValue, handleSubmit, control, formState: { errors }, register } = userForm

    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [selectedRole, setSelectedRole] = useState();
    const [selectedUser, setSelectedUser] = useState();

    const data = watch()
    const { photo, grade, create, photo_url } = data
    const submit = currentSlider.id ? updateUserSetting(currentSlider.id) : createUserSetting

    const changePhoto = async (photos) => {
        try {
            if (photos.length === 0) return;
            var photo = photos[0];
            var mime = photo.type;
            var img = document.getElementById('preview-user-photo');
            var url = URL.createObjectURL(photo);

            if (mime) mime = mime.split('/')[1];
            // img.setAttribute('src', url);
            setValue('photo_url', url)
        } catch (error) {
            console.log('error', error);
        }
    }

    const generate = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        setValue('password', result)
    }
    const copyText = () => {
        if (!watch().password) return;
        if (typeof (navigator.clipboard) == 'undefined') {
            var textArea = document.createElement("textarea");
            textArea.value = watch().password;
            textArea.style.position = "fixed";  //avoid scrolling to bottom
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                notify({ status: 200, message: 'Success copy text' })
            } catch (err) {
                notify({ status: 200, message: 'Was not possible to copy te text ' + err })
            }
            document.body.removeChild(textArea)
            return;
        }
        navigator.clipboard.writeText(watch().password)
        notify({ status: 200, message: 'Success copy text' })
    }

    const debounceSearchRole = debounce((e) => searchRoleSetting(e).then(setRoles), 1000)
    const debounceSearchUser = debounce((e, grade) => searchUser(e, grade).then(setUsers), 1000)

    useEffect(() => {
        if (currentSlider.id) {
            setValue('create', false);
            showUserSetting(currentSlider.id).then(data => {
                setValue('name', data.name);
                setValue('email', data.email);
                // setValue('password', data.password);
                setValue('status', data.status);
                setValue('roles_id', data.roles_id);
                setValue('grade', data.role.grade);
                setValue('managers_id', data.managers_id);
                setValue('created_at', data.created_at);
                setValue('delete_photo', false);
                setValue('photo', null);
                if (data.photo)
                    setValue('photo_url', data.full_path_photo);
                setSelectedRole(data.role)
                setSelectedUser(data.manager)

                // if (data.photo) {
                //     $('#preview-user-photo').attr('src', data.full_path_photo);
                // }
            })
        } else {
            generate(7)
            setValue('create', true);
            setValue('status', 'active');
        }
    }, [])

    useEffect(() => {
        if (photo) changePhoto(photo)
    }, [photo])
    console.log(errors)
    return (
        <div className="w-full h-screen flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button type="button" className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} onClick={() => handleCurrentSlider({ status: false, current: null })} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <UserPlus01 size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">{currentSlider.id ? "Edit" : "Add"} user</p>
                    <p className="text-sm-regular text-gray-light/600">Please provide the details for a new user.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <form className="h-full flex flex-col gap-6" control={control} onSubmit={handleSubmit(submit)}>
                        <div className="flex flex-col gap-4 px-6 flex-1">
                            <div className="flex flex-col gap-4">
                                <hr className="border-gray-light/200" />
                                <div className="flex items-start gap-2 w-full">
                                    <MySwicth name={'status'} control={control} value={'active'} unCheckedValue={'inactive'} />
                                    <div>
                                        <p className="text-sm-medium text-gray-light/700">Is active</p>
                                        <p className="text-sm-regular text-gray-light/600">User access to the login system.</p>
                                    </div>
                                </div>
                                {/* {!create &&
                                    <>
                                        <hr className="border-gray-light/200" />
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-regular text-gray-light/700">Joined</p>
                                            <p className="text-sm-regular text-gray-light/600">{moment(data.created_at).format('DD MMM YYYY • HH:mma')}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-regular text-gray-light/700">SIM card usage</p>
                                            <p className="text-sm-regular text-gray-light/600">1,034</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-regular text-gray-light/700">Task distributed</p>
                                            <p className="text-sm-regular text-gray-light/600">41,234</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-regular text-gray-light/700">Unique attempt</p>
                                            <p className="text-sm-regular text-gray-light/600">39,190</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-regular text-gray-light/700">Messaging activitiy</p>
                                            <div className="flex items-center justify-center gap-1.5">
                                                <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-blue/200 bg-blue/50">
                                                    <p className="text-xs-medium text-blue/700">217</p>
                                                    <ArrowsDown size={12} className={'text-blue/500'} stroke={'currentColor'} />
                                                </div>
                                                <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-brand/200 bg-brand/50">
                                                    <p className="text-xs-medium text-brand/700">217</p>
                                                    <ArrowsUp size={12} className={'text-brand/500'} stroke={'currentColor'} />
                                                </div>
                                                <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-warning/200 bg-warning/50">
                                                    <p className="text-xs-medium text-warning/700">217</p>
                                                    <HourGlass03 size={12} className={'text-warning/500'} stroke={'currentColor'} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-gray-light/200" />
                                    </>
                                } */}
                            </div>
                            <div className="flex flex-col gap-y-6">
                                <div>
                                    <p className="text-sm-semibold text-gray-light/700">Photo</p>
                                    <p className="text-sm-regular text-gray-light/600">This will be displayed on user profile picture.</p>
                                </div>
                                <div className="flex items-start justify-between">

                                    {photo_url ? <div className={" w-16 h-16 min-w-[64px] min-h-[64px] rounded-full overflow-hidden"}>
                                        <img id="preview-user-photo" className="w-full h-full object-cover" src={photo_url} alt="" />
                                    </div> : <div className={[" flex items-center justify-center bg-gray-light/100 w-16 h-16 min-w-[64px] min-h-[64px] border border-[#00000014] rounded-full overflow-hidden"]}>
                                        <User01 size={32} className={'text-gray-light/500'} stroke="currentColor" />
                                    </div>}
                                    <div className="flex gap-x-4">
                                        <button type="button" onClick={() => { setValue('delete_photo', true); setValue('photo_url', null); }} className="text-sm-semibold text-gray-light/600">Delete</button>
                                        <input accept="image/*,.HEIC" id="user-photo" type="file" className="hidden" multiple={false} {...register("photo")} />
                                        <label htmlFor="user-photo" className={"text-sm-semibold text-brand/700 cursor-pointer"}>
                                            Update
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <hr className="border-gray-light/200" />
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="name" className="text-sm-medium text-gray-light/700">Name</label>
                                <MyTextFieldV2 maxLength={100} form={userForm} control={control} name="name" placeholder={'Enter your name'} />
                            </div>
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="email" className="text-sm-medium text-gray-light/700">Email</label>
                                <MyTextFieldV2 maxLength={50} form={userForm} disabled={!create} control={control} name="email" placeholder={'Enter your email'} />
                            </div>
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="roles_id" className="text-sm-medium text-gray-light/700">Role</label>
                                <MyAutocompleteV3 options={roles} value={selectedRole} getOptionLabel={(option) => option?.name} isOptionEqualToValue={(option) => option.id} name="roles_id" placeholder={'Select your role'}
                                    onChange={(e, value) => {
                                        setSelectedRole(value);
                                        setValue('roles_id', value?.id);
                                        setValue('grade', value?.grade);
                                        setSelectedUser(null);
                                        setValue('managers_id', null);
                                    }}
                                    onInputChange={(e) => {
                                        if (!e?.target?.value) setSelectedRole(null);
                                        debounceSearchRole(e);
                                    }}
                                    onInputFocus={(e) => searchRoleSetting({ target: { value: '' } }).then(setRoles)}
                                />
                            </div>
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="managers_id" className="text-sm-medium text-gray-light/700">Manager</label>
                                <MyAutocompleteV3 options={users} value={selectedUser} getOptionLabel={(option) => option.name} name="managers_id" placeholder={'Select your manager'}
                                    startAdornment={<User01 size={20} className={'text-gray-light/500'} stroke="currentColor" />}
                                    disabled={!selectedRole}
                                    onChange={(e, value) => {
                                        setSelectedUser(value);
                                        setValue('managers_id', value?.id);
                                    }}
                                    onInputChange={(e) => {
                                        if (!e?.target?.value) setSelectedUser(null);
                                        debounceSearchUser(e, grade);
                                    }}
                                    onInputFocus={(e) => searchUser(e, grade).then(setUsers)}
                                    renderOption={(option) => {
                                        return (<div className="flex items-center gap-x-2 overflow-hidden">
                                            {option.photo ? <div className="border border-[#00000014] w-6 h-6 min-w-[24px] min-h-[24px] rounded-full overflow-hidden">
                                                <img className="w-full h-full object-cover" src={option.full_path_photo} alt="" />
                                            </div>
                                                : <div className="bg-gray-light/100 flex justify-center items-center w-6 h-6 min-w-[24px] min-h-[24px] rounded-full border border-[#00000014]">
                                                    <User01 className={'text-gray-light/500'} size={18} stroke={'currentColor'} />
                                                </div>}
                                            <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option?.name}</p>
                                            <p className="text-md-regular text-gray-light/600 line-clamp-1">{option?.email}</p>
                                        </div>)
                                    }}
                                />
                            </div>
                            <div className="flex flex-col gap-4 ">
                                <hr className="border-gray-light/200" />
                                <div className="flex items-end gap-x-1">
                                    <div className="flex flex-col gap-y-1.5 w-full">
                                        <label htmlFor="password" className="text-sm-medium text-gray-light/700">Password</label>
                                        <MyTextFieldV2 disabled={true} control={control} name="password" placeholder={currentSlider.id ? 'Click generate to update' : 'Enter your password'} />
                                    </div>
                                    <button type="button" onClick={copyText} className="p-2.5 text-gray-light/600">
                                        <Copy01 size={20} stroke={'currentColor'} />
                                    </button>
                                </div>
                                <button type="button" onClick={() => generate(7)} className="flex items-center gap-1.5 text-brand/700">
                                    <RefreshCCW04 size={20} stroke={'currentColor'} />
                                    <p className="text-sm-semibold ">Generate</p>
                                </button>
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 px-6 py-4 flex items-center justify-end gap-4">
                            {!create && <button type="button" onClick={() => { deleteUserSetting(currentSlider.id); handleCurrentSlider({ status: false, current: null }) }} className="px-[14px] py-2.5 flex items-center gap-x-1 text-error/700">
                                <Trash01 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold ">Delete user</p>
                            </button>}
                            <button type="button" onClick={() => handleCurrentSlider({ status: false, current: null })} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button type="submit" className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Apply</p>
                            </button>
                        </footer>
                    </form>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalUserSetting;