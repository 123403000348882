import React, { useRef, useEffect } from "react";
import { SearchLG, XClose } from "untitledui-js/icons/general";
import { MessageChatSquare } from "untitledui-js/icons/communication";
import { useAuditTrial } from "../../contexts/AuditTrialContext";
import SimpleBar from "simplebar-react";
import moment from "moment";
import MessageDivheaderBox from "../../components/Message/MessageDivheaderBox";
import MessageSenderBox from "../../components/Message/MessageSenderBox";
import MessageReceiverBox from "../../components/Message/MessageReceiverBox";
import MyTextField from "../../components/TextField/MyTextField";
import { ChevronLeft, ChevronRight } from "untitledui-js/icons/arrow";
import MyTextFieldV2 from "../../components/TextField/MyTextFieldv2";
import $ from "jquery";

const ModalDetailConversation = () => {
    const { focusSearch, searchConversations, boxConversationRef, detailConversations, handleCurrentSlider, isCollapseSearch, setIsCollapseSearch, data } = useAuditTrial();
    useEffect(() => {
        if (detailConversations && data?.id) {
            console.log('data?.id', data?.id)
            var $element = $(`#${data?.id}`);
            if ($element.length) {
                $element[0].scrollIntoView({ behavior: 'smooth' });
                var $message = $element.find('.message');
                var $preview = $element.find('.message-preview');

                var innerHTML = $message.html();
                var highlightedText = '<span class="bg-yellow-400">' + innerHTML + '</span>';
                $preview.html(highlightedText);
            }
        }
    }, [detailConversations])

    return (
        <div className="w-[400px] h-screen flex flex-col gap-6">
            <header className="flex flex-col gap-6 ">
                <div className="pt-6 px-6 flex items-start gap-x-4 relative">
                    <button onClick={() => handleCurrentSlider({ status: false, current: null })} className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                        <XClose size={24} stroke={'currentColor'} />
                    </button>
                    <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                        <MessageChatSquare size={20} stroke={'currentColor'} />
                    </span>
                    <section className="flex flex-col gap-1">
                        <p className="text-xl-semibold text-gray-light/900">Conversation</p>
                        <p className="text-sm-regular text-gray-light/600">See chat between user and customer.</p>
                    </section>
                </div>
                <div className="px-6 flex items-center">
                    <div className="flex-1">
                        <MyTextFieldV2 id="search-conversation" placeholder={'Search'}
                            startAdornment={<SearchLG size={20} className={'text-gray-light/500'} stroke={'currentColor'} />}
                            onChangeForm={searchConversations}
                            onKeyDown={(e) => {
                                console.log('key', e.key)
                                if (e.key == 'Enter') focusSearch('down')
                            }}
                        />
                    </div>
                    <div id="box-collapse-search" className="w-0 duration-500 overflow-hidden" style={{ width: `${isCollapseSearch ? '152' : '0'}px` }}>
                        <div className="pl-4 flex items-center gap-2">
                            <div className="flex items-center">
                                <button onClick={() => focusSearch('up')} className="p-3 rounded-lg hover:bg-gray-light/50">
                                    <ChevronLeft size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                                </button>
                                <button onClick={() => focusSearch('down')} className="p-3 rounded-lg hover:bg-gray-light/50">
                                    <ChevronRight size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                                </button>
                            </div>
                            <button onClick={() => setIsCollapseSearch(false)}>
                                <p className="text-md-semibold text-brand/700">Done</p>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <div className="relative flex-1 overflow-hidden">

                <SimpleBar forceVisible="y" onScroll={(e) => {
                    console.log(e);
                }} ref={boxConversationRef} style={{ height: '100%' }}>
                    <div className="px-6 h-full">
                        <div className="h-2 w-full"> </div>
                        {(() => {
                            if (detailConversations?.data.length === 0 && !data) return <></>
                            const groups = (detailConversations?.data ?? []).filter(e => e && e.send_time).reduce((groups, item) => {
                                const date = moment(item.send_time).format("YYYY-MM-DD");
                                (groups[date] = groups[date] || []).push(item);
                                return groups;
                            }, {});

                            return Object.keys(groups).map((date) => (
                                <div key={date} className="flex flex-col gap-6">
                                    <MessageDivheaderBox date={date} />
                                    <div className='flex flex-col gap-4'>
                                        {groups[date].map((message, i) => {
                                            const targetRef = data.id === message.id;
                                            if (message.is_sender) {
                                                return <MessageSenderBox key={i} maxWidth="272px" targetRef={targetRef} message={message} />
                                            } else {
                                                return <MessageReceiverBox key={i} maxWidth="260px" targetRef={targetRef} message={message} />
                                            }
                                        })}
                                    </div>
                                </div>
                            ))
                        })()}
                        <div className="h-6 w-full"> </div>
                    </div>
                </SimpleBar>
            </div>
        </div >
    );
};

export default ModalDetailConversation;