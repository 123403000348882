import React, { createContext, useContext } from "react";

const ViolationContext = createContext();
const ViolationProvider = props => {
    return <ViolationContext.Provider value={{
    }}> {props.children}</ViolationContext.Provider >;
};

const useViolation = () => {
    const context = useContext(ViolationContext);
    if (context === undefined) {
        throw new Error('userViolation must be used within a ViolationProvider')
    }
    return context;
}

export { ViolationProvider, useViolation };