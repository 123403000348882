import React, { createContext, useContext, useEffect, useState } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import { notify } from "../helpers/Helpers";
import { CheckCircle, Settings02 } from "untitledui-js/icons/general";
import { AlertCircle } from "untitledui-js/icons/alerts";
const { socket } = HttpRequests;

const SimCardContext = createContext();
const SimCardProvider = props => {
    const [data, setData] = useState(null)
    const [params, setParams] = useState({ page: 1 });

    const [simCard, setSimCard] = useState({ data: [], meta: [] })
    const [isLoadingQr, setIsLoadingQr] = useState();
    const [isTimeOutQr, setIsTimeOutQr] = useState();
    const [qrValue, setQrValue] = useState();
    const [newCard, setNewCard] = useState(null);
    const [users, setUsers] = useState([]);

    const [currentTable, setCurrentTable] = useState('table-register');
    const [currentModal, setCurrentModal] = useState();
    const [currentSlider, setCurrentSlider] = useState({ status: null, current: null });
    const handleCurrentTable = (current) => {
        setCurrentTable(current)
    }
    const handleCurrentSlider = (slider, data) => {
        if (slider && slider.status) {
            switch (slider.current) {
                case 'slider-edit-sim-card':
                    setData(data);
                    break;
                default:
                    break;
            }

            setCurrentSlider({ status: slider.status, current: slider.current })
        } else {
            setCurrentSlider({ status: false, current: null })
        }
    }

    const handleCurrentModal = (current, data) => {
        switch (current) {
            case 'modal-add-sim-card':
                addSimCard(data);
                break;
            default:
                break;
        }

        setCurrentModal(current)
    }

    const getSimCard = async () => {
        await HttpRequests.getSimCard(params)
            .then(res => {
                setSimCard({ data: res.data, meta: res.meta })
                console.log(res);
            })
            .catch(notify)
    }

    const getUser = async (name) => {
        await HttpRequests.getUser({ search: name })
            .then(res => {
                setUsers(res.data)
                console.log(res);
            })
            .catch(notify)
    }

    const addSimCard = async (id) => {
        try {
            setIsTimeOutQr(false);
            setIsLoadingQr(true);
            setQrValue(null);
            if (!id) {
                var response = await HttpRequests.addSimCard().catch((e) => {
                    notify(e);
                    setIsLoadingQr(false);
                    setQrValue(null);
                    handleCurrentModal(null)
                });
                setNewCard(response.data)
            } else {
                var response = await HttpRequests.relogin(id);
                setNewCard(id)
            }

            socket.on(`new-qr-${response.data}`, (res) => {
                console.log(res);

                setIsLoadingQr(false);
                setQrValue(res.qr);
            })
            socket.on(`err-${response.data}`, (res) => {
                console.log(res);
                setIsLoadingQr(false);
                setQrValue(null);
                handleCurrentModal(null)
                notify({ status: 'error', title: 'Error', message: res.message }, {
                    icon: <AlertCircle size={20} stroke={'currentColor'} />,
                })
            })
            socket.on(`ready-${response.data}`, (res) => {
                getSimCard();

                handleCurrentModal(null);
                setQrValue(null);
                notify({ status: 200, title: 'Successfully add new number', message: res.message }, {
                    icon: <CheckCircle size={20} stroke={'currentColor'} />,
                    onDismiss: () => {
                        // TODO Dismiss new add number
                    }
                })
                console.log(res);
            })
        } catch (error) {
            console.log(error)
        }
    }

    const removeListener = () => {
        console.log('remove listener')
        if (newCard) {
            socket.off(`new-qr-${newCard}`)
            socket.off(`ready-${newCard}`)
            socket.off(`err-${newCard}`)
            // socket.emit(`cancel-sim-card`,newCard)
        }
    }
    const cancelSubcribe = () => {
        socket.emit(`cancel-sim-card`, newCard)
    }
    useEffect(() => {
        console.log(currentModal, currentSlider)
        if (currentModal !== "modal-add-sim-card") removeListener()
        if (currentSlider === "slider-edit-sim-card") getUser()
        if (currentSlider === "modal-add-sim-card") getUser()
    }, [currentModal, currentSlider])

    const assignNumber = async (value) => {
        await HttpRequests.assignNumber(data.id, { users_id: value?.id })
            .then(res => {
                handleCurrentSlider(null);
                notify(res, {
                    icon: <CheckCircle size={20} stroke={'currentColor'} />,
                    onDismiss: () => {
                        // TODO Dismiss new add number
                    }
                })
                getSimCard()
            })
            .catch(notify)
    }

    const logoutEngine = async (id) => {
        // var code = `new Promise(async(resolve,reject)=>{
        //     try{
        //       var data = await client.logout()
        //       console.log(data)
        //      resolve(data)
        //     }catch (error){
        //      reject(error)
        //     }
        //    })`;
        const valid = window.confirm('Anda yakin?')
        if (!valid) return;
        handleCurrentSlider(null);
        await HttpRequests.logoutWa(id).then(notify).catch(notify)
        getSimCard();
    }

    return <SimCardContext.Provider value={{
        data, setData, simCard, setSimCard, qrValue, setQrValue,
        isLoadingQr, setIsLoadingQr, isTimeOutQr, setIsTimeOutQr,

        assignNumber,
        getSimCard,
        getUser, users, cancelSubcribe,
        params, setParams,
        currentTable, setCurrentTable,
        currentSlider, setCurrentSlider,
        currentModal, setCurrentModal, logoutEngine,
        handleCurrentTable, handleCurrentSlider, handleCurrentModal, removeListener
    }}> {props.children}</SimCardContext.Provider >;
};

const useSimCard = () => {
    const context = useContext(SimCardContext);
    if (context === undefined) {
        throw new Error('userSimCard must be used within a SimCardProvider')
    }
    return context;
}

export { SimCardProvider, useSimCard };