import React, { useState } from "react";
import { DownloadCloud02, Edit01, FilterLines, Plus, Trash01 } from "untitledui-js/icons/general";
import MyTextField from "../../../components/TextField/MyTextField";
import MyFilter from "../../SimCard/Poppers/filter";
import MySorted from "../../SimCard/Poppers/sorted";
import { ArrowUp, SwitchVertical01 } from "untitledui-js/icons/arrow";
import SimpleBar from "simplebar-react";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import MySwicth from "../../../components/Switch/MySwitch";
import MyPagination from "../../../components/Pagination/MyPagination";
import { useSettings } from "../../../contexts/SettingContext";
import { useEffect } from "react";
import { User01 } from "untitledui-js/icons/users";
import { debounce } from "lodash";
import moment from "moment";
import MyFilterModal from "../../../components/Modal/MyFilterModal";

const UserSetting = () => {
    const { getUserSetting, setParams, createUserSetting, updateUserStatusSetting, deleteUserSetting, userSetting, setUserSetting, params, handleCurrentSlider } = useSettings();
    useEffect(() => {
        getUserSetting();
    }, [params])
    const search = (e) => {
        setParams(value => { return { ...value, search: e?.target?.value, page: 1 } })
    }
    const debounceSearch = debounce(search, 1000)
    return (
        <>
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                <div className="pt-5 flex flex-col gap-y-5">
                    <div className='px-6 flex items-center gap-x-4 w-full'>
                        <div className="flex-1 flex-col gap-1">
                            <div className="flex items-center gap-2">
                                <p className="text-lg-semibold text-gray-light/900">User</p>
                                <div className="px-2 py-0.5 rounded-full bg-brand/50 border border-brand/200">
                                    <p className="text-xs-medium text-brand/700">{userSetting?.meta?.total ?? '0'} people</p>
                                </div>
                            </div>
                            <p className="text-sm-regular text-gray-light/600">List of users registered in the system.</p>
                        </div>
                        <div className="flex items-center gap-x-3">
                            {/* <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                <DownloadCloud02 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Export</p>
                            </button> */}
                            <button onClick={() => handleCurrentSlider({ status: true, current: "user" })} className="w-max h-max bg-brand/600 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-white shadow-shadows/shadow-xs">
                                <Plus size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Add user</p>
                            </button>
                        </div>
                    </div>
                    <hr className='border-gray-light/200' />
                </div>
                <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                    <div className="w-full max-w-xs">
                        <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                    </div>
                    <MyFilterModal id={'filter-sim-card-register'} currentFilters={userSetting.filter} onChange={(filter) => setParams({ ...params, filter: filter, page: 1 })}
                        target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                            <FilterLines size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Filter</p>
                        </button>)} />
                    {/*<MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                        <SwitchVertical01 size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Sort</p>
                    </button>)} /> */}
                </div>
                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                    <table className='table border-collapse w-full'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            <tr className='p-0'>
                                <th className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-3">
                                        {/* <MyCheckbox /> */}
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">User</p>
                                    </div>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Role</p>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-1 text-gray-light/600">
                                        <p className="text-xs-medium  whitespace-nowrap">Joined date</p>
                                        {/* <button>
                                            <ArrowUp size={16} stroke={'currentColor'} />
                                        </button> */}
                                    </div>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Manager</p>
                                </th>
                                <th className='px-6 py-3 text-center'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Login access</p>
                                </th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(userSetting.data ?? []).map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-3">
                                                {/* <span><MyCheckbox /></span> */}
                                                <div className="relative">
                                                    {e.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src={e.full_path_photo} alt="" />
                                                    </div> : <div className="flex items-center justify-center bg-gray-light/100 w-10 h-10 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                                        <User01 size={20} className={'text-gray-light/500'} stroke="currentColor" />
                                                    </div>}
                                                    <span className="absolute bottom-0 right-0 rounded-full flex bg-success/500 w-2.5 h-2.5 border border-white"></span>
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="text-sm-medium text-gray-light/900">{e.name}</p>
                                                    <p className="text-sm-regular text-gray-light/600">{e.email}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">
                                                {e.role?.name ?? '-'}
                                            </p>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">
                                                {moment(e.created_at).format('D MMM YYYY • HH:mma')}
                                            </p>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            {e.manager && <div className="flex items-center gap-3">
                                                <div className="relative">
                                                    {e.manager?.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src={e.manager.full_path_photo} alt="" />
                                                    </div> : <div className="flex items-center justify-center bg-gray-light/100 w-10 h-10 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                                        <User01 size={20} className={'text-gray-light/500'} stroke="currentColor" />
                                                    </div>}
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="text-sm-medium text-gray-light/900">{e.manager.name}</p>
                                                    <p className="text-sm-regular text-gray-light/600">{e.manager.email}</p>
                                                </div>
                                            </div>}
                                        </td>
                                        <td className='px-6 py-4 border-t border-gray-light/200'>
                                            <div className="w-full flex justify-center">
                                                <MySwicth onChangeForm={(e2) => { updateUserStatusSetting(e.id, { "status": e2.target.checked ? "active" : "inactive" }) }} checked={e.status === "active"} />
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex justify-end gap-x-1">
                                                <button onClick={() => deleteUserSetting(e.id)} className="my-button flex items-center p-2.5">
                                                    <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                                <button onClick={() => handleCurrentSlider({ status: true, current: "user" }, e.id)} className="my-button flex items-center p-2.5">
                                                    <Edit01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SimpleBar>
                <div className="border-t border-gray-light/200">
                    <MyPagination meta={userSetting.meta} setParams={setParams} />
                </div>
            </div >
        </>
    );
};

export default UserSetting;