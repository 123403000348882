import React, { createContext, useContext, useState } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import { fromObject, notify } from "../helpers/Helpers";
import { pick } from "lodash";
const DashboardContext = createContext();
const DashboardProvider = props => {
    const [params, setParams] = useState({ page: 1, dates: '12m' })
    const [params2, setParams2] = useState({ page: 1, dates: '12m' })
    const [violation, setViolation] = useState({ page: 1 })
    const [attempt, setAttempt] = useState({ page: 1 })
    const getViolation = async () => {
        await HttpRequests.getViolationDashboard(params).then(setViolation).catch(notify)
    }
    const getAttempt = async () => {
        await HttpRequests.getAttemptDashboard(params2).then(setAttempt).catch(notify)
    }
    const getViolationChart = async (dates) => {
        return await HttpRequests.getViolationChartDashboard({ dates }).catch(notify)
    }
    const downloadAttempt = async () => {
        try {
            var url = ''
            var where = {
                ...pick(params2, ['page', 'search']),
            }
            if (params2.filter?.length != 0) {
                console.log('filter[0]' + fromObject(params2.filter).slice(1))
                url = HttpRequests.downloadAttempt() + '&filter[0]' + fromObject(params2.filter).slice(1).replace(/&0/g, '&filter[0]')
            } else {
                url = HttpRequests.downloadAttempt(where)
            }
            // HttpRequests.downloadAudit(params)
            console.log(url)
            window.open(url)
        } catch (error) {
            console.log(error)
        }
    }
    const downloadViolated = async () => {
        try {
            var url = ''
            var where = {
                ...pick(params, ['page', 'search']),
            }
            if (params.filter?.length != 0) {
                console.log('filter[0]' + fromObject(params.filter).slice(1))
                url = HttpRequests.downloadViolated() + '&filter[0]' + fromObject(params.filter).slice(1).replace(/&0/g, '&filter[0]')
            } else {
                url = HttpRequests.downloadViolated(where)
            }
            // HttpRequests.downloadAudit(params)
            console.log(url)
            window.open(url)
        } catch (error) {
            console.log(error)
        }
    }
    return <DashboardContext.Provider value={{
        params, setParams,
        getViolation, violation, setViolation, getViolationChart, downloadViolated,
        getAttempt, attempt, setAttempt, downloadAttempt,
        params2, setParams2,
    }}> {props.children}</DashboardContext.Provider >;
};

const useDashboard = () => {
    const context = useContext(DashboardContext);
    if (context === undefined) {
        throw new Error('userDashboard must be used within a DashboardProvider')
    }
    return context;
}

export { DashboardProvider, useDashboard };