import React, { createContext, useContext, useRef, useState } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import $ from "jquery";
import { useEffect } from "react";
import { notify } from "../helpers/Helpers";
import { pick } from "lodash";

const AuditTrialContext = createContext();
const AuditTrialProvider = props => {
    const [params, setParams] = useState({ page: 1 });
    const [conversations, setConversations] = useState();
    const [data, setData] = useState();
    const [isCollapseSearch, setIsCollapseSearch] = useState(false);
    const [detailConversations, setDetailConversations] = useState();
    const boxConversationRef = useRef(null);
    const targetChat = useRef(null);

    const [currentTable, setCurrentTable] = useState('table-conversation');
    const [currentModal, setCurrentModal] = useState({ status: false, current: null });
    const [currentSlider, setCurrentSlider] = useState();

    const handleCurrentTable = (current) => {
        setCurrentTable(current)
    }
    const handleCurrentSlider = (slider, data) => {
        setData(data);
        if (slider && slider.status) {
            switch (slider.current) {
                case 'slider-detail-conversation':
                    getDetailConversations(data);
                    break;
                default:
                    break;
            }

            setCurrentSlider({ status: slider.status, current: slider.current })
        } else {
            setCurrentSlider({ status: false, current: null })
        }
    }
    const handleCurrentModal = (current) => {
        setCurrentModal(current)
    }

    const getConversations = async () => {
        try {
            // var where = {
            //     ...pick(params, ['page', 'search', 'start_date', 'end_date']),
            //     users_id: params.user?.id,
            //     customers_id: params.customer?.id,
            // }
            const response = await HttpRequests.getConversations(params);
            setConversations({ data: response.data, meta: response.meta, filter: response.filter })
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }
    const getDetailConversations = async (data) => {
        try {
            const response = await HttpRequests.getDetailConversations(data.id);
            // const response = await HttpRequests.getDetailConversations('e032de87-abf2-482c-bac5-bc26929b83b8');
            setDetailConversations({ data: response.data, meta: response.meta })
            // setTimeout(() => {
            //     var node = document.getElementById(data.id);
            //     if (node) node.scrollIntoView({ behavior: 'smooth' })
            // }, 1000);
        } catch (error) {
            console.log(error)
            notify(error)
        }
    }

    const searchConversations = ({ target: { value } }) => {
        var isExistText = false;
        $('.message-text').each(function (i) {
            var $element = $(this);
            var $message = $element.find('.message');
            var $preview = $element.find('.message-preview');

            var innerHTML = $message.html();
            var index = (innerHTML ?? '').toLowerCase().indexOf(value.toLowerCase());

            if (index !== -1) {
                var highlightedText = '<span class="bg-yellow-400">' + innerHTML.substring(index, index + value.length) + '</span>';
                innerHTML = innerHTML.substring(0, index) + highlightedText + innerHTML.substring(index + value.length);

                isExistText = true;
            }

            $preview.html(innerHTML);
        });

        if (isExistText) {
            setIsCollapseSearch(true);
        } else {
            setIsCollapseSearch(false);
        }

    }

    const focusSearch = (direction) => {
        var value = $('#search-conversation').val();
        if (boxConversationRef.current && value) {
            const contentWrapperElScrollTop = boxConversationRef.current.contentWrapperEl.scrollTop;

            if (direction === 'up') {
                const $messageBoxes = $('.message-box').get().reverse();
                const $messageBoxesReversed = $($messageBoxes);
                $messageBoxesReversed.each(function (i) {
                    var $element = $(this);
                    var $message = $element.find('.message');

                    var innerHTML = $message.html();
                    var exist = (innerHTML ?? '').toLowerCase().includes(value.toLowerCase());
                    if (exist) {
                        // console.log('contentWrapperElScrollTop', contentWrapperElScrollTop);
                        // console.log('position', $element.position());
                        // console.log('offset', $element.offset());
                        // console.log('clientHeight', clientHeight);
                        let scrollTop = 0;
                        if ($element.position().top >= 0) {
                            scrollTop = contentWrapperElScrollTop + Math.abs($element.position().top);
                            // console.log('srcrollTop', contentWrapperElScrollTop + Math.abs($element.position().top));
                        } else {
                            scrollTop = contentWrapperElScrollTop - Math.abs($element.position().top);
                            // console.log('srcrollTop', contentWrapperElScrollTop - Math.abs($element.position().top));
                        }

                        // console.log('=============================================');
                        if (scrollTop < contentWrapperElScrollTop && !$element.hasClass('highlight-up')) {
                            $element[0].scrollIntoView({ behavior: 'smooth' });
                            $element.addClass('highlight-up');

                            return false;
                        } else {
                            $element.removeClass('highlight-up highlight-down');
                        }
                    } else {
                        $element.removeClass('highlight-up highlight-down');
                    }
                });
            } else {
                const $messageBoxes = $('.message-box');
                $messageBoxes.each(function (i) {
                    var $element = $(this);
                    var $message = $element.find('.message');

                    var innerHTML = $message.html();
                    var exist = (innerHTML ?? '').toLowerCase().includes(value.toLowerCase());
                    if (exist) {
                        // console.log('contentWrapperElScrollTop', contentWrapperElScrollTop);
                        // console.log('position', $element.position());
                        // console.log('offset', $element.offset());
                        // console.log('clientHeight', clientHeight);
                        let scrollTop = 0;
                        if ($element.position().top >= 0) {
                            scrollTop = contentWrapperElScrollTop + Math.abs($element.position().top);
                            // console.log('srcrollTop', contentWrapperElScrollTop + Math.abs($element.position().top));
                        } else {
                            scrollTop = contentWrapperElScrollTop - Math.abs($element.position().top);
                            // console.log('srcrollTop', contentWrapperElScrollTop - Math.abs($element.position().top));
                        }

                        // console.log('=============================================');
                        if (scrollTop > contentWrapperElScrollTop && !$element.hasClass('highlight-down')) {
                            $element[0].scrollIntoView({ behavior: 'smooth' });
                            $element.addClass('highlight-down');

                            return false;
                        } else {
                            $element.removeClass('highlight-up highlight-down');
                        }
                    } else {
                        $element.removeClass('highlight-up highlight-down');
                    }
                });
            }
        }
    }
    const download = async () => {
        try {
            var url = ''
            var where = {
                ...pick(params, ['page', 'search']),
            }
            if (params.filter?.length != 0) {
                console.log('filter[0]' + fromObject(params.filter).slice(1))
                url = HttpRequests.downloadAudit() + '&filter[0]' + fromObject(params.filter).slice(1).replace(/&0/g, '&filter[0]')
            } else {
                url = HttpRequests.downloadAudit(where)
            }
            // HttpRequests.downloadAudit(params)
            console.log(url)
            window.open(url)
        } catch (error) {
            console.log(error)
        }
    }
    var isObj = function (a) {
        if ((!!a) && (a.constructor === Object)) {
            return true;
        }
        return false;
    };
    var _st = function (z, g) {
        return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
    };
    var fromObject = function (params, skipobjects, prefix) {
        if (skipobjects === void 0) {
            skipobjects = false;
        }
        if (prefix === void 0) {
            prefix = "";
        }
        var result = "";
        if (typeof (params) != "object") {
            return prefix + "=" + encodeURIComponent(params) + "&";
        }
        for (var param in params) {
            var c = "" + prefix + _st(param, prefix);
            if (isObj(params[param]) && !skipobjects) {
                result += fromObject(params[param], false, "" + c);
            } else if (Array.isArray(params[param]) && !skipobjects) {
                params[param].forEach(function (item, ind) {
                    result += fromObject(item, false, c + "[" + ind + "]");
                });
            } else {
                result += c + "=" + encodeURIComponent(params[param]) + "&";
            }
        }
        return result;
    };
    const searchUser = async (e) => await HttpRequests.searchUserAudit({ search: e?.target?.value })
    const searchCustomer = async (e) => await HttpRequests.searchCustomerAudit({ search: e?.target?.value })

    return <AuditTrialContext.Provider value={{
        data, targetChat,
        isCollapseSearch, setIsCollapseSearch,
        conversations, detailConversations,
        getConversations, getDetailConversations,
        boxConversationRef, searchConversations, focusSearch,
        currentTable, setCurrentTable,
        currentSlider, setCurrentSlider,
        currentModal, setCurrentModal,
        params, setParams, download,
        handleCurrentTable, handleCurrentSlider, handleCurrentModal,
        searchUser, searchCustomer,
    }}> {props.children}</AuditTrialContext.Provider >;
};

const useAuditTrial = () => {
    const context = useContext(AuditTrialContext);
    if (context === undefined) {
        throw new Error('userAuditTrial must be used within a AuditTrialProvider')
    }
    return context;
}

export { AuditTrialProvider, useAuditTrial };