import React, { useEffect, useRef, useState } from "react";
import { Hash02, HelpCircle, Plus, Trash01, XClose } from "untitledui-js/icons/general";
import SimpleBar from 'simplebar-react';
import { useSettings } from "../../../contexts/SettingContext";
import { BookOpen01 } from "untitledui-js/icons/education";
import MyTooltip from "../../../components/Tooltip/MyTooltip";
import { Database03 } from "../../../contants/icons/MyIcon/Database";
import MyTextField from "../../../components/TextField/MyTextField";
import PopperViewSample from "./popper_view_sample";
import MyAutocompleteV2 from "../../../components/Autocomplete/MyAutocompleteV2";
import MyAutocompleteV3 from "../../../components/Autocomplete/MyAutocompleteV3";
import { ChevronDown, ChevronUp } from "untitledui-js/icons/arrow";
import { templateSchema } from "../../../yup_schema/setting_schema";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MyFormTextField from "../../../components/TextField/MyFormTextField";

const ModalTemplateSetting = () => {
    const textareaRef = useRef()
    const [hide, setHide] = useState([])
    const { handleCurrentSlider, getFields, createTemplate, currentSlider, getDetailTemplate, updateTemplate } = useSettings();
    const { clearErrors, watch, setValue, handleSubmit, control, formState: { errors }, register } = useForm({ resolver: yupResolver(templateSchema) });
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "generates", // unique name for your Field Array
    });
    const submit = currentSlider?.id ? updateTemplate : createTemplate
    const [availabelFields, setAvailabelFields] = useState([])
    useEffect(() => {
        getFields().then(e => {
            setAvailabelFields(e.data.map(e => { return { label: e.label, id: e.value } }))
            setValue('custom', e.data)
        })
    }, [])
    useEffect(() => {
        if (currentSlider?.id) {
            setValue('create', false)
            getDetailTemplate(currentSlider?.id).then(({ data }) => {
                setValue('name', data.name)
                setValue('text', data.text)
                setValue('shortcut', data.shortcut)
                setValue('generates', data.generates)
                setHide(data.generates.map(e => false))
            })
        } else {
            setValue('create', true)
            setValue('generates', [])
            setHide([])
        }
    }, [])
    const { generates, text, shortcut } = watch()
    const insertText = (text) => {
        const textarea = document.activeElement;
        if (textarea && textarea.tagName == 'TEXTAREA' && text) {
            const cursorPos = textarea.selectionStart;

            const currentText = textarea.value;

            const textBeforeCursor = currentText.substring(0, cursorPos);
            const textAfterCursor = currentText.substring(cursorPos);

            const newText = `${textBeforeCursor}<<${text}>> ${textAfterCursor}`;
            console.log(newText)
            if (!textarea.id)
                setValue('text', newText)
            else setValue(`generates.${textarea.id}`, newText)

            // Memperbarui posisi kursor
            // const newCursorPos = cursorPos + text.length + 1;
            // textarea.setSelectionRange(newCursorPos, newCursorPos);
            // textarea.focus();
        }
    };
    // console.log(errors)
    return (
        <SimpleBar forceVisible="y" style={{ height: '100vh' }}>
            <div className="w-[400px] h-screen flex flex-col gap-6">
                <header className="pl-6 pr-3 pt-6 pb-4 relative flex flex-col gap-y-6 border-b border-gray-light/200">
                    <button type="button" className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11 h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                        <XClose size={24} stroke={'currentColor'} onClick={() => handleCurrentSlider({ status: false, current: null })} />
                    </button>
                    <div className="flex items-start gap-x-4">
                        <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                            <Hash02 size={20} stroke={'currentColor'} />
                        </span>
                        <section className="flex flex-col gap-1">
                            <p className="text-xl-semibold text-gray-light/900">{currentSlider?.id ? 'Edit' : 'Add'} template message</p>
                            <p className="text-sm-regular text-gray-light/600">Please provide the details for a new template message.</p>
                        </section>
                    </div>
                    <div>
                        {/* <button type="button" className="w-max h-max flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/600 border border-gray-light/300 shadow-shadows/shadow-xs">
                            <BookOpen01 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Guide</p>
                        </button> */}
                    </div>
                </header>
                <div className="flex-1 overflow-hidden">
                    <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                        <form onSubmit={handleSubmit(submit)} className="h-full flex flex-col gap-6">
                            <div className="px-6 flex flex-col gap-6 flex-1">
                                <div className="flex flex-col gap-1.5">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-1.5">
                                            <p className="text-sm-medium text-gray-light/700 whitespace-nowrap">Available field</p>
                                            <MyTooltip placement={"left"} target={<span><HelpCircle size={16} className={'text-gray-light/400'} stroke={'currentColor'} /></span>}>
                                                <div className="flex flex-col gap-y-1">
                                                    <p className="text-white text-xs-semibold">Query from database</p>
                                                    <p className="text-xs-medium text-gray-light/300">
                                                        You need to connect to existing distributed database in order to fetch all available field.
                                                    </p>
                                                </div>
                                            </MyTooltip>
                                        </div>
                                        {/* <button type="button" className="w-max h-max flex items-center gap-x-1.5 text-brand/700">
                                            <Database03 size={20} stroke={'currentColor'} />
                                            <p className="text-sm-semibold">Connected database</p>
                                        </button> */}
                                    </div>
                                    <div>
                                        {<MyAutocompleteV3 removeable={false} onClick={(e) => {
                                            console.log('ini di klik', e)
                                            insertText(e)
                                        }} value={availabelFields} name="available_field" multiple={true} disabled={true} />}
                                    </div>
                                </div>
                                <hr className='border-gray-light/200' />
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-col gap-y-1.5 w-full">
                                        <label htmlFor="template_name" className="text-sm-medium text-gray-light/700">Template message name</label>
                                        <MyFormTextField control={control} name="name" placeholder={'Enter your template name'} />
                                    </div>
                                    <div className="flex flex-col gap-y-1.5 w-full">
                                        <label htmlFor="shortcut" className="text-sm-medium text-gray-light/700">Shortcut</label>
                                        {<MyAutocompleteV3 value={shortcut ? { label: shortcut } : null} onChange={(e, value) => setValue('shortcut', value?.label)} options={Array.from({ length: 9 }, (_, index) => {
                                            return { label: `Ctrl + ${index + 1}` }
                                        })} name="shortcut" placeholder={'Enter your shortcut'} />}
                                    </div>
                                    <div className="flex flex-col gap-y-1.5 w-full">
                                        <div className="flex flex-col gap-y-1.5 w-full">
                                            <PopperViewSample text={text} availableFields={availabelFields} id={'popper-view-sample'} target={(open, handleClick) => <div className="flex items-center gap-3">
                                                <div className="flex-1 overflow-hidden">
                                                    <p className="text-sm-medium text-gray-light/700">Variant 1</p>
                                                </div>
                                                <button type="button" onClick={handleClick} className="text-sm-semibold text-brand/700">
                                                    View sample
                                                </button>
                                            </div>} />
                                            <textarea value={text} maxLength={750} onChange={(e) => setValue('text', e?.target?.value)} ref={textareaRef} placeholder="Type here..." rows={12} className="w-full resize-none py-3 px-[14px] border border-gray-light/300 shadow-shadows/shadow-xs rounded-lg outline-none placeholder-gray-light/500 focus:outline-none focus:border-brand/300 focus:shadow-focus-rings/ring-brand-shadow-xs"></textarea>
                                        </div>
                                        <p className="text-sm-regular text-gray-light/600">{750 - (text?.length ?? 0)} characters left</p>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        <hr className='border-gray-light/200' />
                                        <div className="flex justify-end items-center gap-3">
                                            <button onClick={() => {
                                                setHide([...hide.map(e => false)])
                                            }} type="button" className="text-gray-light/600 text-sm-semibold">Expand All</button>
                                            <button onClick={() => {
                                                setHide([...hide.map(e => true)])
                                            }} type="button" className="text-brand/700 text-sm-semibold">Collapse All</button>
                                        </div>
                                        <div className="flex flex-col gap-3 pt-6">
                                            {generates?.map((e, index) => {
                                                return <>
                                                    <div className="flex flex-col gap-3">
                                                        <div className="flex items-center gap-3">
                                                            <button onClick={() => {
                                                                var newList = [...hide]
                                                                newList[index] = !newList[index];
                                                                setHide(newList)
                                                            }} type="button" className="p-2">
                                                                {!hide[index] ? <ChevronUp size={20} stroke={'currentColor'} />
                                                                    : <ChevronDown size={20} stroke={'currentColor'} />}
                                                            </button>
                                                            <div className="flex-1 overflow-hidden">
                                                                <p className="text-sm-medium text-gray-light/700">Variant {index + 2}</p>
                                                            </div>
                                                            <PopperViewSample text={e} availableFields={availabelFields} id={'popper-view-sample'} target={(open, handleClick) => <div className="flex items-center gap-3">
                                                                <button type="button" onClick={handleClick} className="text-sm-semibold text-brand/700">
                                                                    View sample
                                                                </button>
                                                            </div>} />
                                                            <button onClick={() => {
                                                                var newList = [...generates]
                                                                if (generates.length != 1)
                                                                    newList.splice(index, 1);
                                                                else newList = []
                                                                hide.splice(index, 1)
                                                                setHide([...hide])
                                                                setValue('generates', newList)
                                                            }} type="button" className="p-2 text-error/700">
                                                                <Trash01 size={20} stroke={'currentColor'} />
                                                            </button>
                                                        </div>
                                                        <div className={hide[index] ? "hidden" : ""}>
                                                            <textarea maxLength={750} id={index} value={e} onChange={(e) => setValue(`generates.${index}`, e?.target?.value)} placeholder="Type here..." rows={12} className="w-full resize-none py-3 px-[14px] border border-gray-light/300 shadow-shadows/shadow-xs rounded-lg outline-none placeholder-gray-light/500 focus:outline-none focus:border-brand/300 focus:shadow-focus-rings/ring-brand-shadow-xs"></textarea>
                                                            <p className="text-sm-regular text-gray-light/600">{750 - (e?.length ?? 0)} characters left</p>
                                                        </div>
                                                    </div>
                                                    {/* <hr className='border-gray-light/200' /> */}
                                                </>
                                            })}
                                            {/* <hr className='border-gray-light/200' />
                                            <div className="flex flex-col gap-3">
                                                <div className="flex items-center gap-3">
                                                    <button type="button" className="p-2">
                                                        <ChevronUp size={20} stroke={'currentColor'} />
                                                    </button>
                                                    <div className="flex-1 overflow-hidden">
                                                        <p className="text-sm-medium text-gray-light/700">Variant 3</p>
                                                    </div>
                                                    <button type="button" className="text-brand/700 text-sm-semibold">View Sample</button>
                                                    <button type="button" className="p-2 text-error/700">
                                                        <Trash01 size={20} stroke={'currentColor'} />
                                                    </button>
                                                </div>
                                                <div className="hidden">
                                                    <textarea placeholder="Type here..." rows={12} className="w-full resize-none py-3 px-[14px] border border-gray-light/300 shadow-shadows/shadow-xs rounded-lg outline-none placeholder-gray-light/500 focus:outline-none focus:border-brand/300 focus:shadow-focus-rings/ring-brand-shadow-xs"></textarea>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="flex flex-col gap-3">
                                            <hr className='border-gray-light/200' />
                                            <div className="flex justify-end items-center gap-3">
                                                <button type="button" onClick={() => {
                                                    generates.push('')
                                                    setValue('generates', generates)
                                                    setHide(value => { value.push(false); return value })
                                                }} className="flex items-center gap-1">
                                                    <Plus className="text-brand/700" size={20} stroke={'currentColor'} />
                                                    <p className="text-brand/700 text-sm-semibold">Add more variant</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer className="border-t border-gray-light/200 px-6 py-4 flex items-center justify-end gap-x-3">
                                {/* <PopperViewSample id={'popper-view-sample'} target={(open, handleClick) => <button onClick={handleClick} className="text-sm-semibold text-brand/700">
                                    View sample
                                </button>} /> */}

                                <button type="button" onClick={() => handleCurrentSlider({ status: false, current: null })} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                    <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                                </button>
                                <button type="submit" className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                    <p className="text-sm-semibold text-white">Continue</p>
                                </button>
                            </footer>
                        </form>
                    </SimpleBar>
                </div>
            </div>
        </SimpleBar>
    );
};

export default ModalTemplateSetting;