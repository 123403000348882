import React, { useState } from "react";
import { XClose } from "untitledui-js/icons/general";
import MyTextField from "../../../components/TextField/MyTextField";
import SimpleBar from 'simplebar-react';
import { useSettings } from "../../../contexts/SettingContext";
import MyAutocompleteV2 from "../../../components/Autocomplete/MyAutocompleteV2";
import { ShieldPlus } from "untitledui-js/icons/security";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import MySwicth from "../../../components/Switch/MySwitch";
import MyTextFieldV2 from "../../../components/TextField/MyTextFieldv2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ruleSettingSchema } from "../../../yup_schema/setting_schema";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import MyAutocompleteV3 from "../../../components/Autocomplete/MyAutocompleteV3";
import { User01 } from "untitledui-js/icons/users";

const ModalRulesSetting = () => {
    const { updateRule, createRuleSetting, handleCurrentSlider, currentSlider, showRuleSetting } = useSettings();
    const formRuleSetting = useForm({ resolver: yupResolver(ruleSettingSchema) });
    const { clearErrors, watch, setValue, handleSubmit, control, formState: { errors }, register } = formRuleSetting;

    const [wordOptions, setWordOptions] = useState([]);
    const submit = currentSlider.id ? updateRule(currentSlider.id) : createRuleSetting
    useEffect(() => {
        if (currentSlider.id) {
            showRuleSetting(currentSlider.id).then(data => {
                setValue('name', data.name);
                setValue('words', data.words);
                setValue('prevent_send', data.prevent_send);
                setValue('is_active', data.is_active);
                setWordOptions(data.words.map(e => { return { label: e, id: e } }))
            })
        } else {
            setValue('words', []);
        }
    }, [])
    return (
        <div className="w-[400px] h-screen flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11 h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} onClick={() => handleCurrentSlider({ status: false, current: null })} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <ShieldPlus size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">{currentSlider.id ? 'Edit' : 'Add'} rules</p>
                    <p className="text-sm-regular text-gray-light/600">Please provide the details for a new rules.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <form className="h-full flex flex-col gap-6" control={control} onSubmit={handleSubmit(submit)}>
                        <div className="px-6 flex flex-col gap-4 flex-1">
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="name" className="text-sm-medium text-gray-light/700">Rule name</label>
                                <MyTextFieldV2 maxLength={25} onChangeForm={(e) => setValue('name', e?.target?.value)} control={control} name="name" placeholder={'Enter your rule name'} />
                            </div>
                            {/* <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="applied_for_label" className="text-sm-medium text-gray-light/700">Applied for label</label>
                                <MyAutocompleteV2 control={control} name="applied_for_label" multiple={true} placeholder={'Select your applied for label'} />
                            </div> */}
                            <div className="flex flex-col gap-y-1.5 w-full">
                                <label htmlFor="words" className="text-sm-medium text-gray-light/700">Restricted word</label>
                                {<MyAutocompleteV3 value={wordOptions} freeSolo={true} name="words" placeholder={'Enter your word'} multiple={true}
                                    onChange={(e, value) => {
                                        setValue('words', value.map((e) => e.label));
                                        setWordOptions(value)
                                    }}
                                    renderTag={(option, i, onDelete) => {
                                        return (
                                            <div className="h-max w-max py-0.5 pr-1 pl-2.5 flex items-center gap-x-1 border border-error/600 rounded-full" >
                                                <p className="text-sm-medium text-error/700">{option.label} </p>
                                                <button type="button" onClick={() => onDelete(i)} className="w-4 h-max min-w-[16px] min-h-[16px] p-0.5">
                                                    <XClose className={'text-error/400'} size={14} strokeWidth={3} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        )
                                    }}
                                />}
                            </div>
                            <div className="flex flex-col gap-y-5">
                                {/* <div className="flex items-start gap-x-2 w-full">
                                    <MyCheckbox control={control} value={true} name={'prevent_send'} />
                                    <div className="flex flex-col">
                                        <p className="text-sm-medium text-gray-light/700">Prevent send</p>
                                        <p className="text-sm-regular text-gray-light/600">Prevent users from sending the message after they have clicked the send button.</p>
                                    </div>
                                </div>
                                <hr className='border-gray-light/200' /> */}
                                <div className="flex items-start gap-x-2 w-full">
                                    <MySwicth control={control} value={true} name={"is_active"} />
                                    <div className="flex flex-col">
                                        <p className="text-sm-medium text-gray-light/700">Is active</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 px-6 py-4 flex items-center justify-end gap-4">
                            <button type="reset" onClick={() => handleCurrentSlider({ status: false, current: null })} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button type="submit" className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Apply</p>
                            </button>
                        </footer>
                    </form>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalRulesSetting;