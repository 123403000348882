import React from "react";
import SimpleBar from "simplebar-react";
import TableContainerConversation from "./table_container_conversation";
import TableContainerSystem from "./table_container_system";
import ModalDetailConversation from "./modal_detail_conversation";
import { useAuditTrial } from "../../contexts/AuditTrialContext";
import MyContainerSliderModal from "../../components/SliderModal/MyContainerSliderModal";

const AuditTrial = () => {
    const { currentSlider, currentTable, handleCurrentTable, handleCurrentSlider } = useAuditTrial();

    return (
        <SimpleBar forceVisible="y" style={{ height: '100vh' }}>
            <MyContainerSliderModal open={currentSlider?.status ?? false} onClose={() => handleCurrentSlider()}>
                {currentSlider?.current === 'slider-detail-conversation' && <ModalDetailConversation />}
            </MyContainerSliderModal>

            <main className="w-full h-full pt-8 flex flex-col gap-8">
                <header className="px-8 flex flex-col gap-6">
                    <div className="flex flex-col">
                        <p id="audit-title" className="display-sm-semibold text-gray-light/900">Audit trail</p>
                        <p className="text-md-regular text-gray-light/600">Monitor and stay updated with the activities happening in the system.</p>
                    </div>
                    {/* <div className="flex items-center gap-1">
                        <button onClick={() => handleCurrentTable('table-conversation')} className={`${currentTable === 'table-conversation' ? 'text-brand/700 bg-brand/50' : 'text-gray-light/500'} text-sm-semibold rounded-lg px-3 py-2`}>Conversations SIM card</button>
                        <button onClick={() => handleCurrentTable('table-system')} className={`${currentTable === 'table-system' ? 'text-brand/700 bg-brand/50' : 'text-gray-light/500'} text-sm-semibold rounded-lg px-3 py-2`}>System events</button>
                    </div> */}
                </header>
                <div className="flex-1 pb-12">
                    {(() => {
                        switch (currentTable) {
                            case 'table-conversation':
                                return <TableContainerConversation />;
                            case 'table-system':
                                return <TableContainerSystem />;
                            default:
                                return <></>;
                        }
                    })()}
                </div>
            </main>
        </SimpleBar>

    );
};

export default AuditTrial;