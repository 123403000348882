import React, { useEffect } from "react";
import { ArrowDown, ArrowsDown, ArrowsUp, SwitchVertical01 } from "untitledui-js/icons/arrow";
import { TrendDown01, TrendUp01 } from "untitledui-js/icons/charts";
import MyPagination from "../../../components/Pagination/MyPagination";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import MyTextField from "../../../components/TextField/MyTextField";
import MyFilter from "../../SimCard/Poppers/filter";
import { DownloadCloud02, FilterLines, HelpCircle } from "untitledui-js/icons/general";
import MySorted from "../../SimCard/Poppers/sorted";
import SimpleBar from "simplebar-react";
import MyTooltip from "../../../components/Tooltip/MyTooltip";
import { useDashboardAttempt } from "../../../contexts/DashboardAttemptContext";
import moment from "moment";
import { useApp } from "../../../contexts/AppContext";
import { User01 } from "untitledui-js/icons/users";
import { debounce } from "lodash";
import MyFilterModal from "../../../components/Modal/MyFilterModal";

const Performance = () => {
  const { session } = useApp()
  const { getAttempt, attempt, params2, setParams2, downloadAttempt } = useDashboardAttempt()
  useEffect(() => {
    getAttempt()
  }, [params2])
  const search = (e) => {
    setParams2(value => { return { ...value, search: e?.target?.value, page: 1 } })
  }
  useEffect(() => {
    console.log(params2, 'Params2 <<<<<')
  }, [params2])

  const debounceSearch = debounce(search, 1000)
  return (
    <>
      <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
        <main className="pt-8 pb-12 flex flex-col gap-8">
          <div className="px-8 flex">
            <div className="flex-1 flex flex-col gap-y-1">
              <p className="display-sm-semibold text-gray-light/900">Welcome back, {session?.name}</p>
              <p className="text-md-regular text-gray-light/600">Keep track of your team's performance</p>
            </div>
          </div>
          {/* <div className="px-8 grid grid-cols-3 gap-x-6">
            <div className="rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-light/200 text-blue-light/600">
                <ArrowsDown size={24} stroke={'currentColor'} />
              </div>
              <div className="flex flex-col gap-y-2 justify-center">
                <p className="text-sm-medium text-gray-light/600">Inbound messages</p>
                <div className="w-full flex items-center justify-between">
                  <p className="display-md-semibold text-gray-light/900">2,420</p>
                  <div className="flex items-center gap-x-2">
                    <div className="flex items-center gap-x-1">
                      <TrendUp01 className={'text-success/500'} size={20} stroke={'currentColor'} />
                      <p className="text-sm-medium text-success/600">44%</p>
                    </div>
                    <p className="text-sm-medium text-gray-light/600">vs last month</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-brand/100 text-brand/600">
                <ArrowsUp size={24} stroke={'currentColor'} />
              </div>
              <div className="flex flex-col gap-y-2 justify-center">
                <p className="text-sm-medium text-gray-light/600">Inbound messages</p>
                <div className="w-full flex items-center justify-between">
                  <p className="display-md-semibold text-gray-light/900">7,316</p>
                  <div className="flex items-center gap-x-2">
                    <div className="flex items-center gap-x-1">
                      <TrendDown01 className={'text-error/600'} size={20} stroke={'currentColor'} />
                      <p className="text-sm-medium text-error/600">44%</p>
                    </div>
                    <p className="text-sm-medium text-gray-light/600">vs last month</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-warning/100 text-warning/600">
                <ArrowsDown size={24} stroke={'currentColor'} />
              </div>
              <div className="flex flex-col gap-y-2 justify-center">
                <p className="text-sm-medium text-gray-light/600">Inbound messages</p>
                <div className="w-full flex items-center justify-between">
                  <p className="display-md-semibold text-gray-light/900">1,210</p>
                  <div className="flex items-center gap-x-2">
                    <div className="flex items-center gap-x-1">
                      <TrendUp01 className={'text-success/500'} size={20} stroke={'currentColor'} />
                      <p className="text-sm-medium text-success/600">44%</p>
                    </div>
                    <p className="text-sm-medium text-gray-light/600">vs last month</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="px-8">
            <div className="rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200">
              <div className="flex items-center justify-between">
                <p className="text-lg-semibold gray-light/600">Conversation overview</p>
                <button className="text-sm-semibold text-gray-light/700 px-3 py-2 flex items-center gap-x-1.5 border gray-light/300 rounded-md">
                  More report
                </button>
              </div>
              <div className="flex items-center gap-x-1">
                <button onClick={() => setParams2(value => { return { ...value, dates: '12m' } })} className={[params2.dates == '12m' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                  12 months
                </button>
                <button onClick={() => setParams2(value => { return { ...value, dates: '3m' } })} className={[params2.dates == '3m' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                  3 months
                </button>
                <button onClick={() => setParams2(value => { return { ...value, dates: '30d' } })} className={[params2.dates == '30d' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                  30 days
                </button>
                <button onClick={() => setParams2(value => { return { ...value, dates: '7d' } })} className={[params2.dates == '7d' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                  7 days
                </button>
                <button onClick={() => setParams2(value => { return { ...value, dates: '24h' } })} className={[params2.dates == '24h' ? "bg-gray-light/50 " : "", " text-sm-semibold text-gray-light/700 rounded-md px-3 py-2"]}>
                  24 hours
                </button>
              </div>
            </div>
          </div> */}
          <div className="px-8">
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
              <div className="pt-5 flex flex-col gap-y-5">
                <div className='px-6 flex items-center gap-x-4 w-full'>
                  <div className="flex-1 flex-col gap-1">
                    <p className="text-lg-semibold text-gray-light/900">Recent attempt</p>
                    <p className="text-sm-regular text-gray-light/600">See your team’s most recent interactions and attempts.</p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <button onClick={downloadAttempt} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                      <DownloadCloud02 size={20} stroke={'currentColor'} />
                      <p className="text-sm-semibold">Export</p>
                    </button>
                  </div>
                </div>
                <hr className='border-gray-light/200' />
              </div>
              <div className="py-3 px-4 flex gap-3">
                <div className="w-full max-w-xs">
                  <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                </div>
                <MyFilterModal id={'filter-violated'} currentFilters={attempt?.filter} onChange={(filter) => setParams2({ ...params2, filter: filter, page: 1 })}
                  target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                    <FilterLines size={20} stroke={'currentColor'} />
                    <p className="text-sm-semibold">Filter</p>
                  </button>)} />
                {/* 

                <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                  <SwitchVertical01 size={20} stroke={'currentColor'} />
                  <p className="text-sm-semibold">Sort</p>
                </button>)} /> */}
              </div>
              <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                <table className='table border-collapse w-full'>
                  <thead className='border-t border-gray-light/200 p-0'>
                    <tr className='p-0'>
                      <th className='px-6 py-3 text-left'>
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Attempt by</p>
                      </th>
                      <th className='px-6 py-3 text-left'>
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Customer</p>
                      </th>
                      <th className='px-6 py-3 text-left'>
                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Attempt status</p>
                      </th>
                      <th className='px-6 py-3 text-left'>
                        <div className="flex items-center gap-x-1 text-gray-light/600">
                          <p className="text-xs-medium  whitespace-nowrap">Time & message</p>
                          {/* <ArrowDown size={16} stroke={'currentColor'} /> */}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {attempt?.data?.map((e, i) => {
                      const user = e.user;
                      return (
                        <tr key={i}>
                          <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                            <div className="flex items-center gap-3">
                              <div className="relative">
                                {user.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                  <img className="w-full h-full object-cover" src={user.full_path_photo} alt="" />
                                </div>
                                  : <div className="bg-gray-light/100 flex justify-center items-center w-10 h-10 min-w-[40px] min-h-[40px] rounded-full border border-[#00000014]">
                                    <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                                  </div>}
                                {/* <span className="absolute bottom-0 right-0 rounded-full flex bg-success/500 w-2.5 h-2.5 border border-white"></span> */}
                              </div>
                              <div className="flex flex-col">
                                <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">{e.user.name}</p>
                                <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{e.user.email}</p>
                              </div>
                            </div>
                          </td>
                          <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                            <div className="flex items-center gap-3">
                              <div className="bg-gray-light/100 flex justify-center items-center w-10 h-10 min-w-[40px] min-h-[40px] rounded-full border border-[#00000014]">
                                <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                              </div>
                              <div className="flex flex-col">
                                <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">{e.task?.detail_customer?.name}</p>
                                <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{e.task?.detail_customer?.number}</p>
                              </div>
                            </div>
                          </td>
                          <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                            <div className="w-max border border-gray-light/300 rounded-md shados-shadows/shadow-xs py-0.5 px-1.5">
                              <p className="text-xs-medium text-gray-light/700 whitespace-nowrap">{e.attempt}</p>
                            </div>
                          </td>
                          <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                            <div>
                              <p className="text-sm-regular text-gray-light/900">{moment(e.created_at).format('DD MMM YYYY • hh:mma')}</p>
                              <p className="text-sm-regular text-gray-light/600 line-clamp-1">{e.description}</p>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </SimpleBar>
              <div className="border-t border-gray-light/200">
                <MyPagination meta={attempt.meta} setParams={setParams2} />
              </div>
            </div>
          </div>
        </main>
      </SimpleBar>
    </>
  );
};

export default Performance;