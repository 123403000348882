import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";
import TableContainerRegistered from "./table_container_registered";
import TableContainerAppeal from "./table_container_appeal";
import { useSimCard } from "../../contexts/SimCardContext";
import SliderEditSimCard from "./SliderModals/slider_edit_sim_card";
import SliderUpdateProof from "./SliderModals/slider_upload_proof";
import ModalRegisterSimCard from "./Modals/modal_register_sim_card";
import MyContainerSliderModal from "../../components/SliderModal/MyContainerSliderModal";
import MySorted from "./Poppers/sorted";

const SimCard = () => {
    const { currentSlider, currentTable, handleCurrentTable, handleCurrentSlider } = useSimCard();

    return (
        <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
            <ModalRegisterSimCard />
            <MyContainerSliderModal open={currentSlider.status} onClose={() => handleCurrentSlider()}>
                {currentSlider.current === 'slider-edit-sim-card' && <SliderEditSimCard />}
                {currentSlider.current === 'slider-upload-proof' && <SliderUpdateProof />}
            </MyContainerSliderModal>

            <main className="w-full h-full pt-8 pb-12 flex flex-col gap-8">
                <MySorted />
                <header className="px-8 flex flex-col gap-6">
                    <div className="flex flex-col">
                        <p className="display-sm-semibold text-gray-light/900">SIM card management</p>
                        <p className="text-md-regular text-gray-light/600">Add, remove, and allocate SIM cards utilized by users.</p>
                    </div>
                    {/* <div className="flex items-center gap-1">
                        <button onClick={() => handleCurrentTable('table-register')} className={`${currentTable === 'table-register' ? 'text-brand/700 bg-brand/50' : 'text-gray-light/500'} text-sm-semibold rounded-lg px-3 py-2`}>Registered SIM card</button>
                        <button onClick={() => handleCurrentTable('table-appeal')} className={`${currentTable === 'table-appeal' ? 'text-brand/700 bg-brand/50' : 'text-gray-light/500'} text-sm-semibold rounded-lg px-3 py-2`}>Appeal process</button>
                    </div> */}
                </header>
                <div className="flex-1">
                    {(() => {
                        switch (currentTable) {
                            case 'table-register':
                                return <TableContainerRegistered />;
                            case 'table-appeal':
                                return <TableContainerAppeal />;
                            default:
                                return <></>;
                        }
                    })()}
                </div>
            </main>
        </SimpleBar>
    );
};

export default SimCard;