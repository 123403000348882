import axios from "axios";
import {
  get,
  post,
  patch,
  remove,
  socket,
  socketLocal,
  download,
} from "./NetworkUtils";
const HttpRequests = {
  socket,
  login: async (data) => await post("/v1/auth", data),
  session: async (data) => await get("/v1/auth/session", data),

  getSimCard: async (params) => await get("/v1/sim-card", params),
  addSimCard: async (data) => await post("/v1/sim-card", data),
  relogin: async (id) => await post("/v1/sim-card/" + id),
  assignNumber: async (id, data) => await patch("/v1/sim-card/" + id, data),
  getUser: async (params) => await get("/v1/sim-card/user", params),
  logoutWa: async (id) => await post("/v1/sim-card/" + id + "/logout"),

  getTaskDistribution: async (params) =>
    await get("/v1/task-distribution/", params),
  taskDistribution: async (data) =>
    await post("/v1/task-distribution/", data, "form-data", 60 * 1000 * 15),
  updateTaskDistribution: async (id, data) =>
    await patch("/v1/task-distribution/" + id + "/status", data),
  deleteTask: async (id) => await remove("/v1/task-distribution/" + id),
  getTask: async (data) =>
    await get("/v1/chat/task", data, null, 60 * 1000 * 5),
  getMessage: async (id, params) => await get("/v1/chat/" + id, params),
  sendMessage: async (id, message) => await post("/v1/chat/" + id, { message }),
  listAttempts: async (params) => await get("/v1/chat/attempts", params),
  reportAttempt: async (id, data) =>
    await post("/v1/chat/" + id + "/report", data),
  attemptHistory: async (id, data) =>
    await get("/v1/chat/" + id + "/attempt-history", data),
  search: async (text, filter) =>
    await get("/v1/chat/search", { search: text, filter }, null, 60 * 1000 * 5),
  searchTemplate: async (text) =>
    await get("/v1/chat/search-template", { search: text }),
  assignSim: async () => await post("/v1/chat/assign-sim"),
  removeSim: async () => await remove("/v1/chat/assign-sim"),

  checkNumber: async (data) => await post("/v1/number/check", data),

  checkEngine: async (data) => await socketEmit("check", data),
  running: async (data) => await socketEmit("initialize", data),
  sendMessageLocal: async (data) => await socketEmit("command", data),
  logoutLocal: async (id) => await socketEmit("logout", { id }),
  logoutWaLocal: async (data) => await socketEmit("command", data),
  setCustomers: async (data) => await socketEmit("my-customer", data),

  getConversations: async (params) => await get("/v1/audit-trail", params),
  searchUserAudit: async (params) =>
    await get("/v1/audit-trail/list-user", params),
  searchCustomerAudit: async (params) =>
    await get("/v1/audit-trail/list-customer", params),
  getDetailConversations: async (id) =>
    await get("/v1/audit-trail/" + id, {}, null, 60 * 1000 * 2),
  downloadAudit: (params) => download("/v1/audit-trail/download", params),

  getRuleSetting: async (params) => await get("/v1/setting/rule/", params),
  createRuleSetting: async (data) => await post("/v1/setting/rule/", data),
  showRuleSetting: async (id) => await get("/v1/setting/rule/" + id),
  updateRuleSetting: async (id, data) =>
    await patch("/v1/setting/rule/" + id, data),
  updateStatusRuleSetting: async (id, data) =>
    await patch("/v1/setting/rule/" + id + "/status", data),
  deleteRuleSetting: async (id) => await remove("/v1/setting/rule/" + id),

  getUserSetting: async (params) => await get("/v1/setting/user/", params),
  searchManager: async (params) =>
    await get("/v1/setting/user/manager", params),
  createUserSetting: async (data) =>
    await post("/v1/setting/user/", data, {}, "form-data"),
  showUserSetting: async (id) => await get("/v1/setting/user/" + id),
  updateUserSetting: async (id, data) =>
    await patch("/v1/setting/user/" + id, data, {}, "form-data"),
  updateUserStatusSetting: async (id, data) =>
    await patch("/v1/setting/user/" + id + "/status", data, {}, "form-data"),
  deleteUserSetting: async (id) => await remove("/v1/setting/user/" + id),

  getRoleSetting: async (params) => await get("/v1/setting/role/", params),
  searchRoleSetting: async (params) =>
    await get("/v1/setting/role/search", params),
  createRoleSetting: async (data) => await post("/v1/setting/role/", data),
  showRoleSetting: async (id) => await get("/v1/setting/role/" + id),
  updateRoleSetting: async (id, data) =>
    await patch("/v1/setting/role/" + id, data),
  deleteRoleSetting: async (id) => await remove("/v1/setting/role/" + id),

  getAttemptSetting: async (params) =>
    await get("/v1/setting/attempt/", params),
  createAttemptSetting: async (data) =>
    await post("/v1/setting/attempt/", data),
  showAttemptSetting: async (id) => await get("/v1/setting/attempt/" + id),
  updateAttemptSetting: async (id, data) =>
    await patch("/v1/setting/attempt/" + id, data),
  deleteAttemptSetting: async (id) => await remove("/v1/setting/attempt/" + id),

  getRoles: async (params) => await get("/v1/setting/role/", params),
  searchRolePage: async (params) => await get("/v1/setting/role/page", params),
  createRole: async (data) => await post("/v1/setting/role/", data),
  showRole: async (id) => await get("/v1/setting/role/" + id),
  updateRole: async (id, data) => await patch("/v1/setting/role/" + id, data),
  deleteRole: async (id) => await remove("/v1/setting/role/" + id),

  getFields: async () => await get("/v1/setting/template/fields", {}),
  createTemplate: async (data) => await post("/v1/setting/template", data),
  getTemplate: async (params) => await get("/v1/setting/template", params),
  getDetailTemplate: async (id) => await get("/v1/setting/template/" + id),
  updateTemplate: async (id, data) =>
    await patch("/v1/setting/template/" + id, data),
  deleteTemplate: async (id) => await remove("/v1/setting/template/" + id),

  getTeam: async (params) => await get("/v1/team", params),
  searchTeam: async (params) => await get("/v1/team/search", params),
  addTeam: async (data) => await post("/v1/team", data),
  removeTeam: async (id) => await remove("/v1/team/" + id, {}),

  getAttemptDashboard: async (params) =>
    await get("/v1/dashboard/attempt", params),
  getViolationDashboard: async (params) =>
    await get("/v1/dashboard/violation", params),
  getViolationChartDashboard: async (params) =>
    await get("/v1/dashboard/violation-chart", params),
  downloadAttempt: (params) =>
    download("/v1/dashboard/attempt-download", params),
  downloadViolated: (params) =>
    download("/v1/dashboard/violation-download", params),

  getDataDynamic: async (endpoint, params) => await get(endpoint, params),
};
const socketEmit = (name, data, timeout = 500) => {
  if (!socketLocal.connected && socketLocal.connecting === false) {
    socketLocal.connect();
    throw "not connected";
  }
  console.log("name", name);
  return new Promise((resolve, reject) => {
    socketLocal.emit(name, data, (res) => {
      console.log("success", name, res);
      socketLocal.off("receive-" + name);
      if (res) {
        resolve({ data: res });
      } else reject({ message: "Internal server error" });
    });
    var sended = false;
    // socketLocal.emit(name, data);
    // socketLocal.on(name, (data) => {
    //   console.log("socketLocal.on", data);
    //   socketLocal.off(name);
    //   resolve({ data });
    // });
    socketLocal.on("receive-" + name, (data) => {
      sended = true;
    });
    socketLocal.on("connect_error", () => {
      socketLocal.off("receive-" + name);
      reject({ message: "Internal server error" });
    });
    setTimeout(() => {
      if (sended) return;
      console.log("emit", name, "Request timeout");
      socketLocal.off("receive-" + name);
      reject({ message: "Request timeout" });
    }, timeout);
  });
};
export default HttpRequests;
