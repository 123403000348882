import React, { useState } from "react";
import { FilterLines, Hash02, Loading01, Plus, X } from "untitledui-js/icons/general";
import { SimCard } from "untitledui-js/icons/media-devices";
import MyTextField from "../../components/TextField/MyTextField";
import { UserSquare } from "untitledui-js/icons/users";
import { Flag05 } from "untitledui-js/icons/maps";
import { AlertCircle } from "untitledui-js/icons/alerts";
import ModalInfo from "./Modals/modal_info";
import { useMessage } from "../../contexts/MessageContext";
import { useEffect } from "react";
import { useApp } from "../../contexts/AppContext";
import SimpleBar from "simplebar-react";
import MyContainerSliderModal from "../../components/SliderModal/MyContainerSliderModal";
import PopperCommandBar from "./Poppers/popper_command_bar";
import PopperAssignNew from "./Poppers/popper_assign_new";
import PopperChangeNumber from "./Poppers/popper_change_number";
import ModalBannerNumber from "./Modals/modal_banned_number";
import ModalNumberTakenOver from "./Modals/modal_ number_taken_over";
import MyBlockingModal from "../../components/Modal/MyBlokingModal";
import PopperActiveSession from "./Poppers/popper_active_session";
import PopperNewReport from "./Poppers/popper_new_report";
import AttemptHistory from "./Components/AttemptHistory";
import { debounce } from "lodash";
import UserCard from "./Components/UserCard";
import Chat from "./Components/Chat";
import MyFilterModal from "../../components/Modal/MyFilterModal";

const Message = () => {
    const { isScreenBloking, session, engine, messageStatus, setMessageStatus } = useApp()
    const { handleSelectedTask, tasks, getTask, setText, text, setChatRoom, chatRoom, getMessage, sendMessage,
        params, setParams, currentSlider, handleCurrentSlider, assignSim, search, searchRes, discardSim, filter, setFilter, handleCheckNumber } = useMessage()
    const [selectedFilter, setSelectedFilter] = useState([])
    useEffect(() => {
        getTask();
    }, [])
    useEffect(() => {
        search(null, selectedFilter)
    }, [selectedFilter])
    const waStatus = params?.current?.detail_customer?.wa_status;
    const currId = params?.current?.detail_customer?.id;
    const currNumber = params?.current?.detail_customer?.number;
    const disable = engine && engine.status || waStatus == 'unregistered';
    const debounceSearch = debounce((e) => search(e, selectedFilter), 1000);
    return (
        <>
            <MyContainerSliderModal open={currentSlider.status} onClose={() => handleCurrentSlider()}>
                {currentSlider.current === 'slider-info-message' && <ModalInfo />}

            </MyContainerSliderModal>

            <ModalBannerNumber />
            <ModalNumberTakenOver />
            <MyBlockingModal open={isScreenBloking}>
                <div className="flex flex-col gap-4 text-center items-center justify-center">
                    <Loading01 style={{ animation: "spin 2s linear infinite" }} className="text-white" size={48} strokeWidth={1} stroke={'currentColor'} />
                    <div className="flex flex-col gap-1 w-[352px]">
                        <p className="display-xs-semibold text-white">Please wait...</p>
                        <p className="text-lg-regular text-white">This might take 1 to 5 minutes based on your computer’s specification. Please avoid refreshing your windows while the process is ongoing.</p>
                    </div>
                </div>
            </MyBlockingModal>


            <div className="w-full flex h-screen">
                <div className="flex-1 flex flex-col h-screen">
                    <div className="h-24 w-full flex items-center justify-between gap-4 px-6 py-5">
                        <div className="flex items-center gap-x-2">
                            <p className="text-lg-semibold text-gray-light/900">Messages</p>
                            {/* <div className="flex items-center justify-center gap-1 py-0.5 px-1.5 border border-gray-light/300 rounded-md">
                                <span className="w-2 h-2 bg-success/500 rounded-full"></span>
                                <p className="text-xs-medium text-gray-light/700">40</p>
                            </div> */}
                        </div>
                        <div className="flex items-center gap-3">
                            <MyFilterModal id={'filter-task'} currentFilters={filter} onChange={(filter) => setSelectedFilter(filter)}
                                target={(open, handleClick) => (<button onClick={handleClick} className={`flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700`}>
                                    <FilterLines size={20} stroke={'currentColor'} />
                                </button>)} />
                            {engine && (() => {
                                switch (engine.status) {
                                    case 'multi-tab':
                                        return <button onClick={() => alert('There\'s merpati CRM in another tab, please close current tab or rerun the engine to use chat function')} className="text-gray-light/700 flex items-center gap-1 justify-center py-2.5 px-[14px] rounded-lg border border-gray-light/300 shadow-shadows/shadow-xs">
                                            <AlertCircle className="text-warning/600" size={16} stroke={'currentColor'} />
                                            <p className="text-sm-semibold">Multi Tab Detected</p>
                                        </button>
                                    case 'number-not-found':
                                        return <PopperAssignNew onClick={assignSim} id={'popper-asign-new-number-1'} target={(open, handleClick) => <button onClick={handleClick} className="text-gray-light/700 flex items-center gap-1 justify-center py-2.5 px-[14px] rounded-lg border border-gray-light/300 shadow-shadows/shadow-xs">
                                            <SimCard size={20} stroke={'currentColor'} />
                                            <p className="text-sm-semibold">Assign SIM card</p>
                                        </button>} />
                                    case 'engine-not-running':
                                        return <PopperChangeNumber onDiscard={discardSim} onClick={assignSim} id={"popper-change-number"} target={(open, handleClick) => <button onClick={handleClick} className="text-gray-light/700 justify-center py-2.5 px-[14px] rounded-lg border border-gray-light/300 shadow-shadows/shadow-xs">
                                            <div className="flex items-center gap-x-3">
                                                <div className='w-max relative flex items-center justify-center z-10'>
                                                    <AlertCircle className="text-warning/600" size={16} stroke={'currentColor'} />
                                                    <div className='w-[38px] h-[38px] absolute flex items-center justify-center'>
                                                        <span className="block w-7 h-7 absolute border-2 rounded-full border-warning/600/30"></span>
                                                        <span className="block w-[38px] h-[38px] absolute border-2 rounded-full border-warning/600/10"></span>
                                                    </div>
                                                </div>
                                                <p className="text-sm-semibold">{session?.sim_card?.number}</p>
                                            </div>
                                        </button>} />
                                    case 'session-not-found':
                                        return <PopperActiveSession otherOpsi={assignSim} id={"popper-active-session-1"} target={(open, handleClick) => <button onClick={handleClick} className="text-gray-light/700 justify-center py-2.5 px-[14px] rounded-lg border border-gray-light/300 shadow-shadows/shadow-xs">
                                            <div className="flex items-center gap-x-3">
                                                <div className='w-max relative flex items-center justify-center z-10'>
                                                    <AlertCircle className="text-warning/600" size={16} stroke={'currentColor'} />
                                                    <div className='w-[38px] h-[38px] absolute flex items-center justify-center'>
                                                        <span className="block w-7 h-7 absolute border-2 rounded-full border-warning/600/30"></span>
                                                        <span className="block w-[38px] h-[38px] absolute border-2 rounded-full border-warning/600/10"></span>
                                                    </div>
                                                </div>
                                                <p className="text-sm-semibold">{session?.sim_card?.number}</p>
                                            </div>
                                        </button>} />
                                    default:
                                        return <PopperChangeNumber onDiscard={discardSim} onClick={assignSim} id={"popper-change-number"} target={(open, handleClick) => <button onClick={handleClick} className="text-gray-light/700 flex items-center gap-2 justify-center py-2.5 px-4 rounded-lg border border-gray-light/300 shadow-shadows/shadow-xs">
                                            <span className="w-2 h-2 bg-success/500 rounded-full"></span>
                                            <p className="text-sm-semibold">{session?.sim_card?.number}</p>
                                        </button>} />
                                }
                            })()}
                        </div>
                    </div>

                    {/* SEARCH CHAT */}
                    <div className="pb-3 px-4">
                        <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                    </div>
                    {/* LIST TASK */}
                    <div className="flex-1 overflow-hidden">
                        <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                            {!searchRes && tasks && tasks.map((e, i) => {
                                return <UserCard key={i} selected={params?.current?.id === e.id} e={e} onClick={() => handleSelectedTask(e)} />
                            })}
                            {searchRes && <div id="box-list-contacts">
                                {searchRes.tasks.length !== 0 && <div className="flex flex-col">
                                    <div className="px-4 py-2 border-t border-gray-light/200">
                                        <div className="pl-5">
                                            <p className="text-lg-semibold text-gray-light/900">Contacts</p>
                                        </div>
                                    </div>
                                    {searchRes.tasks?.map((e, i) => {
                                        return <UserCard key={i} selected={params?.current?.id === e.id} e={e} onClick={() => handleSelectedTask(e, e.messages?.find(e => e)?.id)} />
                                    })}
                                </div>}
                                {searchRes.messages.length !== 0 && <div id="box-list-messages">
                                    <div className="flex flex-col">
                                        <div className="h-11"></div>
                                        <div className="px-4 py-2">
                                            <div className="pl-5">
                                                <p className="text-lg-semibold text-gray-light/900">Messages</p>
                                            </div>
                                        </div>
                                    </div>
                                    {searchRes.messages?.map((e, i) => {
                                        return <UserCard key={i} search={true} selected={params?.current?.id === e.id} e={e} onClick={() => handleSelectedTask(e, e.messages?.find(e => e)?.id)} />
                                    })}
                                </div>}
                            </div>}

                        </SimpleBar>
                    </div>
                </div>
                {params?.current ?
                    <main className="flex-2 flex flex-col h-full relative">
                        {/* <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black/30 z-50 backdrop-blur-sm">
                            <Loading01 style={{ animation: "spin 2s linear infinite" }} className="text-white" size={48} strokeWidth={1} stroke={'currentColor'} />
                        </div> */}
                        <div className="flex flex-col gap-5 w-full">
                            <div className="pt-5 px-6 flex justify-between gap-4">
                                {params.current.detail_customer && 
                                <div className="flex flex-col flex-1 ">
                                    <div className="flex gap-2">
                                        <p className="text-lg-semibold text-gray-light/900 whitespace-nowrap">{params.current.detail_customer.name}</p>
                                        {/* <div className="flex items-center gap-2">
                                            <div className="border border-brand/200 rounded-md py-0.5 px-1.5">
                                                <p className="text-xs-medium text-brand/700 whitespace-nowrap">Overdue 1</p>
                                            </div>
                                            <div className="flex items-center gap-0.5 border border-blue-light/200 rounded-md py-0.5 px-1.5">
                                                <p className="text-xs-medium text-blue-light/700 whitespace-nowrap">Difficult to handle</p>
                                                <span className="p-0.5">
                                                    <XClose size={12} className={'text-blue-light/400'} stroke={'currentColor'} />
                                                </span>
                                            </div>
                                            <button className="flex bg-gray-light/50 border border-gray-light/200 rounded-md p-[5px]">
                                                <Plus size={12} className={'text-gray-light/600'} stroke={'currentColor'} />
                                            </button>
                                        </div> */}
                                    </div>
                                    <p className="text-sm-regular text-gray-light/600">{params.current.detail_customer.number}</p>
                                </div>
                                }
                                <button onClick={() => handleCheckNumber({ currId })} className="py-2 px-3 flex items-center gap-1 shadow-shadows/shadow-xs border border-brand/300 rounded-lg">
                                        <p className="text-sm-semibold text-brand/700 whitespace-nowrap">Check Phone Number</p>
                                    </button>
                                <div className="flex items-center gap-3">
                              


                                    {
                                        params?.current?.attempts.length === 0 ? <PopperNewReport refresh={() => { }} tasks_id={params.current.id} id={'popper-new-report'} target={(open, handleClick) => <button onClick={handleClick} className="flex items-center text-brand/700 gap-1.5">
                                            <Plus size={20} stroke={'currentColor'} />
                                            <p className="text-sm-semibold">New report</p>
                                        </button>} /> :
                                            // <PopperPromisePayment id={'popper-promise-payment'} target={(open, handleClick) => <button onClick={handleClick} className="min-m-max py-2 px-3 flex items-center gap-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg">
                                            //     <Flag05 size={20} className={'gray-light/700'} stroke={'currentColor'} />
                                            //     <p className="text-sm-semibold gray-light/700 whitespace-nowrap">Berjanji membayar</p>
                                            // </button>} />
                                            <PopperNewReport history={<AttemptHistory />} refresh={() => { }} tasks_id={params.current.id} id={'popper-new-report'} target={(open, handleClick) => <button onClick={handleClick} className="min-m-max py-2 px-3 flex items-center gap-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg">
                                                <Flag05 size={20} className={'gray-light/700'} stroke={'currentColor'} />
                                                <p className="text-sm-semibold gray-light/700 whitespace-nowrap">{params?.current?.attempts[0].attempt}</p>
                                            </button>} />
                                    }


                                    <button onClick={() => handleCurrentSlider({ status: true, current: 'slider-info-message' })} className="min-m-max py-2 px-3 flex items-center gap-1 shadow-shadows/shadow-xs border border-brand/300 rounded-lg">
                                        <UserSquare size={20} className={'text-brand/700'} stroke={'currentColor'} />
                                        <p className="text-sm-semibold text-brand/700 whitespace-nowrap">Info</p>
                                    </button>
                                </div>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>

                        {/* LIST CHAT */}
                        <div className="flex-1 overflow-hidden">
                            <Chat current={params.current} />
                        </div>

                        {disable && <div className="px-8 py-4 flex items-center justify-between gap-x-3">
                            <div className="flex items-center gap-x-4">
                                <div className='w-max relative flex items-center justify-center z-10'>
                                    <AlertCircle className="text-warning/600" size={20} stroke={'currentColor'} />
                                    <div className='w-[38px] h-[38px] absolute flex items-center justify-center'>
                                        <span className="block w-7 h-7 absolute border-2 rounded-full border-warning/600/30"></span>
                                        <span className="block w-[38px] h-[38px] absolute border-2 rounded-full border-warning/600/10"></span>
                                    </div>
                                </div>
                                <div className="text-sm-semibold text-gray-light/700">
                                    {params.current.detail_customer.wa_status === 'inactive' || params.current.detail_customer.wa_status === 'unregistered' ? (
                                 <p className="text-warning/600 text-sm-semibold">Number inactive</p>
                                    ) : (
                                <div onClick={engine.action} dangerouslySetInnerHTML={{ __html: engine.message }}></div>
                                    )}
                                </div>
                            </div>
                            {(() => {

                                // const waStatus = params.current.detail_customer.wa_status;
                                // if (waStatus === 'inactive' || waStatus === 'unregistered') {
                                //     return <p className="text-warning/600 text-sm-semibold">Number Inactive</p>;
                                // }
                                switch (engine.status) {
                                    case 'engine-not-running':
                                        return <></>
                                    case 'session-not-found':
                                        return <PopperActiveSession placement="top-end" id={'popper-active-session-2'} target={(open, handleClick) => <button onClick={handleClick} className="text-brand/700">
                                            <p className="text-sm-semibold">Activate session</p>
                                        </button>} />
                                    case 'number-not-found':
                                        return <PopperAssignNew onClick={assignSim} placement="top-end" id={'popper-asign-new-number-2'} target={(open, handleClick) => <button onClick={handleClick} className="flex items-center gap-x-2 text-brand/700">
                                            <SimCard size={20} stroke={'currentColor'} />
                                            <p className="text-sm-semibold">Assign SIM card</p>
                                        </button>} />
                                    default:
                                        return <></>
                                }
                            })()}
                        </div>}

                        {(messageStatus ?? []).map((e, i) => {
                            return <div key={i} className="px-8 py-4 flex items-center justify-between gap-x-3">
                                <div className="flex items-center gap-x-4">
                                    <div className='w-max relative flex items-center justify-center z-10'>
                                        <AlertCircle className="text-warning/600" size={20} stroke={'currentColor'} />
                                        <div className='w-[38px] h-[38px] absolute flex items-center justify-center'>
                                            <span className="block w-7 h-7 absolute border-2 rounded-full border-warning/600/30"></span>
                                            <span className="block w-[38px] h-[38px] absolute border-2 rounded-full border-warning/600/10"></span>
                                        </div>
                                    </div>
                                    <div className="text-sm-semibold text-gray-light/700"><div onClick={e.action} dangerouslySetInnerHTML={{ __html: e.message }}></div></div>
                                </div>
                                {(() => {
                                    switch (e.status) {
                                        case 'new-number':
                                            return <div className="flex items-center gap-x-2">
                                                <p className="text-sm-semibold text-gray-light/600">Dismis</p>
                                                <button onClick={() => setMessageStatus(messageStatus.splice(i, 0))} className="p-2 text-gray-light/400">
                                                    <X size={20} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        default:
                                            return <></>
                                    }
                                })()}
                            </div>
                        })}

                        <div className="px-6 pb-6">
                            <div className="relative w-full h-max">
                                {/* <div>{params.current.detail_customer.wa_status}</div> */}
                                <textarea disabled={disable} value={text} placeholder="Send a message" className="pr-[182px] w-full resize-none py-3 px-4 border border-gray-light/300 shadow-shadows/shadow-xs rounded-lg outline-none placeholder-gray-light/500 focus:outline-none focus:border-brand/300 focus:shadow-focus-rings/ring-brand-shadow-xs" rows={4}
                                    onChange={(e) => setText(e?.target?.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            sendMessage()
                                        }
                                    }}
                                ></textarea>
                                <div className="absolute bottom-[18px] right-[12px] flex items-center gap-x-2">
                                    <div className="flex items-center gap-0.5">
                                        {/* <button className="p-2">
                                            <FaceSmile size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                                        </button> */}
                                        <PopperCommandBar id={'popper-command-bar'} target={(open, handleClick) => <button disabled={disable} onClick={handleClick} className="p-2">
                                            <Hash02 size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                                        </button>} />
                                        {/* <button className="p-2">
                                            <DotsHorizontal size={20} className={'text-gray-light/500'} stroke={'currentColor'} />
                                        </button> */}
                                    </div>
                                    <button disabled={disable} onClick={sendMessage} className="px-[14px] py-2.5 text-white text-sm-semibold bg-brand/600 rounded-lg">Send</button>
                                </div>
                            </div>
                        </div>
                    </main> :
                    <div className="flex-2 h-full py-12 flex flex-col">
                        <div className="flex-1 flex flex-col w-full items-center justify-center gap-[14px]">
                            <div className="w-44 h-28">
                                <img className="w-full h-full object-contain" src="/asset/chat-logo.png" alt="" />
                            </div>
                            <p className="display-md-medium text-[#969696]">Merpati CRM</p>
                            {disable &&
                                <div className="px-10 flex items-center gap-x-4">
                                    <div className='w-max relative flex items-center justify-center z-10'>
                                        <AlertCircle className="text-warning/600" size={20} stroke={'currentColor'} />
                                        <div className='w-[38px] h-[38px] absolute flex items-center justify-center'>
                                            <span className="block w-7 h-7 absolute border-2 rounded-full border-warning/600/30"></span>
                                            <span className="block w-[38px] h-[38px] absolute border-2 rounded-full border-warning/600/10"></span>
                                        </div>
                                    </div>
                                    <div className="text-sm-semibold text-gray-light/700"><div onClick={engine.action} dangerouslySetInnerHTML={{ __html: engine.message }}></div></div>
                                </div>}
                        </div>
                        <div className="w-full flex justify-center text-center">
                            <p className="text-xl-medium text-[#969696]">“Enhancing monitoring, control & compliance in Desk Collection activity.”</p>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};


export default Message;