import React, { } from "react";

const TableExcel = ({datas}) => {
    return (
        <>
            <table className='table border-collapse w-full max-w'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            {
                                <tr className='p-0'>
                                    {datas[0].fields.map(e=>{
                                        return (
                                            <th className='px-6 py-3 text-left'>
                                            <div className="flex items-center gap-3">
                                                <p className="text-xs-medium text-gray-light/600">{e.label}</p>
                                            </div>
                                        </th>)
                                        })} 
                                </tr> 
                            }
                        </thead>
                        <tbody>
                            {
                                datas.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            {e.fields.map(e=>{
                                                return (
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex items-center gap-3">
                                                        <p className="text-sm-medium text-gray-light/900">{e.value}</p>
                                                    </div>
                                                </td>)
                                                })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
        </>
    );
};

export default TableExcel;