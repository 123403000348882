import React, { createContext, useContext, useEffect, useState } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import { socket, socketLocal } from "../services/NetworkUtils";
import { toast } from "react-toastify";
import { newMessage, notify } from "../helpers/Helpers";
import { CheckCircle } from "untitledui-js/icons/general";
import { delay } from "lodash";
import { User01 } from "untitledui-js/icons/users";

const AppContext = createContext();
const AppProvider = props => {
    const [isScreenBloking, setIsScreenBloking] = useState(false)
    const [messageStatus, setMessageStatus] = useState([])
    const [session, setSession] = useState(null)
    const [engine, setEngine] = useState(null)

    const getSession = async () => {
        await HttpRequests.session()
            .then(response => {
                if (!response.data.sim_card) {

                }
                console.log(response)
                setSession(response.data);
            })
            .catch(notify)
    }

    const runningEngine = async () => {
        setIsScreenBloking(true);
        const response = await HttpRequests.running(session?.sim_card).catch(notify)
        setIsScreenBloking(false);
        if (response?.data) {
            notify(response.data)
            setEngine(response.data)
        } else {
            console.log(response)
        }
    }

    const checkEngine = async () => {
        console.log('window.tabId', localStorage.getItem('tabID'))
        const response = await HttpRequests.checkEngine({ id: session?.sim_card?.id, tabId: localStorage.getItem('tabID') }).catch((e) => {
            if (!session?.sim_card?.id)
                return {
                    data: {
                        status: 'number-not-found',
                        message:
                            "To start the conversation, please assign the SIM card first.",
                    }
                }
            return {
                data: {
                    status: 'engine-not-running',
                    message: 'To start the conversation, please run the WhatsApp engine first.'
                }
            }
        });
        console.log('checkEngine', response);
        if (response?.data) {
            setEngine(response?.data);
        }
        // setTimeout(() => {
        //     checkEngine()
        // }, 5000);
    }

    const logoutLocal = async () => {
        await HttpRequests.logoutLocal(session?.sim_card?.id).catch(console.error);
    }


    useEffect(() => {
        getSession()
        // window.addEventListener("beforeunload", function (e) {
        //     window.sessionStorage.tabId = window.tabId;

        //     return null;
        // });

        // window.addEventListener("load", function (e) {
        //     if (window.sessionStorage.tabId) {
        //         window.tabId = window.sessionStorage.tabId;
        //         // window.sessionStorage.removeItem("tabId");
        //     }
        //     else {
        //         window.tabId = Math.floor(Math.random() * 1000000);
        //     }

        //     return null;
        // });
    }, [])

    useEffect(() => {
        var interval;
        if (session) {
            interval = setInterval(() => {
                checkEngine()
            }, 5000);
            delay(checkEngine, 1000)
        }
        return () => clearInterval(interval)
    }, [session])

    useEffect(() => {
        if (session && session.sim_card) {
            socket.emit("pong", { instance_id: session.sim_card.id })
            socket.on(`message:${session.sim_card.id}`, (data) => {
                console.log(data);
                if (data.message.sender_id !== session.id) {
                    newMessage({ status: 200, title: data.message?.sender?.name, message: data.message?.text }, {
                        icon: <User01 size={24} stroke={'currentColor'} />,
                        onDismiss: () => { }
                    })
                }
            })
            socket.on(`change-session-${session.sim_card.id}`, () => {
                logoutLocal().then(getSession)
            })
            var timeout;
            socket.on(`pong:${session.sim_card.id}`, (data) => {
                console.log('ponggg dari server')
                timeout = setTimeout(() => {
                    clearTimeout(timeout)
                    console.log('pinggg ke engine dari server')
                    socketLocal.emit("ping-server");
                }, 2500);
            });
        }
        if (session) {
            var timeout;
            var timeoutLocal;

            socketLocal.on("pong", (data) => {
                console.log('ponggg dari engine', data)
                timeoutLocal = setTimeout(() => {
                    clearTimeout(timeoutLocal)
                    console.log('pinggg ke engine dari local')
                    socketLocal.emit("ping");
                }, 2500);
            });

        }
        return () => {
            if (session && session.sim_card) {
                socket.off(`pong:${session.sim_card.id}`)
                socket.off(`message:${session.sim_card.id}`)
                socket.off(`change-session-${session.sim_card.id}`)
            }
            if (session) {
                clearTimeout(timeout)
                socketLocal.off(`pong`)
            }
        };
    }, [session])
    return <AppContext.Provider value={{
        getSession, isScreenBloking, setIsScreenBloking, session, engine, setEngine, messageStatus, setMessageStatus,
        runningEngine, logoutLocal,
    }}> {props.children}</AppContext.Provider >;
};
const useApp = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useApp must be used within a AppProvider')
    }
    return context;
}

export { AppProvider, useApp };