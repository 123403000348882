import * as Yup from 'yup';

export const generalSchema = Yup.object().shape({
    isSetIdentity: Yup.boolean().nullable(),
    profileName: Yup.string().required(),
    profilePicture: Yup.mixed().required(),
    isShowRecentConversation: Yup.boolean().nullable(),
    isMaskedHome: Yup.boolean().nullable(),
    isMaskedTaskDistribution: Yup.boolean().nullable(),
    isMaskedConversationMonitoring: Yup.boolean().nullable(),
    isMaskedChats: Yup.boolean().nullable(),
    isMaskedSettings: Yup.boolean().nullable(),
    isSendFile: Yup.boolean().nullable(),
    isSendPhoto: Yup.boolean().nullable(),
    isSendVoiceNote: Yup.boolean().nullable(),
    isSendEmoji: Yup.boolean().nullable(),
    isSendSticker: Yup.boolean().nullable(),
    maxMessageSent: Yup.number().required(),
    resetIntervalSec: Yup.number().required(),
    preventSendMessages: Yup.boolean().nullable(),
    minIntervalInSec: Yup.number().required(),
    isMessageFromCustomer: Yup.boolean().nullable(),
    isViolatedRules: Yup.boolean().nullable(),
    isChangeObject: Yup.boolean().nullable(),
});


export const userSettingSchema = Yup.object().shape({
    create: Yup.boolean(),
    photo: Yup.mixed().nullable(),
    status: Yup.string().oneOf(['active', 'inactive']).required(),
    name: Yup.string().required(),
    email: Yup.string().email("Must be valid email").required("Email is required"),
    password: Yup.string().when("create", {
        is: true,
        then: () => Yup.string().required("Password is required")
    }),
    roles_id: Yup.string().nullable(),
    managers_id: Yup.string().nullable(),
});

export const ruleSettingSchema = Yup.object().shape({
    name: Yup.string().required(),
    words: Yup.array().required().of(Yup.string()),
    prevent_send: Yup.boolean().nullable(),
    is_active: Yup.boolean().nullable(),
});
export const roleSchema = Yup.object().shape({
    name: Yup.string().required(),
    grade: Yup.number().required(),
    pages: Yup.array().required().of(Yup.string()),
});

export const attemptSettingSchema = Yup.object().shape({
    create: Yup.boolean(),
    name: Yup.string().required(),
});

export const templateSchema = Yup.object().shape({
    create: Yup.boolean(),
    name: Yup.string().required(),
    text: Yup.string().required(),
    shortcut: Yup.string().required(),
    generates: Yup.array().optional()

});
