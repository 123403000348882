import React, { useEffect, useState } from "react";
import { Plus, XClose } from "untitledui-js/icons/general";
import { UserSquare } from "untitledui-js/icons/users";
import SimpleBar from "simplebar-react";
import { useMessage } from "../../../contexts/MessageContext";
import PopperNewReport from "../Poppers/popper_new_report";
import AttemptHistory from "../Components/AttemptHistory";

const ModalInfo = () => {
    const [history, setHistory] = useState([])
    const { handleCurrentSlider, params, attemptHistory } = useMessage();

    useEffect(() => {
        attemptHistory(setHistory)
    }, [])

    const customer = params.current.detail_customer

    if (!params.current) return (<></>);
    return (
        <div className="w-full h-full flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button onClick={() => handleCurrentSlider({ status: false, current: null })} className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <UserSquare size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">Info</p>
                    <p className="text-sm-regular text-gray-light/600">Details information about this customer.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <div className="h-full flex flex-col gap-6">
                        <div className="flex flex-col gap-6 flex-1">
                            <div className="flex-1 flex flex-col gap-6">
                                {/* <div className="flex items-center gap-2 px-6">
                            <div className="border border-brand/200 rounded-lg py-1 px-2.5">
                                <p className="text-sm-medium text-brand/700">Overdue 1</p>
                            </div>
                            <div className="flex items-center gap-0.5 border border-blue-light/200 rounded-lg py-1 pl-2.5 pr-1.5">
                                <p className="text-sm-medium text-blue-light/700">Difficult to handle</p>
                                <span className="p-0.5">
                                    <XClose size={12} className={'text-blue-light/400'} stroke={'currentColor'} />
                                </span>
                            </div>
                            <button className="flex bg-gray-light/50 border border-gray-light/200 rounded-md p-2">
                                <Plus size={12} className={'text-gray-light/600'} stroke={'currentColor'} />
                            </button>
                        </div> */}
                                <div className="pt-2 px-6 flex flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm-medium text-gray-light/700">Details information</p>
                                        <p className="text-sm-regular text-gray-light/600">All customer details</p>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <hr className="border-gray-light/200" />
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-medium text-gray-light/700">Name</p>
                                            <p className="text-sm-regular text-gray-light/600">{customer.name}</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm-medium text-gray-light/700">Number</p>
                                            <p className="text-sm-regular text-gray-light/600">{customer.number}</p>
                                        </div>
                                        {params.current.custom.map((e, i) => {
                                            return (
                                                <div key={i} className="flex items-center justify-between">
                                                    <p className="text-sm-medium text-gray-light/700">{e.label.replace('{custom_field} {', '').replace('}', '')}</p>
                                                    <p className="text-sm-regular text-gray-light/600">{e.value}</p>
                                                </div>
                                            )
                                        })}
                                        <hr className="border-gray-light/200" />
                                    </div>
                                </div>
                                <AttemptHistory addNew={(refresh) => <PopperNewReport refresh={refresh} tasks_id={params.current.id} id={'popper-new-report'} target={(open, handleClick) => <button onClick={handleClick} className="flex items-center text-brand/700 gap-1.5">
                                    <Plus size={20} stroke={'currentColor'} />
                                    <p className="text-sm-semibold">New report</p>
                                </button>} />} />
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalInfo;