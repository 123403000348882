import React, { } from "react";
import SimpleBar from "simplebar-react";
import { useSettings } from "../../contexts/SettingContext";

import MyContainerSliderModal from "../../components/SliderModal/MyContainerSliderModal";
import ModalInteractionStatus from "./AttemptStatusSetting/modal_interaction_status_setting";
import ModalRoleSetting from "./RoleSetting/modal_role_setting";
import ModalRulesSetting from "./RulesSetting/modal_rules_setting";
import ModalUserSetting from "./UserSetting/modal_user_setting";
import GeneralSetting from "./GeneralSetting/general_setting";
import UserSetting from "./UserSetting/user_setting";
import RoleSetting from "./RoleSetting/role_setting";
import LabelSetting from "./LabelSetting/label_setting";
import RulesSetting from "./RulesSetting/rules_setting";
import CustomerSetting from "./CustomerSetting/customer_setting";
import AttemptStatusSetting from "./AttemptStatusSetting/interaction_status_setting";
import TemplateSetting from "./TemplateSetting/template_setting";
import ModalTemplateSetting from "./TemplateSetting/modal_template_setting";

const Settings = () => {

    const { currentPage, handleCurrentPage, currentSlider, handleCurrentSlider } = useSettings();
    return (
        <SimpleBar forceVisible="y" className="flex-1 h-screen" >
            <MyContainerSliderModal open={currentSlider?.status} onClose={() => handleCurrentSlider()}>
                {/* <ModalUserSetting /> */}
                {currentSlider?.current === "template" && <ModalTemplateSetting />}
                {currentSlider?.current === "user" && <ModalUserSetting />}
                {currentSlider?.current === "rule" && <ModalRulesSetting />}
                {currentSlider?.current === "role" && <ModalRoleSetting />}
                {currentSlider?.current === "attempt" && <ModalInteractionStatus />}
                {/* <ModalLabelSetting /> */}
                {/* <ModalCustomerSetting /> */}
            </MyContainerSliderModal>

            <main className="w-full h-full pt-8 flex flex-col gap-8 bg-gray-light/25">
                {/* <MyDropzone /> */}
                <header className="px-8 flex flex-col gap-y-6">
                    <p className="display-sm-semibold text-gray-light/900">Settings</p>
                    <div className="flex gap-x-1">
                        {/* <button onClick={() => handleCurrentPage('general')} className={`${currentPage === "general" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            General
                        </button> */}
                        <button onClick={() => handleCurrentPage('user')} className={`${currentPage === "user" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md `}>
                            User
                        </button>
                        <button onClick={() => handleCurrentPage('role')} className={`${currentPage === "role" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            Role & access
                        </button>
                        {/* <button onClick={() => handleCurrentPage('label')} className={`${currentPage === "label" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            Label
                        </button> */}
                        <button onClick={() => handleCurrentPage('rule')} className={`${currentPage === "rule" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            Rules
                        </button>
                        {/* <button onClick={() => handleCurrentPage('customer')} className={`${currentPage === "customer" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            Customer
                        </button> */}
                        <button onClick={() => handleCurrentPage('attempt')} className={`${currentPage === "attempt" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            Attempt status
                        </button>
                        <button onClick={() => handleCurrentPage('template')} className={`${currentPage === "template" ? "text-brand/700 bg-brand/50" : "text-gray-light/500"} cursor-pointer px-3 py-2 text-sm-semibold rounded-md`}>
                            Template message
                        </button>
                    </div>
                </header>
                <div className="flex-1 px-8 pb-12">
                    {(() => {
                        switch (currentPage) {
                            case 'general':
                                return <GeneralSetting />
                            case 'user':
                                return <UserSetting />
                            case 'role':
                                return <RoleSetting />
                            case 'label':
                                return <LabelSetting />
                            case 'rule':
                                return <RulesSetting />
                            case 'customer':
                                return <CustomerSetting />
                            case 'attempt':
                                return <AttemptStatusSetting />
                            case 'template':
                                return <TemplateSetting />
                            default:
                                return <></>;
                        }
                    })()}
                </div>
            </main>
        </SimpleBar>
    );
};

export default Settings;