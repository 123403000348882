import React, { } from "react";
import { DownloadCloud02, Edit01, FilterLines, Logout04 } from "untitledui-js/icons/general";
import MyTextField from "../../components/TextField/MyTextField";
import { SwitchVertical01 } from "untitledui-js/icons/arrow";
import MyPagination from "../../components/Pagination/MyPagination";
import SimpleBar from "simplebar-react";

const TableContainerSystem = () => {

    return (
        <>
            <div className="px-8 w-full">
                <div className="w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                    <div className="py-5 px-6 flex gap-4">
                        <div className="flex-1 flex-col gap-1">
                            <p className="text-lg-semibold text-gray-light/900">System events logs</p>
                            <p className="text-sm-regular text-gray-light/600">List of record for all system activities and events.</p>
                        </div>
                        <button className="border border-gray-light/300 flex items-center rounded-lg px-3 py-2 gap-x-1 text-gray-light/700">
                            <DownloadCloud02 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Export</p>
                        </button>
                    </div>
                    <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                        <div className="w-full max-w-xs">
                            <MyTextField placeholder={'Search'} />
                        </div>
                        <button className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                            <FilterLines size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Filter</p>
                        </button>
                        <button className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                            <SwitchVertical01 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Sort</p>
                        </button>
                    </div>
                    <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                        <table className='table border-collapse w-full'>
                            <thead className='border-t border-gray-light/200 p-0'>
                                <tr className='p-0'>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Timestamp</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Event</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Module</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">User</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Remarks</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">28 May 2023 • 11:41am</p>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">Failed login</p>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">Login & sign up</p>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm-regular text-gray-light/900 whitespace-nowrap">Deni Kuncoro</p>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">0812887742192</p>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 break-words">User failed login from web because using wrong password</p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </SimpleBar>
                    <div className="border-t border-gray-light/200">
                        <MyPagination />
                    </div>
                </div>

            </div>
        </>
    );
};

export default TableContainerSystem;