import React, { useEffect, useState } from "react";
import { Plus, Trash01, XClose } from "untitledui-js/icons/general";
import { Eraser } from "untitledui-js/icons/editor";
import MyPopper from "../Poppper/MyPopper";
import MyIconPatternDecorative from "../Icon/MyIconPatternDecorative";
import MyAutocompleteV3 from "../Autocomplete/MyAutocompleteV3";
import MyDatePickerRange from "../DatePicker/MyCalendar";
import MyTextFieldV2 from "../TextField/MyTextFieldv2";
import HttpRequests from "../../services/ApiServiceHttp";
import { notify } from "../../helpers/Helpers";
import { debounce } from "lodash";
import MyCalendar from "../DatePicker/MyCalendar";
import { format, formatISO, parse, parseISO } from 'date-fns';
import MyDateRange from "../DatePicker/MyDateRange";
import MySwicth from "../Switch/MySwitch";

const MyFilterModal = ({ id, target, currentFilters, onChange }) => {
    const [filter, setFilter] = useState([]);
    const addFilter = () => {
        var data = { field: null, condition: null, value: null, operator: null };
        if (filter.length == 1) data.operator = { label: 'and', value: 'and' }
        if (filter.length >= 2) {
            data.operator = filter[1].operator;
        }

        const newFilter = [...filter, data];
        setFilter(newFilter);
    }
    var isObj = function (a) {
        if ((!!a) && (a.constructor === Object)) {
            return true;
        }
        return false;
    };
    var _st = function (z, g) {
        return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
    };
    var fromObject = function (params, skipobjects, prefix) {
        if (skipobjects === void 0) {
            skipobjects = false;
        }
        if (prefix === void 0) {
            prefix = "";
        }
        var result = "";
        if (typeof (params) != "object") {
            return prefix + "=" + encodeURIComponent(params) + "&";
        }
        for (var param in params) {
            var c = "" + prefix + _st(param, prefix);
            if (isObj(params[param]) && !skipobjects) {
                result += fromObject(params[param], false, "" + c);
            } else if (Array.isArray(params[param]) && !skipobjects) {
                params[param].forEach(function (item, ind) {
                    result += fromObject(item, false, c + "[" + ind + "]");
                });
            } else {
                result += c + "=" + encodeURIComponent(params[param]) + "&";
            }
        }
        return result;
    };

    const clearFilter = () => {
        console.log('jalan')
        setFilter([]);
    }

    const removeFilter = (index) => {
        if (filter && filter.length) {
            const newFilter = filter.slice();
            newFilter.splice(index, 1);
            setFilter(newFilter);
        }
    };

    const handleChangeOperator = (e, value, index) => {
        const temp = filter.slice();
        temp.map(e => {
            e.operator = value;
        })

        setFilter(temp);

    }
    const handleChangeField = (e, value, index) => {
        const temp = filter.slice();
        temp[index].filter = value;
        temp[index].field = value.name;
        temp[index].condition = null;
        temp[index].value = null

        setFilter(temp);

    }
    const handleChangeCondition = (e, value, index) => {
        const temp = filter.slice();
        temp[index].condition = value;

        setFilter(temp);

    }
    const handleChangeValue = (value, index) => {
        const temp = filter.slice();
        temp[index].value = value
        setFilter(temp);
    }

    const handleChange = () => {
        console.log('INI JALAN')
        onChange && onChange({ ...filter })
    }
    useEffect(() => {
        var valid = true;
        filter.map(e => {
            if (!e.field) valid = false;
            if (!e.condition) valid = false;
            if (e.value == undefined || e.value == null || (e.value == '' && typeof e.value != 'boolean')) valid = false;
            console.log(e.value, e.value == undefined, valid, 'Value <<<<<<')
        })
        if (valid)
            handleChange()
    }, [filter])

    return (
        <>
            <MyPopper haveValue={filter.filter(e => e.value)?.length} id={id} target={target} placement={'bottom-start'}>
                {(open, handleOpen, handleClose) => (
                    <div className="flex flex-col w-[881px]">
                        <div className="p-6 pb-5 z-10">
                            <MyIconPatternDecorative originClass={'items-center justify-end'}>
                                <p className="text-sm-regular text-gray-light/600">In this view, show records.</p>
                            </MyIconPatternDecorative>
                        </div>
                        <div className="flex flex-col gap-y-4 z-20">
                            <div className="px-6 flex flex-col gap-4 min-h-[100px]">
                                {(filter ?? []).map((e, i) => {
                                    return (
                                        <div key={i} className="flex items-center gap-4 w-full">
                                            <div className="w-24">
                                                {i === 0 ? <div className="px-[14px] py-2">
                                                    <p className="tex-md-regular text-gray-light/900">Where</p>
                                                </div> : i === 1 ? <MyAutocompleteV3 disableRemove={true} options={[{ label: 'and', value: 'and' }, { label: 'or', value: 'or' }]} isOptionEqualToValue={(e) => e.value} value={e?.operator} placeholder={'-'}
                                                    onChange={(e, value) => handleChangeOperator(e, value, i)} /> : <div className="px-[14px] py-2">
                                                    <p className="tex-md-regular text-gray-light/900">{e?.operator.label}</p>
                                                </div>}
                                            </div>
                                            <div className="flex-1">
                                                <MyAutocompleteV3 disableRemove={true} value={e?.filter} placeholder={'Field'} options={currentFilters}
                                                    isOptionEqualToValue={(e) => e.name}
                                                    onChange={(e, value) => handleChangeField(e, value, i)} />
                                            </div>
                                            <div className="flex-1">
                                                {e?.filter?.options && <MyAutocompleteV3 disableRemove={true} options={e?.filter?.options ?? []} value={e.condition} placeholder={'Condition'}
                                                    onChange={(e, value) => handleChangeCondition(e, value, i)} />}
                                            </div>
                                            <div className="flex-1">
                                                {e?.condition && e?.filter &&
                                                    <FilterInput element={e} onChange={(value) => handleChangeValue(value, i)} />}
                                            </div>
                                            <button onClick={(e) => removeFilter(i)} className="p-3">
                                                <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                            </button>
                                        </div>
                                    )
                                })}

                            </div >

                            <div className="px-6 flex items-center justify-between p-6 pt-8">
                                <button onClick={addFilter} className="flex items-center gap-2">
                                    <Plus size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                    <p className="text-md-semibold text-gray-light/600">Add condition</p>
                                </button>
                                <button onClick={clearFilter} className="flex items-center gap-2">
                                    <Eraser size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                    <p className="text-md-semibold text-gray-light/600">Clear</p>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </MyPopper>
        </>
    );
};
const FilterInput = ({ element, onChange }) => {
    const [options, setOptions] = useState([])
    const getOptions = async ({ search = '' } = {}) => {
        await HttpRequests.getDataDynamic(element?.filter.path, { search: search })
            .then(res => {
                // bingung dari mana tau pake labelnya, buat option
                console.log(res);
                setOptions(res.data)
            })
            .catch(notify)
    }

    const inputComponent = () => {
        const search = debounce((e) => onChange(e?.target?.value), 1000);
        if (element?.filter.type === 'string') {
            return (<MyTextFieldV2 value={element?.value} placeholder={"Enter your value"} onChangeForm={(e) => {
                console.log('lorem ipsum')
                onChange && search(e)
                // onChange && debounce((e) => onChange(e?.target?.value), 1000)(e)
            }} />)
        } else if (element?.filter.type === 'number') {
            return (<MyTextFieldV2 value={element?.value} placeholder={"Enter your value"} type="number" onChangeForm={(e) => {
                onChange && search(e)
            }} />)
        } else if (element?.filter.type === 'date') {
            // if (element.condition.value === '<' || element.condition.value === '>') {
            //     return <MyDateRange startDate={element.value && element.value.startDate && parseISO(element.value.startDate)} endDate={element.value && element.value.endDate && parseISO(element.value.endDate)}
            //         target={(open, handleClick) => (<MyTextFieldV2 readOnly={true} isRealtime={true} placeholder={"Enter your date"}
            //             value={(element.value && element.value.startDate && element.value.endDate) ? `${format(parseISO(element.value.startDate), 'MMM d, yyyy')} - ${format(parseISO(element.value.endDate), 'MMM d, yyyy')}` : ''}
            //             onTapForm={handleClick} />)}
            //         onChange={(startDate, endDate) => {
            //             if (startDate && endDate) {
            //                 onChange && onChange([
            //                     formatISO(startDate, { representation: 'complete' }),
            //                     formatISO(endDate, { representation: 'complete' })
            //                 ]);
            //             }
            //         }}
            //     />
            // } else {
            return <MyCalendar value={element.value && parseISO(element.value)}
                target={(open, handleClick) => (<MyTextFieldV2 readOnly={true} isRealtime={true} placeholder={"Enter your date"}
                    value={element.value && format(parseISO(element.value), 'MMM d, yyyy')}
                    onTapForm={handleClick} />)}
                onChange={(date) => {
                    if (date) {
                        onChange && onChange(formatISO(date, { representation: 'complete' }));
                    }
                }}
            />
            // }
        } else if (element?.filter.type === 'select') {
            return (<MyAutocompleteV3 disableRemove={true} placeholder={"Enter your value"} value={element?.value} options={options}
                onInputFocus={(e) => getOptions()}
                getOptionLabel={element.filter.optionLabel ? (option => option[element.filter.optionLabel]) : null}
                onInputChange={({ target: { value } }) => {
                    getOptions({ search: value })
                }}
                onChange={(e, value) => {
                    onChange && onChange(value);
                }}

            />)
        } else if (element?.filter.type === 'multi') {
            if (element?.filter.path) {
                return (<MyAutocompleteV3 disableRemove={true} placeholder={"Enter your value"} multiple={true} isMultipleSmall={true} value={element?.value} options={options}
                    onInputFocus={(e) => getOptions()}
                    onInputChange={({ target: { value } }) => {
                        getOptions({ search: value })
                    }}
                    onChange={(e, value) => {
                        onChange && onChange(value.length !== 0 ? value : null);
                    }}
                />)
            } else {
                // untuk yang typing / tidak ada enpointnya
                return <MyAutocompleteV3 disableRemove={true} placeholder={"Enter your value"} freeSolo={true} multiple={true} isMultipleSmall={true} value={element?.value}
                    onChange={(e, value) => {
                    }}
                    onInputFocus={(e) => getOptions()} />
            }
        } else if (element?.filter.type === 'boolean') {
            return (<div className="mx-auto"><MySwicth checked={element?.value} value={true} onChangeForm={(e) => {
                onChange && onChange(e.target.checked);
            }} /></div>)
        }
    }

    return (inputComponent());
};

export default MyFilterModal;