import React, { useEffect } from "react";
import MyModal from "../../../components/Modal/MyModal";
import { DotsVertical, Settings01, XClose } from "untitledui-js/icons/general";
import { SimCard } from "untitledui-js/icons/media-devices";
import { useSimCard } from "../../../contexts/SimCardContext";
import QRCode from "qrcode.react";
import MySwicth from "../../../components/Switch/MySwitch";
import MyIconPatternDecorative from "../../../components/Icon/MyIconPatternDecorative";
import { WhatsApp } from "../../../contants/icons/MyIcon/MediaSosial";

const ModalRegisterSimCard = () => {
    const { isTimeOutQr, isLoadingQr, qrValue, data, setData, currentModal, handleCurrentModal, cancelSubcribe } = useSimCard();
    return (
        <MyModal maxWidth={512} onClose={() => { cancelSubcribe(); handleCurrentModal(null) }} open={currentModal === "modal-add-sim-card"}>
            <div className="flex flex-col bg-white w-full relative rounded-xl shadow-shadows/shadow-xl overflow-hidden">
                <header className="p-6 pb-5 flex flex-col gap-4 relative z-10">
                    <button className="my-modal-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center gap-y-4 text-gray-light/400 rounded-lg p-2">
                        <XClose onClick={() => { cancelSubcribe(); handleCurrentModal(null) }} size={24} stroke={'currentColor'} />
                    </button>
                    <MyIconPatternDecorative>
                        <div className="w-max p-3 rounded-xl border border-gray-light/200 shadow-shadows/shadow-xs">
                            <SimCard size={24} className={'text-gray-light/700'} stroke={'currentColor'} />
                        </div>
                    </MyIconPatternDecorative>
                    <div className="flex flex-col z-20">
                        <p className="text-lg-semibold text-gray-light/900">Register SIM card</p>
                        <p className="text-sm-regular text-gray-light/600">To Add the SIM card, please scan this QR code with your registered WhatsApp application on your mobile device.</p>
                    </div>
                </header>
                <div className="px-6 flex flex-col items-start gap-5 z-20">
                    {/* <section className="flex items-start gap-x-2">
                        <div>
                            <MySwicth checked={data?.isAssignThisNumber ?? false} onChangeForm={({ targer: { checked } }) => {
                                setData(...data, { isAssignThisNumber: checked });
                            }} />
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm-medium text-gray-light/700">Assign this number to yourself.</p>
                            <p className="text-sm-regular text-gray-light/600">Your current linked number will be replaced by this number.</p>
                        </div>
                    </section> */}
                    <section className="flex items-center justify-center py-4 w-full bg-gray-light/50 rounded-lg">
                        {isLoadingQr && <div className="w-[246px] h-[246px] p-[7px] bg-white">
                            <p>loading</p>
                        </div>}
                        {isTimeOutQr && <div className="w-[246px] h-[246px] p-[7px] bg-white">
                            <button >reload</button>
                        </div>}
                        {qrValue && <div className="w-[246px] h-[246px] p-[7px] bg-white">
                            <QRCode value={qrValue} fgColor="#000" style={{ width: '100%', height: '100%' }}
                                imageSettings={{ src: './asset/whatsapp.png', height: 30, width: 30, }}
                            />
                        </div>}

                    </section>
                    <div className="w-full p-3 flex flex-col gap-2 rounded-xl border border-gray-light/200">
                        <ul class="list-decimal pl-5">
                            <li className="text-black text-sm-regular">
                                <div className="flex items-center gap-1">
                                    <p className="text-sm-regular text-black">Open</p>
                                    <WhatsApp size={18} stroke={'currentColor'} />
                                    <p className="text-sm-regular text-black">WhatsApp on your primary phone</p>
                                </div>
                            </li>
                            <li className="text-black text-sm-regular">
                                <div className="flex items-center gap-1">
                                    <p className="text-sm-regular text-black">Tap</p>
                                    <span className="flex items-center justify-center text-center text-gray-light/500 rounded bg-[#B3B3B8] w-4 h-4">
                                        <DotsVertical size={12} stroke={'currentColor'} />
                                    </span>
                                    <p className="text-sm-regular text-black"><span className="text-sm-bold">Menu</span> or</p>
                                    <span className="flex items-center justify-center text-center text-white rounded bg-[#B3B3B8] w-4 h-4">
                                        <Settings01 size={12} stroke={'currentColor'} />
                                    </span>
                                    <p className="text-sm-regular text-black"><span className="text-sm-bold">Settings</span> and select Linked Devices</p>
                                </div>
                            </li>
                            <li className="text-black text-sm-regular">
                                <p className="text-sm-regular text-black">Tap on <span className="text-sm-bold">Link a Device</span></p>
                            </li>
                            <li className="text-black text-sm-regular">
                                <p className="text-sm-regular text-black">Point your phone to this screen capture the code</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="pt-8 px-6 pb-6 w-full">
                    <button onClick={() => { cancelSubcribe(); handleCurrentModal(null) }} className="w-full py-2.5 px-4 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg text-md-semibold text-gray-light/700">Cancel</button>
                </div>
            </div>
        </MyModal>
    );
};

export default ModalRegisterSimCard;