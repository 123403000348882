import React, { } from "react";
import { ArrowLeft } from "untitledui-js/icons/arrow";
import MyIconPatternDecorativeRadial from "../../../components/Icon/MyIconPatternDecorativeRadial";
import { Mail01 } from "untitledui-js/icons/communication";

const CheckEmail = () => {
    return (
        <div className="px-8 h-full w-full flex items-center justify-center overflow-hidden">
            <div className="w-full max-w-[360px] flex flex-col items-center justify-center gap-8">
                <div className="flex flex-col items-center justify-center gap-6">
                    <MyIconPatternDecorativeRadial>
                        <span className="text-gray-light/700 w-14 h-14 min-w-[56px] min-h-[56px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                            <Mail01 size={28} stroke={'currentColor'} />
                        </span>
                    </MyIconPatternDecorativeRadial>
                    <div className="flex flex-col gap-y-3 text-center">
                        <p className="display-sm-semibold text-gray-light/900">Check your email</p>
                        <p className="text-md-regular text-gray-light/600">We sent a password reset link to olivia@verify.com</p>
                    </div>
                </div>
                <button className={`bg-brand/600 text-white border border-gray-light/200 text-gray-light/400'} text-center shadow-shadows/shadow-xs text-md-semibold px-4 py-2.5 w-full rounded-lg`}>
                    Open email app
                </button>
                <div className="flex items-center gap-x-1">
                    <p className="text-sm-regular text-gray-light/600">Didn’t receive the email?</p>
                    <a className="text-sm-semibold text-brand/700" href="#">Click to resend</a>
                </div>
                <button className="flex items-center gap-1.5">
                    <span className="text-gray-light/600">
                        <ArrowLeft size={20} stroke={'currentColor'} />
                    </span>
                    <p className="text-sm-semibold text-gray-light/600">Back to log in</p>
                </button>
            </div>
        </div>
    );
};

export default CheckEmail;