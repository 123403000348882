import React, { useState, useRef, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment, Paper } from "@mui/material";
import { Check, XClose } from "untitledui-js/icons/general";
import { ChevronDown } from "untitledui-js/icons/arrow";

const MyAutocompleteV3 = ({
  options, value, name, onClick, removeable = true,
  disabled, multiple, isMultipleSmall = false, freeSolo = false, placeholder,
  startAdornment, endAdornment, disableRemove = false,
  filterOptions, getOptionLabel, isOptionEqualToValue, renderOption, renderTag, defaultValue,
  onChange, onInputChange, onInputFocus, onInputKeyUp }) => {

  const styleTextField = {
    '& .MuiInputBase-root::-webkit-scrollbar': {
      display: "none"
    },
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: multiple && !isMultipleSmall ? '12px !important' : '10px 14px !important',
      height: multiple && !isMultipleSmall ? "118px" : "44px",
      overflowY: "scroll",
      overflowX: "hidden",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      display: "flex",
      alignItems: multiple ? "flex-start" : "center",
      alignContent: "flex-start",
      rowGap: "6px !important",
      columnGap: "8px !important",
      boxShadow: '0px 1px 2px 0px #1018280D',
      border: '1px solid #D0D5DD',
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        fontFamily: "'Inter', sans-serif",
        fontWeight: "400",
        lineHeight: "24px",
        wordWrap: "break-word",
        color: "#667085",
        opacity: 1,
      },
      "&.Mui-focused": {
        border: '1px solid #D6BBFB',
        boxShadow: '0px 0px 0px 4px #9e77ed3d',
      },
      '& .MuiInputBase-input': {
        padding: "0px",
        minWidth: "inherit"
      },
      '&.Mui-disabled': {
        backgroundColor: '#F9FAFB',
      },
      '&.Mui-disabled .MuiInputBase-input': {
        WebkitTextFillColor: '#667085',
        color: '#667085',
      },
      '& .MuiInputAdornment-root': {
        marginRight: "0px",
      },
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      "&.Mui-focused fieldset": {
        border: 'none',
      },
      "&.Mui-disabled fieldset": {
        border: 'none',
      },
    },
  };

  const stylePapper = {
    "&.MuiPaper-root": {
      marginTop: '4px',
      border: ((options && options.length) || value) ? "1px solid #EAECF0" : "",
      borderRadius: "8px",
      boxShadow: ((options && options.length) || value) ? "0px 4px 6px -2px #10182808" : "",
    },
    "&.MuiPaper-root .MuiAutocomplete-listbox": {
      padding: "4px 0px"
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const textFieldRef = useRef();
  useEffect(() => {
    if (!multiple && renderOption) {
      if (isOpen) {
        textFieldRef.current.style.display = "flex";
        textFieldRef.current.focus();
      } else {
        if (value) {
          textFieldRef.current.style.display = "none";
        }
      }
    }
  }, [isOpen]);
  useEffect(() => {
    if (!multiple && value && renderOption) {
      textFieldRef.current.style.display = "none";
    }
  }, [value]);
  return (
    <>
      <div>
        <Autocomplete id="my-papper-auto-complete" open={isOpen} freeSolo={freeSolo} fullWidth multiple={multiple}
          options={options ?? []}
          value={multiple ? value ?? [] : value ?? null}
          onOpen={(e) => setIsOpen(true)}
          onClose={(e) => setIsOpen(false)}
          disabled={disabled}
          onChange={(e, value) => {
            if (multiple && freeSolo) {
              //   if (isTyping) {
              let tempValue = value.map((e) => {
                if (typeof e === 'string') {
                  return { label: e.trim() };
                } else {
                  return e;
                }
              })

              const uniqueData = tempValue.filter((obj, index, self) => {
                if (obj.label && (index === self.findIndex((o) => o.label === obj.label))) {
                  return true;
                } else {
                  return false;
                }
              });

              onChange && onChange(e, uniqueData);
              //   } else {
              //     let tempValue = [];
              //     for (let i = 0; i < value.length; i++) {
              //       if (typeof value[i] === 'string') {
              //         let val = (options ?? []).find((e) => {
              //           if (getOptionLabel && getOptionLabel(e)) {
              //             return getOptionLabel(e).trim().toLowerCase() === value[i].trim().toLowerCase();
              //           } else {
              //             return e.label.trim().toLowerCase() === value[i].trim().toLowerCase();
              //           }
              //         });

              //         if (val) {
              //           tempValue.push(val);
              //         }
              //       } else {
              //         tempValue.push(value[i]);
              //       }
              //     }
              //     onChange(e, tempValue);
              //   }
              // } else {
              //   if (typeof value === 'string') {
              //     let tempValue = (options ?? []).find((e) => {
              //       if (getOptionLabel && getOptionLabel(e)) {
              //         return getOptionLabel(e).trim().toLowerCase() === value.trim().toLowerCase();
              //       } else {
              //         return e.label.trim().toLowerCase() === value.trim().toLowerCase();
              //       }
              //     });

              //     onChange(e, tempValue ?? null);
              //   } else {
              //     onChange(e, value);
              //   }
            } else {
              onChange && onChange(e, value);
            }

          }}
          disableClearable={multiple ? true : false}
          ListboxProps={{ style: { maxHeight: 320 } }}
          PaperComponent={(props) => <Paper {...props} sx={stylePapper} />}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) ?? '' : option.label}
          renderOption={(props, option, ownerState) => {
            var selected = false;
            if (value) {
              if (multiple && getOptionLabel) {
                selected = value.some(e => (getOptionLabel(e) ?? '') === option.label);
              } else if (multiple) {
                selected = value.some(e => e.label === option.label);
              } else if (getOptionLabel) {
                if (getOptionLabel(option) && getOptionLabel(value)) {

                  selected = getOptionLabel(option) === getOptionLabel(value);
                } else {
                  selected = false;
                }
              } else {
                selected = option?.label === value?.label;
              }
            }

            return (
              <div {...props} key={props['data-option-index']} className={`${selected ? 'bg-gray-light/50' : ''} px-1.5 py w-full cursor-pointer hover:bg-gray-light/50`}>
                <div className="pl-2 pr-2.5 py-2.5 w-full flex items-center gap-x-2">
                  <div className="flex-1 overflow-hidden">
                    {renderOption ? renderOption(option) :
                      <p className="text-md-medium text-gray-light/900 whitespace-nowrap"> {getOptionLabel ? getOptionLabel(option) ?? '' : option?.label} </p>}
                  </div>
                  {value && <span className="text-brand/600 w-5 h-5 min-w-[20px] min-h-[20px]">
                    {selected && <Check size={20} stroke={'currentColor'} />}
                  </span>}
                </div>
              </div>
            )
          }}
          renderTags={(options, getTagProps, ownerState) => {
            return options.map((option, index) => {
              var { onDelete } = getTagProps({ index });

              return <div key={index} onClick={() => onClick && onClick(option.label)} className="w-max h-max" >
                {renderTag ? renderTag(option, index, onDelete) : <div className="bg-white py-0.5 pr-1 pl-2.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md" >
                  <p className="text-sm-medium text-gray-light/700">{getOptionLabel ? getOptionLabel(option) ?? '' : option.label}</p>
                  {removeable && <button type="button" onClick={onDelete} className="w-4 h-max min-w-[16px] min-h-[16px] p-0.5">
                    <XClose className={'text-gray-light/400'} size={14} strokeWidth={3} stroke={'currentColor'} />
                  </button>}
                </div>}
              </div>
            })
          }}
          filterOptions={(options, state) => {
            var inputValue = state.inputValue.trim().toLowerCase();
            if (inputValue) {
              return options.filter((e) => {
                if (filterOptions) {
                  if (filterOptions(e, inputValue)) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (getOptionLabel) {
                  if ((getOptionLabel(e) ?? '').trim().toLowerCase().includes(inputValue)) {
                    return true;
                  } else {
                    return false;
                  }
                } else if ((e?.label && e?.label.trim().toLowerCase()).includes(inputValue)) {
                  return true;
                }

                return false;
              });
            } else {
              return options ?? [];
            }
          }}
          renderInput={(params) => {
            const { InputProps } = params;
            return (
              <TextField
                {...params}
                inputRef={textFieldRef}
                placeholder={placeholder}
                onChange={(e) => {
                  onInputChange && onInputChange(e);
                }}
                onBlur={(e) => {
                  setIsOpen(false);
                }}
                onFocus={(e) => {
                  onInputFocus && onInputFocus(e);
                }}
                onKeyUp={onInputKeyUp}
                disabled={disabled}
                InputProps={{
                  ...InputProps,
                  startAdornment: multiple ? InputProps.startAdornment : (value && renderOption && !isOpen) ?
                    <div style={{
                      height: multiple && !isMultipleSmall ? `${118 - 20}px` : `${44 - 20}px`
                    }} className="flex-1 overflow-hidden" onClick={(e) => !disabled && setIsOpen(true)}> {renderOption(value)}</div>
                    : startAdornment ? <InputAdornment position="start"> {startAdornment}</InputAdornment> : null,
                  endAdornment: multiple ? <></> : <InputAdornment position="end">
                    {value && !disabled && isOpen && !disableRemove ? <XClose onClick={() => { onInputChange && onInputChange({ target: { value: '' } }); onChange && onChange(null, undefined) }} className={'cursor-pointer'} size={18} stroke={'currentColor'} /> : (endAdornment ? endAdornment : <span onClick={(e) => !disabled && setIsOpen(true)}><ChevronDown size={20} stroke={'currentColor'} /></span>)}
                  </InputAdornment>,
                }}
                sx={styleTextField}
              />
            )
          }}
        />
      </div >
    </>
  );
};

export default MyAutocompleteV3;