import React, { } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const MyTextField = ({
    type = "text", name, value, id,
    startAdornment, endAdornment, placeholder,
    disabled, onChange, onKeyDown }) => {

    return (
        <TextField
            id={id}
            variant="outlined" autoComplete="off" fullWidth
            name={name} defaultValue={value} type={type} placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            sx={{
                '& .MuiInputBase-root': {
                    borderRadius: '8px',
                    padding: '10px 14px',
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    boxShadow: '0px 1px 2px 0px #1018280D',
                    backgroundColor: '#FFFFFF',
                    '& .MuiInputBase-input': {
                        padding: '0',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#F9FAFB',
                    },
                    '&.Mui-disabled .MuiInputBase-input': {
                        WebkitTextFillColor: '#667085',
                        color: '#667085',
                    },
                    '& fieldset': {
                        border: '1px solid #D0D5DD',
                        boxShadow: 'box-shadow: 0px 1px 2px 0px #1018280D',
                    },
                    '&:hover fieldset': {
                        border: '1px solid #D0D5DD',
                    },
                    "&.Mui-focused fieldset": {
                        border: '1px solid #D6BBFB',
                        boxShadow: '0px 0px 0px 4px rgba(158, 119, 237, 0.24)',
                    },
                    "&.Mui-disabled fieldset": {
                        border: '1px solid #D0D5DD',
                        WebkitTextFillColor: 'red'
                    }
                },
            }}
            InputProps={{
                startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
                endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
            }}

        />
    );
};

export default MyTextField;