import React, { useState, useRef, useEffect, useCallback } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/material/styles';
import { InputAdornment, Paper } from "@mui/material";
import { Check, SearchLG, XClose } from "untitledui-js/icons/general";
import { ChevronDown } from "untitledui-js/icons/arrow";

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const options = [
//   { label: 'The Shawshank Redemption', id: 1 },
//   { label: 'The Godfather', id: 2 },
//   { label: 'The Godfather: Part II', id: 3 },
//   { label: 'The Dark Knight', id: 4 },
//   { label: '12 Angry Men', id: 5 },
// ];

// const multiple = false;
// const value = null;
// const value = { label: 'The Godfather', id: 2 };
// const value = [
//   { label: 'The Shawshank Redemption', id: 1 },
//   { label: 'The Godfather', id: 2 },
//   { label: 'The Dark Knight', id: 4 },
// ];

{/* <div className="flex items-center gap-x-2 overflow-hidden">
            <div className="w-6 h-6 min-w-[24px] min-h-[24px] rounded-full overflow-hidden">
              <img className="w-full h-full object-cover" src="" alt="" />
            </div>
            <p className="text-md-medium text-gray-light/900 whitespace-nowrap">Mochamad Yusuf</p>
            <p className="text-md-regular text-gray-light/600 line-clamp-1">yusuf@verify.com</p>
          </div> */}
{/* <div key={i} className="py-0.5 pr-1 pl-2.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md" >
                <p className="text-sm-medium text-gray-light/700"> Mochamad Yusuf </p>
                <button type="button" onClick={() => onDelete(i)} className="w-4 h-max min-w-[16px] min-h-[16px] p-0.5">
                  <XClose className={'text-gray-light/400'} size={14} strokeWidth={3} stroke={'currentColor'} />
                </button>
              </div> */}
const MyAutocompleteV2 = ({
  options, value, name, register,
  disabled, multiple, placeholder, startAdornment, endAdornment,
  filterOptions, getOptionLabel, renderOption, renderTag, defaultValue,
  onChange, onInputChange, onInputKeyUp }) => {
  const styleTextField = {
    '& .MuiInputBase-root::-webkit-scrollbar': {
      display: "none"
    },
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: multiple ? '12px !important' : '10px 14px !important',
      height: !multiple && "44px",
      overflowY: "scroll",
      overflowX: "hidden",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      display: "flex",
      rowGap: "6px !important",
      columnGap: "8px !important",
      alignItems: "center",
      boxShadow: '0px 1px 2px 0px #1018280D',
      border: '1px solid #D0D5DD',
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        fontFamily: "'Inter', sans-serif",
        fontWeight: "400",
        lineHeight: "24px",
        wordWrap: "break-word",
        color: "#667085",
        opacity: 1,
      },
      "&.Mui-focused": {
        border: '1px solid #D6BBFB',
        boxShadow: '0px 0px 0px 4px #9e77ed3d',
      },
      '& .MuiInputBase-input': {
        padding: "0px"
      },
      '&.Mui-disabled .MuiInputBase-input': {
        WebkitTextFillColor: '#667085',
        color: '#667085',
      },
      '& .MuiInputAdornment-root': {
        marginRight: "0px",
      },
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      "&.Mui-focused fieldset": {
        border: 'none',
      },
      "&.Mui-disabled fieldset": {
        border: 'none',
      },
    },
  };

  const stylePapper = {
    "&.MuiPaper-root": {
      marginTop: '4px',
      border: options && "1px solid #EAECF0",
      borderRadius: "8px",
      boxShadow: options && "0px 4px 6px -2px #10182808"
    },
    "&.MuiPaper-root .MuiAutocomplete-listbox": {
      padding: "4px 0px"
    }
  };

  const [isOpen, setIsOpen] = useState(false)
  const textFieldRef = useRef();

  useEffect(() => {
    if (isOpen) {
      textFieldRef.current.focus();
    }
  }, [isOpen]);
  return (
    <>
      <div>
        {/* {(!multiple && renderOption && !isOpen) ? <div onClick={() => {
          setIsOpen(true);
        }} className="h-11 cursor-pointer flex items-center gap-x-2 px-[14px] py-2.5 border border-gray-light/300 rounded-lg shadow-shadows/shadow-xs" >
          <div className="flex-1 overflow-hidden">
            {value ? renderOption(value) : <p className="text-md-regular text-gray-light/500">{placeholder}</p>}
            
          </div>
          <span className="w-5 h-5 min-w-[20px] min-h-[20px]">
            <ChevronDown className={'text-gray-light/500'} size={20} stroke={'currentColor'} />
          </span>
        </div > : */}
        <Autocomplete id="my-papper-auto-complete" freeSolo fullWidth multiple={multiple}
          // value={value ?? []}
          options={options ?? []}
          value={value && ((multiple) ? [] : null)}
          onOpen={(e) => {
            setIsOpen(true);
          }}
          onClose={(e) => {
            setIsOpen(false);
          }}
          onChange={onChange}
          disableClearable={multiple ? true : false}
          ListboxProps={{ style: { maxHeight: 320 } }}
          PaperComponent={(props) => <Paper {...props}
            sx={stylePapper}
          />}
          getOptionLabel={(option) => option.label}
          // isOptionEqualToValue={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <div {...props} key={option.id} className={`${value && value.id === option.id ? 'bg-gray-light/50' : ''} px-1.5 py w-full cursor-pointer hover:bg-gray-light/50`}>
                <div className="pl-2 pr-2.5 py-2.5 w-full flex items-center gap-x-2">
                  <div className="flex-1 overflow-hidden">
                    {renderOption ? renderOption(option) :
                      <p className="text-md-medium text-gray-light/900 whitespace-nowrap">
                        {getOptionLabel ? getOptionLabel(option) : option.label}
                      </p>}
                  </div>
                  {value && <span className="text-brand/600 w-5 h-5 min-w-[20px] min-h-[20px]">
                    {value.id === option.id && <Check size={20} stroke={'currentColor'} />}
                  </span>}
                </div>
              </div>
            )
          }}
          renderTags={(options, getTagProps, ownerState) => {
            return options.map((option, i) => {
              var { onDelete } = getTagProps(i);
              return <div key={i}>
                {renderTag ? renderTag(option, i, onDelete) : <div className="py-0.5 pr-1 pl-2.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md" >
                  <p className="text-sm-medium text-gray-light/700">
                    {/* {getOptionLabel ? getOptionLabel(option) : option.label} */}
                    {option}
                  </p>
                  <button type="button" onClick={() => onDelete(i)} className="w-4 h-max min-w-[16px] min-h-[16px] p-0.5">
                    <XClose className={'text-gray-light/400'} size={14} strokeWidth={3} stroke={'currentColor'} />
                  </button>
                </div>}


              </div>
            })
          }}
          filterOptions={(options, state) => {
            var inputValue = state.inputValue.trim().toLowerCase();
            return options.filter((e) => {
              if (filterOptions && filterOptions(e, inputValue)) {
                return true;
              } else if (getOptionLabel && getOptionLabel(e).trim().toLowerCase().includes(inputValue)) {
                return true;
              } else if (e.label && e.label.trim().toLowerCase().includes(inputValue)) {
                return true;
              }

              return false;
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputRef={textFieldRef}
                placeholder={placeholder}
                onChange={onInputChange}
                onKeyUp={onInputKeyUp}
                disabled={disabled}
                // InputProps={{
                //   ...params.InputProps,
                //   startAdornment: startAdornment && <InputAdornment position="start">
                //     {startAdornment}
                //   </InputAdornment>,
                //   endAdornment: !multiple && (isOpen ? params.InputProps?.endAdornment : <InputAdornment position="end">
                //     {endAdornment ? endAdornment : <span><ChevronDown size={20} stroke={'currentColor'} /></span>}
                //   </InputAdornment>),
                // }}
                sx={styleTextField}
              />
            )
          }}
        />
        {/* } */}
      </div >
    </>
  );
};

export default MyAutocompleteV2;