import React from 'react';
import { useApp } from '../../contexts/AppContext';
import moment from 'moment';

const MessageSenderBox = ({ message, maxWidth = '442px', targetRef }) => {
    const { session } = useApp();
    const parsedDate = moment(message?.send_time ?? message?.created_at);
    const formattedDate = parsedDate.format('HH:mma');
    var text = message.text;
    message.is_violated?.map(e => {
        e.words.map(word => {
            text = text.replace(new RegExp(word, "ig"), `<label class="text-red-500">${word}</label>`)
        })
    })


    return (
        <div id={message.id} aria-label={message.last ? 'last' : ''} className="flex justify-end w-full message-box">
            <div className="flex justify-end w-full" style={{ maxWidth: maxWidth }}>
                {(message.is_violated) ? <div className={`bg-white w-full max-w-max flex flex-col gap-2 px-[14px] py-2.5 rounded-lg border border-error/600 rounded-tr-none message-text`}>
                    <div className='w-full overflow-hidden'>
                        <p className="text-sm-medium text-gray-light/700 break-words line-clamp-1">
                            {message?.sender_id === session?.id ? 'You' : message?.sender?.name}
                        </p>
                    </div>
                    <div className='flex gap-4 items-end w-full'>
                        <div className='flex-1 overflow-hidden'>
                            <p className="text-md-regular break-words line-clamp-10 message hidden">{message?.text}</p>
                            <p className="text-md-regular break-words line-clamp-10 message-preview whitespace-pre-line" dangerouslySetInnerHTML={{ __html: text }}></p>
                        </div>
                        <div>
                            <p className="text-xs-regular text-gray-light/600">{formattedDate}</p>
                        </div>
                    </div>

                </div> : message.external_id ? <div className={`${message?.sender_id === session?.id ? 'bg-brand/600' : 'bg-gray-light/600'} w-full max-w-max flex flex-col gap-2 px-[14px] py-2.5 rounded-lg rounded-tr-none message-text`}>
                    <div className='w-full overflow-hidden'>
                        <p className="text-sm-medium text-white break-words line-clamp-1">
                            {message?.sender_id === session?.id ? 'You' : message?.sender?.name}
                        </p>
                    </div>
                    <div className='flex gap-4 items-end w-full'>
                        <div className='flex-1 overflow-hidden'>
                            <p className="text-md-regular text-white break-words line-clamp-10 message hidden">{message?.text}</p>
                            <p className="text-md-regular text-white break-words line-clamp-10 message-preview whitespace-pre-line">{message?.text}</p>
                        </div>
                        <div>
                            <p className="text-xs-regular text-white">{formattedDate}</p>
                        </div>
                    </div>

                </div> : <div className={`${message?.sender_id === session?.id ? 'bg-brand/600' : 'bg-gray-light/600'} bg-warning/300 w-full max-w-max flex flex-col gap-2 px-[14px] py-2.5 rounded-lg rounded-tr-none message-text`}>
                    <div className='w-full overflow-hidden'>
                        <p className="text-sm-medium text-white break-words line-clamp-1">
                            {message?.sender_id === session?.id ? 'You' : message?.sender?.name}
                        </p>
                    </div>
                    <div className='flex gap-4 items-end w-full'>
                        <div className='flex-1 overflow-hidden'>
                            <p className="text-md-regular text-white break-words line-clamp-10 message hidden">{message?.text}</p>
                            <p className="text-md-regular text-white break-words line-clamp-10 message-preview whitespace-pre-line">{message?.text}</p>
                        </div>
                        <div>
                            <p className="text-xs-regular text-white">{formattedDate}</p>
                        </div>
                    </div>

                </div>}
            </div>
            {/* <div className="flex justify-end w-full" style={{ maxWidth: maxWidth }}>
                <div className="max-w-max flex flex-col items-end gap-1.5">
                    <div className="w-full flex justify-between items-center gap-2">
                        <p className="text-sm-medium text-gray-light/700">
                            {message?.sender_id === session?.id ? 'You' : message?.sender?.name}
                        </p>
                        <p className="text-xs-regular text-gray-light/600">{formattedDate}</p>
                    </div>
                    {(message.is_violated) ? <div className={`bg-white w-full max-w-max px-[14px] py-2.5 border rounded-lg border-error/600 rounded-tr-none message-text`}>
                        <p className="text-md-regular break-words line-clamp-10 message hidden">{message?.text}</p>
                        <p className="text-md-regular break-words line-clamp-10 message-preview whitespace-pre-line" dangerouslySetInnerHTML={{ __html: text }}></p>
                    </div> : <div className={`${message?.sender_id === session?.id ? 'bg-brand/600' : 'bg-gray-light/600'} w-full max-w-max px-[14px] py-2.5 rounded-lg rounded-tr-none message-text`}>
                        <p className="text-md-regular text-white break-words line-clamp-10 message hidden">{message?.text}</p>
                        <p className="text-md-regular text-white break-words line-clamp-10 message-preview whitespace-pre-line">{message?.text}</p>
                    </div>}
                </div>
            </div> */}
        </div>
    );
};

export default MessageSenderBox;