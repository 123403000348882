import React, { useEffect } from "react";
import MyPagination from "../../../components/Pagination/MyPagination";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import { DownloadCloud02, Edit01, FilterLines, HelpCircle, Plus, Trash01 } from "untitledui-js/icons/general";
import MyTextField from "../../../components/TextField/MyTextField";
import MySorted from "../../SimCard/Poppers/sorted";
import { SwitchVertical01 } from "untitledui-js/icons/arrow";
import SimpleBar from "simplebar-react";
import MyTooltip from "../../../components/Tooltip/MyTooltip";
import { useSettings } from "../../../contexts/SettingContext";
import { debounce } from "lodash";
import MyFilter from "../../SimCard/Poppers/filter";
import MyFilterModal from "../../../components/Modal/MyFilterModal";
import { Pencil01 } from "untitledui-js/icons/editor";
import MyCalendar from "../../../components/DatePicker/MyCalendar";
import MyDateRange from "../../../components/DatePicker/MyDateRange";

const RoleSetting = () => {
    const { setParams, params, addFilter, clearFilter, removeFilter, roles, getRoles, handleCurrentSlider, removeRole } = useSettings();
    useEffect(() => {
        getRoles();
    }, [params])

    const search = (e) => {
        setParams(value => { return { ...value, search: e?.target?.value, page: 1 } })
    }
    const debounceSearch = debounce(search, 1000)
    return (
        <>
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                <div className="pt-5 flex flex-col gap-y-5">
                    <div className='px-6 flex items-center gap-x-4 w-full'>
                        <div className="flex-1 flex-col gap-1">
                            <div className="flex items-center gap-2">
                                <p className="text-lg-semibold text-gray-light/900">Role & access</p>
                                <div className="px-2 py-0.5 rounded-full bg-brand/50 border border-brand/200">
                                    <p className="text-xs-medium text-brand/700">{roles?.meta?.total ?? '0'} item</p>
                                </div>
                            </div>
                            <p className="text-sm-regular text-gray-light/600">Manage user roles and their access control.</p>
                        </div>
                        <div className="flex items-center gap-x-3">
                            {/* <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                <DownloadCloud02 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Export</p>
                            </button> */}
                            {/* <button onClick={() => handleCurrentSlider({ status: true, current: "role" })} className="w-max h-max bg-brand/600 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-white shadow-shadows/shadow-xs">
                                <Plus size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Add role</p>
                            </button> */}
                        </div>
                    </div>
                    <hr className='border-gray-light/200' />
                </div>
                <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                    <div className="w-full max-w-xs">
                        <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                    </div>
                    <MyFilterModal id={'filter-role'} currentFilters={roles.filter} onChange={(filter) => setParams({ ...params, filter: filter, page: 1 })}
                        target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                            <FilterLines size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Filter</p>
                        </button>)} />

                    {/* <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                        <SwitchVertical01 size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Sort</p>
                    </button>)} /> */}

                    {/* <MyCalendar target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                        <FilterLines size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Calendar</p>
                    </button>)}
                        onChange={(date) => {
                            console.log(date);
                        }}
                    />
                    <MyDateRange target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                        <FilterLines size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">DateRange</p>
                    </button>)}
                        onChange={(date) => {
                            console.log(date);
                        }}
                    /> */}
                </div>
                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                    <table className='table border-collapse w-full'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            <tr className='p-0'>
                                <th className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-3">
                                        {/* <MyCheckbox /> */}
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Role name</p>
                                    </div>
                                </th>
                                <th className='px-6 py-3'>
                                    <div className="flex items-center justify-center gap-1">
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Grade</p>
                                        <MyTooltip target={<span><HelpCircle size={16} className={'text-gray-light/400'} stroke={'currentColor'} /></span>}>
                                            <div className="flex flex-col gap-y-1">
                                                <p className="text-white text-xs-semibold">Grade</p>
                                                <p className="text-gray-light/300 text-xs-medium">"Grade" indicates the user's role hierarchy. A higher grade means a higher hierarchy. It also determines authority, particularly in selecting team members, where you can choose members with an equal or lower hierarchy.</p>
                                            </div>
                                        </MyTooltip>
                                    </div>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Page access</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(roles.data ?? []).map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-3">
                                                {/* <MyCheckbox /> */}
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">{e.name}</p>
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-center border-t border-gray-light/200'>
                                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{e.grade}</p>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="w-full flex gap-x-1">
                                                {(e.pages ?? []).filter((e) => e).slice(0, 4).map((e2, i) => {
                                                    return (<div key={i} className="rounded-md py-0.5 px-1.5 border border-gray-light/300 shadow-shadows/shadow-xs">
                                                        <p className="text-xs-medium text-gray-light/700 truncate">{e2?.label ?? '-'}</p>
                                                    </div>);
                                                })}

                                                {e.pages.length > 4 && <div className="rounded-md py-0.5 px-1.5 border border-gray-light/300 shadow-shadows/shadow-xs">
                                                    <p className="text-xs-medium text-gray-light/700">+{e.pages.length - 4}</p>
                                                </div>}
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex justify-end gap-x-1">
                                                <button onClick={() => removeRole(e.id)} className="my-button flex items-center p-2.5">
                                                    <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                                <button onClick={() => handleCurrentSlider({ current: 'role', status: true }, e.id)} className="my-button flex items-center p-2.5">
                                                    <Edit01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SimpleBar>
                <div className="border-t border-gray-light/200">
                    <MyPagination meta={roles.meta} setParams={setParams} />
                </div>
            </div>
        </>
    );
};

export default RoleSetting;