import React, { useState } from "react";
import { Popover } from "@mui/material";
import MyIconPatternDecorative from "../../../components/Icon/MyIconPatternDecorative";
import { SimCard } from "untitledui-js/icons/media-devices";
import { AlertCircle } from "untitledui-js/icons/alerts";
import { useApp } from "../../../contexts/AppContext";

const PopperActiveSession = ({ id, placement = "bottom-start", target ,otherOpsi}) => {
    const { runningEngine } = useApp()

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <>
            {target && target(open, handleClick)}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={(placement === 'bottom-start') ? { vertical: "bottom", horizontal: "left" } : { vertical: "top", horizontal: "right" }}
                transformOrigin={(placement === 'bottom-start') ? { vertical: "top", horizontal: "left" } : { vertical: "bottom", horizontal: "right" }}
                sx={{
                    '&.MuiPopover-root .MuiPaper-root': {
                        marginTop: placement === 'bottom-start' ? "8px" : "-8px",
                        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                        border: '1px solid #EAECF0',
                        borderRadius: '12px',
                        width: 'max-content',
                    }
                }}
            >
                <div className="flex flex-col w-[480px] h-max">
                    <div className="p-6 pb-5 flex flex-col gap-y-4">
                        <MyIconPatternDecorative>
                            <div className="w-max p-3 rounded-full border-[3px] border-[#F5D2B4]">
                                <AlertCircle size={24} className={'text-warning/600'} stroke={'currentColor'} />
                            </div>
                        </MyIconPatternDecorative>
                        <div className="flex flex-col z-20">
                            <p className="text-lg-semibold text-gray-light/900">Activate Session?</p>
                            <p className="text-sm-regular text-gray-light/600">Your session appears inactive. Click the activate button to activate your session or click the change number button to switch to the SIM card you want to use.</p>
                        </div>
                    </div>
                    <div className="pt-8 pb-6 px-6 flex items-center justify-between gap-3">
                        <button onClick={()=>{otherOpsi();handleClose()}} className="flex items-center justify-center gap-x-2 text-gray-light/600">
                            <SimCard size={20} stroke={'currentColor'} />
                            <p className="whitespace-nowrap text-md-semibold">Change SIM Card</p>
                        </button>
                        <div className="flex items-center gap-x-3">
                            <button onClick={handleClose} className="w-max py-2.5 px-4 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg text-md-semibold text-gray-light/700">Cancel</button>
                            <button onClick={()=>{runningEngine();handleClose()}} className="w-max py-2.5 px-4 shadow-shadows/shadow-xs bg-brand/600 border border-brand/600 rounded-lg text-md-semibold text-white">Activate</button>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default PopperActiveSession;