import React, { } from "react";
import MyPagination from "../../../components/Pagination/MyPagination";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import { DownloadCloud02, FilterLines, HelpCircle, Plus } from "untitledui-js/icons/general";
import MyTextField from "../../../components/TextField/MyTextField";
import MyFilter from "../../SimCard/Poppers/filter";
import MySorted from "../../SimCard/Poppers/sorted";
import { SwitchVertical01 } from "untitledui-js/icons/arrow";
import SimpleBar from "simplebar-react";
import MyTooltip from "../../../components/Tooltip/MyTooltip";

const LabelSetting = () => {
    return (
        <>
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                <div className="pt-5 flex flex-col gap-y-5">
                    <div className='px-6 flex items-center gap-x-4 w-full'>
                        <div className="flex-1 flex-col gap-1">
                            <div className="flex items-center gap-2">
                                <p className="text-lg-semibold text-gray-light/900">Label</p>
                                <div className="px-2 py-0.5 rounded-full bg-brand/50 border border-brand/200">
                                    <p className="text-xs-medium text-brand/700">40 item</p>
                                </div>
                            </div>
                            <p className="text-sm-regular text-gray-light/600">Manage the list of labels that you can add to customer.</p>
                        </div>
                        <div className="flex items-center gap-x-3">
                            <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                <DownloadCloud02 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Export</p>
                            </button>
                            <button onClick={() => { }} className="w-max h-max bg-brand/600 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-white shadow-shadows/shadow-xs">
                                <Plus size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Add label</p>
                            </button>
                        </div>
                    </div>
                    <hr className='border-gray-light/200' />
                </div>
                <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                    <div className="w-full max-w-xs">
                        <MyTextField placeholder={'Search'} />
                    </div>
                    {/* <MyFilter id={'filter-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                        <FilterLines size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Filter</p>
                    </button>)} />

                    <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                        <SwitchVertical01 size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Sort</p>
                    </button>)} /> */}
                </div>
                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                    <table className='table border-collapse w-full'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            <tr className='p-0'>
                                <th width="25%" className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-3">
                                        {/* <MyCheckbox /> */}
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Label name</p>
                                    </div>
                                </th>
                                <th className='px-6 py-3'>
                                    <div className="flex items-center justify-start gap-1">
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Type</p>
                                        <MyTooltip target={<span><HelpCircle size={16} className={'text-gray-light/400'} stroke={'currentColor'} /></span>}>
                                            <div className="flex flex-col gap-y-1">
                                                <p className="text-white text-xs-semibold">Label behavior</p>
                                                <ul class="list-disc px-4">
                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">Dynamic</span> can be added during CSV upload but the labels must be registered first in the system.</li>
                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">Static</span> cannot be added during CSV upload and can only be added directly in the application</li>
                                                </ul>
                                            </div>
                                        </MyTooltip>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5].map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td width="25%" className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-3">
                                                {/* <MyCheckbox /> */}
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Overdue 1</p>
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="w-max px-2 py-0.5 rounded-md bg-brand/50 border border-brand/200">
                                                <p className="text-xs-medium text-brand/700">Dynamic</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SimpleBar>
                <div className="border-t border-gray-light/200">
                    <MyPagination meta={null} />
                </div>
            </div>
        </>
    );
};

export default LabelSetting;