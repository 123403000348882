import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";
import { User01, UserPlus01 } from "untitledui-js/icons/users";
import MyTooltip from "../../components/Tooltip/MyTooltip";
import { HelpCircle, Trash01 } from "untitledui-js/icons/general";
import MyCheckbox from "../../components/Checkbox/MyCheckbox";
import MyContainerSliderModal from "../../components/SliderModal/MyContainerSliderModal";
import ModalTeam from "./modal_team";
import { useTeamManagement } from "../../contexts/TeamManagementContext";
import moment from "moment";

const TeamManagement = () => {
    const { handleCurrentSlider, currentSlider, params, setParams, teams, getTeam, removeTeam } = useTeamManagement()
    useEffect(() => {
        getTeam()
    }, [params])

    return (
        <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
            <MyContainerSliderModal open={currentSlider.status} onClose={() => { handleCurrentSlider({ status: false, current: null }) }}>
                {currentSlider.current == "add-team" && <ModalTeam />}
            </MyContainerSliderModal>

            <main className="w-full h-full pt-8 pb-12 flex flex-col gap-8">
                <header className="px-8 flex flex-col gap-y-5">
                    <div className="flex items-start gap-x-6">
                        <div className="flex flex-col gap-1 flex-1">
                            <p className="display-sm-semibold text-gray-light/900">Team management</p>
                            <p className="text-md-regular text-gray-light/600">Manage and organize your team structure.</p>
                        </div>
                        <button onClick={() => handleCurrentSlider({ status: true, current: "add-team" })} className="bg-brand/600 text-white flex items-center gap-2 py-2.5 px-[14px] rounded-lg shadow-shadows/shadow-xs">
                            <UserPlus01 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Add new team member</p>
                        </button>
                    </div>
                    <hr className="border-gray-light/200" />
                </header>
                <div className="flex-1 px-8 flex items-start gap-x-8">
                    <div className="flex flex-col w-full max-w-[280px]">
                        <div className="flex items-center gap-0.5">
                            <p className="text-sm-semibold text-gray-light/700 whitespace-nowrap">Team members</p>
                            <MyTooltip target={<span><HelpCircle size={16} className={'text-gray-light/400'} stroke={'currentColor'} /></span>}>
                                <div className="flex flex-col gap-y-1">
                                    <p className="text-white text-xs-semibold">Hierarchical control</p>
                                    <p className="text-xs-medium text-gray-light/300">
                                        You will get detailed information about everything your team members do. Kindly keep in mind that this details are only for team members in your higher positions. Now you can't choose or get information from roles that are higher graded than yours.
                                    </p>
                                </div>
                            </MyTooltip>
                        </div>
                        <p className="text-sm-regular text-gray-light/600">The list of individuals recorded as members of your team.</p>
                    </div>
                    <div className="flex-1 rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                        <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                            <table className='table border-collapse w-full'>
                                <thead className='p-0'>
                                    <tr className='p-0'>
                                        <th className='px-6 py-3 text-left'>
                                            <div className="flex items-center gap-3">
                                                {/* <MyCheckbox /> */}
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Name</p>
                                            </div>
                                        </th>
                                        <th className='px-6 py-3 text-left'>
                                            <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Role</p>
                                        </th>
                                        <th className='px-6 py-3 text-left'>
                                            <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Date added</p>
                                        </th>
                                        <th className='px-6 py-3'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teams?.data.map((e, i) => {
                                        return (
                                            <tr key={e.id}>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex items-center gap-3">
                                                        {/* <span><MyCheckbox /></span> */}
                                                        {e?.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                            <img className="w-full h-full object-cover" src={e.full_path_photo} alt="" />
                                                        </div> : <div className="flex items-center justify-center bg-gray-light/100 w-10 h-10 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                                            <User01 size={24} className={'text-gray-light/500'} stroke="currentColor" />
                                                        </div>}
                                                        <div className="flex flex-col">
                                                            <p className="text-sm-medium text-gray-light/900">{e.name}</p>
                                                            <p className="text-sm-regular text-gray-light/600">{e.email}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{e.role?.name ?? '-'}</p>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">{moment(e.add_team_date).format('DD MMM YYYY • HH:mma')}</p>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex justify-end">
                                                        <button onClick={() => { removeTeam(e.id) }} className="my-button flex items-center p-2.5">
                                                            <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </div>
                </div>
                <div className="pt-4"></div>
            </main>
        </SimpleBar>
    );
};

export default TeamManagement;