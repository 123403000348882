import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Controller } from "react-hook-form";
import Switch from '@mui/material/Switch';

const MySwicth = ({ name, value, unCheckedValue, id, checked, control,
    disabled, onChangeForm }) => {
    const uniqueId = id && uuidv4();
    const style = {
        '&.MuiSwitch-root': {
            width: "36px", height: "20px",
            padding: "0px",
            overflow: "inherit",
            '& .MuiButtonBase-root': {
                padding: 0, transitionDuration: '300ms',
                margin: 0, marginTop: '2px',
                marginLeft: '2px',
                '&.Mui-checked': {
                    marginLeft: '-2px',
                    '& + .MuiSwitch-track': {
                        backgroundColor: '#7F56D9',
                        opacity: 1,
                    },
                    "& .MuiSwitch-thumb": {
                        background: '#FFFFFF',
                        boxShadow: '0px 1px 2px #1018280f'
                    },
                },
                "& .MuiSwitch-thumb": {
                    width: 16,
                    height: 16,
                    background: '#F9FAFB',
                    boxShadow: '0px 1px 2px #1018280f'
                },
                '&.Mui-focusVisible.Mui-checked': {
                    '& + .MuiSwitch-track': {
                        boxShadow: '0px 0px 0px 4px #9e77ed3d',
                    },
                },
                '&.Mui-focusVisible': {
                    '& + .MuiSwitch-track': {
                        boxShadow: '0px 0px 0px 4px #98a2b324',
                    },
                },
            },
            '& .MuiSwitch-track': {
                borderRadius: '20px',
                backgroundColor: '#F2F4F7',
                opacity: 1,
            },
        },
    }

    return (
        <>
            {control ? <Controller name={name} control={control} render={({ field }) => {
                return (
                    <Switch name={name} id={uniqueId} value={value} checked={checked || field?.value === value} disabled={disabled} disableRipple
                        onChange={(e) => {
                            var { target: { checked } } = e;
                            field?.onChange && field?.onChange((checked) ? value : unCheckedValue ?? null);
                            onChangeForm && onChangeForm(e);
                        }}
                        sx={style} />
                )
            }} /> : <Switch id={uniqueId} name={name} value={value} checked={checked} disabled={disabled} disableRipple
                onChange={onChangeForm}
                sx={style} />}
        </>
    );
};

export default MySwicth;