import React, { } from "react";
import { UploadCloud02, XClose } from "untitledui-js/icons/general";
import MyTextField from "../../../components/TextField/MyTextField";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import SimpleBar from "simplebar-react";

const SliderUpdateProof = () => {
    return (
        <div className="w-full h-full flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} />
                </button>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">Upload appeal proof</p>
                    <p className="text-sm-regular text-gray-light/600">Provide evidence of your appeal here..</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <div className="h-full flex flex-col gap-6">
                        <div className="flex-1 flex flex-col gap-6">
                            <div className="px-6 flex flex-col gap-y-6">
                                <div className="flex flex-col gap-y-4">
                                    <section>
                                        <input id="icon-button-photo" multiple={true} style={{ display: 'none' }} type="file" onChange={(e) => {
                                            console.log(e);
                                        }} />
                                        <label htmlFor="icon-button-photo">
                                            <section className="flex flex-col items-center gap-y-3 px-6 py-4 rounded-xl border-2 border-brand/600 ">
                                                <div className="w-max block p-2.5 border border-gray-light/200 shadow-shadows/shadow-xs rounded-lg">
                                                    <UploadCloud02 size={20} className={'text-gray-light/700'} stroke={'currentColor'} />
                                                </div>
                                                <div className="flex flex-col items-center text-center gap-1">
                                                    <p className="text-sm-semibold text-brand/700">Click to upload</p>
                                                    <p className="text-xs-regular text-gray-light/600">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                                </div>
                                            </section>
                                        </label>
                                    </section>
                                    <div className="flex flex-col gap-y-4">
                                        <section className="relative overflow-hidden flex rounded-lg border border-gray-light/200 p-4">
                                            <div className="z-10 absolute top-0 left-0 bg-gray-light/50 h-full" style={{ width: '60%' }}> </div>
                                            <div className="z-20 flex-1 flex items-center gap-3">
                                                <div className="w-10 h-10 min-w-[40px] min-h-[40px]">
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="text-sm-medium text-gray-light/900 line-clamp-1">Dashboard screenshot.jpg</p>
                                                    <p className="text-sm-regular text-gray-light/600">720 KB</p>
                                                </div>
                                            </div>
                                            <div className="z-20 absolute top-[16px] right-[16px]">
                                                <MyCheckbox />
                                            </div>
                                            <div className="z-20 w-8 h-8 min-w-[32px] min-h-[32px]">
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <section className="flex flex-col gap-y-1">
                                    <p className="text-lg-semibold text-gray-light/900">Attached files</p>
                                    <p className="text-sm-regular text-gray-light/600">Files and assets that have been attached to this project.</p>
                                </section>
                                <section>
                                    <MyTextField placeholder={'Search for proofs'}></MyTextField>
                                </section>
                            </div>
                            <div className="">
                                <table className='table border-collapse w-full max-w'>
                                    <thead className='border-t border-gray-light/200 p-0'>
                                        <tr className='p-0'>
                                            <th className='px-6 py-3 text-left bg-gray-light/50'>
                                                <div className="flex items-center gap-3">
                                                    <MyCheckbox />
                                                    <p className="text-xs-medium text-gray-light/600">File name</p>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[1, 2, 3, 4, 5].map((e, i) => {
                                            return (<tr key={i}>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex items-center gap-3">
                                                        <MyCheckbox />
                                                        <div className="w-10 h-10 min-w-[40px] min-h-[40px]">
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-sm-medium text-gray-light/900 line-clamp-1">Dashboard screenshot.jpg</p>
                                                            <p className="text-sm-regular text-gray-light/600">720 KB</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 py-4 px-6 flex items-center justify-end gap-x-3">
                            <button className="px-[14px] py-2 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button className="px-[14px] py-2 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Submit proof</p>
                            </button>
                        </footer>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default SliderUpdateProof;