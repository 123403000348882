import React from "react";
import { ArrowLeft } from "untitledui-js/icons/arrow";
import { TrendDown01 } from "untitledui-js/icons/charts";
import { Calender } from "untitledui-js/icons/time";
import { DownloadCloud02, FilterLines } from "untitledui-js/icons/general";
import SimpleBar from "simplebar-react";

const PerformanceReport = () => {
  return (
    <>
      <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
        <main className="pt-8 pb-12 flex flex-col gap-8">
          <div className="px-8 flex flex-col gap-y-6 w-full">
            <button className="flex items-center gap-1.5 text-brand/700">
              <ArrowLeft size={20} stroke={'currentColor'} />
              <p className="text-sm-semibold">Back to home</p>
            </button>
            <div className="flex flex-col gap-y-1 w-full">
              <p className="display-sm-semibold text-gray-light/900">More report</p>
              <p className="text-md-regular text-gray-light/600">In-depth charts and insights regarding your team's performance.</p>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="w-max border border-gray-light/300 rounded-lg shadow-shadows/shadow-xs overflow-hidden">
                <button className="px-4 py-2 text-gray-light/700 text-sm-semibold border-r border-border-gray-light/300">
                  12 months
                </button>
                <button className="px-4 py-2 text-gray-light/700 text-sm-semibold border-r border-border-gray-light/300">
                  30 days
                </button>
                <button className="px-4 py-2 text-gray-light/700 text-sm-semibold border-r border-border-gray-light/300">
                  7 days
                </button>
                <button className="bg-gray-light/50 px-4 py-2 text-gray-light/700 text-sm-semibold">
                  24 hours
                </button>
              </div>
              <button className="flex items-center gap-1 px-[14px] py-2.5 w-max border border-gray-light/300 rounded-lg shadow-shadows/shadow-xs overflow-hidden">
                <Calender size={20} className={'text-gray-light/700'} stroke={'currentColor'} />
                <p className="text-sm-semibold text-gray-light/500">Select dates</p>
              </button>
            </div>
          </div>
          <div className="px-8 flex flex-wrap gap-6 w-full">
            <div className="flex-1 flex flex-col gap-6">
              <div className="rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200 shadows-shadows/shadow-xs">
                <div className="flex flex-col gap-y-2">
                  <p className="text-sm-medium text-gray-light/600">Avg. of distributed task / attempt / day</p>
                  <div className="flex items-center justify-between gap-4 w-full">
                    <p className="display-md-semibold text-gray-light/900 whitespace-nowrap">42%</p>
                    <div className="flex items-center gap-x-2">
                      <div className="flex items-center gap-x-1">
                        <TrendDown01 size={20} className={'text-error/500'} stroke={'currentColor'} />
                        <p className="text-error/600">100%</p>
                      </div>
                      <p className="text-sm-medium text-gray-light/600 whitespace-nowrap">vs last month</p>
                    </div>
                  </div>
                </div>
                {/* // TODO Chart nya disini */}
              </div>
              <div className="flex gap-x-6">
                <div className="flex-1 rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200 shadows-shadows/shadow-xs">
                  <div className="flex flex-col gap-y-2">
                    <p className="text-sm-medium text-gray-light/600">Avg. of distributed task / day</p>
                    <div className="flex flex-wrap items-center justify-between w-full">
                      <p className="display-md-semibold text-gray-light/900 whitespace-nowrap">20.8k</p>
                      <div className="flex items-center gap-x-2">
                        <div className="flex items-center gap-x-1">
                          <TrendDown01 size={20} className={'text-success/500'} stroke={'currentColor'} />
                          <p className="text-success/600">100%</p>
                        </div>
                        <p className="text-sm-medium text-gray-light/600 whitespace-nowrap">vs last month</p>
                      </div>
                    </div>
                  </div>
                  {/* // TODO Chart nya disini */}
                </div>
                <div className="flex-1 rounded-xl p-6 flex flex-col gap-y-5 border border-gray-light/200 shadows-shadows/shadow-xs">
                  <div className="flex flex-col gap-y-2">
                    <p className="text-sm-medium text-gray-light/600">Avg. of attempt / day</p>
                    <div className="flex flex-wrap items-center justify-between w-full">
                      <p className="display-md-semibold text-gray-light/900 whitespace-nowrap">20.8k</p>
                      <div className="flex items-center gap-x-2">
                        <div className="flex items-center gap-x-1">
                          <TrendDown01 size={20} className={'text-success/500'} stroke={'currentColor'} />
                          <p className="text-success/600">100%</p>
                        </div>
                        <p className="text-sm-medium text-gray-light/600 whitespace-nowrap">vs last month</p>
                      </div>
                    </div>
                  </div>
                  {/* // TODO Chart nya disini */}
                </div>
              </div>
            </div>
            <div className="w-max py-5 px-4 flex flex-col gap-y-6 rounded-xl border border-gray-light/200 shadows-shadows/shadow-xs">
              <p className="text-sm-semibold text-gray-light/900">Top latest attempt</p>
              <div className="w-max flex items-start gap-x-4">
                <div className="w-[280px] h-[280px] min-w-[280px] min-h-[280px]">

                </div>
                <div className="flex flex-col gap-y-1">
                  <div className="flex items-center gap-x-2">
                    <span className="flex w-2 h-2 rounded-full bg-brand/600"></span>
                    <p className="text-sm-regular text-gray-light/600">Berjanji membayar</p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="flex w-2 h-2 rounded-full bg-brand/500"></span>
                    <p className="text-sm-regular text-gray-light/600">Negosiasi restruktur</p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="flex w-2 h-2 rounded-full bg-brand/400"></span>
                    <p className="text-sm-regular text-gray-light/600">Negosiasi bunga</p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="flex w-2 h-2 rounded-full bg-brand/300"></span>
                    <p className="text-sm-regular text-gray-light/600">Reminder</p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="flex w-2 h-2 rounded-full bg-brand/200"></span>
                    <p className="text-sm-regular text-gray-light/600">Customer sakit</p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="flex w-2 h-2 rounded-full bg-gray-light/200"></span>
                    <p className="text-sm-regular text-gray-light/600">Customer meninggal</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-8">
            <div className="bg-white w-full flex flex-col gap-y-5 px-6 rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
              <div className="pt-5 flex flex-col gap-y-5">
                <div className='flex items-center gap-x-4 w-full'>
                  <div className="flex-1 flex-col gap-1">
                    <p className="text-lg-semibold text-gray-light/900">Overview</p>
                    <p className="text-sm-regular text-gray-light/600">Your team's top performer.</p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                      <DownloadCloud02 size={20} stroke={'currentColor'} />
                      <p className="text-sm-semibold">Export</p>
                    </button>
                  </div>
                </div>
                <div className="flex items-center gap-x-1">
                  <button className="bg-gray-light/50 text-sm-semibold text-gray-light/700 rounded-md px-3 py-2">
                    12 months
                  </button>
                  <button className="text-sm-semibold text-gray-light/500 rounded-md px-3 py-2">
                    3 months
                  </button>
                  <button className="text-sm-semibold text-gray-light/500 rounded-md px-3 py-2">
                    30 days
                  </button>
                  <button className="text-sm-semibold text-gray-light/500 rounded-md px-3 py-2">
                    7 days
                  </button>
                  <button className="text-sm-semibold text-gray-light/500 rounded-md px-3 py-2">
                    24 hours
                  </button>
                </div>
                <hr className='border-gray-light/200' />
              </div>
              <div className="w-full flex gap-x-8 pb-4">
                <div className="flex-1 flex flex-col gap-y-2">
                  <div className="flex flex-col gap-y-5">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-lg-semibold text-gray-light/900">Top users who handled the most customers</p>
                      <p className="text-sm-regular text-gray-light/600">Top customers handled.</p>
                    </div>
                    <hr className='border-gray-light/200' />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                      <table className='table border-collapse w-full'>
                        <tbody>
                          {[1, 2, 3, 4, 5].map((e, i) => {
                            return (
                              <tr key={i}>
                                <td className='px-6 py-4 text-left border-b border-gray-light/200'>
                                  <div className="flex items-center gap-3">
                                    <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                      <img className="w-full h-full object-cover" src="" alt="" />
                                    </div>
                                    <div className="flex flex-col">
                                      <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">Phoenix Baker</p>
                                      <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">phoenix.baker@verify.com</p>
                                    </div>
                                  </div>
                                </td>
                                <td className='px-6 py-4 text-right border-b border-gray-light/200'>
                                  <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">4,783 cust.</p>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </SimpleBar>
                    <div className="w-full flex justify-end">
                      <button className="w-max text-sm-semibold text-brand/700">
                        View all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col gap-y-2">
                  <div className="flex flex-col gap-y-5">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-lg-semibold text-gray-light/900">Top users with the most attempts</p>
                      <p className="text-sm-regular text-gray-light/600">Top attempt by user.</p>
                    </div>
                    <hr className='border-gray-light/200' />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                      <table className='table border-collapse w-full'>
                        <tbody>
                          {[1, 2, 3, 4, 5].map((e, i) => {
                            return (
                              <tr key={i}>
                                <td className='px-6 py-4 text-left border-b border-gray-light/200'>
                                  <div className="flex items-center gap-3">
                                    <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                      <img className="w-full h-full object-cover" src="" alt="" />
                                    </div>
                                    <div className="flex flex-col">
                                      <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">Phoenix Baker</p>
                                      <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">phoenix.baker@verify.com</p>
                                    </div>
                                  </div>
                                </td>
                                <td className='px-6 py-4 text-right border-b border-gray-light/200'>
                                  <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">24 attempt</p>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </SimpleBar>
                    <div className="w-full flex justify-end">
                      <button className="w-max text-sm-semibold text-brand/700">
                        View all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col gap-y-2">
                  <div className="flex flex-col gap-y-5">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-lg-semibold text-gray-light/900">Top users who completed the most tasks</p>
                      <p className="text-sm-regular text-gray-light/600">Unique attempt / distributed task.</p>
                    </div>
                    <hr className='border-gray-light/200' />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                      <table className='table border-collapse w-full'>
                        <tbody>
                          {[1, 2, 3, 4, 5].map((e, i) => {
                            return (
                              <tr key={i}>
                                <td className='px-6 py-4 text-left border-b border-gray-light/200'>
                                  <div className="flex items-center gap-3">
                                    <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                      <img className="w-full h-full object-cover" src="" alt="" />
                                    </div>
                                    <div className="flex flex-col">
                                      <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">Phoenix Baker</p>
                                      <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">phoenix.baker@verify.com</p>
                                    </div>
                                  </div>
                                </td>
                                <td className='px-6 py-4 text-right border-b border-gray-light/200'>
                                  <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">24 attempt</p>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </SimpleBar>
                    <div className="w-full flex justify-end">
                      <button className="w-max text-sm-semibold text-brand/700">
                        View all
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </SimpleBar>
    </>
  );
};

export default PerformanceReport;