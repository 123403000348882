import React, { } from "react";
import SimpleBar from "simplebar-react";
import { ArrowLeft } from "untitledui-js/icons/arrow";

const FAQ = () => {
    return (
        <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
            <main className="w-full h-full pt-8 pb-12 flex flex-col gap-8 bg-white">
                <header className="px-8 flex flex-col gap-y-6">
                    <button className="flex items-center gap-1.5 text-brand/700">
                        <ArrowLeft size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Back to Task Distribution</p>
                    </button>
                    <p className="display-sm-semibold text-gray-light/900">Task Distribution Template FAQs</p>
                    <div className="flex gap-x-1">
                        <button className="px-3 py-2 text-sm-semibold rounded-md text-brand/700 bg-brand/50">
                            General
                        </button>
                        <button className="px-3 py-2 text-sm-semibold rounded-md text-gray-light/500">
                            User email
                        </button>
                        <button className="px-3 py-2 text-sm-semibold rounded-md text-gray-light/500">
                            Customer name & number
                        </button>
                        <button className="px-3 py-2 text-sm-semibold rounded-md text-gray-light/500">
                            Dynamic label
                        </button>
                        <button className="px-3 py-2 text-sm-semibold rounded-md text-gray-light/500">
                            Custom field
                        </button>
                    </div>
                </header>
                <div className="flex-1">
                    {/* <div className="px-8 flex flex-col gap-y-6">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex flex-col gap-1">
                                <p className="text-lg-semibold text-gray-light/900">General</p>
                                <p className="text-sm-regular text-gray-light/600">An overview of the project, goals and outcomes.</p>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-8">
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apa itu fitur Task Distribution?</p>
                                <p className="text-md-regular text-gray-light/600">Fungsi dari fitur Task Distribution adalah untuk mendistribusikan nomor-nomor nasabah yang harus dihubungi oleh agen-agen. Distribusi ini dilakukan berdasarkan pemasangan antara agen dan nasabah.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apa pengertian dari sheet Template, Guide, & Example pada file template Task Distribution?</p>
                                <div>
                                    <img src="" alt="" />
                                </div>
                                <p className="text-md-regular text-gray-light/600">
                                    <span className="text-md-bold">Sheet "Template"</span> merupakan kumpulan data yang akan diunggah ke dalam sistem. Ini berfungsi sebagai area kerja utama di mana pengguna memasukkan informasi yang diperlukan yang nantinya akan diunggah ke dalam sistem.
                                </p>
                                <p className="text-md-regular text-gray-light/600">
                                    <span className="text-md-bold">Sheet "Guide"</span> berfungsi sebagai panduan terperinci bagi pengguna yang ingin mengunggah file task distribution ke dalam sistem. Sheet ini memberikan panduan komprehensif, termasuk penjelasan untuk setiap kolom dan petunjuk tentang cara memasukkan data ke dalam setiap kolom. Sheet ini dirancang untuk membantu pengguna dalam menavigasi dan mengisi template dengan benar.
                                </p>
                                <p className="text-md-regular text-gray-light/600">
                                    <span className="text-md-bold">Sheet "Example"</span> berisi contoh dari template yang telah diisi sebagai gambaran. Ini menunjukkan bagaimana template seharusnya diisi dan memberikan pengguna referensi visual untuk format yang diharapkan ketika mereka mengisi data mereka sendiri. Ini berfungsi sebagai contoh praktis untuk membantu pengguna memahami cara mengatur informasi yang nantinya akan diunggah ke dalam sistem.
                                </p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Berapa banyak file task distribution yang dapat saya unggah per harinya?</p>
                                <p className="text-md-regular text-gray-light/600">Anda dapat mengunggah file task distribution sebanyak yang Anda inginkan.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <div className="flex flex-col gap-y-3">
                                    <p className="text-md-medium text-gray-light/900">Apa yang akan terjadi apabila tedapat file duplikat pada file task distribution yang saya upload?</p>
                                    <p className="text-md-regular text-gray-light/900">Terdapat beberapa kondisi yang berpotensi terjadi untuk permasalahan duplikat ini:</p>
                                </div>
                                <p className="text-md-regular text-gray-light/600">
                                    <span className="text-md-bold">Duplikat dalam 1 File XLSX:</span> Jika terdapat file duplikat dalam satu file XLSX yang akan diupload, maka baris (row) yang akan diambil adalah baris yang paling terakhir. Artinya, data dari baris terakhir akan menggantikan data dari baris sebelumnya jika ada duplikasi.
                                </p>
                                <p className="text-md-regular text-gray-light/600">
                                    <span className="text-md-bold">Duplikat dalam File Berbeda pada 1 Kesempatan Upload:</span> Jika terdapat file duplikat dalam file yang berbeda, tetapi diunggah dalam satu kesempatan upload yang sama, maka informasi yang akan diambil adalah informasi yang terdapat pada file yang paling terakhir diupload. Data dari file terakhir akan menggantikan data dari file-file sebelumnya.
                                </p>
                                <p className="text-md-regular text-gray-light/600">
                                    <span className="text-md-bold">Duplikat dengan Data yang Sudah Ada dalam Sistem:</span> Jika terdapat file duplikat dengan data yang sudah ada dalam sistem, maka data yang sudah ada dalam sistem akan tergantikan oleh data dari file yang diupload. Proses ini akan menggantikan informasi yang sudah ada dengan versi terbaru dari file yang diunggah.
                                </p>
                            </div>
                            <div className="p-8 rounded-2xl flex items-center justify-between bg-gray-light/50">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xl-semibold text-gray-light/900">Still have questions?</p>
                                    <p className="text-lg-regular text-gray-light/600">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                                </div>
                                <button className="flex items-center text-white px-[18px] py-3 bg-brand/600 rounded-lg shadow-shadows/shadow-xs">
                                    Get in touch
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="px-8 flex flex-col gap-y-6">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex flex-col gap-1">
                                <p className="text-lg-semibold text-gray-light/900">User email</p>
                                <p className="text-sm-regular text-gray-light/600">User Email adalah daftar alamat email yang akan menjadi identifier ketika sistem akan mendistribusikan daftar nama-nama debitur.</p>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-8">
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Email siapa yang harus diisi?</p>
                                <p className="text-md-regular text-gray-light/600">Email yang harus diisi adalah nama dari agen yang anda miliki yang bertugas untuk menghubungi para customer tersebut. Sebagaimana yang telah terdaftar ke dalam sistem. Daftar email yang telah tedaftar dalam sistem dapat dilihat pada sheet “Guide” di file template sebagaimana tampilan berikut:</p>
                            </div>
                            <div>
                                <img src="" alt="" />
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Berapa maksimum email yang dapat diisi?</p>
                                <p className="text-md-regular text-gray-light/600">Maksimum email yang dapat diisi tidak terbatas jumlahnya. Anda dapat menginput bebas sesuai yang diinginkan.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Bagaimana jika user email dibiarkan kosong?</p>
                                <p className="text-md-regular text-gray-light/600">Dalam mengupload tugas, setidaknya harus terdapat satu email yang diupload, apabila file diupload dengan kondisi tidak ada email yang didaftarkan, maka feature Task tidak akan didistribusikan ke siapapun.</p>
                            </div>
                            <div className="p-8 rounded-2xl flex items-center justify-between bg-gray-light/50">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xl-semibold text-gray-light/900">Still have questions?</p>
                                    <p className="text-lg-regular text-gray-light/600">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                                </div>
                                <button className="flex items-center text-white px-[18px] py-3 bg-brand/600 rounded-lg shadow-shadows/shadow-xs">
                                    Get in touch
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="px-8 flex flex-col gap-y-6">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex flex-col gap-1">
                                <p className="text-lg-semibold text-gray-light/900">Customer name & number</p>
                                <ul class="list-disc px-4">
                                    <li className="text-gray-light/600 text-sm-regular">Customer name adalah nama dari pelanggan yang akan atau telah dihubungi.</li>
                                    <li className="text-gray-light/600 text-sm-regular">Customer number adalah nomor WhatsApp milik pelanggan yang nantinya akan dihubungi oleh agen. Sedangkan</li>
                                </ul>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-8">
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Bagaimana Jika nomor yang diinput tidak sesuai?</p>
                                <p className="text-md-regular text-gray-light/600">Sangat penting bahwa nomor yang dimasukkan adalah benar dan sesuai dengan data pelanggan. Jika nomor yang dimasukkan tidak sesuai, maka agen tidak dapat menghubungi pelanggan.</p>
                            </div>

                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apakah agen akan mengetahui nomor telepon dari customer?</p>
                                <p className="text-md-regular text-gray-light/600">Tidak, agen hanya akan memiliki akses kepada nama pelanggan, karena nomor telepon akan disamarkan (dimasking) sebagaimana tampilan berikut:</p>
                                <div>
                                    <img src="" alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Ketika saya mengupload Task Distribution apakah nomor pelanggan akan tersimpan dalam database aplikasi?</p>
                                <p className="text-md-regular text-gray-light/600">Ya, setiap informasi yang diupload akan tersimpan pada database.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apakah ada format khusus dalam penulisan nomor telepon?</p>
                                <p className="text-md-regular text-gray-light/600">
                                    Untuk memastikan pengenalan nomor telepon yang akurat, sistem akan hanya mengenali nomor telepon jika formatnya dimulai dengan 62. Tanda tambahan seperti '+' atau '0' tidak perlu disertakan.
                                    <br /><br />
                                    Contoh: 6281214527466
                                </p>
                            </div>
                            <div className="p-8 rounded-2xl flex items-center justify-between bg-gray-light/50">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xl-semibold text-gray-light/900">Still have questions?</p>
                                    <p className="text-lg-regular text-gray-light/600">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                                </div>
                                <button className="flex items-center text-white px-[18px] py-3 bg-brand/600 rounded-lg shadow-shadows/shadow-xs">
                                    Get in touch
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="px-8 flex flex-col gap-y-6">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex flex-col gap-1">
                                <p className="text-lg-semibold text-gray-light/900">Dynamic label</p>
                                <p className="text-sm-regular text-gray-light/600">Adalah Informasi dalam bentuk badge/emblem yang dapat ditempel kedalam informasi debitur. Berikut adalah contoh dari tampilan label:</p>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-8">
                            <div className="flex flex-col gap-1">
                                <p className="text-md-medium text-gray-light/900">Apa perbedaan Dynamic Label & Static Label?</p>
                                <ul class="list-disc px-4">
                                    <li className="text-gray-light/600 text-md-regular">Dynamic Label dapat ditambahkan pada saat upload CSV namun label tersebut harus sudah dipastikan telah terdaftar pada sistem Merpati CRM.</li>
                                    <li className="text-gray-light/600 text-md-regular">Static Label tidak dapat ditambahkan saat upload CSV dan hanya dapat ditambahkan secara langsung pada aplikasi</li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apakah posisi column ketika saya upload file akan mempengaruhi urutannya ketika ditampilkan pada aplikasi?</p>
                                <p className="text-md-regular text-gray-light/600">
                                    Ya, urutan dynamic label pada Merpati CRM akan diurutkan berdasarkan urutan dynamic label column pada template.
                                    <br /> <br />
                                    Contoh: dynamic label "Overdue 1" berada pada column D dan dynamic label "17 - 25 y/o" berada pada column E", maka dynamic label "Overdue 1" akan diurutkan lebih dahulu.
                                </p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Bagaimana bentuk tampilan nanti ketika saya mengupload lebih dari 1 Dynamic Label?</p>
                                <p className="text-md-regular text-gray-light/600">Column dynamic label pada Merpati CRM akan diaggregat kedalam 1 column berdasarkan column dynamic label pada template.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-regular text-gray-light/600">{'Contoh: Saya menambahkan dynamic label "Overdue 1" dan dynamic label "<200", maka tampilan pada sistem akan sebagai berikut:'}</p>
                                <div>
                                    <img src="" alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apa yang akan terjadi apabila saya mengupload Dynamic Label yang belum terdaftar pada sistem Merpati CRM?</p>
                                <p className="text-md-regular text-gray-light/600">
                                    Label tidak akan terupload kedalam sistem, karena dynamic label yang tidak terdaftar pada Merpati CRM tidak akan muncul ke dalam sistem ketika diupload.
                                    <br /> <br />
                                    Contoh: Terdapat dynamic label "Overdue 1" dan "Overdue 2" yang terdaftar pada Merpati CRM, maka "Overdue 421" akan ditolak apabila diupload
                                </p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Adakah jumlah maksimal dari label yang diinput?</p>
                                <p className="text-md-regular text-gray-light/600">Tentu, jumlah label yang dapat Anda tambahkan adalah sebanyak (?) kolom.</p>
                            </div>
                            <div className="p-8 rounded-2xl flex items-center justify-between bg-gray-light/50">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xl-semibold text-gray-light/900">Still have questions?</p>
                                    <p className="text-lg-regular text-gray-light/600">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                                </div>
                                <button className="h-max flex items-center text-white px-[18px] py-3 bg-brand/600 rounded-lg shadow-shadows/shadow-xs">
                                    Get in touch
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="px-8 flex flex-col gap-y-6">
                        <div className="flex flex-col gap-y-5">
                            <div className="flex flex-col gap-1">
                                <p className="text-lg-semibold text-gray-light/900">Custom field</p>
                                <p className="text-sm-regular text-gray-light/600">Custom Field adalah informasi dalam bentuk kolom yang dapat ditambahkan dan nantinya akan muncul pada informasi debitur.</p>
                            </div>
                            <hr className="border-gray-light/200" />
                        </div>
                        <div className="flex flex-col gap-y-8">
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apa itu Column Header Name?</p>
                                <p className="text-md-regular text-gray-light/600">Column Header Name adalah judul dari custom field yang akan ditampilkan pada sistem.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apakah posisi column ketika saya upload file akan mempengaruhi urutannya ketika ditampilkan pada aplikasi?</p>
                                <p className="text-md-regular text-gray-light/600">Column Header Name adalah judul dari custom field yang akan ditampilkan pada sistem.</p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Apakah posisi column ketika saya upload file akan mempengaruhi urutannya ketika ditampilkan pada aplikasi?</p>
                                <p className="text-md-regular text-gray-light/600">
                                    Ya, Urutan custom field pada Merpati CRM akan diurutkan berdasarkan urutan {'{dynamic_label}'} column pada template.
                                    <br /> <br />
                                    Contoh: custom field "Denda" berada pada column D dan custom field "Domisili" berada pada column E", maka custom field "Denda" akan diurutkan lebih dahulu.
                                </p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-medium text-gray-light/900">Bagaimana cara saya menambahkan judul pada custom field?</p>
                                <p className="text-md-regular text-gray-light/600">
                                    Ubah text pada {'{column header name}'} sesuai dengan judul yang Anda inginkan.
                                    <br /> <br />
                                    Contoh: Saya ingin menambahkan custom field "Denda", maka saya harus mengganti {'{column header name}'} menjadi {'{Denda}'} ketika diupload sebagaimana foto berikut:
                                </p>
                            </div>
                            <div className="flex flex-col gap-y-3">
                                <p className="text-md-regular text-gray-light/600">Hasil input CSV:</p>
                                <div>
                                    <img src="" alt="" />
                                </div>
                                <p className="text-md-regular text-gray-light/600">Output pada Merpati CRM:</p>
                                <div>
                                    <img src="" alt="" />
                                </div>
                            </div>

                            <div className="p-8 rounded-2xl flex items-center justify-between bg-gray-light/50">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xl-semibold text-gray-light/900">Still have questions?</p>
                                    <p className="text-lg-regular text-gray-light/600">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                                </div>
                                <button className="h-max flex items-center text-white px-[18px] py-3 bg-brand/600 rounded-lg shadow-shadows/shadow-xs">
                                    Get in touch
                                </button>
                            </div>
                        </div>
                    </div> */}
                    
                </div>
            </main>
        </SimpleBar>
    );
};

export default FAQ;