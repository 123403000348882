import React, { useEffect } from "react";
import { Edit01, FilterLines, HelpCircle, Logout04, Plus, Trash01 } from "untitledui-js/icons/general";
import MyTextField from "../../components/TextField/MyTextField";
import { ArrowsDown, ArrowsUp, SwitchVertical01 } from "untitledui-js/icons/arrow";
import MyCheckbox from "../../components/Checkbox/MyCheckbox";
import { HourGlass03 } from "untitledui-js/icons/time";
import MyPagination from "../../components/Pagination/MyPagination";
import { useSimCard } from "../../contexts/SimCardContext";
import SimpleBar from "simplebar-react";
import MyTooltip from "../../components/Tooltip/MyTooltip";
import MySorted from "./Poppers/sorted";
import MyFilter from "./Poppers/filter";
import { User01 } from "untitledui-js/icons/users";
import { debounce } from "lodash";

const TableContainerRegistered = () => {
    const { setSimCard, simCard, getSimCard, handleCurrentSlider, handleCurrentModal, params, setParams, logoutEngine } = useSimCard();

    useEffect(() => {
        getSimCard();
    }, [params])
    const search = (e) => {
        setParams(value => { return { ...value, search: e?.target?.value, page: 1 } })
    }

    const convertUsageLength = (date) => {
        const timestamp = new Date(date);
        const currentTime = new Date();
        const timeDifference = (currentTime - timestamp) / (1000 * 60 * 60); // Dalam jam

        if (timeDifference < 1) {
            const minutes = Math.round(timeDifference * 60);
            return `${minutes} min`;
        } else if (timeDifference < 24) {
            const hours = Math.round(timeDifference);
            return `${hours} hr`;
        } else if (timeDifference < 720) {
            const days = Math.round(timeDifference / 24);
            return `${days} days`;
        } else if (timeDifference < 8760) {
            const months = Math.round(timeDifference / (24 * 30));
            return `${months} months`;
        } else {
            const years = Math.round(timeDifference / (24 * 365));
            return `${years} years`;
        }
    };

    const debounceSearch = debounce(search, 1000)
    return (
        <div className="px-8 w-full">
            <div className="w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                <div className="py-5 px-6 flex gap-4">
                    <div className="flex-1 flex-col gap-1">
                        <p className="text-lg-semibold text-gray-light/900">Registered SIM card</p>
                        <p className="text-sm-regular text-gray-light/600">List of registered SIM cards in the system.</p>
                    </div>
                    <button onClick={() => handleCurrentModal('modal-add-sim-card')} className="w-max h-max bg-brand/600 flex items-center rounded-lg px-3 py-2 gap-x-1 text-white">
                        <Plus size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">SIM card</p>
                    </button>
                </div>
                <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                    <div className="w-full max-w-xs">
                        <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                    </div>
                    {/* <MyFilter id={'filter-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                        <FilterLines size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Filter</p>
                    </button>)} />

                    <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                        <SwitchVertical01 size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Sort</p>
                    </button>)} /> */}
                </div>
                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                    <table className='table border-collapse w-full'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            <tr className='p-0'>
                                <th className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-3">
                                        {/* <MyCheckbox checked={simCard?.checkedAll ?? false} onChangeForm={({ target: { checked } }) => {
                                            let updatedList = simCard.data.map(item => {
                                                return { ...item, checked: checked };
                                            });
                                            setSimCard({ ...simCard, data: updatedList, checkedAll: checked })
                                        }} /> */}
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Number</p>
                                    </div>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Status</p>
                                </th>
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Usage length</p>
                                </th>
                                {/* <th className='px-6 py-3'>
                                    <div className="flex items-center gap-1">
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Messaging activitiy</p>
                                        <MyTooltip target={<span><HelpCircle size={16} className={'text-gray-light/400'} stroke={'currentColor'} /></span>}>
                                            <div className="flex flex-col gap-y-1">
                                                <p className="text-white text-xs-semibold">Inbound, outbound & pending message</p>
                                                <ul class="list-disc px-4">
                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">Inbound message</span> is the total number of messages received by user.</li>
                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">Outbound message</span> is the total number of messages sent by user.</li>
                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">Pending message</span> is the total number of messages identified as not yet successfully delivered.</li>
                                                </ul>
                                            </div>
                                        </MyTooltip>
                                    </div>
                                </th> */}
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Assigned to</p>
                                </th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(simCard?.data ?? []).filter((e) => e.status.toLowerCase() !== 'auth').map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-3">
                                                {/* <MyCheckbox checked={e.checked} onChangeForm={({ target: { checked, value } }) => {
                                                    let updatedList = simCard?.data.map(item => {
                                                        if (item._id === e._id) {
                                                            return { ...item, checked: checked };
                                                        }
                                                        return item;
                                                    });
                                                    setSimCard({ ...simCard, data: updatedList })
                                                }} /> */}
                                                <p className="text-sm-medium text-gray-light/900">{e.number}</p>
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            {(() => {
                                                switch (e.status) {
                                                    case 'In use':
                                                        return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                            <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-blue-light/500"></span>
                                                            <p className="text-xs-medium text-gray-light/700">In use</p>
                                                        </div>)
                                                    case 'Ready to use':
                                                        return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                            <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-success/500"></span>
                                                            <p className="text-xs-medium text-gray-light/700">Ready to use</p>
                                                        </div>)
                                                    case 'Permanent banned':
                                                        return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                            <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-error/500"></span>
                                                            <p className="text-xs-medium text-gray-light/700">Permanent banned</p>
                                                        </div>)
                                                    case 'Temporary banned':
                                                        return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                            <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-warning/500"></span>
                                                            <p className="text-xs-medium text-gray-light/700">Temporary banned</p>
                                                        </div>)
                                                    case 'Logout':
                                                        return (<div onClick={() => { handleCurrentModal('modal-add-sim-card', e.id) }} className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                            <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-warning/500"></span>
                                                            <p className="text-xs-medium text-gray-light/700">Logout</p>
                                                        </div>)
                                                    default:
                                                        return <></>;
                                                }
                                            })()}
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className='flex items-center'>
                                                <p className="text-sm-regular text-gray-light/600">{convertUsageLength(e.created_at)}</p>
                                            </div>
                                        </td>
                                        {/* <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-1">
                                                <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-blue/200 bg-blue/50">
                                                    <p className="text-xs-medium text-blue/700">217</p>
                                                    <ArrowsDown size={12} className={'text-blue/500'} stroke={'currentColor'} />
                                                </div>
                                                <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-brand/200 bg-brand/50">
                                                    <p className="text-xs-medium text-brand/700">217</p>
                                                    <ArrowsUp size={12} className={'text-brand/500'} stroke={'currentColor'} />
                                                </div>
                                                <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-warning/200 bg-warning/50">
                                                    <p className="text-xs-medium text-warning/700">217</p>
                                                    <HourGlass03 size={12} className={'text-warning/500'} stroke={'currentColor'} />
                                                </div>
                                            </div>
                                        </td> */}
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            {(e.user) &&
                                                <div className="flex items-center gap-3">
                                                    {/* <div className="w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src="" alt="" />
                                                    </div> */}
                                                    {e.user?.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src={e.user.full_path_photo} alt="" />
                                                    </div> : <div className="flex items-center justify-center bg-gray-light/100 w-10 h-10 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                                        <User01 size={24} className={'text-gray-light/500'} stroke="currentColor" />
                                                    </div>}
                                                    {/* <div className="flex items-center justify-center w-10 h-10 min-h-[40px] min-w-[40px] rounded-full border border-[#00000014] bg-gray-light/100">
                                                        <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                                                    </div> */}
                                                    <div className="flex flex-col">
                                                        <p className="text-sm-medium text-gray-light/900">{e?.user?.name}</p>
                                                        <p className="text-sm-regular text-gray-light/600">{e?.user?.email}</p>
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center justify-end gap-1">
                                                <button onClick={() => handleCurrentSlider({ status: true, current: 'slider-edit-sim-card' }, e)} className="my-button flex items-center p-2">
                                                    <Edit01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                                <button onClick={() => logoutEngine(e.id)} className="my-button flex items-center p-2">
                                                    <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SimpleBar>
                <div className="border-t border-gray-light/200">
                    <MyPagination meta={simCard?.meta} setParams={setParams} />
                </div>
            </div>
        </div>
    );
};

export default TableContainerRegistered;