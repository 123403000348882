// App.jsx
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LoginProvider } from './contexts/LoginContext';
import { DashboardProvider } from './contexts/DashboardContext';
import { DashboardAttemptProvider } from './contexts/DashboardAttemptContext';
import { ViolationProvider } from './contexts/ViolationContext';
import Login from './pages/Login';
import SimCard from './pages/SimCard';
import AuditTrial from './pages/AuditTrial';
import Message from './pages/Message';
import MyNavigation from './components/Navigation/MyNavigation';
import { SimCardProvider } from './contexts/SimCardContext';
import { AuditTrialProvider } from './contexts/AuditTrialContext';
import { MessageProvider } from './contexts/MessageContext';
import { useCookies } from 'react-cookie';
import TaskDistribution from './pages/TaskDistribution';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from './contexts/AppContext';
import NotFound from './pages/404';
import { TeamManagementProvider } from './contexts/TeamManagementContext';
import TeamManagement from './pages/TeamManagement';
import { SettingsProvider } from './contexts/SettingContext';
import Settings from './pages/Settings';
import { ProfileProvider } from './contexts/ProfileContext';
import Profile from './pages/Profile';
import { ForgotPasswordProvider } from './contexts/ForgotPasswordContext';
import ForgotPassword from './pages/ForgotPassword';
import PerformanceReport from './pages/Dashboard/Performance/PerformanceReport/performance_report';
import MostCompletedTasks from './pages/Dashboard/Performance/PerformanceReport/most_completed_tasks_user';
import MostCustomersHandledUser from './pages/Dashboard/Performance/PerformanceReport/most_customers_handled_user';
import MostAttemptsUsers from './pages/Dashboard/Performance/PerformanceReport/most_attempts_users';
import Violation from './pages/Dashboard/Violation';
import ViolationReport from './pages/Dashboard/Violation/ViolationReport/violation_report';
import TopRuleBreakers from './pages/Dashboard/Violation/ViolationReport/top_rule_breakers';
import TopNumberUsageByUser from './pages/Dashboard/Violation/ViolationReport/top_number_usage_by_user';
import TopViolatedRules from './pages/Dashboard/Violation/ViolationReport/top_violated_rules';
import TemplateMessage from './pages/TaskDistribution/Template/template';
import FAQ from './pages/TaskDistribution/FAQ/FAQ';
import { TaskDistributionProvider } from './contexts/TaksDistributionContext';
import Performance from './pages/Dashboard/Performance';
import 'react-date-range/dist/styles.css'; // file gaya utama
import 'react-date-range/dist/theme/default.css'; // file css tema
import Welcome from './pages/Welcome';


const App = () => {
  const [cookies, setCookie] = useCookies(['token']);
  return (
    <>
      <BrowserRouter>
        <ToastContainer containerId='default' autoClose={5000}
          enableMultiContainer hideProgressBar newestOnTop
          pauseOnFocusLoss draggable pauseOnHover closeOnClick
        />

        {(cookies.token) ?
          <AppProvider>
            <div className="relative flex w-full h-screen">
              <MyNavigation />
              <div className='flex-1 h-full'>
                <Routes>
                  <Route path="/" element={<Welcome />} />
                  <Route path="/attempt" element={<DashboardAttemptProvider><Performance /></DashboardAttemptProvider>} />
                  <Route path="/performance-report" element={<DashboardProvider><PerformanceReport /></DashboardProvider>} />
                  <Route path="/most-completed-tasks" element={<DashboardProvider><MostCompletedTasks /></DashboardProvider>} />
                  <Route path="/most-customers-handle-user" element={<DashboardProvider><MostCustomersHandledUser /></DashboardProvider>} />
                  <Route path="/most-attempts-user" element={<DashboardProvider><MostAttemptsUsers /></DashboardProvider>} />
                  <Route path="/violation" element={<DashboardProvider><Violation /></DashboardProvider>} />
                  <Route path="/violation-report" element={<ViolationProvider><ViolationReport /></ViolationProvider>} />
                  <Route path="/top-rule-breakers" element={<ViolationProvider><TopRuleBreakers /></ViolationProvider>} />
                  <Route path="/top-number-usege-by-user" element={<ViolationProvider><TopNumberUsageByUser /></ViolationProvider>} />
                  <Route path="/top-violated-rules" element={<ViolationProvider><TopViolatedRules /></ViolationProvider>} />

                  <Route path="/task-distribution" element={<TaskDistributionProvider><TaskDistribution /></TaskDistributionProvider>} />
                  <Route path="/task-distribution/FAQ" element={<FAQ />} />
                  <Route path="/task-distribution/template-message" element={<TemplateMessage />} />

                  <Route path="/sim-card" element={<SimCardProvider><SimCard /></SimCardProvider>} />
                  <Route path="/message" element={<MessageProvider><Message /></MessageProvider>} />
                  <Route path="/audit-trail" element={<AuditTrialProvider><AuditTrial /></AuditTrialProvider>} />
                  <Route path="/team-management" element={<TeamManagementProvider><TeamManagement /></TeamManagementProvider>} />
                  <Route path="/settings" element={<SettingsProvider><Settings /></SettingsProvider>} />
                  <Route path="/profile" element={<ProfileProvider><Profile /></ProfileProvider>} />

                  <Route path="/login" element={<Navigate to="/" replace />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
              </div>
            </div>
          </AppProvider>
          :
          <Routes>
            <Route path="/login" element={<LoginProvider><Login /></LoginProvider>} />
            <Route path="/forgot-password" element={<ForgotPasswordProvider><ForgotPassword /></ForgotPasswordProvider>} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>

        }
      </BrowserRouter>
    </>
  );
};

export default App;