import React, { } from "react";
import { Button, Popover } from "@mui/material";
import MyIconPatternDecorative from "../../../components/Icon/MyIconPatternDecorative";
import { Plus, XClose } from "untitledui-js/icons/general";
import { Eraser } from "untitledui-js/icons/editor";

const MySorted = ({ id, target }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <>
            {target && target(open, handleClick)}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                sx={{
                    '&.MuiPopover-root .MuiPaper-root': {
                        marginTop: '8px',
                        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                        border: '1px solid #EAECF0',
                        borderRadius: '12px',
                        width: 'max-content',
                    }
                }}
            >
                <div className="flex flex-col w-[569px] h-max">
                    <div className="p-6 pb-5">
                        <MyIconPatternDecorative >
                            <p className="text-sm-regular text-gray-light/600">Sort by</p>
                        </MyIconPatternDecorative>
                    </div>
                    <div className="px-6 flex flex-col gap-y-4">
                        <section className="flex items-center gap-x-4">
                            <div className="flex-1">

                            </div>
                            <button className="p-3">
                                <XClose size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                            </button>
                        </section>
                        <section className="flex items-center gap-x-4">
                            <div className="flex-1">

                            </div>
                            <button className="p-3">
                                <XClose size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                            </button>
                        </section>
                        <section className="flex items-center justify-between p-6 pt-8">
                            <button className="flex items-center gap-2">
                                <Plus size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                <p className="text-md-semibold text-gray-light/600">Add sort</p>
                            </button>
                            <button className="flex items-center gap-2">
                                <Eraser size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                <p className="text-md-semibold text-gray-light/600">Clear</p>
                            </button>
                        </section>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default MySorted;