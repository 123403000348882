import React, { } from "react";
import { FilterLines, Plus, UploadCloud02 } from "untitledui-js/icons/general";
import MyTextField from "../../components/TextField/MyTextField";
import { SwitchVertical01 } from "untitledui-js/icons/arrow";
import MyCheckbox from "../../components/Checkbox/MyCheckbox";
import MyPagination from "../../components/Pagination/MyPagination";
import SimpleBar from "simplebar-react";
import { useSimCard } from "../../contexts/SimCardContext";

const TableContainerAppeal = () => {
    const { handleCurrentSlider } = useSimCard();

    return (
        <>
            <div className="px-8 w-full">
                <div className="w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                    <div className="py-5 px-6 flex gap-4">
                        <div className="flex-1 flex-col gap-1">
                            <p className="text-lg-semibold text-gray-light/900">List of registered appeal</p>
                            <p className="text-sm-regular text-gray-light/600">Here is the list of registered appeal number.</p>
                        </div>
                        <button className="h-max bg-brand/600 flex items-center rounded-lg px-3 py-2 gap-x-1 text-white">
                            <Plus size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">SIM card</p>
                        </button>
                    </div>
                    <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                        <div className="w-full max-w-xs">
                            <MyTextField placeholder={'Search'} />
                        </div>
                        <button className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                            <FilterLines size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Filter</p>
                        </button>
                        <button className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                            <SwitchVertical01 size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Sort</p>
                        </button>
                    </div>
                    <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                        <table className='table border-collapse w-full'>
                            <thead className='border-t border-gray-light/200 p-0'>
                                <tr className='p-0'>
                                    <th className='px-6 py-3 text-left'>
                                        <div className="flex items-center gap-3">
                                            {/* <MyCheckbox /> */}
                                            <p className="text-xs-medium text-gray-light/600">
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Number</p>
                                            </p>
                                        </div>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Banned / unbanned time</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Appeal status</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Banned length</p>
                                    </th>
                                    <th className='px-6 py-3 text-left'>
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Appeal by</p>
                                    </th>
                                    <th className='px-6 py-3'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex items-center gap-3">
                                                        {/* <MyCheckbox /> */}
                                                        <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">6287923456789</p>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm-regular text-gray-light/900 whitespace-nowrap">28 May 2023 • 11:41am</p>
                                                        <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">28 May 2023 • 11:41am</p>
                                                    </div>
                                                </td>

                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    {/* <div className="w-max py-0.5 px-2 bg-blue-light/50 border border-blue-light/300 rounded-full">
                                                        <p className="text-xs-medium text-blue-light/700">In use</p>
                                                    </div>
                                                    <div className="w-max py-0.5 px-2 bg-success/50 border border-success/300 rounded-full">
                                                        <p className="text-xs-medium text-success/700">Ready to use</p>
                                                    </div>
                                                    <div className="w-max py-0.5 px-2 bg-error/50 border border-error/300 rounded-full">
                                                        <p className="text-xs-medium text-error/700">Temporary banned</p>
                                                    </div> */}
                                                    <div className="w-max py-0.5 px-2 bg-warning/50 border border-warning/200 rounded-full">
                                                        <p className="text-xs-medium text-warning/700 whitespace-nowrap">New</p>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">0 day</p>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex items-center gap-3">
                                                        <div className="w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                            <img className="w-full h-full object-cover" src="" alt="" />
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-sm-medium text-gray-light/900 whitespace-nowrap">Endang</p>
                                                            <p className="text-sm-regular text-gray-light/600 whitespace-nowrap">adi.sumito@verify.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                    <div className="flex items-center justify-end gap-1">
                                                        <button onClick={() => handleCurrentSlider({ status: true, current: 'slider-upload-proof' }, null)} className="flex items-center gap-1 px-3 py-2">
                                                            <UploadCloud02 size={20} className={'text-brand/700'} stroke={'currentColor'} />
                                                            <p className="text-sm-semibold text-brand/700 whitespace-nowrap">Upload appeal proof</p>
                                                        </button>
                                                        {/* <button className="flex items-center p-2">
                                                        <Edit01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                    </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </SimpleBar>
                    <div className="border-t border-gray-light/200">
                        <MyPagination />
                    </div>
                </div>

            </div>
        </>
    );
};

export default TableContainerAppeal;