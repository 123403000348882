import React, { useEffect, useState } from 'react'
import { useMessage } from '../../../contexts/MessageContext';
import { User01 } from 'untitledui-js/icons/users';
import moment from 'moment';

const AttemptHistory = ({ addNew }) => {
    const [history, setHistory] = useState([])
    const { attemptHistory } = useMessage();
    
    useEffect(() => {
        attemptHistory(setHistory)
    }, [])
    return (
        <div className="flex flex-col gap-6 pt-2">
            <div className="flex px-6 gap-6">
                <div className="flex-1">
                    <p className="text-sm-semibold text-gray-light/700">Attempt history</p>
                    <p className="text-sm-regular text-gray-light/600">Record of past interactions.</p>
                </div>
                {addNew && addNew(() => attemptHistory(setHistory))}
            </div>
            <div className='flex flex-col'>
                <div className="bg-gray-light/50 py-3 px-6 border-t border-gray-light/200">
                    <p className="text-xs-medium text-gray-light/600">Interaction by</p>
                </div>
                <div className={!addNew ? 'h-36 overflow-auto' : ''}>
                    {history.map((e, i) => {
                        return (
                            <div key={i} className="py-4 px-6 flex gap-3  border-t border-gray-light/200">
                                {e.user?.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                    <img className="w-full h-full object-cover" src={e.user.full_path_photo} alt="" />
                                </div> : <div className="flex items-center justify-center bg-gray-light/100 w-10 h-10 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                    <User01 size={24} className={'text-gray-light/500'} stroke="currentColor" />
                                </div>}
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <p className="text-sm-medium text-gray-light/900">{e.user.name}</p>
                                        <p className="text-sm-regular text-gray-light/600">{moment(e.created_at).format('D MMM YYYY • hh:mma')}</p>
                                    </div>
                                    <div className="w-max border border-gray-light/300 rounded-md shados-shadows/shadow-xs py-0.5 px-1.5">
                                        <p className="text-xs-medium text-gray-light/700">{e.attempt}</p>
                                    </div>
                                    <p className="text-sm-regular text-gray-light/600">{e.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AttemptHistory