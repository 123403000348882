import React, { } from "react";
import SimpleBar from "simplebar-react";
import { ArrowLeft } from "untitledui-js/icons/arrow";

const TemplateMessage = () => {
    return (
        <SimpleBar forceVisible="y" className="flex-1" style={{ height: '100vh' }}>
            <main className="w-full h-full pt-8 pb-12 flex flex-col gap-8 bg-white">
                <header className="px-8 flex flex-col gap-y-6">
                    <button className="flex items-center gap-1.5 text-brand/700">
                        <ArrowLeft size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Back to Task Distribution</p>
                    </button>
                    <div className="flex flex-col gap-y-1">
                        <p className="display-sm-semibold text-gray-light/900">Template message</p>
                        <p className="text-md-regular text-gray-light/600">Guide for creating template messages in the "Chat" feature.</p>
                    </div>

                </header>
                <div className="flex-1">
                    <div className="flex flex-col gap-y-8 px-8">
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Apa itu template message?</p>
                            <p className="text-md-regular text-gray-light/600">Template message adalah pesan yang telah dibuat sebelumnya dan dapat digunakan berulang kali untuk efisiensi dan konsistensi dalam komunikasi. Anda dapat mengirim template message ini dengan menekan tombol pada fitur chat sebagaimana gambar berikut:</p>
                            <div className="pb-6">
                                <img src="" alt="" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Apa itu available field?</p>
                            <p className="text-md-regular text-gray-light/600">Available field adalah kumpulan kolom yang terdaftar pada database yang telah Anda hubungkan. Sebagai contoh, apabila Anda menghubungkan database dengan daftar kolom sebagai berikut:</p>
                            <div className="pb-6">
                                <img src="" alt="" />
                            </div>
                            <p className="text-md-regular text-gray-light/600">Maka daftar field yang akan muncul pada available field adalah sebagai berikut:</p>
                            <div className="pb-6">
                                <img src="" alt="" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Bagaimana cara menghubungkan database yang ingin saya gunakan dalam pembuatan template message?</p>
                            <p className="text-md-regular text-gray-light/600">Tekan tombol berikut yang terdapat pada tampilan add template message, setelah itu pilih daftar database yang terdapat pada riwayat uploaded plan yang ingin Anda hubungkan.</p>
                            <div className="pb-6">
                                <img src="" alt="" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Apa itu shortcut?</p>
                            <p className="text-md-regular text-gray-light/600">Shortcut adalah cara cepat untuk memudahkan pengguna mengaktifkan template pesan yang ingin dikirim, sehingga pengguna tidak perlu memilih secara manual, melainkan dapat langsung mengaktifkannya dengan menekan tombol pada shortcut yang telah ditentukan.</p>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Apa itu body message</p>
                            <p className="text-md-regular text-gray-light/600">Body message adalah konten dari pesan yang akan dikirim.</p>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Bagaimana cara saya menghubungkan informasi pada body message dengan field pada database?</p>
                            <p className="text-md-regular text-gray-light/600">{'Untuk mengaitkan informasi dengan melihat kolom yang terdaftar pada database, Anda harus menggunakan format <available field>, sehingga sistem dapat mengetahui bahwa Anda ingin merujuk informasi tersebut ke kolom yang mana. Sebagai contoh:'}</p>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-md-medium text-gray-light/900">Bagaimana cara saya menghubungkan informasi pada body message dengan field pada database?</p>
                            <p className="text-md-regular text-gray-light/600">{'Untuk mengaitkan informasi dengan melihat kolom yang terdaftar pada database, Anda harus menggunakan format <available field>, sehingga sistem dapat mengetahui bahwa Anda ingin merujuk informasi tersebut ke kolom yang mana. Sebagai contoh:'}</p>
                        </div>

                        <div className="p-8 rounded-2xl flex items-center justify-between bg-gray-light/50">
                            <div className="flex flex-col gap-1">
                                <p className="text-xl-semibold text-gray-light/900">Still have questions?</p>
                                <p className="text-lg-regular text-gray-light/600">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                            </div>
                            <button className="flex items-center text-white px-[18px] py-3 bg-brand/600 rounded-lg shadow-shadows/shadow-xs">
                                Get in touch
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </SimpleBar>
    );
};

export default TemplateMessage;