import React, { createContext, useContext, useState } from "react";
import HttpRequests from "../services/ApiServiceHttp";
import { notify } from "../helpers/Helpers";
import { useApp } from "./AppContext";

const TeamManagementContext = createContext();
const TeamManagementProvider = props => {
    const {session} = useApp()
    const [params, setParams] = useState({ page: 1 });
    const [teams, setTeams] = useState();
    const [data, setData] = useState();
    const [currentSlider, setCurrentSlider] = useState({ status: false, current: null });
    const handleCurrentSlider = (slider, data) => {
        if (slider && slider.status) {
            switch (slider.current) {
                case 'slider-add-new-team':
                    setData(data);
                    break;
                default:
                    break;
            }

            setCurrentSlider({ status: slider.status, current: slider.current })
        } else {
            setCurrentSlider({ status: false, current: null })
        }
    }

    const searchTeamUser = async (e) => {
        return await HttpRequests.searchTeam({ search: e?.target?.value,grade:session?.role?.grade }).then(e => e.data).catch(notify)
    }

    const getTeam = async () => {
        return await HttpRequests.getTeam(params).then(setTeams).catch(notify)
    }

    const addTeam = async (teams) => {
        return await HttpRequests.addTeam({ teams })
            .then(notify)
            .then(() => setCurrentSlider({ status: false, current: null }))
            .then(getTeam)
            .catch(notify)
    }

    const removeTeam = async (id) => {
        var valid = window.confirm('Anda yakin ingin menghapus team ini?')
        if (!valid) return;
        return await HttpRequests.removeTeam(id).then(notify).then(getTeam).catch(notify)
    }
    return <TeamManagementContext.Provider value={{
        currentSlider, setCurrentSlider, handleCurrentSlider,
        data, setData, params, setParams,
        searchTeamUser, getTeam, teams, setTeams, removeTeam, addTeam
    }}> {props.children}</TeamManagementContext.Provider >;
};

const useTeamManagement = () => {
    const context = useContext(TeamManagementContext);
    if (context === undefined) {
        throw new Error('userTeamManagement must be used within a TeamManagementProvider')
    }
    return context;
}

export { TeamManagementProvider, useTeamManagement };