import React, { useEffect } from "react";
import { Logout04, XClose } from "untitledui-js/icons/general";
import { SimCard } from "untitledui-js/icons/media-devices";
import MyTextField from "../../../components/TextField/MyTextField";
import { ArrowsDown, ArrowsUp, ChevronDown } from "untitledui-js/icons/arrow";
import { HourGlass03 } from "untitledui-js/icons/time";
import { useSimCard } from "../../../contexts/SimCardContext";
import { formatDate } from "../../../helpers/Helpers";
import MyAutocomplete from "../../../components/Autocomplete/MyAutocomplete";
import MyAutocompleteV3 from "../../../components/Autocomplete/MyAutocompleteV3";
import SimpleBar from 'simplebar-react';
import { User01 } from "untitledui-js/icons/users";

const SliderEditSimCard = () => {
    const { data, handleCurrentSlider, getUser, users, assignNumber, logoutEngine } = useSimCard();
    useEffect(() => {
        getUser()
    }, [])
    const convertUsageLength = (date) => {
        const timestamp = new Date(date);
        const currentTime = new Date();
        const timeDifference = (currentTime - timestamp) / (1000 * 60 * 60); // Dalam jam

        if (timeDifference < 1) {
            const minutes = Math.round(timeDifference * 60);
            return `${minutes} min`;
        } else if (timeDifference < 24) {
            const hours = Math.round(timeDifference);
            return `${hours} hr`;
        } else if (timeDifference < 720) {
            const days = Math.round(timeDifference / 24);
            return `${days} days`;
        } else if (timeDifference < 8760) {
            const months = Math.round(timeDifference / (24 * 30));
            return `${months} months`;
        } else {
            const years = Math.round(timeDifference / (24 * 365));
            return `${years} years`;
        }
    };
    return (
        <div className="w-[400px] h-screen flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} onClick={() => handleCurrentSlider({ status: false, current: null })} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <SimCard size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">Edit SIM card</p>
                    <p className="text-sm-regular text-gray-light/600">Details SIM card information.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <div className="flex flex-col gap-6 h-full">
                        <div className="flex-1 flex flex-col gap-6">
                            <div className="px-6 flex flex-col gap-5">
                                <section className="flex flex-col gap-y-1.5">
                                    <label htmlFor="" className="text-sm-medium text-gray-light/700">Number</label>
                                    <MyTextField value={(data?.number ?? '').toString()} placeholder={'Enter your number'} disabled={true} />
                                </section>
                                <section className="flex flex-col gap-y-1.5">
                                    <label htmlFor="" className="text-sm-medium text-gray-light/700">Assigned to</label>
                                    <MyAutocompleteV3 options={users} value={data?.user} placeholder={'Enter your user'}
                                        getOptionLabel={(option) => option.name}
                                        filterOptions={(option, inputValue) => option.email.trim().toLowerCase().includes(inputValue) ||
                                            option.name.trim().toLowerCase().includes(inputValue)}
                                        onChange={(e, value) => {
                                            if (window.confirm('Anda yakin?'))
                                                assignNumber(value)
                                        }}
                                        onInputChange={({ target: { value } }) => getUser(value)}
                                        renderOption={(option) => <div className="w-full flex items-center gap-x-2">
                                            {option?.photo ? <div className="border border-[#00000014] w-6 h-6 min-h-[24px] min-w-[24px] rounded-full overflow-hidden">
                                                <img className="w-full h-full object-cover" src={option.full_path_photo} alt="" />
                                            </div> : <div className="flex items-center justify-center bg-gray-light/100 w-6 h-6 min-h-[24px] min-w-[24px] border border-[#00000014] rounded-full overflow-hidden">
                                                <User01 size={16} className={'text-gray-light/500'} stroke="currentColor" />
                                            </div>}
                                            <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option.name}</p>
                                            <p className="text-md-regular text-gray-light/600 line-clamp-1">{option.email}</p>
                                        </div>}
                                    />
                                    {/* <MyAutocomplete placeholder={'Search user'}
                                        options={users} value={data?.user}
                                        onChange={(e, value) => {
                                            if (window.confirm('Anda yakin?'))
                                                assignNumber(value)
                                        }}
                                        onInputChange={(e) => {
                                            getUser(e?.target?.value);
                                        }}
                                        filterOptions={(option, inputValue) => option.email.trim().toLowerCase().includes(inputValue) ||
                                            option.name.trim().toLowerCase().includes(inputValue)}
                                        getOptionLabel={(option => option.email)}
                                        renderOption={(option) => option ? (
                                            <div className="w-full flex items-center gap-x-2">
                                                 {option?.photo ? <div className="border border-[#00000014] w-6 h-6 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src={option.full_path_photo} alt="" />
                                                    </div> : <div className="flex items-center justify-center bg-gray-light/100 w-6 h-6 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                                                        <User01 size={24} className={'text-gray-light/500'} stroke="currentColor" />
                                                    </div>}
                                                {/* <div className="flex items-center justify-center w-6 h-6 min-w-[24px] min-h-[24px] rounded-full border border-[#00000014] bg-gray-light/100">
                                                    <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                                                </div>
                                                <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option.name}</p>
                                                <p className="text-md-regular text-gray-light/600 line-clamp-1">{option.email}</p>
                                            </div>
                                        ) : (<>Select User</>)}
                                    /> */}
                                </section>
                            </div>
                            <div className="px-6 pt-2 flex flex-col gap-4">
                                <hr className="border-gray-light/200" />
                                <section className="flex items-center justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Status</p>
                                    {(() => {
                                        switch (data?.status) {
                                            case 'In use':
                                                return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                    <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-blue-light/500"></span>
                                                    <p className="text-xs-medium text-gray-light/700">In use</p>
                                                </div>)
                                            case 'Ready to use':
                                                return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                    <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-success/500"></span>
                                                    <p className="text-xs-medium text-gray-light/700">Ready to use</p>
                                                </div>)
                                            case 'Permanent banned':
                                                return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                    <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-error/500"></span>
                                                    <p className="text-xs-medium text-gray-light/700">Permanent banned</p>
                                                </div>)
                                            case 'Temporary banned':
                                                return (<div className="w-max py-0.5 px-1.5 flex items-center gap-x-1 border border-gray-light/300 rounded-md">
                                                    <span className="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full bg-warning/500"></span>
                                                    <p className="text-xs-medium text-gray-light/700">Temporary banned</p>
                                                </div>)
                                            default:
                                                return <></>;
                                        }
                                    })()}
                                </section>
                                {/* <div className="flex items-center justify-between">
                                <p className="text-sm-medium text-gray-light/700">Messaging activitiy</p>
                                <section className="flex items-center justify-center gap-1.5">
                                    <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-blue/200 bg-blue/50">
                                        <p className="text-xs-medium text-blue/700">217</p>
                                        <ArrowsDown size={12} className={'text-blue/500'} stroke={'currentColor'} />
                                    </div>
                                    <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-brand/200 bg-brand/50">
                                        <p className="text-xs-medium text-brand/700">217</p>
                                        <ArrowsUp size={12} className={'text-brand/500'} stroke={'currentColor'} />
                                    </div>
                                    <div className="flex items-center gap-1 py-0.5 pl-2 pr-1.5 rounded-full border border-warning/200 bg-warning/50">
                                        <p className="text-xs-medium text-warning/700">217</p>
                                        <HourGlass03 size={12} className={'text-warning/500'} stroke={'currentColor'} />
                                    </div>
                                </section>
                            </div> */}
                                <section className="flex items-center justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Registered date</p>
                                    <p className="text-sm-regular text-gray-light/600">{formatDate(data?.created_at)}</p>
                                </section>
                                <section className="flex items-center justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Usage length</p>
                                    <p className="text-sm-regular text-gray-light/600">{convertUsageLength(data?.created_at)}</p>
                                </section>
                                <section className="flex items-center justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Registered by</p>
                                    <p className="text-sm-regular text-gray-light/600">{data?.creator?.email}</p>
                                </section>
                                <hr className="border-gray-light/200" />
                            </div>
                            {/* <div className="px-6 flex flex-col gap-y-6">
                            <section className="flex flex-col gap-y-5">
                                <div className="flex flex-col gap-y-1">
                                    <p className="text-lg-semibold text-gray-light/900">Activity history</p>
                                    <p className="text-sm-regular text-gray-light/600">You’re currently on these teams.</p>
                                </div>
                                <hr className="border-gray-light/200" />
                            </section>
                            <div className="flex flex-col gap-y-6">
                                {[1, 2, 3, 4, 5].map((e, i) => {
                                    return (<section key={i} className="flex items-center gap-x-3">
                                        <div className="w-8 h-8 min-h-[32px] min-w-[32px] rounded-full overflow-hidden">
                                            <img className="w-full h-full object-cover" src="" alt="" />
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-sm-medium text-gray-light/900">Bayu Purnama</p>
                                            <p className="text-sm-regular text-gray-light/600">Registered on Mar 2022</p>
                                        </div>
                                    </section>);
                                })}
                            </div>
                        </div> */}
                        </div>
                        <div className="border-t border-gray-light/200 py-4 px-6 flex items-center justify-end gap-x-3">
                            <button onClick={() => logoutEngine(data.id)} className="px-3 py-2 flex items-center gap-x-1">
                                <Logout04 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                <p className="text-sm-semibold text-gray-light/600">Logout</p>
                            </button>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default SliderEditSimCard;