import React, { useCallback } from "react";
import Dropzone, { useDropzone } from 'react-dropzone'
import { UploadCloud02 } from "untitledui-js/icons/general";

const MyDropzone = ({ children, accept, onDrop, onDropAccepted, onDropRejected, maxSize = 5242880 }) => {
    return (
        <>
            <Dropzone maxSize={maxSize} onDrop={onDrop} onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} accept={accept} >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <section className={`${isDragReject ? 'border-2 border-error/600 cursor-not-allowed' : isDragActive ? 'border-2 border-brand/600 cursor-pointer' : 'border border-gray-light/200 cursor-pointer'} w-full flex flex-col items-center gap-y-3 px-6 py-4 rounded-xl`}>
                                <div className="w-max block p-2.5 border border-gray-light/200 shadow-shadows/shadow-xs rounded-lg">
                                    <UploadCloud02 size={20} className={'text-gray-light/700'} stroke={'currentColor'} />
                                </div>
                                <div className="flex flex-col items-center text-center gap-1">
                                    <p className={`text-sm-semibold ${isDragReject ? 'text-error/700' : ' text-brand/700'}`}>Click to upload</p>
                                    <p className="text-xs-regular text-gray-light/600">XLSX, CSV (max. 5MB)</p>
                                </div>
                            </section>
                        </div>
                    )
                }}
            </Dropzone >
        </>
    );
};


export default MyDropzone;
