export const WhatsApp = ({ size = 24 }) => <span dangerouslySetInnerHTML={{
    __html: `<svg width="${size}" height="${size}" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17.4375C13.3492 17.4375 16.875 13.9117 16.875 9.5625C16.875 5.21326 13.3492 1.6875 9 1.6875C4.65076 1.6875 1.125 5.21326 1.125 9.5625C1.125 10.9749 1.49681 12.3004 2.14789 13.4465L1.125 17.4375L5.23961 16.4834C6.35702 17.0918 7.63814 17.4375 9 17.4375ZM9 16.226C12.6801 16.226 15.6635 13.2426 15.6635 9.5625C15.6635 5.88237 12.6801 2.89904 9 2.89904C5.31987 2.89904 2.33654 5.88237 2.33654 9.5625C2.33654 10.9834 2.78128 12.3004 3.53916 13.382L2.94231 15.6202L5.21996 15.0508C6.29402 15.7919 7.59632 16.226 9 16.226Z" fill="#BFC8D0"/>
    <path d="M15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C7.57809 15.75 6.25887 15.3103 5.17087 14.5596L2.86364 15.1364L3.46824 12.8691C2.70052 11.7735 2.25 10.4394 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9Z" fill="url(#paint0_linear_9350_36525)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.875C13.3492 16.875 16.875 13.3492 16.875 9C16.875 4.65076 13.3492 1.125 9 1.125C4.65076 1.125 1.125 4.65076 1.125 9C1.125 10.4124 1.49681 11.7379 2.14789 12.884L1.125 16.875L5.23961 15.9209C6.35702 16.5293 7.63814 16.875 9 16.875ZM9 15.6635C12.6801 15.6635 15.6635 12.6801 15.6635 9C15.6635 5.31987 12.6801 2.33654 9 2.33654C5.31987 2.33654 2.33654 5.31987 2.33654 9C2.33654 10.4209 2.78128 11.7379 3.53916 12.8195L2.94231 15.0577L5.21996 14.4883C6.29402 15.2294 7.59632 15.6635 9 15.6635Z" fill="white"/>
    <path d="M7.03126 5.34353C6.84403 4.96746 6.5568 5.00075 6.26663 5.00075C5.74805 5.00075 4.93945 5.62191 4.93945 6.77795C4.93945 7.72538 5.35694 8.76249 6.76373 10.3139C8.1214 11.8112 9.90528 12.5857 11.3862 12.5593C12.8672 12.5329 13.1719 11.2585 13.1719 10.8282C13.1719 10.6374 13.0535 10.5422 12.972 10.5164C12.4673 10.2742 11.5365 9.82287 11.3247 9.73807C11.1129 9.65328 11.0023 9.76797 10.9336 9.83036C10.7415 10.0134 10.3608 10.5528 10.2305 10.6741C10.1001 10.7954 9.90578 10.734 9.82491 10.6882C9.52731 10.5687 8.7204 10.2098 8.0772 9.58632C7.28174 8.81522 7.23506 8.54993 7.08519 8.31377C6.9653 8.12485 7.05328 8.00893 7.09718 7.95828C7.26857 7.76053 7.50522 7.45522 7.61134 7.30349C7.71747 7.15177 7.63322 6.92142 7.58266 6.77795C7.36525 6.16092 7.18105 5.64439 7.03126 5.34353Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_9350_36525" x1="14.9062" y1="3.9375" x2="2.25" y2="15.75" gradientUnits="userSpaceOnUse">
    <stop stop-color="#5BD066"/>
    <stop offset="1" stop-color="#27B43E"/>
    </linearGradient>
    </defs>
    </svg>
`}} />