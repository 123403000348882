import React from "react";
import { MessageChatSquare } from "untitledui-js/icons/communication";
import { ClipboardCheck } from "untitledui-js/icons/files";
import { HomeSmile, Logout01, Settings01 } from "untitledui-js/icons/general"
import { SimCard } from "untitledui-js/icons/media-devices";
import { FileShield01, FileShield02 } from "untitledui-js/icons/security";
import { User01 } from "untitledui-js/icons/users";
import { setCookie } from "../../services/NetworkUtils";
import { useApp } from "../../contexts/AppContext"
import { TerminalSquare } from "../../contants/icons/TerminalSquare";
import MyTooltip from "../Tooltip/MyTooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { VerifyLogo } from "../../contants/icons/MyIcon/VerifyLogo";

const MyNavigation = ({ childs }) => {
    let navigate = useNavigate();
    const location = useLocation();
    const { logoutLocal, session } = useApp()
    const logout = async () => {
        setCookie('token', null, '-1');
        logoutLocal()
        window.location.href = '/login'
    }
    const changePage = (name) => {
        navigate(name)
        // window.location.href = name
    }
    const checkPermission = (name) => {
        return session?.my_access?.includes(name)
    }
    return (
        <>
            <div className="h-screen w-20 min-w-[80px] flex flex-col justify-between border-r border-gray-light/200">
                <div className="flex flex-col w-full items-center gap-y-6 pt-8">
                    <div className="px-5">
                        <div className="w-8 h-8 min-w-[32px] overflow-hidden">
                            <VerifyLogo size={32} />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        {checkPermission('dashboard') && <Link to={'/attempt'}>
                            <MyTooltip placement="right" target={<div className={`${location?.pathname === '/attempt' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                                <HomeSmile size={24} stroke={'currentColor'} />
                            </div>}><p className="text-white text-xs-semibold">Dashboard</p>
                            </MyTooltip>
                        </Link>}
                        {checkPermission('dashboard') && <MyTooltip placement="right" target={<div onClick={() => changePage('/violation')} className={`${location?.pathname === '/violation' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <FileShield02 size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">Conversation monitoring</p>
                        </MyTooltip>}
                        {checkPermission('team') && <MyTooltip placement="right" target={<div onClick={() => changePage('/team-management')} className={`${location?.pathname === '/team-management' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <User01 size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">Team management</p>
                        </MyTooltip>}
                        {checkPermission('task-distribution') && <MyTooltip placement="right" target={<div onClick={() => changePage('/task-distribution')} className={`${location?.pathname === '/task-distribution' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <ClipboardCheck size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">Task distribution</p>
                        </MyTooltip>}
                        {checkPermission('sim-card') && <MyTooltip placement="right" target={<div onClick={() => changePage('/sim-card')} className={`${location?.pathname === '/sim-card' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <SimCard size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">SIM card</p>
                        </MyTooltip>}
                        {checkPermission('chat') && <MyTooltip placement="right" target={<div onClick={() => changePage('/message')} className={`${location?.pathname === '/message' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <MessageChatSquare size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">Message</p>
                        </MyTooltip>}
                    </div>
                </div>
                <div className="flex flex-col w-full items-center gap-y-6 pb-6">
                    <div className="flex flex-col gap-y-2">
                        {checkPermission('audit-trail') && <MyTooltip placement="right" target={<div onClick={() => changePage('/audit-trail')} className={`${location?.pathname === '/audit-trail' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <TerminalSquare size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">Audit trail</p>
                        </MyTooltip>}
                        {checkPermission('setting') && <MyTooltip placement="right" target={<div onClick={() => changePage('/settings')} className={`${location?.pathname === '/settings' ? 'bg-gray-light/50 text-gray-light/700' : 'text-gray-light/500'} cursor-pointer rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center`}>
                            <Settings01 size={24} stroke={'currentColor'} />
                        </div>}>
                            <p className="text-white text-xs-semibold">Settings</p>
                        </MyTooltip>}
                        <MyTooltip placement="right" target={<button onClick={logout} className="text-gray-light/500 rounded-md w-12 min-w-[48px] h-12 flex items-center justify-center">
                            <Logout01 size={24} stroke={'currentColor'} />
                        </button>}>
                            <p className="text-white text-xs-semibold">Logout</p>
                        </MyTooltip>
                    </div>
                    {session?.photo ? <div className="border border-[#00000014] w-12 h-12 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                        <img className="w-full h-full object-cover" src={session.full_path_photo} alt="" />
                    </div> : <div className="flex items-center justify-center bg-gray-light/100 w-12 h-12 min-h-[40px] min-w-[40px] border border-[#00000014] rounded-full overflow-hidden">
                        <User01 size={24} className={'text-gray-light/500'} stroke="currentColor" />
                    </div>}
                    {/* <div className="w-12 min-w-[48px] h-12 rounded-full overflow-hidden">
                        <img className="w-full h-full" src="" alt="" />
                    </div> */}
                </div>
            </div>
            {childs}
        </>
    );
};

export default MyNavigation;