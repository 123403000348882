import moment from 'moment';
import React from 'react';
import { User01 } from 'untitledui-js/icons/users';

const MessageReceiverBox = ({ message, customer, maxWidth = "508px", targetRef }) => {
    var user = message.sender ? message.sender : customer;
    const parsedDate = moment(message?.send_time ?? message?.created_at);
    const formattedDate = parsedDate.format('HH:mma');

    return (
        <div id={message.id} aria-label={message.last ? 'last' : ''} className="flex justify-start w-full message-box">
            <div className={`${message.isSameUser && message.isLastSameUser ? 'items-end' : 'items-end'} flex gap-3`}>
                <div className='w-10 h-10 min-w-[40px] min-h-[40px]'>
                    {((!message.isSameUser && !message.isFirstSameUser) || message.isLastSameUser) &&
                        <div className="bg-gray-light/100 flex justify-center items-center w-full h-full rounded-full border border-[#00000014]">
                            <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                        </div>
                    }
                </div>
                <div className={`${message.isSameUser ? message.isLastSameUser ? 'rounded-lg rounded-bl-none' : 'rounded-lg' : 'rounded-lg rounded-bl-none'} flex flex-col gap-2 w-full px-[14px] py-2.5 bg-white border border-gray-light/200 message-text`} style={{ maxWidth: maxWidth }}>
                    {(!message.isSameUser || message.isFirstSameUser) && <div className='w-full overflow-hidden'>
                        <p className="text-sm-medium text-gray-light/700 break-words line-clamp-1">{user?.name}</p>
                    </div>}
                    {message.attachment && <div className='rounded-lg rounded-tl-none overflow-hidden'>
                        <img onClick={() => {
                            var image = new Image();
                            image.src = `data:${message.attachment.mimetype};base64,${message.attachment.data}`

                            var w = window.open("");
                            w.document.write(image.outerHTML);
                        }} loading='lazy' className='h-full w-full object-contain' src={`data:${message.attachment.mimetype};base64,${message.attachment.data}`}></img>
                    </div>}
                    <div className='flex gap-4 items-end w-full'>
                        <div className='flex-1 overflow-hidden'>
                            <p className="text-md-regular text-gray-light/900 break-words line-clamp-10 message hidden">{message.text}</p>
                            <p className="text-md-regular text-gray-light/900 break-words line-clamp-10 message-preview">{message.text}</p>
                        </div>
                        <div>
                            <p className="text-xs-regular text-gray-light/600">{formattedDate}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="flex items-start gap-3">
                <div className="bg-gray-light/100 flex justify-center items-center w-10 h-10 min-w-[40px] min-h-[40px] rounded-full border border-[#00000014]">
                    <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                </div>
                <div className="flex flex-col gap-1.5 w-full " style={{ maxWidth: maxWidth }}>
                    {!isSameUser && <div className="flex justify-between items-center gap-2">
                        <p className="text-sm-medium text-gray-light/700">{user?.name}</p>
                        <p className="text-xs-regular text-gray-light/600">{formattedDate}</p>
                    </div>}
                    <div className="flex flex-col gap-2.5 w-full max-w-max px-[14px] py-2.5 bg-white border border-gray-light/200 rounded-lg rounded-tl-none message-text">
                        {message.attachment && <div className='rounded-lg rounded-tl-none overflow-hidden'>
                            <img loading='lazy' className='h-full w-full object-contain' src={`data:${message.attachment.mimetype};base64,${message.attachment.data}`}></img>
                        </div>}
                        <p className="text-md-regular text-gray-light/900 break-words line-clamp-10 message hidden">{message.text}</p>
                        <p className="text-md-regular text-gray-light/900 break-words line-clamp-10 message-preview">{message.text}</p>
                    </div>
                </div>
            </div> */}
        </div >
    );
};

export default MessageReceiverBox;