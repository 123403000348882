import React, { useEffect } from "react";
import { useLogin } from "../../contexts/LoginContext";
import MyFormTextField from "../../components/TextField/MyFormTextField";
import { getDataWithExpiration } from "../../helpers/Helpers";
import MyCheckbox from "../../components/Checkbox/MyCheckbox";
import { VerifyLogo } from "../../contants/icons/MyIcon/VerifyLogo";
import mainLogo from "./Assets/login-image.png";

const Login = () => {
  const { watch, control, setValue, checkLoginValid, handleSubmit, handleChangeEmail, handleChangePassword, login, loginError } = useLogin();
  const { email, password } = watch();

  const isValid = checkLoginValid();
  useEffect(() => {
    var local = getDataWithExpiration('login');
    if (local) {
      setValue('email', local.email);
      setValue('password', local.password);
      setValue('remember', local.remember);
    }

    return () => {

    }
  }, [])


  return (
    <>
      <main className="w-full h-screen flex items-center justify-center">
        <div className="flex w-full max-w-[1440px] h-full max-h-[960px]">
          <div className="flex-1 h-full flex items-center justify-center px-8">
            <div className="w-full max-w-[360px] flex flex-col gap-y-8">
              <div className="flex flex-col items-center gap-y-6">
                <section className="w-12 h-12">
                  <VerifyLogo size={48} />
                  {/* <img className="w-full h-full object-contain" src="/asset/verify.png" alt="" /> */}
                </section>
                <section className="flex flex-col items-center text-center gap-y-3">
                  <p className="display-sm-semibold text-gray-light/900">Welcome</p>
                  <p className="text-md-reguler text-gray-light/600">Welcome! Please enter your details.</p>
                </section>
              </div>
              <form control={control} onSubmit={handleSubmit(login)}>
                <div className="flex flex-col gap-y-6">
                  <div className="flex flex-col gap-y-5">
                    <section className="flex flex-col gap-y-1.5">
                      <label htmlFor="email" className="text-sm-medium text-gray-light/700">Email</label>
                      <MyFormTextField control={control} onChangeForm={handleChangeEmail} name="email" placeholder={'Enter your email'} />
                    </section>
                    <section className="flex flex-col gap-y-1.5">
                      <label htmlFor="password" className="text-sm-medium text-gray-light/700">Password</label>
                      <MyFormTextField type="password" control={control} onChangeForm={handleChangePassword} name="password" placeholder={'Enter your password'} />
                    </section>
                  </div>
                  <section className="flex items-center">
                    <div className="flex-1 flex gap-x-2">
                      <MyCheckbox control={control} value={true} id={'remember'} name={'remember'} />
                      <p className="text-sm-medium text-gray-light/700">Remember for 7 days</p>
                    </div>
                    {/* <button className="text-sm-semibold text-brand/700">
                      Forgot password
                    </button> */}
                  </section>
                  <button type="submit" disabled={!isValid} className={`${isValid ? 'bg-brand/600 text-white' : 'bg-gray-light/100 border border-gray-light/200 text-gray-light/400'} text-center shadow-shadows/shadow-xs text-md-semibold px-4 py-2.5 w-full rounded-lg`}>
                    Log in
                  </button>
                </div>
              </form>
              <div className="text-center w-full">
                <p className="text-sm-regular text-gray-light/600">User can only log in on one device at a time to keep data safe and secure.</p>
              </div>
            </div>
          </div>
          <div className="flex-1 pr-6 py-6">
            <div className="w-full h-full relative rounded-[20px] overflow-hidden">
              <img className="w-full h-full" src={mainLogo} alt="mainLogo" />
              {/* <img className="w-full h-full object-cover" src="https://media.discordapp.net/attachments/1131472894271160421/1183619782667423794/image.png?ex=6588fed2&is=657689d2&hm=7a31776a4d44426a46e1134f441376045c3c15682d1c813789e0cbacdd798bbf&=&format=webp&quality=lossless&width=512&height=671" alt="" /> */}
              {/* <div className="h-full w-full absolute top-0 left-0 z-10" style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.40) 100%)' }}>
              </div> */}
              <div className="absolute bottom-0 left-0 flex flex-col w-full z-10">
                <div className="pt-24">
                  <div style={{ backdropFilter: 'blur(10px)' }} className="border-t border-white/30 bg-white/30 flex flex-col p-8  gap-y-8">
                    <p className="display-xs-medium text-white">“Enhancing monitoring, control & compliance in Desk Collection activity.”</p>
                    <div className="flex flex-col gap-y-3">
                      <p className="display-md-semibold text-white">Merpati CRM</p>
                      <p className="text-md-medium text-white">© Verify 2023</p>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;