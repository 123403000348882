import React, { useEffect } from "react";
import { DownloadCloud02, Edit01, FilterLines, Plus, Trash01 } from "untitledui-js/icons/general";
import MyCheckbox from "../../../components/Checkbox/MyCheckbox";
import MySwicth from "../../../components/Switch/MySwitch";
import MyPagination from "../../../components/Pagination/MyPagination";
import MyTextField from "../../../components/TextField/MyTextField";
import MyFilter from "../../SimCard/Poppers/filter";
import MySorted from "../../SimCard/Poppers/sorted";
import { ArrowDown, SwitchVertical01 } from "untitledui-js/icons/arrow";
import SimpleBar from "simplebar-react";
import { debounce } from "lodash";
import { useSettings } from "../../../contexts/SettingContext";
import MyFilterModal from "../../../components/Modal/MyFilterModal";

const RulesSetting = () => {
    const { ruleSetting, getRuleSetting, handleCurrentSlider, setParams, params, updateRuleStatus, deleteRule } = useSettings();
    useEffect(() => {
        getRuleSetting();
    }, [params])
    const search = (e) => {
        setParams(value => { return { ...value, search: e?.target?.value, page: 1 } })
    }
    const debounceSearch = debounce(search, 1000)
    return (
        <>
            <div className="bg-white w-full rounded-xl shadow-shadows/shadow-xs border border-gray-light/200">
                <div className="pt-5 flex flex-col gap-y-5">
                    <div className='px-6 flex items-center gap-x-4 w-full'>
                        <div className="flex-1 flex-col gap-1">
                            <div className="flex items-center gap-2">
                                <p className="text-lg-semibold text-gray-light/900">Rules</p>
                                <div className="px-2 py-0.5 rounded-full bg-brand/50 border border-brand/200">
                                    <p className="text-xs-medium text-brand/700">{ruleSetting?.meta?.total ?? '0'} item</p>
                                </div>
                            </div>
                            <p className="text-sm-regular text-gray-light/600">Manage your team restricted words.</p>
                        </div>
                        <div className="flex items-center gap-x-3">
                            {/* <button onClick={() => { }} className="w-max h-max flex items-center border border-gray-light/300 rounded-lg px-[14px] py-2.5 gap-x-1 text-gray-light/700 shadow-shadows/shadow-xs">
                                <DownloadCloud02 size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Export</p>
                            </button> */}
                            <button onClick={() => handleCurrentSlider({ status: true, current: "rule" })} className="w-max h-max bg-brand/600 flex items-center rounded-lg px-[14px] py-2.5 gap-x-1 text-white shadow-shadows/shadow-xs">
                                <Plus size={20} stroke={'currentColor'} />
                                <p className="text-sm-semibold">Add rule</p>
                            </button>
                        </div>
                    </div>
                    <hr className='border-gray-light/200' />
                </div>
                <div className="py-3 px-4 flex gap-3 border-t border-gray-light/200">
                    <div className="w-full max-w-xs">
                        <MyTextField onChange={debounceSearch} placeholder={'Search'} />
                    </div>
                    <MyFilterModal id={'filter-role'} currentFilters={ruleSetting.filter} onChange={(filter) => setParams({ ...params, filter: filter, page: 1 })}
                        target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300 text-gray-light/700">
                            <FilterLines size={20} stroke={'currentColor'} />
                            <p className="text-sm-semibold">Filter</p>
                        </button>)} />
                    {/*

                    <MySorted id={'sorted-sim-card-register'} target={(open, handleClick) => (<button onClick={handleClick} className="flex items-center gap-1 px-4 py-2.5 rounded-lg border border-gray-light/300">
                        <SwitchVertical01 size={20} stroke={'currentColor'} />
                        <p className="text-sm-semibold">Sort</p>
                    </button>)} /> */}
                </div>
                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                    <table className='table border-collapse w-full'>
                        <thead className='border-t border-gray-light/200 p-0'>
                            <tr className='p-0'>
                                <th className='px-6 py-3 text-left'>
                                    <div className="flex items-center gap-3">
                                        {/* <MyCheckbox /> */}
                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Rule name</p>
                                    </div>
                                </th>
                                {/* <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Applied for label (and)</p>
                                </th> */}
                                <th className='px-6 py-3 text-left'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Restricted word (or)</p>
                                </th>
                                {/* <th className='px-6 py-3 text-center'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Prevent send</p>
                                </th> */}
                                <th className='px-6 py-3 text-center'>
                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Is active</p>
                                </th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(ruleSetting?.data ?? []).map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-3">
                                                {/* <MyCheckbox /> */}
                                                <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">{e.name ?? "-"}</p>
                                            </div>
                                        </td>
                                        {/* <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-x-1">
                                                <div className="w-max px-2 py-0.5 rounded-md bg-brand/50 border border-brand/200">
                                                    <p className="text-xs-medium text-brand/700">Overdue 1</p>
                                                </div>
                                                <div className="w-max px-2 py-0.5 rounded-md bg-blue-light/50 border border-blue-light/200">
                                                    <p className="text-xs-medium text-blue-light/700">Difficult to handle</p>
                                                </div>
                                                <div className="w-max px-2 py-0.5 rounded-md bg-gray-light/50 border border-gray-light/200">
                                                    <p className="text-xs-medium text-gray-light/700">+4</p>
                                                </div>
                                            </div>
                                        </td> */}
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex items-center gap-x-1">
                                                {(e.words ?? []).slice(0, 4).map((word, i) => {
                                                    return (<div key={i} className="px-2 py-0.5 rounded-full border border-error/600">
                                                        <p className="text-xs-medium text-error/700">{word}</p>
                                                    </div>)
                                                })}

                                                {e.words.length > 4 && <div className="rounded-full px-2 py-0.5 border border-error/600">
                                                    <p className="text-xs-medium text-error/700">+{e.words.length - 4}</p>
                                                </div>}
                                            </div>
                                        </td>
                                        {/* <td className='px-6 py-4 border-t border-gray-light/200'>
                                            <div className="w-full flex justify-center">
                                                <MyCheckbox onChangeForm={(e2) => { updateRuleStatus(e.id, { prevent_send: e2.target.checked }) }} checked={e.prevent_send ?? false} />
                                            </div>
                                        </td> */}
                                        <td className='px-6 py-4 border-t border-gray-light/200'>
                                            <div className="w-full flex justify-center">
                                                <MySwicth checked={e.is_active} onChangeForm={(e2) => { updateRuleStatus(e.id, { is_active: e2.target.checked }) }} />
                                            </div>
                                        </td>
                                        <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                            <div className="flex justify-end gap-x-1">
                                                <button onClick={() => deleteRule(e.id)} className="my-button flex items-center p-2.5">
                                                    <Trash01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                                <button onClick={() => { handleCurrentSlider({ status: true, current: "rule" }, e.id) }} className="my-button flex items-center p-2.5">
                                                    <Edit01 size={20} className={'text-gray-light/600'} stroke={'currentColor'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </SimpleBar>
                <div className="border-t border-gray-light/200">
                    <MyPagination meta={ruleSetting.meta} setParams={setParams} />
                </div>
            </div>
        </>
    );
};

export default RulesSetting;