import React, { useState } from "react";
import { HelpCircle, XClose } from "untitledui-js/icons/general";
import SimpleBar from 'simplebar-react';
import { User01, UserPlus01 } from "untitledui-js/icons/users";
import MyAutocompleteV2 from "../../components/Autocomplete/MyAutocompleteV2";
import MyTooltip from "../../components/Tooltip/MyTooltip";
import MyAutocompleteV3 from "../../components/Autocomplete/MyAutocompleteV3";
import { useTeamManagement } from "../../contexts/TeamManagementContext";
import { debounce } from "lodash";

const ModalTeam = () => {
    const { searchTeamUser, addTeam, handleCurrentSlider } = useTeamManagement()
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const debounceSearchUser = debounce((e) => searchTeamUser(e).then(setUsers), 1000)
    return (
        <div className="w-[400px] h-screen flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} onClick={() => { handleCurrentSlider({ status: false, current: null }) }} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <UserPlus01 size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">Add new team member</p>
                    <p className="text-sm-regular text-gray-light/600">Choose the members you want to add to your team.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <div className="h-full flex flex-col gap-6">
                        <div className="flex flex-col gap-y-1.5 w-full px-6">
                            <label htmlFor="roleName" className="text-sm-medium text-gray-light/700">Search</label>
                            <MyAutocompleteV3 options={users} value={null} getOptionLabel={(option) => option.name || option.email} placeholder={'Search user'}
                                startAdornment={<User01 size={20} className={'text-gray-light/500'} stroke="currentColor" />}
                                onChange={(e, value) => {
                                    setTeams(teams => [...teams, value].filter((value, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.id === value.id
                                        ))
                                    ));
                                }}
                                onInputChange={(e) => {
                                    debounceSearchUser(e);
                                }}
                                onInputFocus={(e) => searchTeamUser(e).then(setUsers)}
                                renderOption={(option) => {
                                    return (<div className="flex items-center gap-x-2 overflow-hidden">
                                        {option.photo ? <div className="border border-[#00000014] w-6 h-6 min-w-[24px] min-h-[24px] rounded-full overflow-hidden">
                                            <img className="w-full h-full object-cover" src={option.full_path_photo} alt="" />
                                        </div>
                                            : <div className="bg-gray-light/100 flex justify-center items-center w-6 h-6 min-w-[24px] min-h-[24px] rounded-full border border-[#00000014]">
                                                <User01 className={'text-gray-light/500'} size={18} stroke={'currentColor'} />
                                            </div>}
                                        <p className="text-md-medium text-gray-light/900 whitespace-nowrap">{option?.name}</p>
                                        <p className="text-md-regular text-gray-light/600 line-clamp-1">{option?.email}</p>
                                    </div>)
                                }}
                            />
                        </div>
                        <div className=" flex flex-col gap-6 flex-1 pt-2">
                            <div className="px-6 flex flex-col gap-y-1">
                                <p className="text-sm-medium text-gray-light/700">Summary</p>
                                <p className="text-sm-regular text-gray-light/600">Your new planned team availability.</p>
                            </div>
                            <div className="shadow-shadows/shadow-xs border-y border-gray-light/200">
                                <SimpleBar forceVisible="x" style={{ maxWidth: '100%' }}>
                                    <table className='table border-collapse w-full'>
                                        <thead className='p-0'>
                                            <tr className='p-0'>
                                                <th className='px-6 py-3 text-left'>
                                                    <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Name</p>
                                                </th>
                                                <th className='px-6 py-3 text-left'>
                                                    <div className="flex items-center justify-start gap-1">
                                                        <p className="text-xs-medium text-gray-light/600 whitespace-nowrap">Status</p>
                                                        <MyTooltip placement="left" target={<span><HelpCircle size={16} className={'text-gray-light/400'} stroke={'currentColor'} /></span>}>
                                                            <div className="flex flex-col gap-y-1">
                                                                <p className="text-white text-xs-semibold">Team member status</p>
                                                                <ul class="list-disc px-4">
                                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">"Available"</span> indicates that the user is ready to be added to your team.</li>
                                                                    <li className="text-gray-light/300 text-xs-regular"><span className="text-xs-bold">"On Another Team"</span> signifies that the member has already been assigned to a different team, and therefore, is not available to be added to your team.</li>
                                                                </ul>
                                                            </div>
                                                        </MyTooltip>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teams.map((e, i) => {
                                                return (<tr key={e.id}>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        <div className="flex items-center gap-3">
                                                            {e.photo ? <div className="border border-[#00000014] w-10 h-10 min-h-[40px] min-w-[40px] rounded-full overflow-hidden">
                                                                <img className="w-full h-full object-cover" src={e.full_path_photo} alt="" />
                                                            </div>
                                                                : <div className="bg-gray-light/100 flex justify-center items-center w-10 h-10 min-w-[40px] min-h-[40px] rounded-full border border-[#00000014]">
                                                                    <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                                                                </div>}
                                                            <div className="flex flex-col">
                                                                <p className="text-sm-medium text-gray-light/900">{e.name}</p>
                                                                <p className="text-sm-regular text-gray-light/600">{e.role?.name ?? '-'}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 text-left border-t border-gray-light/200'>
                                                        {e.available ? <div className="w-max px-2 py-0.5 border rounded-full border-indigo-200 bg-indigo-50 ">
                                                            <p className="text-xs-medium text-indigo-700">Available</p>
                                                        </div> : <div className="w-max px-2 py-0.5 border rounded-full border-error/200 bg-error/50 ">
                                                            <p className="text-xs-medium text-error/700">On other Team</p>
                                                        </div>}
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </SimpleBar>
                            </div>
                            <div className="px-6 flex flex-col gap-y-4">
                                <div className="w-full flex justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Available</p>
                                    <p className="text-sm-regular text-gray-light/600">{teams.filter(e => e.available).length}</p>
                                </div>
                                <div className="w-full flex justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Not available</p>
                                    <p className="text-sm-regular text-gray-light/600">{teams.filter(e => !e.available).length}</p>
                                </div>
                                <div className="w-full flex justify-between">
                                    <p className="text-sm-medium text-gray-light/700">Total user will join your team</p>
                                    <p className="text-sm-regular text-gray-light/600">{teams.filter(e => e.available).length}</p>
                                </div>
                            </div>
                            <div className="px-6">
                                <hr className="border-gray-light/200" />
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 px-6 py-4 flex items-center justify-end gap-4">
                            <button onClick={() => handleCurrentSlider({ status: false, current: null })} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button onClick={() => { addTeam(teams.map(e => e.id)) }} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Confirm</p>
                            </button>
                        </footer>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalTeam;