import React, { } from "react";
import MyModal from "../../../components/Modal/MyModal";
import { Save01, XClose } from "untitledui-js/icons/general";
import MyIconPatternDecorative from "../../../components/Icon/MyIconPatternDecorative";

const ModalBannerNumber = () => {
    return (
        <MyModal maxWidth={400} onClose={() => { }} open={false}>
            <div className="flex flex-col bg-white w-full relative rounded-xl shadow-shadows/shadow-xl overflow-hidden">
                <header className="p-6 pb-5 flex flex-col gap-4 relative z-10">
                    <button className="my-modal-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center gap-y-4 text-gray-light/400 rounded-lg p-2">
                        <XClose onClick={() => { }} size={24} stroke={'currentColor'} />
                    </button>
                    <MyIconPatternDecorative>
                        <div className="w-max p-3 rounded-full bg-warning/100">
                            <Save01 size={24} className={'text-warning/600'} stroke={'currentColor'} />
                        </div>
                    </MyIconPatternDecorative>
                    <div className="flex flex-col z-20">
                        <p className="text-lg-semibold text-gray-light/900">Banned number</p>
                        <p className="text-sm-regular text-gray-light/600">Your number has blocked by WhatsApp. Change SIM card to continue using the Chat feature.</p>
                    </div>
                </header>
                <div className="pt-8 pb-6 px-6 flex items-center gap-3">
                    <button className="flex-1 py-2.5 px-4 shadow-shadows/shadow-xs border border-gray-light/300 rounded-lg text-md-semibold text-gray-light/700">Discard</button>
                    <button className="flex-1 py-2.5 px-4 shadow-shadows/shadow-xs bg-brand/600 border border-brand/600 rounded-lg text-md-semibold text-white">Change SIM card</button>
                </div>
            </div>
        </MyModal>
    );
};

export default ModalBannerNumber;