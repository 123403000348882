import React, { } from "react";
import { ArrowLeft } from "untitledui-js/icons/arrow";
import { Check } from "untitledui-js/icons/general";
import MyIconPatternDecorativeRadial from "../../../components/Icon/MyIconPatternDecorativeRadial";
import { Lock01 } from "untitledui-js/icons/security";
import MyTextField from "../../../components/TextField/MyTextField";

const SetNewPassword = () => {
    return (
        <div className="px-8 h-full w-full flex items-center justify-center overflow-hidden">
            <div className="w-full max-w-[360px] flex flex-col items-center justify-center gap-8">
                <div className="flex flex-col items-center justify-center gap-6">
                    <MyIconPatternDecorativeRadial>
                        <span className="text-gray-light/700 w-14 h-14 min-w-[56px] min-h-[56px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                            <Lock01 size={28} stroke={'currentColor'} />
                        </span>
                    </MyIconPatternDecorativeRadial>
                    <div className="flex flex-col gap-y-3 text-center">
                        <p className="display-sm-semibold text-gray-light/900">Set new password</p>
                        <p className="text-md-regular text-gray-light/600">Your new password must be different to previously used passwords.</p>
                    </div>
                </div>
                <div className="flex flex-col gap-6 w-full">
                    <div className="flex flex-col gap-5 w-full">
                        <div className="flex flex-col gap-y-1.5 w-full">
                            <label htmlFor="name" className="text-sm-medium text-gray-light/700">New password</label>
                            <MyTextField name="name" placeholder={'Enter your name'} />
                        </div>
                        <div className="flex flex-col gap-y-1.5 w-full">
                            <label htmlFor="name" className="text-sm-medium text-gray-light/700">Confirm new password</label>
                            <MyTextField name="name" placeholder={'Enter your name'} />
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <div className="flex items-center gap-2">
                                <div className="text-white flex items-center justify-center text-center bg-gray-light/300 w-5 h-5 min-w-[20px] min-h-[20px] rounded-full">
                                    <Check size={14} strokeWidth={4} stroke={'currentColor'} />
                                </div>
                                <p className="text-sm-regular text-gray-light/600">Must be at least 8 characters</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-white flex items-center justify-center text-center bg-gray-light/300 w-5 h-5 min-w-[20px] min-h-[20px] rounded-full">
                                    <Check size={14} strokeWidth={4} stroke={'currentColor'} />
                                </div>
                                <p className="text-sm-regular text-gray-light/600">Must contain one special character</p>
                            </div>
                        </div>
                    </div>
                    <button className={`bg-brand/600 text-white border border-gray-light/200 text-gray-light/400'} text-center shadow-shadows/shadow-xs text-md-semibold px-4 py-2.5 w-full rounded-lg`}>
                        Reset password
                    </button>
                </div>
                <button className="flex items-center gap-1.5">
                    <span className="text-gray-light/600">
                        <ArrowLeft size={20} stroke={'currentColor'} />
                    </span>
                    <p className="text-sm-semibold text-gray-light/600">Back to log in</p>
                </button>
            </div>
        </div>
    );
};

export default SetNewPassword;