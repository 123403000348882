import React, { } from "react";
import { Key01, Passcode } from "untitledui-js/icons/security";
import MyStepper from "../../components/Stepper/MyStepper";
import { Mail01, Mail02 } from "untitledui-js/icons/communication";
import { CheckCircle } from "untitledui-js/icons/general";
import SuccessReset from "./ForgotStep/success_reset";
import SetNewPassword from "./ForgotStep/set_new_password";
import CheckEmail from "./ForgotStep/check_email";
import InputForgotPassword from "./ForgotStep/input_forgot_password";
import { VerifyLogo } from "../../contants/icons/MyIcon/VerifyLogo";
const steps = [
    {
        icon: <Key01 size={24} stroke={'currentColor'} />,
        label: 'Your details',
        description: 'Please provide your email',
    },
    {
        icon: <Mail02 size={24} stroke={'currentColor'} />,
        label: 'Check your email',
        description: 'Find password reset link on email',
    },
    {
        icon: <Passcode size={24} stroke={'currentColor'} />,
        label: 'Set new password',
        description: 'Choose a secure password',
    },
    {
        icon: <CheckCircle size={24} stroke={'currentColor'} />,
        label: 'Password reset',
        description: 'Your password has been reset',
    },
]

const ForgotPassword = () => {
    return (
        <main className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full max-w-[1440px] h-full max-h-[960px]">
                <div className="flex flex-col justify-between flex-1 h-full max-w-[440px] bg-gray-light/50">
                    <div className="flex-1 pt-12 px-8 flex flex-col gap-y-20">
                        <div className="w-8 h-8 min-w-[32px] min-h-[32px]">
                            <VerifyLogo size={32} />
                        </div>
                        <MyStepper steps={steps} activeStep={0} />
                    </div>
                    <div className="p-8 w-full flex items-center justify-between">
                        <p className="text-sm-regular text-gray-light/600">© Verify 2023</p>
                        <button className="flex items-center gap-2">
                            <span><Mail01 size={16} className={'text-gray-light/500'} stroke={'currentColor'} /></span>
                            <p className="text-sm-regular text-gray-light/600">support@verify.com</p>
                        </button>
                    </div>
                </div>
                <div className="flex-1">
                    <InputForgotPassword />
                    {/* <CheckEmail /> */}
                    {/* <SetNewPassword /> */}
                    {/* <SuccessReset /> */}
                </div>
            </div>
        </main>
    );
};

export default ForgotPassword;