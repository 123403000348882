import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AlertCircle } from "untitledui-js/icons/alerts";
import { CheckCircle, Settings02 } from "untitledui-js/icons/general";
import { User01 } from "untitledui-js/icons/users";

export function compress(source_img_obj, quality, output_format) {
  var mime_type;
  if (output_format === "png") {
    mime_type = "image/png";
  } else if (output_format === "webp") {
    mime_type = "image/webp";
  } else {
    mime_type = "image/jpeg";
  }

  var cvs = document.createElement("canvas");
  cvs.width = source_img_obj.naturalWidth;
  cvs.height = source_img_obj.naturalHeight;
  var newImageData = cvs.toDataURL(mime_type, quality / 100);
  return newImageData;
}

// Function to save data to localStorage with a timestamp
export const saveDataWithExpiration = (key, data, expirationDays) => {
  const expirationTimestamp =
    new Date().getTime() + expirationDays * 24 * 60 * 60 * 1000;
  const dataToStore = { data, expiration: expirationTimestamp };
  console.log("dataToStore", dataToStore);
  localStorage.setItem(key, JSON.stringify(dataToStore));
};

// Function to get data from localStorage, checking for expiration
export const getDataWithExpiration = (key) => {
  const storedData = localStorage.getItem(key);
  if (!storedData) {
    return null; // Data doesn't exist in localStorage
  }

  const parsedData = JSON.parse(storedData);
  console.log("parsedData", parsedData);
  if (parsedData.expiration && new Date().getTime() > parsedData.expiration) {
    // Data has expired, remove it
    localStorage.removeItem(key);
    return null;
  }

  return parsedData.data; // Return the data if it's still valid
};

export const formatDate = (initialDate) => {
  if (initialDate === null || initialDate === "") return;
  return moment(initialDate).format("D MMMM YYYY • h:mma");
};
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kb", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
export const notify = (
  response,
  { icon, iconColor, onDismiss, additionalButton } = {}
) => {
  var notifyClass = {};
  switch (response?.status) {
    case 200:
    case 201:
    case "200":
    case "201":
    case null:
      notifyClass = {
        iconColor: iconColor || "text-success/600",
        outlineColor30: "border-success/600/30",
        outlineColor10: "border-success/600/10",
      };
      if (!icon) icon = <CheckCircle size={20} stroke={"currentColor"} />;
      break;
    default:
      notifyClass = {
        iconColor: iconColor || "text-warning/600",
        outlineColor30: "border-warning/600/30",
        outlineColor10: "border-warning/600/10",
      };
      icon = <AlertCircle size={20} stroke={"currentColor"} />;
      break;
  }

  toast(
    <div className="flex items-start gap-x-4 pr-8">
      <div className="w-max relative flex items-center justify-center z-10">
        <div className={`${notifyClass?.iconColor}`}>{icon ?? <></>}</div>
        <div className="w-[38px] h-[38px] absolute flex items-center justify-center">
          <span
            className={`block w-7 h-7 absolute border-2 rounded-full ${notifyClass?.outlineColor30}`}
          ></span>
          <span
            className={`block w-[38px] h-[38px] absolute border-2 rounded-full ${notifyClass?.outlineColor10}`}
          ></span>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 w-full">
        <div className="w-full flex flex-col gap-y-1">
          <p className="text-gray-light/900 text-sm-semibold">
            {response?.title ?? "Information"}
          </p>
          <p
            className="text-gray-light/600 text-sm-regular line-clamp-2 whitespace-pre-line"
            dangerouslySetInnerHTML={{
              __html: response?.message ?? "Something wrong",
            }}
          ></p>
        </div>
        {
          <div className="w-full flex items-center gap-x-3">
            {
              <button className="text-gray-light/600 text-sm-semibold">
                Dismiss
              </button>
            }
          </div>
        }
      </div>
    </div>,
    { containerId: "default" }
  );
};

export const newMessage = (
  response,
  { icon, iconColor, onDismiss, additionalButton } = {}
) => {
  toast(
    <div className="flex items-start gap-x-4 pr-8">
      <div className="w-10 h-10 min-w-[40px] min-h-[40px]  flex items-center justify-center text-center bg-gray-light/100 border border-[#00000014] rounded-full">
        <User01
          className={"text-gray-light/500"}
          size={24}
          stroke={"currentColor"}
        />
      </div>
      <div className="flex flex-col gap-y-3 w-full">
        <div className="w-full flex flex-col gap-y-1">
          <p className="text-gray-light/900 text-sm-semibold">
            {response?.title ?? "Information"}
          </p>
          <p
            className="text-gray-light/600 text-sm-regular line-clamp-2 whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: response?.message ?? "-" }}
          ></p>
        </div>
        {
          <div className="w-full flex items-center gap-x-3">
            {
              <button className="text-gray-light/600 text-sm-semibold">
                Dismiss
              </button>
            }
          </div>
        }
      </div>
    </div>,
    { containerId: "default" }
  );
};

var isObj = function (a) {
  if (!!a && a.constructor === Object) {
    return true;
  }
  return false;
};
var _st = function (z, g) {
  return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
};
export function fromObject(params, skipobjects, prefix) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = "";
  }
  var result = "";
  if (typeof params != "object") {
    return prefix + "=" + encodeURIComponent(params) + "&";
  }
  for (var param in params) {
    var c = "" + prefix + _st(param, prefix);
    if (isObj(params[param]) && !skipobjects) {
      result += fromObject(params[param], false, "" + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach(function (item, ind) {
        result += fromObject(item, false, c + "[" + ind + "]");
      });
    } else {
      result += c + "=" + encodeURIComponent(params[param]) + "&";
    }
  }
  return result;
}
