import React, { useState } from "react";
import { Popover } from "@mui/material";

const MyPopper = ({ children, id, placement = "bottom", target, onClick, haveValue }) => {
    let anchorOrigin, transformOrigin;
    let styleMuiPaperRoot = {
        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        border: '1px solid #EAECF0',
        borderRadius: '12px',
        width: 'max-content',
    };

    if (placement === 'top-start') {
        anchorOrigin = { vertical: "top", horizontal: "left" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'top') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'top-end') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "-8px";
    } else if (placement === 'left-start') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'left') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'left-end') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'bottom-start') {
        anchorOrigin = { vertical: "bottom", horizontal: "left" };
        transformOrigin = { vertical: "top", horizontal: "left" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'bottom') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'bottom-end') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'right-start') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'right') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    } else if (placement === 'right-end') {
        anchorOrigin = { vertical: "top", horizontal: "right" };
        transformOrigin = { vertical: "bottom", horizontal: "right" };
        styleMuiPaperRoot.marginTop = "8px";
    }


    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    return (
        <div className={`${haveValue ? "bg-green-300 rounded-[10px]" : ""}`}>
            {target && target(open, handleClick)}
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}
                sx={{ '&.MuiPopover-root .MuiPaper-root': styleMuiPaperRoot }}>
                {children && children(open, handleClick, handleClose)}
            </Popover>
        </div>
    );
};

export default MyPopper;