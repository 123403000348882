import React from "react";
import SimpleBar from "simplebar-react";
import MyPopper from "../../../components/Poppper/MyPopper";

const PopperViewSample = ({ id, target, placement = "top-start", text, availableFields }) => {
    text && availableFields?.map(e => {
        var label = e.label;
        text = text.replace(new RegExp(`<<${label}>>`, 'ig'), `<label class="text-sm-semibold text-brand/700">${e.id}</label>`)
    })
    return (
        <>
            <MyPopper id={id} target={target} placement={placement}>
                {(open, handleOpen, handleClose) => (
                    <div className="w-[558px] h-[366px]">
                        <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                            <div className="p-6">
                                <div className="text-sm-regular break-words" dangerouslySetInnerHTML={{ __html: text?.replace(/\n/g, "<br />") }}></div>
                            </div>
                        </SimpleBar>
                    </div>
                )}
            </MyPopper>
        </>
    );
};

export default PopperViewSample;