import React, { createContext, useContext } from "react";

const ProfileContext = createContext();
const ProfileProvider = props => {

    return <ProfileContext.Provider value={{
    }}> {props.children}</ProfileContext.Provider >;
};

const useProfile = () => {
    const context = useContext(ProfileContext);
    if (context === undefined) {
        throw new Error('userProfile must be used within a ProfileProvider')
    }
    return context;
}

export { ProfileProvider, useProfile };