import React, { useEffect } from "react";
import { XClose } from "untitledui-js/icons/general";
import MyTextField from "../../../components/TextField/MyTextField";
import SimpleBar from 'simplebar-react';
import { useSettings } from "../../../contexts/SettingContext";
import { Flag01 } from "untitledui-js/icons/maps";
import { attemptSettingSchema } from "../../../yup_schema/setting_schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MyTextFieldV2 from "../../../components/TextField/MyTextFieldv2";

const ModalInteractionStatus = () => {
    const { handleCurrentSlider, showAttemptSetting, currentSlider, createAttemptSetting, updateAttempt } = useSettings();
    const { clearErrors, watch, setValue, handleSubmit, control, formState: { errors }, register } = useForm({ resolver: yupResolver(attemptSettingSchema) });
    const submit = currentSlider.id ? updateAttempt(currentSlider.id) : createAttemptSetting
    useEffect(() => {
        if (currentSlider.id) {
            setValue('create', false);
            showAttemptSetting(currentSlider.id).then(data => {
                setValue('name', data.name);
            })
        } else {
            setValue('create', true);
        }
    }, [])
    console.log(errors)
    return (
        <div className="w-[400px] h-screen flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} onClick={() => handleCurrentSlider({ status: false, current: null })} />
                </button>
                <span className="text-gray-light/700 w-10 h-10 min-w-[40px] min-h-[40px] flex items-center justify-center shadow-shadows/shadow-xs border border-gray-light/200 rounded-lg">
                    <Flag01 size={20} stroke={'currentColor'} />
                </span>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">{currentSlider?.id ? 'Edit' : 'Add'} Attempt status</p>
                    <p className="text-sm-regular text-gray-light/600">Please provide the details for a new interaction status.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <form onSubmit={handleSubmit(submit)} className="h-full flex flex-col gap-6">
                        <div className="flex flex-col gap-6 flex-1">
                            <div className="px-6 flex flex-col gap-4">
                                <div className="flex flex-col gap-y-1.5 w-full">
                                    <label htmlFor="status_name" className="text-sm-medium text-gray-light/700">Status name</label>
                                    <MyTextFieldV2 maxLength={25} control={control} name="name" placeholder={'Enter your status name'} />
                                </div>
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 px-6 py-4 flex items-center justify-end gap-4">
                            <button type="reset" onClick={() => handleCurrentSlider({ status: false, current: null })} className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button type="submit" className="px-[14px] py-2.5 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Apply</p>
                            </button>
                        </footer>
                    </form>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalInteractionStatus;