
import moment from 'moment'
import React from 'react'
import { User01 } from 'untitledui-js/icons/users'

const UserCard = ({ onClick, selected, e, search = false }) => {
    if (e.messages.length !== 0) {
        var send_time = moment(e.messages[0]?.send_time)
        var current = new Date()
        current.setHours(0)
        current.setMinutes(0)
        send_time = send_time < current ? send_time.format('DD/MM/YY') : moment(send_time).format('HH:mma')
    }

    return (
        <div onClick={onClick} className={["cursor-pointer p-4 flex flex-col gap-4 border-b border-gray-light/200 ", selected && !search ? ' bg-gray-light/50 ' : ' bg-white ']}>
            <div className="flex gap-4">
                <div className="flex-1 flex items-center gap-3">
                    {e.messages.length != 0 && !e.messages[0].read_time && e.messages[0].sender_id == e.detail_customer.id && <div className='w-2 h-2 min-w-[8px] min-h-[8px]'><div className="rounded-full w-full h-full bg-brand/500"></div> </div>}
                    <div className="flex items-center justify-center w-10 h-10 min-h-[40px] min-w-[40px] rounded-full border border-[#00000014] bg-gray-light/100">
                        <User01 className={'text-gray-light/500'} size={24} stroke={'currentColor'} />
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex gap-2">
                        <p className="text-sm-semibold text-gray-light/700">{e.detail_customer.name}</p>
                        {e.detail_customer.wa_status === "unregistered" ? ( 
                        <span class="inline-flex items-center rounded-md bg-red-50 px-1 py-0 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Inactive</span>
                        ) : (<div></div>)}
                        </div>
                        <p className="text-sm-regular text-gray-light/600">{e.detail_customer.number}</p>
                    </div>
                </div>
                <p className="text-sm-regular text-gray-light/600">{e.messages && send_time}</p>
            </div>
            <div className="pl-5 flex">
                <p className="text-sm-regular text-gray-light/600 message-text">
                    {e.messages?.length !== 0 && !(e.customer === e.messages[0]?.sender_id) && <span >{'You: '}</span>}
                    {e.messages && e.messages[0]?.textPreview && <span dangerouslySetInnerHTML={{ __html: e.messages[0]?.textPreview }}></span>}
                    <span>{e.messages && (!e.messages[0]?.textPreview) && e.messages[0]?.text}</span>
                </p>
            </div>
        </div>
    )
}

export default UserCard