import React, { createContext, useContext, useState } from "react";

const TaskDistributionContext = createContext();
const TaskDistributionProvider = props => {
    // const [currentSlider, setCurrentSlider] = useState({ status: true, current: "upload-plans" });
    const [currentSlider, setCurrentSlider] = useState({ status: false, current: null });
    const handleCurrentSlider = (current, id) => {
        if (current && current.status) {
            setCurrentSlider({ status: false, current: current.current, id })
            setTimeout(() => {
                setCurrentSlider({ status: true, current: current.current, id })
            }, 100);
        } else {
            setCurrentSlider({ ...currentSlider, status: false, id })
            setTimeout(() => {
                setCurrentSlider({ status: false, current: null, id })
            }, 500);
        }
    }

    return <TaskDistributionContext.Provider value={{
        currentSlider, setCurrentSlider, handleCurrentSlider,
    }}> {props.children}</TaskDistributionContext.Provider >;
};

const useTaskDistribution = () => {
    const context = useContext(TaskDistributionContext);
    if (context === undefined) {
        throw new Error('userTaskDistribution must be used within a TaskDistributionProvider')
    }
    return context;
}

export { TaskDistributionProvider, useTaskDistribution };