import React, { useState } from "react";
import * as XLSX from "xlsx";
import { UploadCloud02, XClose } from "untitledui-js/icons/general";
import SimpleBar from "simplebar-react";
import MyCheckbox from "../../components/Checkbox/MyCheckbox";
import MyTextField from "../../components/TextField/MyTextField";
import MyDropzone from "../../components/Dropzone/MyDropzone";
import HttpRequests from "../../services/ApiServiceHttp";
import { formatBytes, notify } from "../../helpers/Helpers";
import { useTaskDistribution } from "../../contexts/TaksDistributionContext";
import { FileAttachment02 } from "untitledui-js/icons/files";
import { Csv } from "../../contants/icons/MyIcon/Extension";

const ModalUploadPlans = ({ reload }) => {
    const { handleCurrentSlider } = useTaskDistribution()
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exports, setExports] = useState();
    const multiIndexOf = (arr, el) => {
        var idxs = [];
        for (var i = arr.length - 1; i >= 0; i--) {
            if (arr[i].includes(el)) {
                idxs.unshift(i);
            }
        }
        return idxs;
    };
    const readFile = async (files) => {
        console.log("reading input file:");
        if (files?.length == 0) return;
        const file = files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // const templates = [];
        // workbook.SheetNames.forEach((element) => {
        //   if (element.includes("template"))
        //     templates.push(workbook.Sheets[element]);
        // });
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
        });
        if (!jsonData) return;
        var headers = jsonData[0];
        if (!headers) return;
        var fields = headers
            .filter((e) => e.includes("{") && e.includes("}") && !e.includes('dynamic_label') && !e.includes('custom_field'))
            .map((e) => headers.findIndex((column) => column === e));
        console.log(headers)
        var dynamic = multiIndexOf(headers, "{dynamic_label}")
        var custom = multiIndexOf(headers, "{custom_field}")
        console.log(fields, dynamic, custom)
        // var number = headers
        //   .filter((e) => e.toLowerCase() === "number")
        //   .map((e) => headers.findIndex((column) => column === e));
        // var templateTexts = templates.map(
        //   (e) =>
        //     XLSX.utils.sheet_to_json(e, {
        //       header: 1,
        //       defval: "",
        //     })[0]
        // );
        var datas = jsonData.slice(1, jsonData.length);
        // var vcards = [];
        datas = datas.map((e) => {
            if (!e[0]) return null;
            return {
                fields: fields.map((field) => {
                    return {
                        label: headers[field],
                        value: e[field],
                    };
                }),
                custom: custom.map((field) => {
                    var label = headers[field].replace("{FILTER}", "")
                    return {
                        label,
                        value: e[field],
                        filter: headers[field].includes("{FILTER}")
                    };
                }),
                dynamic: dynamic.map((field) => {
                    var label = headers[field].replace("{FILTER}", "")
                    return {
                        label,
                        value: e[field],
                        filter: headers[field].includes("{FILTER}")
                    };
                }),
            };
        }).filter(e => e);
        console.log(datas);
        setExports({
            filename: (file.name.replace('.xlsx', '')) + "-" + (new Date().getTime()),
            filesize: file.size,
            tasks: datas
        })
    };
    const submit = async () => {
        if (loading) return;
        console.log(files)
        setLoading(true);
        const formData = new FormData();
        files.map((e) => {
            formData.append("tasks", e);
        });
        await HttpRequests.taskDistribution(formData)
            .then(notify)
            .then(() => {
                handleCurrentSlider({ status: false, current: null })
                reload()
            })
            .catch(notify)
            .finally(() => {
                setLoading(false);
            });
    };

    const close = () => handleCurrentSlider({ status: false, current: null });
    return (
        <div className="w-[400px] h-full flex flex-col gap-6">
            <header className="px-6 pt-6 flex items-start gap-x-4 relative">
                <button onClick={close} className="my-sidebar-btn-close absolute top-[12px] right-[12px] w-11	h-11 flex items-center justify-center text-gray-light/400 rounded-lg p-2">
                    <XClose size={24} stroke={'currentColor'} />
                </button>
                <section className="flex flex-col gap-1">
                    <p className="text-xl-semibold text-gray-light/900">Upload plans</p>
                    <p className="text-sm-regular text-gray-light/600">Choose or drag the file you want to upload.</p>
                </section>
            </header>
            <div className="flex-1 overflow-hidden">
                <SimpleBar forceVisible="y" style={{ height: '100%' }}>
                    <div className="h-full flex flex-col gap-6">
                        <div className="flex-1 flex flex-col px-6 gap-y-4">
                            <MyDropzone maxSize={157286400} accept={{
                                'text/csv': ['.csv'],
                                'application/vnd.ms-excel': ['.xls'],
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                            }} onDrop={(acceptedFiles, rejectedFiles, e) => {
                                console.log(acceptedFiles, rejectedFiles, e);
                                readFile(acceptedFiles)
                            }}
                                onDropAccepted={(acceptedFiles, e) => {
                                    setFiles(acceptedFiles)
                                }}
                                onDropRejected={(rejectedFiles, e) => { }}>
                            </MyDropzone>
                            {/* <section>
                                <input id="icon-button-photo" multiple={true} style={{ display: 'none' }} type="file" onChange={(e) => {
                                    console.log(e);
                                }} />
                                <label htmlFor="icon-button-photo">
                                    <section className="flex flex-col items-center gap-y-3 px-6 py-4 rounded-xl border-2 border-brand/600 ">
                                        <div className="w-max block p-2.5 border border-gray-light/200 shadow-shadows/shadow-xs rounded-lg">
                                            <UploadCloud02 size={20} className={'text-gray-light/700'} stroke={'currentColor'} />
                                        </div>
                                        <div className="flex flex-col items-center text-center gap-1">
                                            <p className="text-sm-semibold text-brand/700">Click to upload</p>
                                            <p className="text-xs-regular text-gray-light/600">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                    </section>
                                </label>
                            </section> */}
                            <div className="flex flex-col gap-y-4">
                                {exports && <section className="relative overflow-hidden flex rounded-lg border border-gray-light/200 p-4">
                                    <div className="z-10 absolute top-0 left-0 bg-gray-light/50 h-full" style={{ width: '00%' }}> </div>
                                    <div className="z-20 flex-1 flex items-center gap-3">
                                        <div className="w-10 h-10 min-w-[40px] min-h-[40px]">
                                            <Csv size={40} />
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-sm-medium text-gray-light/900 line-clamp-1">{exports.filename}</p>
                                            <p className="text-sm-regular text-gray-light/600">{formatBytes(exports.filesize)}</p>
                                        </div>
                                    </div>
                                    {/* <div className="z-20 absolute top-[16px] right-[16px]">
                                        <MyCheckbox />
                                    </div> */}
                                    <div className="z-20 w-8 h-8 min-w-[32px] min-h-[32px]">
                                    </div>
                                </section>}
                            </div>
                        </div>
                        <footer className="border-t border-gray-light/200 py-4 px-6 flex items-center justify-end gap-x-3">
                            <button onClick={close} className="px-[14px] py-2 flex items-center gap-x-1 shadow-shadows/shadow-xs border border-gray-light/300 rounded-md">
                                <p className="text-sm-semibold text-gray-light/600">Cancel</p>
                            </button>
                            <button disabled={loading} onClick={submit} className="px-[14px] py-2 flex items-center gap-x-1 shadow-shadows/shadow-xs bg-brand/600 rounded-md">
                                <p className="text-sm-semibold text-white">Attach to task distribution</p>
                            </button>
                        </footer>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ModalUploadPlans;