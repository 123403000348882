import React, { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { generalSchema } from "../yup_schema/setting_schema";
import HttpRequests from "../services/ApiServiceHttp";
import { notify } from "../helpers/Helpers";

const SettingsContext = createContext();
const SettingsProvider = props => {
    // const [currentSlider, setCurrentSlider] = useState({ status: true, current: "role" });
    const [currentSlider, setCurrentSlider] = useState({ status: false, current: null });
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('current_page') ?? 'user');
    const [data, setData] = useState();
    const [params, setParams] = useState({ page: 1, filter: [] });

    const [ruleSetting, setRuleSetting] = useState({ data: [], meta: [] })
    const [userSetting, setUserSetting] = useState({ data: [], meta: [] })
    const [roles, setRoles] = useState({ data: [], meta: [] })
    const [rolePages, setRolePages] = useState({ data: [], meta: [] })
    const [attempt, setAttempt] = useState({ data: [], meta: [] })
    const [template, setTemplates] = useState({ data: [], meta: [] })

    const handleCurrentPage = (current) => {
        setParams({ page: 1 })
        setCurrentPage(current)
        localStorage.setItem('current_page', current);
    }

    const handleCurrentSlider = (current, id) => {
        if (current && current.status) {
            setCurrentSlider({ status: false, current: current.current, id })
            setTimeout(() => {
                setCurrentSlider({ status: true, current: current.current, id })
            }, 100);
        } else {
            setCurrentSlider({ ...currentSlider, status: false, id })
            setTimeout(() => {
                setCurrentSlider({ status: false, current: null, id })
            }, 500);
        }
    }

    const getRuleSetting = async () => {
        await HttpRequests.getRuleSetting(params)
            .then(res => {
                setRuleSetting({ data: res.data, filter: res.filter, meta: res.meta })
                console.log(res)
            })
            .catch(notify)
    }
    const showRuleSetting = async (id) => {
        return await HttpRequests.showRuleSetting(id)
            .then(res => {
                return res.data
            })
            .catch(notify)
    }
    const createRuleSetting = async (body) => {
        if (!body.is_active) body.is_active = false;
        await HttpRequests.createRuleSetting(body)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getRuleSetting)
            .catch(notify)
    }
    const updateRule = (id) => async (data) => {
        if (!data.is_active) data.is_active = false;
        await HttpRequests.updateRuleSetting(id, data)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getRuleSetting)
            .catch(notify)
    }
    const updateRuleStatus = async (id, data) => {
        await HttpRequests.updateStatusRuleSetting(id, data)
            .then(notify)
            .then(getRuleSetting)
            .catch(notify)
    }
    const deleteRule = async (id) => {
        const valid = window.confirm('Anda yakin menghapus data ini?')
        if (!valid) return;
        await HttpRequests.deleteRuleSetting(id)
            .then(notify)
            .then(getRuleSetting)
            .catch(notify)
    }

    const getUserSetting = async () => {
        await HttpRequests.getUserSetting(params)
            .then(res => {
                setUserSetting({ data: res.data, filter: res.filter, total_user: res.total_user, meta: res.meta })
            })
            .catch(notify)
    }

    const showUserSetting = async (id) => {
        return await HttpRequests.showUserSetting(id)
            .then(res => {
                console.log(res);
                return res.data;
            })
            .catch(notify)
    }

    const updateUserStatusSetting = async (id, data) => {
        return await HttpRequests.updateUserStatusSetting(id, data)
            .then(notify)
            .then(getUserSetting)
            .catch(notify)
    }

    const createUserSetting = async (body) => {
        const formData = new FormData();
        if (body.photo)
            formData.append("photo", body.photo[0]);
        formData.append("name", body.name);
        formData.append("email", body.email);
        formData.append("password", body.password);
        formData.append("status", body.status);
        formData.append("roles_id", body.roles_id ?? '');
        formData.append("managers_id", body?.managers_id ?? '');
        await HttpRequests.createUserSetting(formData)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getUserSetting)
            .catch(notify)
    }
    const updateUserSetting = (id) => async (data) => {
        const formData = new FormData();
        if (data.photo)
            formData.append("photo", data.photo[0]);
        formData.append("delete_photo", data.delete_photo ? 1 : 0);
        formData.append("name", data.name);
        formData.append("email", data.email);
        if (data.password)
            formData.append("password", data.password);
        formData.append("status", data.status);
        formData.append("roles_id", data.roles_id ?? '');
        formData.append("managers_id", data.managers_id ?? '');
        await HttpRequests.updateUserSetting(id, formData)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getUserSetting)
            .catch(notify)
    }
    const deleteUserSetting = async (id) => {
        const valid = window.confirm('Anda yakin menghapus data ini?')
        if (!valid) return;
        await HttpRequests.deleteUserSetting(id)
            .then(notify)
            .then(getUserSetting)
            .catch(notify)
    }

    const getAttemptSetting = async () => {
        await HttpRequests.getAttemptSetting(params)
            .then(res => {
                setAttempt({ data: res.data, filter: res.filter, meta: res.meta })
                console.log(res)
            })
            .catch(notify)
    }
    const showAttemptSetting = async (id) => {
        return await HttpRequests.showAttemptSetting(id)
            .then(res => {
                return res.data
            })
            .catch(notify)
    }
    const createAttemptSetting = async (body) => {
        await HttpRequests.createAttemptSetting(body)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getAttemptSetting)
            .catch(notify)
    }
    const updateAttempt = (id) => async (data) => {
        await HttpRequests.updateAttemptSetting(id, data)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getAttemptSetting)
            .catch(notify)
    }
    const deleteAttempt = async (id) => {
        const valid = window.confirm('Anda yakin menghapus data ini?')
        if (!valid) return;
        await HttpRequests.deleteAttemptSetting(id)
            .then(notify)
            .then(getAttemptSetting)
            .catch(notify)
    }

    const getRoles = async () => {
        await HttpRequests.getRoles(params)
            .then(res => {
                console.log(res);
                setRoles({ data: res.data, filter: res.filter, meta: res.meta })
            })
            .catch(notify)
    }

    const showRole = async (id) => {
        return await HttpRequests.showRole(id)
            .then(res => {
                return res.data
            })
            .catch(notify)
    }
    const createRole = async (body) => {
        await HttpRequests.createRole(body)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getRoles)
            .catch(notify)
    }
    const updateRole = async (body) => {
        await HttpRequests.updateRole(currentSlider?.id, body)
            .then(notify)
            .then(() => handleCurrentSlider({ status: false, current: null }))
            .then(getRoles)
            .catch(notify)
    }
    const removeRole = async (id) => {
        if (window.confirm('Anda yakin ingin menghapus data ini?'))
            return await HttpRequests.deleteRole(id)
                .then(notify)
                .then(getRoles)
                .catch(notify)
    }

    const searchUser = async (e, grade) => {
        return HttpRequests.searchManager({ search: e.target.value, grade })
            .then(e => e.data)
            .catch(notify)
    }
    const searchRoleSetting = async (e) => {
        return HttpRequests.searchRoleSetting({ search: e.target.value })
            .then(e => e.data)
            .catch(notify)
    }
    const searchRolePage = async (e) => {
        return HttpRequests.searchRolePage({ search: e.target.value })
            .then(e => e.data)
            .catch(notify)
    }
    const getFields = async () => {
        return await HttpRequests.getFields()
            .catch(notify)
    }
    const createTemplate = async (value) => {
        // return console.log(value)
        await HttpRequests.createTemplate(value)
            .then(notify)
            .then(() => handleCurrentSlider({ current: null, status: false }))
            .then(getTemplate)
            .catch(notify)
    }
    const getTemplate = async () => {
        await HttpRequests.getTemplate(params).then(setTemplates).catch(notify)
    }
    const getDetailTemplate = async (id) => {
        return await HttpRequests.getDetailTemplate(id)
    }
    const updateTemplate = async (data) => {
        return await HttpRequests.updateTemplate(currentSlider?.id, data)
            .then(notify)
            .then(() => handleCurrentSlider({ current: null, status: false }))
            .then(getTemplate)
            .catch(notify)
    }
    const deleteTemplate = async (id) => {
        if (window.confirm('Anda yakin ingin menghapus data ini?'))
            return await HttpRequests.deleteTemplate(id)
                .then(notify)
                .then(() => handleCurrentSlider({ current: null, status: false }))
                .then(getTemplate)
                .catch(notify)
    }
    return <SettingsContext.Provider value={{
        data, setData, params, setParams,
        handleCurrentPage, currentPage, setCurrentPage,
        handleCurrentSlider, currentSlider, setCurrentSlider,

        ruleSetting, setRuleSetting, getRuleSetting, createRuleSetting, showRuleSetting, updateRule, updateRuleStatus, deleteRule,
        userSetting, setUserSetting, getUserSetting, createUserSetting, showUserSetting, updateUserSetting, deleteUserSetting, updateUserStatusSetting,
        attempt, setAttempt, createAttemptSetting, showAttemptSetting, getAttemptSetting, updateAttempt, deleteAttempt,
        roles, setRoles, getRoles, createRole, showRole, removeRole, updateRole,

        searchUser, searchRoleSetting, searchRolePage,
        getFields, createTemplate, getTemplate, template, setTemplates, getDetailTemplate, updateTemplate, deleteTemplate
    }}> {props.children}</SettingsContext.Provider >;
};

const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('userSettings must be used within a SettingsProvider')
    }
    return context;
}

export { SettingsProvider, useSettings };